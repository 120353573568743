import Box from '@mui/material/Box'
import { useRouter, useI18n, LeftMenu } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import {
  isCompanyAdminSelector,
  landdevSelector,
  sharedSelector,
} from '../../../Login'
import useAutoCloseSidebar from '../../../../hooks/useAutoCloseSidebar'
import { getDashboardMenuData } from './DashboardMenu.data'

const DashboardMenu = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const isLandDev = useSelector(landdevSelector)
  const shared = useSelector(sharedSelector)
  const { onCloseSidebar } = useAutoCloseSidebar()

  const menus = getDashboardMenuData(isLandDev, isCompanyAdmin, shared, t)

  const data = menus?.map((menu, i) => ({
    ...menu,
    active: !query.tab ? i === 0 : query.tab === menu.key,
    onClick: () => {
      updateQuery({ tab: menu.key, page: 0, selected: null })
      onCloseSidebar()
    },
  }))

  return (
    <Box width={107} height="100%">
      <LeftMenu data={data as any} />
    </Box>
  )
}

export default DashboardMenu
