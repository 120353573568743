/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Tag, useI18n } from '@popety_io/popety-io-lib'

const FaoHistoryCard = ({ fao, onClick }: any) => {
  const { t } = useI18n()

  return (
    <Box
      sx={{
        padding: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        flexWrap: 'wrap',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: { xs: '100%', lg: '20%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'space-around',
        }}
      >
        <Typography
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 500,
            fontSize: '1.1rem',
          }}
        >
          {t('common.Mise à l’enquête')}
        </Typography>
        {fao.classification === 'development' && (
          <Tag
            color="default"
            style={{
              backgroundColor: '#daf0f7',
              fontWeight: 500,
              fontSize: '9px',
              height: '18px',
              width: 'fit-content',
            }}
          >
            {t('land.fao.developmentProject')}
          </Tag>
        )}
      </Box>
      <Box
        sx={{
          width: { xs: '100%', lg: '80%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'space-around',
          paddingRight: '10px',
          paddingLeft: { xs: '0', lg: '10px' },
          paddingTop: { xs: '10px', lg: '0' },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fao.work_type}
        </Typography>
        <Typography
          sx={{
            color: '#969696',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {fao.work_description}
        </Typography>
      </Box>
    </Box>
  )
}

export default FaoHistoryCard
