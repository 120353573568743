const categories: any = {
  shopping: '#ffff3b',
  transport: '#bebada',
  wellness: '#fb8072',
  grocery: '#81b1d3',
  dining: '#fdb462',
  healthcare: '#b3de69',
  education: '#fccde5',
  culture: '#d9d9d9',
  amenities: '#8dd3c7',
}

const getLivabilityIconStyle = (color: string) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '6px 10px 4px 10px',
  borderRadius: '6px',
  cursor: 'pointer',
  backgroundColor: color,
})

const createLivabilityIcon = (poiIcon: string, size = 14) => {
  const el = document.createElement('div')
  const img = document.createElement('img')

  img.src = `/pin/${poiIcon}.svg`
  img.style.width = `${size}px`
  img.style.height = `${size}px`

  const style: any = getLivabilityIconStyle(categories[poiIcon])

  Object.keys(style).forEach((key) => {
    el.style[key as any] = style[key]
  })

  el.appendChild(img)

  return el
}

const createLivabilityIconPopup = (
  mapboxgl: any,
  feature: any,
  t: Translator,
) => {
  let name = ''

  if (feature.name) {
    // eslint-disable-next-line prefer-destructuring
    name = feature.name
  }

  return new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(
    `<p style="font-size: 16px;font-weight: 500;">${t(
      `land.livability.type.${feature.type}`,
    )}</p>
     <p>${t(`land.livability.subCategory.${feature.subcategory}`)}</p>
     <p>${name}</p>`,
  )
}

export {
  getLivabilityIconStyle,
  createLivabilityIcon,
  createLivabilityIconPopup,
}
