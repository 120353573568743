import { createSelector } from '@reduxjs/toolkit'

const estimateSelector = createSelector(
  (state) => state.estimateSummary.estimate,
  (estimate) => estimate || {},
)

const estimateGeoCenterSelector = createSelector(
  estimateSelector,
  (estimate) => estimate.geo_center,
)

const estimateGeoCenterStrSelector = createSelector(
  estimateSelector,
  (estimate) =>
    estimate.geo_center?.lon &&
    estimate.geo_center?.lat &&
    `${estimate.geo_center?.lon},${estimate.geo_center?.lat}`,
)

const dwellingsSelector = createSelector(
  estimateSelector,
  (estimate) =>
    estimate.buildings_administrative
      ?.flatMap((ba) => ba.addresses?.flatMap((a) => a.dwellings))
      .filter(Boolean) || [],
)

const landRegionSelector = (state) => state.estimateSummary.land?.region_name

const estimateLoadingSelector = (state) => state.estimateSummary.loading

const landGeocenterSelector = (state) => state.estimateSummary.land?.geo_center

const summaryMapSelectedLayersSelector = (state) =>
  state.estimateSummary.selectedLayers

const groupingIdSelector = (state) => state.estimateSummary.land?.groupingId

const geoCenterSelector = (state) => state.estimateSummary.land?.geo_center

const rdppfsSelector = (state) => state.estimateSummary.land?.rdppfs

const estimateLands = createSelector(
  estimateSelector,
  (estimate) => estimate.lands,
)

const estimateIdSelector = createSelector(
  estimateSelector,
  (estimate) => estimate.estimateId,
)

const estimateEditedSelector = createSelector(
  (state) => state.estimateSummary.estimateEdited,
  (estimateEdited) => estimateEdited || {},
)

const estimateEditedPicturesSelector = createSelector(
  estimateEditedSelector,
  (estimateEdited) => estimateEdited.pictures || [],
)

const imagesAreLoadingSelector = (state) =>
  state.estimateSummary.imagesAreLoading

const dwellingSelectedSelector = (state) =>
  state.estimateSummary.dwellingSelected

const loadEstimationSelector = (state) => state.estimateSummary.loadEstimation

const loadEditedSelector = (state) => state.estimateSummary.loadEdited

const loadSavePublicEstimateSelector = (state) =>
  state.estimateSummary.loadSavePublicEstimate

const estimateShareValueSelector = createSelector(
  estimateSelector,
  (estimate) => estimate.share,
)

const estimateHistorySelector = (state) => state.estimateSummary.estimateHistory

const loadHistorySelector = (state) => state.estimateSummary.loadHistory

const estimateLandBuildingsSelector = (state) =>
  state.estimateSummary.landBuildings

const loadBuildingsSelector = (state) => state.estimateSummary.loadLandBuildings

export {
  estimateSelector,
  estimateLoadingSelector,
  landRegionSelector,
  groupingIdSelector,
  summaryMapSelectedLayersSelector,
  landGeocenterSelector,
  geoCenterSelector,
  rdppfsSelector,
  estimateGeoCenterSelector,
  estimateGeoCenterStrSelector,
  dwellingsSelector,
  estimateLands,
  estimateIdSelector,
  estimateEditedSelector,
  dwellingSelectedSelector,
  loadEstimationSelector,
  loadEditedSelector,
  estimateEditedPicturesSelector,
  imagesAreLoadingSelector,
  loadSavePublicEstimateSelector,
  estimateShareValueSelector,
  estimateHistorySelector,
  loadHistorySelector,
  estimateLandBuildingsSelector,
  loadBuildingsSelector,
}
