/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { Fragment, FC, useState } from 'react'
import {
  Button,
  useI18n,
  ellipsis,
  InfoTooltip,
  EditableText,
} from '@popety_io/popety-io-lib'
import { styled } from '@mui/material/styles'
import ApartmentIcon from '@mui/icons-material/Apartment'
import HouseIcon from '@mui/icons-material/House'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Grid,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import LandInfo from '../Summary/LandInfo'
import { AdditionalButton, DesignCompleteRoot } from './Design.style'
import { ButtonDesignStepOne } from './DesignStepOne.style'
import {
  DesignCompleteDay,
  DesignCompleteDesc,
  DesignCompleteInfo,
  DesignCompleteTop,
} from './DesignComplete.style'

interface IProps {
  goBack: () => void
  project: any
}

const ExpandMore = styled((props: any) => {
  const { ...other } = props

  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const DesignComplete: FC<IProps> = ({ goBack, project }) => {
  // const [buildings, setBuildings] = useState([])
  const [expanded, setExpanded] = useState(false)
  const { t } = useI18n()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const actions = [
    {
      id: '1',
      label: 'Ground Area',
      defaultValue: '285',
      estimatedValue: '285',
      endAdornment: 'm2',
    },
    {
      id: '2',
      label: 'Floors',
      defaultValue: '4',
    },
    {
      id: '3',
      label: 'Floor Area',
      value: '1490',
      defaultValue: '1490',
      estimatedValue: '1490',
      endAdornment: 'm2',
    },
    {
      id: '4',
      label: 'Units',
      value: '9',
      defaultValue: '9',
    },
    {
      id: '5',
      label: 'Const. year',
      value: '1994-2005',
      variant: 'yearRange',
      defaultValue: '1994-2005',
      estimatedValue: '1994-2005',
    },
  ]

  const Icon =
    project.type === 'singleHouse' ? (
      <ButtonDesignStepOne sx={{ maxHeight: 90 }}>
        <HouseIcon sx={{ fontSize: 28 }} />
        <Typography component="span">
          {t('land.design.singleHourse')}
        </Typography>
      </ButtonDesignStepOne>
    ) : project.type === 'multiFamilyHouse' ? (
      <ButtonDesignStepOne sx={{ maxHeight: 90 }}>
        <HolidayVillageIcon sx={{ fontSize: 28 }} />
        <Typography component="span">
          {t('land.design.multiFamilyHourse')}
        </Typography>
      </ButtonDesignStepOne>
    ) : (
      <ButtonDesignStepOne sx={{ maxHeight: 90 }}>
        <ApartmentIcon sx={{ fontSize: 28 }} />
        <Typography component="span">{t('land.design.apartments')}</Typography>
      </ButtonDesignStepOne>
    )

  return (
    <DesignCompleteRoot>
      <Button className="backButton" onClick={goBack}>
        <ArrowBackIosIcon sx={{ fontSize: 12 }} />
        {t('land.design.backToProjectList')}
      </Button>

      <Box className="designComplete_info">
        <DesignCompleteInfo>
          <DesignCompleteTop>
            <Typography variant="h3">
              {t('financial.Projet')} 1: “villa individuelle”
            </Typography>
            <EditIcon sx={{ fontSize: 16, color: 'GrayText' }} />
          </DesignCompleteTop>
          <DesignCompleteDesc>
            Projet d’un immeuble résidentiel de 5 étages composés de studios et
            de logement d’une chambre. Possibilité minergie et pompe à chaleur.
            Voir avec l’architecte les détails concernant la possiblité d’un
            toit plat sur ce type d’appartement
          </DesignCompleteDesc>
          <DesignCompleteDay>
            <Box className="design-day">
              {t('land.design.createdTime', { count: 5 })}
            </Box>
            <Box className="desgin-author">Ishak Leghlam</Box>
          </DesignCompleteDay>
          <DesignCompleteDay>
            <Box className="design-day">
              {t('land.design.lastModifiedTime', { count: 2 })}
            </Box>
            <Box className="desgin-author">Thibault Clément</Box>
          </DesignCompleteDay>
        </DesignCompleteInfo>
      </Box>
      <LandInfo />

      <Box sx={{ padding: '16px' }}>
        <Typography variant="h3">{t('land.building')}</Typography>
        <Card>
          <CardHeader
            title={
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                }}
              >
                <Box display="flex" alignItems="center">
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>

                {Icon}

                <Box>
                  <Grid container className="ProjectActions">
                    {actions.map((action) => (
                      <Fragment key={action.id}>
                        <Grid item xs={6}>
                          <Typography
                            color="text.secondary"
                            fontSize="0.923rem"
                            fontWeight={500}
                          >
                            {ellipsis(action.label as string, 14)}
                          </Typography>
                        </Grid>

                        <InfoTooltip infos={[]}>
                          <Grid item xs={5}>
                            <EditableText
                              edit={false}
                              value={
                                action.estimatedValue ??
                                action.defaultValue ??
                                ''
                              }
                              endAdornment={action.endAdornment}
                              fontSize="0.923rem"
                              fontWeight={500}
                            />
                          </Grid>
                        </InfoTooltip>
                      </Fragment>
                    ))}
                  </Grid>
                </Box>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <EditIcon sx={{ fontSize: 16, color: 'GrayText' }} />
                    <span style={{ fontSize: 12, color: '#969696' }}>
                      Modify
                    </span>
                  </Box>
                  <Box display="flex" alignItems="center" gap={2}>
                    <EditIcon sx={{ fontSize: 16, color: 'GrayText' }} />
                    <span style={{ fontSize: 12, color: '#969696' }}>
                      {t('common.move')}
                    </span>
                  </Box>
                  <Box display="flex" alignItems="center" gap={2}>
                    <EditIcon sx={{ fontSize: 16, color: 'GrayText' }} />
                    <span style={{ fontSize: 12, color: '#969696' }}>
                      Rotate
                    </span>
                  </Box>
                  <Box display="flex" alignItems="center" gap={2}>
                    <EditIcon sx={{ fontSize: 16, color: 'GrayText' }} />
                    <span style={{ fontSize: 12, color: '#969696' }}>
                      Scale
                    </span>
                  </Box>
                </Box>
              </div>
            }
          />

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent sx={{ backgroundColor: '#fafafa' }}>
              <Typography paragraph sx={{ color: 'rgba(0, 173, 230, 1)' }}>
                {t('land.design.add_other_information')}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      </Box>

      <AdditionalButton sx={{ paddingY: 2 }}>
        + {t('land.design.addANewBuildingManually')}
      </AdditionalButton>

      <Typography variant="h3">{t('land.design.othersObject')}</Typography>
      <AdditionalButton>+ {t('land.design.addAnotherObject')}</AdditionalButton>
    </DesignCompleteRoot>
  )
}

export default DesignComplete
