import { useEffect, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { useI18n } from '@popety_io/popety-io-lib'
import { saveAnalyticData } from '../../redux'
import { getConfidenceScore } from '../../../../utils'
import {
  BulletContainer,
  BulletItem,
  BulletToolTipContainer,
  BulletPopper,
} from './ListingAnalyticCard.style'

const colors = {
  green: 'rgba(127, 188, 83, 0.7)',
  red: 'rgba(177, 73, 69, 0.7)',
  orange: 'rgb(251, 190, 100)',
}

export type BulletProps = {
  priceData: any[]
  dealType: string
}

const Bullet = ({ priceData, dealType }: BulletProps) => {
  const score = getConfidenceScore(priceData)
  const { t } = useI18n()

  let color = colors.red

  if (score === 3) {
    color = colors.green
  }

  if (score === 2) {
    color = colors.orange
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event: Record<string, any>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  useEffect(() => {
    saveAnalyticData({ confidenceScore: score }, dealType)
  }, [priceData, score, dealType])

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <BulletContainer onMouseOver={handleOpen} onMouseLeave={handleClose}>
          <BulletItem {...({ color } as any)}> </BulletItem>
          <BulletItem
            {...({} as any)}
            color={score === 2 || score === 3 ? color : undefined}
          >
            {' '}
          </BulletItem>
          <BulletItem {...({} as any)} color={score === 3 ? color : undefined}>
            {' '}
          </BulletItem>
        </BulletContainer>
      </ClickAwayListener>

      <BulletPopper id={id} open={open} anchorEl={anchorEl}>
        <BulletToolTipContainer variant="outlined">
          <span>{t('common.listing.bullet.text')}</span>
          <div>
            <BulletContainer>
              <BulletItem {...({} as any)} color={colors.green}>
                {' '}
              </BulletItem>
              <BulletItem {...({} as any)} color={colors.green}>
                {' '}
              </BulletItem>
              <BulletItem {...({} as any)} color={colors.green}>
                {' '}
              </BulletItem>
            </BulletContainer>
            <span>{t('common.listing.bullet.green')}</span>
          </div>
          <div>
            <BulletContainer>
              <BulletItem {...({} as any)} color={colors.orange}>
                {' '}
              </BulletItem>
              <BulletItem {...({} as any)} color={colors.orange}>
                {' '}
              </BulletItem>
              <BulletItem {...({} as any)}> </BulletItem>
            </BulletContainer>
            <span>{t('common.listing.bullet.orange')}</span>
          </div>
          <div>
            <BulletContainer>
              <BulletItem {...({} as any)} color={colors.red}>
                {' '}
              </BulletItem>
              <BulletItem {...({} as any)}> </BulletItem>
              <BulletItem {...({} as any)}> </BulletItem>
            </BulletContainer>
            <span>{t('common.listing.bullet.red')}</span>
          </div>
        </BulletToolTipContainer>
      </BulletPopper>
    </>
  )
}

export default Bullet
