import {
  EventHistoryLegend,
  EventHistoryController,
} from '../../../../components/EventHistory'

export { default } from './EstimateEventHistory'

export const EstimateEventHistoryMapConfig = {
  Content: EventHistoryLegend,
  Controller: EventHistoryController,
}
