import EnergyLegend from './EnergyLegend'

const EnergyMapContent = () => {
  return (
    <div>
      <EnergyLegend />
    </div>
  )
}

export default EnergyMapContent
