/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { iterateClassificationsOverTime } from '../stats.utils'

import listingService from './listingService'

const initialState = {
  listings: [],
  listingAround: [],
  listingRange: [2008, new Date().getFullYear()],
  selectedEstimateListings: [],
  rent: true,
  purchase: true,
  count: {},
  aggregations: {},
  houseAggs: {},
  apartmentAggs: {},
  loading: true,
}

export const getListingsByIds = createAsyncThunk(
  'listing/getListingsByIds',
  listingService.getListingsByIds,
)

export const getLandListings = createAsyncThunk(
  'listing/getLandListings',
  listingService.getLandListings,
)

export const getListingAround = createAsyncThunk(
  'listing/getListingAround',
  listingService.getListingAround,
)

export const countLandListings = createAsyncThunk(
  'listing/countLandListings',
  listingService.countLandListings,
)

export const getListingAggs = createAsyncThunk(
  'listing/getListingAggs',
  listingService.getListingAggs,
)

export const getApartmentListingAggs = createAsyncThunk(
  'listing/getApartmentListingAggs',
  listingService.getApartmentListingAggs,
)

export const getHouseListingsAggs = createAsyncThunk(
  'listing/getHouseListingsAggs',
  listingService.getHouseListingsAggs,
)

export const getSelectedEstimateListingsByIds = createAsyncThunk(
  'listing/getSelectedEstimateListingsByIds',
  listingService.getSelectedEstimateListingsByIds,
)

const listing = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    updateListingOptions: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandListings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.listings = data?.result?.filter(Boolean) || []
      })
      .addCase(getListingsByIds.fulfilled, (state, action) => {
        const { data } = action.payload || []

        state.listings = data?.filter(Boolean) || []
      })
      .addCase(getSelectedEstimateListingsByIds.fulfilled, (state, action) => {
        const { data } = action.payload || []

        state.selectedEstimateListings = data?.filter(Boolean) || []
      })
      .addCase(countLandListings.fulfilled, (state, action) => {
        const { data = 0 } = action.payload || {}
        const { dealType } = action.meta.arg

        state.count = { ...state.count, [dealType]: data }
      })
      .addCase(getListingAround.pending, (state) => {
        state.loading = true
      })
      .addCase(getListingAround.rejected, (state) => {
        state.loading = false
      })
      .addCase(getListingAround.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.listingAround = data?.result

        if (data?.result?.length) {
          const minListingDate = Math.min(
            ...data.result.map((l) => new Date(l.listing_timestamp)),
          )

          state.listingRange = [
            new Date(minListingDate).getFullYear(),
            new Date().getFullYear(),
          ]
        }
        state.loading = false
      })
      .addCase(getListingAggs.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.stats = iterateClassificationsOverTime(data?.aggregations)

        state.aggregations = data?.aggregations || {}
      })
      .addCase(getApartmentListingAggs.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.apartmentAggs = { ...data?.aggregations, isLoading: false } || {
          isLoading: false,
        }
      })
      .addCase(getApartmentListingAggs.pending, (state) => {
        state.apartmentAggs.isLoading = true
      })
      .addCase(getHouseListingsAggs.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.houseAggs = { ...data?.aggregations, isLoading: false } || {
          isLoading: false,
        }
      })
      .addCase(getHouseListingsAggs.pending, (state) => {
        state.houseAggs.isLoading = true
      })
      .addCase(getHouseListingsAggs.rejected, (state) => {
        state.houseAggs.isLoading = false
      })
  },
})

export const listingReducer = {
  listing: listing.reducer,
}

export const { updateListingOptions } = listing.actions
