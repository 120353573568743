export type BuildingHistoryMenuProps = {
  id?: string
  archive?: boolean
}

const buildingColor = (classification: string) => {
  switch (classification) {
    case 'Retail':
      return '#D3B39C'
    case 'Residential':
      return '#F9D518'
    case 'Public':
      return '#8C8C8C'
    case 'Other':
      return '#CCCCCC'
    case 'Offices':
      return '#9ecae1'
    case 'Not classified':
      return '#CCCCCC'
    case 'Mixed_2':
      return '#F3733C'
    case 'Mixed_1':
      return '#F3A83C'
    case 'Industrial':
      return '#C39EF9'
    case 'Garage':
      return '#bbbbbb'
    default:
      return '#F3733C'
  }
}

const getMultipleLayers = (element: Record<string, any>) => {
  const building =
    element?.value?.geo_polygon || element?.default_value?.estimate?.geo_polygon
  const estimate = element?.value?.estimate || element?.default_value?.estimate

  if (!building) return []

  const buildingPolygon = building && {
    geometry: building,
    type: 'Feature',
    properties: {
      stroke: '#000',
      'stroke-width': 2,
      'stroke-opacity': 1,
      fill: buildingColor(estimate.popety_classification),
      'fill-opacity': 0.4,
      height: estimate?.height || 3,
    },
  }

  const GeoJSON = {
    type: 'FeatureCollection',
    features: [buildingPolygon].filter(Boolean),
  }

  return GeoJSON
}

const handleOpenLink = ({ id }: BuildingHistoryMenuProps) => {
  let url = '?tab=summary'

  if (id) {
    const buildingIds = Array.isArray(id) ? id.join(',') : id

    url += `&buildingId=${buildingIds}`
  }

  window.open(url, '_blank')
}

export { getMultipleLayers, handleOpenLink }
