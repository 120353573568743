import { useEffect } from 'react'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { Box, Tooltip } from '@mui/material'
import { noiseOptions } from '../../../../../../utils/thematic/glossary/noiseOptions'
// import SelectorLegend from '../../../../../../components/SelectorLegend/SelectorLegend'
import IconWIthLegends from '../../../../../../components/IconWIthLegends'
import { TitleRoot } from '../../../../../../components/Legend/Legend.style'

const NoiseMapContent = () => {
  const { t } = useI18n()
  const options = noiseOptions(t)
  const { query, updateQuery } = useRouter()

  const onChange = (event: any) => {
    const { value } = event

    updateQuery({ noiseLayer: value })
  }

  useEffect(() => {
    updateQuery({ displayNoiseLegend: true })
    updateQuery({ mapLg: 'noise-legend' })

    return () => {
      updateQuery({ displayNoiseLegend: undefined })
      updateQuery({ mapLg: undefined })
    }
  }, [])

  return (
    <div>
      <IconWIthLegends
        id="noise-legend"
        options={options}
        value={query.noiseLayer || 'noise-road-day-v1'}
        keyValue="noiseLayer"
        displayValue="displayNoiseLegend"
        onChange={onChange}
      >
        <Box
          sx={{ overflowY: 'auto', maxHeight: 500, padding: '8px' }}
          display="flex"
          flexDirection="column"
          gap="4px"
          className="print-legend"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            sx={{
              flexDirection: 'column',
            }}
          >
            {options?.[0]?.legend?.map((item: any, i: number) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <TitleRoot color={item.color} />
                <Tooltip title={item.value}>
                  <span>{item.value}</span>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
      </IconWIthLegends>
    </div>
  )
}

export default NoiseMapContent
