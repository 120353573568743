/* eslint-disable max-lines */
import { useState, useMemo, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getYear } from 'date-fns'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import {
  EventHistory,
  EventHistoryHeader,
  EventHistorySkeleton,
  EventHistoryDetails,
  EVENT_HISTORY_CATEGORY,
} from '../../../../components/EventHistory'
import {
  landTransactionSetSelector,
  landFaosSelector,
  landTransactionsLoadingSelector,
  landFaosLoadingSelector,
  landListingsSelector,
  landListingsLoadingSelector,
} from '../../../LandDetails'
import {
  estimateSelector,
  saveEditedEstimate,
  estimateIdSelector,
  estimateEditedSelector,
  estimateLoadingSelector,
} from '../../redux'

const EstimateEventHistory = () => {
  const { t } = useI18n()
  const dispatch: any = useDispatch()
  const { updateQuery } = useRouter()

  const [event, setEvent] = useState<any>({})
  const [checked, setChecked] = useState<any[]>([
    { key: EVENT_HISTORY_CATEGORY.FAO, checked: true },
    { key: EVENT_HISTORY_CATEGORY.TRANSACTION, checked: true },
    { key: EVENT_HISTORY_CATEGORY.RENTAL, checked: true },
    { key: EVENT_HISTORY_CATEGORY.SALE, checked: true },
  ])

  const estimate: any = useSelector(estimateSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const estimateIsLoading = useSelector(estimateLoadingSelector)
  const estimateId = useSelector(estimateIdSelector)
  const transactions = useSelector(landTransactionSetSelector)
  const faos = useSelector(landFaosSelector)
  const listings = useSelector(landListingsSelector)
  const transactionsAreLoading = useSelector(landTransactionsLoadingSelector)
  const faosAreLoading = useSelector(landFaosLoadingSelector)
  const listingsAreLoading = useSelector(landListingsLoadingSelector)

  const regionName: any = estimate?.region_name
  const filteredTransactions = regionName === 'Genève' ? transactions : []

  const rentListings = useMemo(() => {
    return listings?.filter((listing: any) => listing?.deal_type === 'rent')
  }, [listings])

  const purchaseListings = useMemo(() => {
    return listings?.filter((listing: any) => listing?.deal_type === 'purchase')
  }, [listings])

  const transactionList = useMemo(() => {
    return [...filteredTransactions].sort((a, b) => (a.date > b.date ? -1 : 1))
  }, [filteredTransactions])

  const loading = useMemo(() => {
    return (
      transactionsAreLoading ||
      faosAreLoading ||
      listingsAreLoading ||
      estimateIsLoading
    )
  }, [
    transactionsAreLoading,
    faosAreLoading,
    estimateIsLoading,
    listingsAreLoading,
  ])

  const listEventHistory = useMemo(() => {
    const data = [
      ...transactionList.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.TRANSACTION,
        ...item,
      })),
      ...faos.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.FAO,
        date: item?.inquiry_start_date,
        ...item,
      })),
      ...rentListings.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.RENTAL,
        date: item?.listing_timestamp,
        ...item,
      })),
      ...purchaseListings.map((item: any) => ({
        type: EVENT_HISTORY_CATEGORY.SALE,
        date: item?.listing_timestamp,
        ...item,
      })),
    ]?.filter(
      (item: any) =>
        checked?.find((check) => check?.key === item?.type)?.checked,
    )

    return data.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    )
  }, [checked, transactionList, faos, rentListings, purchaseListings])

  useEffect(() => {
    if (estimateEdited?.historySections) {
      setChecked([
        {
          key: EVENT_HISTORY_CATEGORY.FAO,
          checked: estimateEdited?.historySections?.Fao !== false,
        },
        {
          key: EVENT_HISTORY_CATEGORY.TRANSACTION,
          checked: estimateEdited?.historySections?.Transaction !== false,
        },
        {
          key: EVENT_HISTORY_CATEGORY.RENTAL,
          checked: estimateEdited?.historySections?.Rental !== false,
        },
        {
          key: EVENT_HISTORY_CATEGORY.SALE,
          checked: estimateEdited?.historySections?.Sale !== false,
        },
      ])
    }
  }, [estimateEdited])

  useEffect(() => {
    if (event?.date) {
      const year = new Date(event.date).getFullYear()

      updateQuery({
        historyLayer: year === getYear(new Date()) ? year - 1 : year,
      })
    } else {
      updateQuery({ historyLayer: getYear(new Date()) - 1 })
    }
  }, [event])

  const handleChange = (key: string) => {
    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: {
          ...estimateEdited,
          historySections: {
            ...estimateEdited?.historySections,
            [key]: !checked.find((item) => item.key === key)?.checked,
          },
        },
      }),
    )
    setChecked((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.key === key ? !item.checked : item.checked,
      })),
    )
  }

  const handleSelectEvent = (event: any) => {
    setEvent(event)
  }

  const countEvent = 10

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <EventHistoryHeader
          countEvent={countEvent}
          event={event}
          handleChange={handleChange}
          loading={loading}
          checked={checked}
          regionName={regionName}
          faos={faos}
          rentListings={rentListings}
          purchaseListings={purchaseListings}
          transactionList={transactionList}
        />
        {loading && <EventHistorySkeleton />}

        <EventHistoryDetails event={event} setEvent={setEvent} />

        {!loading && (!event || Object.keys(event)?.length === 0) && (
          <EventHistory
            listEventHistory={listEventHistory}
            checked={checked}
            handleClickSeeMore={() => {}}
            event={event}
            handleSelectEvent={handleSelectEvent}
            sx={{
              display: !(event?.id && event?._id) ? '' : 'none',
              marginLeft: '-9px',
            }}
          />
        )}
        {!loading && listEventHistory && listEventHistory?.length === 0 && (
          <Typography sx={{ textAlign: 'center', marginTop: '20px' }}>
            {t('common.no_data_available')}
          </Typography>
        )}
      </Box>
    </div>
  )
}

export default EstimateEventHistory
