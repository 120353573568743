import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { estimateEditedSelector, estimateIdSelector } from '../../../redux'

import { getFilesEstimate } from '../../../../DeveloperMap/redux'
import EstimateFilesHeader from './EsitmateFilesHeader'
import EstimateFilesBody from './EstimateFilesBody'

const EstimateFiles = () => {
  const dispatch = useDispatch()
  const estimateId = useSelector(estimateIdSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const [isActive, setIsActive] = useState(false)

  const handleIsActive = (checked: boolean) => {
    setIsActive(checked)
  }

  useEffect(() => {
    if (estimateId) {
      dispatch(getFilesEstimate({ estimateId }) as any)
    }
  }, [estimateId])

  useEffect(() => {
    if (estimateEdited?.isFiles === undefined) {
      setIsActive(true)
    } else {
      setIsActive(!!estimateEdited?.isFiles)
    }
  }, [estimateEdited?.isFiles])

  return (
    <Box
      id="estimate-files"
      sx={{
        marginTop: '20px',
      }}
    >
      <EstimateFilesHeader active={isActive} handleIsActive={handleIsActive} />
      {isActive && <EstimateFilesBody />}
    </Box>
  )
}

export default EstimateFiles
