import { styled } from '@mui/material/styles'

export const RestrictionUnAffectedContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-top: 16px;
`

export const ListItem = styled('div')`
  padding: 12px 16px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
`
