import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

export const DesignCompleteTop = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 1.077rem;
    color: #00ade6;
    text-transform: uppercase;
    font-weight: 500;
  }
`

export const DesignCompleteDesc = styled(Typography)`
  font-size: 0.923rem;
  font-weight: 300;
  color: #969696;
`
export const DesignCompleteInfo = styled(Box)`
  margin-bottom: 1.5rem;
`

export const DesignCompleteDay = styled(Box)`
  font-size: 0.615rem;
  color: #041725;
  display: flex;
  gap: 5px;
  .design-day {
    opacity: 0.8;
  }
  .desgin-author {
    font-weight: 700;
  }
`
