import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useI18n } from '@popety_io/popety-io-lib'
import Divider from '@mui/material/Divider'

const EstimateLivabilityHeader = () => {
  const { t } = useI18n()

  return (
    <>
      <Box
        position="sticky"
        top={0}
        pt={2}
        zIndex={10}
        bgcolor="background.paper"
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '4px',
            textTransform: 'uppercase',
          }}
          fontSize="1.7rem"
          fontWeight={500}
        >
          {t('common.landRadarInfo.radarLivability')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#969696',
            paddingBottom: '10px',
          }}
          fontSize="1rem"
        >
          {t('common.commodites.header_description')}
        </Typography>
        <Divider />
      </Box>
    </>
  )
}

export default EstimateLivabilityHeader
