import { Box, Button, styled } from '@mui/material'

export const DesignProjectListRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  h3 {
    color: #041725;
    font-size: 0.923rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`

export const DesignStepTwoRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 8px 24px;

  .constrain-title {
    display: grid;
    grid-template-columns: 240px 72px 60px;
    column-gap: 26px;
    margin-top: 16px;

    p {
      font-size: 0.923rem;
      color: #969696;
    }
  }
`

export const DesignActionButton = styled(Box)`
  display: flex;
  column-gap: 16px;
  margin: 8px 0;

  button {
    text-transform: uppercase;
  }

  button:last-child {
    background-color: #ffffff;
    box-shadow:
      0 0 2px 0 rgba(0, 0, 0, 0.12),
      0 2px 2px 0 rgba(0, 0, 0, 0.24);
    color: #969696;
  }
`
export const DesignStepThreeRoot = styled(Box)`
  padding: 8px 24px;
`
export const AdditionalButton = styled(Button)`
  text-align: start;
  justify-content: start;
  padding: 0;
  text-transform: none;
  font-size: 0.769rem;
`

export const DesignStepFourRoot = styled(Box)`
  padding: 8px;

  .project-list {
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    row-gap: 16px;
    flex-direction: column;

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #666;
    }
  }
`
export const DesignCompleteRoot = styled(Box)`
  .backButton {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;
    font-size: 0.923rem;
    color: #041725;
    padding-left: 0;
    text-transform: none;
  }

  h3 {
    color: rgba(4, 23, 37, 1);
    font-size: 0.923rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 6px 0;
  }

  .designComplete_info {
    display: flex;
    justify-content: space-between;

    h3 {
      color: rgba(0, 173, 230, 1);
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    i {
      color: rgba(150, 150, 150, 1);
      font-size: 0.923rem;
      font-weight: 300;
    }
  }
`
