import { createSelector } from '@reduxjs/toolkit'

const landUsePlan = (state) => state.landuseplan.landUsePlan
const futureLandUsePlan = (state) => state.landuseplan.futureLandUsePlan

const landUsePlanAssignement = (state) =>
  state.landuseplan.landUsePlanAssignement
const futureLandUsePlanAssignement = (state) =>
  state.landuseplan.futureLandUsePlanAssignement

const principalTypesSelector = createSelector(
  (state) => state.landuseplan.principalTypes,
  (principalTypes) => principalTypes.filter((type) => type.principal_type),
)

const overideLandUsePlansSelector = (state) =>
  state.landuseplan.overideLandUsePlans

const futureOverideLandUsePlansSelector = (state) =>
  state.landuseplan.futureOverideLandUsePlans

const loadLandUsePlanSelector = (state) => state.landuseplan.loadLandUsePlan

const loadLandUsePlanAssignementSelector = (state) =>
  state.landuseplan.loadLandUsePlanAssignement

const loadPrincipalTypeSelector = (state) => state.landuseplan.loadPrincipalType

export {
  futureOverideLandUsePlansSelector,
  overideLandUsePlansSelector,
  principalTypesSelector,
  landUsePlan,
  futureLandUsePlan,
  landUsePlanAssignement,
  futureLandUsePlanAssignement,
  loadLandUsePlanSelector,
  loadLandUsePlanAssignementSelector,
  loadPrincipalTypeSelector,
}
