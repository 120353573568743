import { Fragment } from 'react'
import Grid from '@mui/material/Grid'

import { useI18n } from '@popety_io/popety-io-lib'
import RestrictionAccordon from '../RestrictionAccordon'
import ListItem from './ListItem'
import {
  RestrictionAffectedContainer,
  StyledDivider,
} from './RestrictionAffected.style'

const RestrictionAffected = ({ affectedList }: any) => {
  const { t } = useI18n()

  return (
    <RestrictionAffectedContainer>
      {affectedList?.map((affection: any, index: number) => {
        const title = affection?.[0]?.name

        return (
          <RestrictionAccordon
            key={index}
            title={t(`land.restrictions.zone.${title}`)}
          >
            {affection?.map((listItem: any, index: number) => {
              const restrictionInfo = listItem.extra_information_json

              return (
                <Fragment key={index}>
                  <h3>{listItem.title}</h3>
                  <Grid>
                    {affectedList?.length !== 0 &&
                      !!restrictionInfo &&
                      Object?.keys?.(restrictionInfo)?.map((key, idx) => (
                        <ListItem
                          key={idx}
                          title={key}
                          value={restrictionInfo[key]}
                          md={3}
                        />
                      ))}
                  </Grid>
                  {affection.length - 1 !== index && <StyledDivider />}
                </Fragment>
              )
            })}
          </RestrictionAccordon>
        )
      })}
    </RestrictionAffectedContainer>
  )
}

export default RestrictionAffected
