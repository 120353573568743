/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { lazy } from 'react'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'

const AnalyzedBuildings = lazy(() => import('../../AnalyzedBuildings'))

const CompanyAnalyzedLands = lazy(
  () => import('../../CompanyAnalyzedBuildings'),
)

const MyFavorites = lazy(() => import('../../Favorites'))

const CompanyFavorites = lazy(() => import('../../CompanyFavorites'))

const Tags = lazy(() => import('../../Tags'))

const CompanyTags = lazy(() => import('../../CompanyTags'))

const getDashboardMenuData = (
  isLandDev: boolean,
  isCompanyAdmin: boolean,
  shared: {
    sharedFavorite: boolean
    shareAnalysedLands: boolean
    shareTaggedLands: boolean
  },
  t = (k: string) => k,
) => {
  const sharedFavorite = shared.sharedFavorite || isCompanyAdmin
  const shareTaggedLands = shared.shareTaggedLands || isCompanyAdmin
  const shareAnalysedLands = shared.shareAnalysedLands || isCompanyAdmin

  return [
    ...[
      {
        shared: sharedFavorite,
        key: 'myFavorites',
        text: t('dashboard.menu.myFavorite'),
        broker: true,
        icon: <StarOutlineIcon />,
        page: <MyFavorites />,
      },
      {
        shared: !sharedFavorite,
        key: 'companyFavorites',
        text: t('dashboard.menu.companyFavorite'),
        broker: true,
        icon: <StarOutlineIcon />,
        page: <CompanyFavorites />,
      },
    ].filter((subMenu) => !subMenu.shared),
    ...[
      {
        icon: <AssessmentOutlinedIcon />,
        shared: shareAnalysedLands,
        key: 'myAnalysis',
        text: t('dashboard.menu.myAnalysis'),
        page: <AnalyzedBuildings />,
        broker: false,
      },
      {
        icon: <AssessmentOutlinedIcon />,
        shared: !shareAnalysedLands,
        key: 'companyAnalysis',
        text: t('dashboard.menu.companyAnalysis'),
        page: <CompanyAnalyzedLands />,
        broker: false,
      },
    ].filter((subMenu) => !subMenu.shared),
    ...[
      {
        shared: shareTaggedLands,
        key: 'myTags',
        text: t('dashboard.menu.myTags'),
        page: <Tags />,
        icon: <LocalOfferOutlinedIcon />,
        broker: true,
      },
      {
        shared: !shareTaggedLands,
        key: 'companyTags',
        text: t('dashboard.menu.companyTags'),
        page: <CompanyTags />,
        icon: <LocalOfferOutlinedIcon />,
        broker: true,
      },
    ].filter((subMenu) => !subMenu.shared),
  ]?.filter((menu) => isLandDev || (menu?.broker && !isLandDev))
}

export { getDashboardMenuData }
