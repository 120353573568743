/* eslint-disable max-lines */
import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

interface IopProps extends SvgIconProps {
  fillColor?: string
}

const LayersIcon = (props: IopProps) => {
  return (
    <SvgIcon
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 101.78 81.23"
      {...props}
    >
      <defs>
        <clipPath id="clippath">
          <path
            d="M50.69,51.05c-.6,0-1.19-.14-1.74-.41L.39,26.07,48.92,1.43c1.1-.56,2.41-.56,3.52,0l48.94,24.65-48.97,24.58c-.54.27-1.13.41-1.72.41v-.02ZM2.61,26.08l46.8,23.68c.8.41,1.76.41,2.56,0l47.2-23.69L52,2.32c-.82-.41-1.8-.41-2.61,0L2.62,26.07h0Z"
            style={{ fill: 'none', strokeWidth: '0px' }}
          />
        </clipPath>
      </defs>
      <g>
        <path
          d="M92.99,51.58l7.28,3.67-48.06,24.12c-.96.48-2.1.48-3.06,0L1.5,55.25l6.91-3.51"
          style={{ fill: 'none', strokeWidth: '0px' }}
        />
        <path
          d="M50.69,81.22c-.76,0-1.52-.17-2.21-.52L.82,56.58c-.5-.26-.82-.77-.82-1.34s.32-1.08.82-1.34l6.91-3.51c.74-.37,1.64-.08,2.02.66s.08,1.64-.66,2.02l-4.27,2.17,45.01,22.78c.53.27,1.17.27,1.71,0l45.39-22.78-4.62-2.32c-.74-.37-1.04-1.27-.67-2.01.37-.74,1.27-1.04,2.01-.67l7.28,3.67c.51.25.83.77.83,1.34s-.32,1.09-.83,1.34l-48.06,24.12c-.69.34-1.44.52-2.2.52h.02Z"
          style={{ fill: props?.fillColor, strokeWidth: '0px' }}
        />
      </g>
      <g>
        <path
          d="M92.99,37.03l7.28,3.67-48.06,24.12c-.96.48-2.1.48-3.06,0L1.5,40.71l6.91-3.51"
          style={{ fill: 'none', strokeWidth: '0px' }}
        />
        <path
          d="M50.69,66.69c-.76,0-1.52-.18-2.21-.53L.83,42.05c-.5-.26-.82-.77-.82-1.34s.32-1.08.82-1.34l6.91-3.51c.74-.37,1.64-.08,2.02.66s.08,1.64-.66,2.02l-4.27,2.17,45.01,22.78c.53.27,1.17.27,1.71,0l45.39-22.78-4.62-2.32c-.74-.37-1.04-1.27-.67-2.01.37-.74,1.27-1.04,2.01-.67l7.28,3.67c.51.26.83.77.83,1.34s-.32,1.09-.83,1.34l-48.06,24.12c-.69.35-1.44.52-2.2.52h0Z"
          style={{ fill: props?.fillColor, strokeWidth: '0px' }}
        />
      </g>
      <g>
        <path
          d="M50.69,51.05c-.6,0-1.19-.14-1.74-.41L.39,26.07,48.92,1.43c1.1-.56,2.41-.56,3.52,0l48.94,24.65-48.97,24.58c-.54.27-1.13.41-1.72.41v-.02ZM2.61,26.08l46.8,23.68c.8.41,1.76.41,2.56,0l47.2-23.69L52,2.32c-.82-.41-1.8-.41-2.61,0L2.62,26.07h0Z"
          style={{ fill: props?.fillColor, strokeWidth: '0px' }}
        />
        <g style={{ clipPath: 'url(#clippath)' }}>
          <g>
            <circle
              cx="6.87"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="21.55"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="36.22"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="50.89"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="65.56"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="80.23"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="94.91"
              cy="11.59"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="6.87"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="21.55"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="36.22"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="50.89"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="65.56"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="80.23"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="94.91"
              cy="26.51"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="6.87"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="21.55"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="36.22"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="50.89"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="65.56"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="80.23"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
            <circle
              cx="94.91"
              cy="41.44"
              r="1.82"
              style={{ fill: props?.fillColor, strokeWidth: '0px' }}
            />
          </g>
        </g>
      </g>
      <path
        d="M50.69,52.05c-.75,0-1.5-.17-2.19-.52L.82,27.4c-.5-.26-.82-.77-.82-1.34s.32-1.08.82-1.34L48.47.52c1.38-.7,3.03-.7,4.42,0l48.06,24.2c.51.26.83.77.83,1.34s-.32,1.09-.83,1.34l-48.08,24.13c-.68.34-1.43.51-2.17.51h0ZM4.82,26.06l45.04,22.79c.52.26,1.14.26,1.66,0l45.42-22.79L51.55,3.2c-.54-.27-1.17-.27-1.71,0L4.83,26.06h-.01Z"
        style={{ fill: props?.fillColor, strokeWidth: '0px' }}
      />
    </SvgIcon>
  )
}

export default LayersIcon
