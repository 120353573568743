// import { MapColorLegend, useI18n } from '@popety_io/popety-io-lib'
// import { useSelector } from 'react-redux'

// import { faoSinceSelector } from '../../redux'

const FaoMapContent = () => {
  // const { t } = useI18n()
  // const faoSince = useSelector(faoSinceSelector)

  return <></>

  // return (
  //   <>
  //     <MapColorLegend
  //       startDate={faoSince}
  //       land={t('land.history.currentlyAnalysed')}
  //       id="fao-year"
  //       left="auto"
  //       right="11px"
  //       padding="3px"
  //     />
  //   </>
  // )
}

export default FaoMapContent
