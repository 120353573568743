/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import alertFaoService from './alertFaoService'

interface IState {
  dataByAlertId: Record<string, any>
  loading: boolean
}

const initialState: IState = {
  dataByAlertId: {},
  loading: false,
}

export const getFaos = createAsyncThunk(
  'alertFao/getFaos',
  alertFaoService.getFaos,
)

export const countFaos = createAsyncThunk(
  'alertFao/countFaos',
  alertFaoService.countFaos,
)

const alertFao = createSlice({
  name: 'alertFao',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaos.pending, (state) => {
        state.loading = true
      })
      .addCase(getFaos.rejected, (state) => {
        state.loading = false
      })
      .addCase(getFaos.fulfilled, (state, action) => {
        const input = action.meta.arg
        const id = input.alertId || input.id || '0'

        const { data } = action.payload || {}

        state.loading = false

        state.dataByAlertId[id] = {
          ...state.dataByAlertId[id],
          ...data,
        }

        if (!data) delete state.dataByAlertId[id]
      })
      .addCase(countFaos.fulfilled, (state, action) => {
        const { data = 0 } = action.payload || {}
        const input = action.meta.arg
        const id = input.alertId || input.id || '0'

        state.dataByAlertId[id] = {
          ...state.dataByAlertId[id],
          count: data,
        }
      })
  },
})

export const alertFaoReducer = {
  alertFao: alertFao.reducer,
}
