/* eslint-disable max-lines */
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useResize, useI18n } from '@popety_io/popety-io-lib'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  LabelList,
  Cell,
} from 'recharts'

const COLORS = [
  '#00ADE6',
  '#374955',
  '#9AAEBB',
  '#FFEBCD',
  '#CE9659',
  '#946329',
  '#753500',
  '#8D7257',
  '#ED982A',
]

interface FaoStatsProps {
  faosStatsData: {
    year: string
    total: number
    developmentCount: number
    otherCount: number
  }[]
  faosPercent?: any
}

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props
  const radius = -10

  return (
    <g>
      <text
        x={x + width + 20}
        y={y - radius}
        fontSize="0.7em"
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}%
      </text>
    </g>
  )
}

const FaoStats = ({ faosStatsData, faosPercent }: FaoStatsProps) => {
  const { ref, width = 530 } = useResize()
  const { t } = useI18n()

  const aggsPercent =
    faosPercent?.length > 0
      ? faosPercent?.reduce((acc: any, fao: any) => {
          if (fao.name !== 'development') {
            const find = faosPercent.find(
              (fao: { name: string }) => fao.name !== 'development',
            )

            const values = {
              name: t('common.other'),
              value: find.value + fao.value,
            }

            return values
          }

          return [acc, { name: t('common.fao.development'), value: fao.value }]
        })
      : []

  return (
    <Box ref={ref} ml="-16px" mt={3} width="100%" height="100%" minHeight={500}>
      <Box>
        <AreaChart
          width={width}
          height={180}
          data={faosStatsData}
          margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00ade6" stopOpacity={0.15} />
              <stop offset="55%" stopColor="#00ade6" stopOpacity={0.08} />
              <stop offset="90%" stopColor="#00ade6" stopOpacity={0.03} />
              <stop offset="100%" stopColor="#00ade6" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="total"
            stroke="#00ade6"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          <XAxis
            style={{ fontSize: '0.7rem' }}
            dataKey="year"
            axisLine={false}
            tickLine={false}
            type="category"
            domain={['dataMin', 'dataMax']}
          />

          <YAxis
            style={{ fontSize: '0.7rem' }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
        </AreaChart>
      </Box>

      <Box>
        <Typography variant="h6" ml="32px" mt={2} mb={2}>
          {t("land.fao.Evolution du volume de mises à l'enquête par catégorie")}
        </Typography>

        <Box display="flex" flexDirection="row">
          <AreaChart width={width - 200} height={250} data={faosStatsData}>
            <Area
              type="monotone"
              dataKey="developmentCount"
              stackId="1"
              stroke="none"
              fill={COLORS[0]}
            />
            <Area
              type="monotone"
              dataKey="otherCount"
              stackId="1"
              stroke="none"
              fill={COLORS[1]}
            />
            <XAxis
              style={{ fontSize: '0.7rem' }}
              dataKey="year"
              tickLine={false}
            />
            <YAxis
              style={{ fontSize: '0.7rem' }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip />
          </AreaChart>
          <BarChart
            layout="vertical"
            width={200}
            height={200}
            data={Array.isArray(aggsPercent) ? aggsPercent : []}
          >
            <YAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              type="category"
              reversed
              style={{ fontSize: '0.8rem' }}
            />
            <XAxis
              type="number"
              hide
              axisLine={false}
              tickLine={false}
              domain={[0, (dataMax: number) => dataMax * 1.5]}
            />
            <Bar dataKey="value" barSize={20} fill="#00ADE6">
              {Array.isArray(aggsPercent) &&
                aggsPercent?.map((entry: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    opacity={0.8}
                  />
                ))}
              <LabelList dataKey="value" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </Box>
      </Box>
    </Box>
  )
}

export default FaoStats
