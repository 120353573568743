/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

const typeColor = (type: string) => {
  switch (type) {
    case '1':
      return '#ffff04'
    case '2':
      return '#ff8002'
    case '3':
      return '#ff0201'
    case '4':
      return '#8000ff'
    default:
      return '#00ade6'
  }
}

const getGeneratedLayers = (generatedBuildings: any) => {
  if (!generatedBuildings) return []

  const buildings = generatedBuildings.buildings
    .filter((building: any) => !building.properties.existing)
    .flatMap((building: any) => [
      ...building.properties.floors.flatMap((floor: any) => {
        return floor.properties.units
          ? [...floor.properties.units]
          : [...building.properties.floors]
      }),
    ])

  const features = buildings?.map((feature: any, i: number) => ({
    id: `dwellings-${i}-${Math.random() * 1000}`,
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: feature.geometry,
      properties: feature.properties,
    },
    layers: [
      {
        id: `floor-${feature?.properties?.floor}-${Math.random() * 1000}`,
        type: 'fill-extrusion',
        paint: {
          'fill-extrusion-color': typeColor(
            feature?.properties?.subtype?.charAt(0) || '',
          ),
          'fill-extrusion-height':
            feature?.properties?.height || feature?.properties?.top_height || 3,
          'fill-extrusion-base': feature?.properties?.base_height || 0,
          'fill-extrusion-vertical-gradient': false,
          'fill-extrusion-opacity': 0.4,
        },
      },
    ],
  }))

  return [...features]
}

export { getGeneratedLayers }
