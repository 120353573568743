import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'

export const LandUsePlanRoot = styled(Card)`
  line-height: 14px;

  .iconOk {
    font-size: 24px;
    padding-bottom: 4px;
    color: #8ed1a5;
  }

  .iconNotOk {
    font-size: 24px;
    padding-bottom: 4px;
    color: red;
  }

  .button {
    padding: 0px 4px 0px 0px;
  }

  .icon {
    font-size: 16px;
    padding-bottom: 4px;
  }

  .planDetail {
    color: #2196f3;

    &:hover {
      cursor: pointer;
    }
  }
`

export const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .articleIcon {
    font-size: 16px;
    color: grey;
  }

  .documents {
    display: flex;
    align-items: center;
    margin-left: 4px;
    line-height: 20px;
  }

  a {
    margin-left: 4px;
  }
`

export const StyledSpan = styled('span')`
  padding: 4px;
  color: #9095a2;
`

export const DocumentsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 30px;

  .spanDocument {
    font-weight: 500;
    color: darkgrey;
  }
`

export const StyledA = styled('a')`
  padding: 5px;
`

export const LabelContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DocumentsBody = styled('div')`
  display: flex;
  flex-direction: column;
`

export const StyledDivider = styled(Divider)`
  margin: 1rem -12px !important;
`
export const DialogSpan = styled('span')`
  font-weight: 500;
  width: 280px;
`

export const CardContentRoot = styled(CardContent)`
  background: #f9f9f9;
`
