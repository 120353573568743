/* eslint-disable prettier/prettier */
import { groupBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'
import { getTransactionAroudLayers } from './transaction.layers'

const landTransactionsSelector = (state) =>
  state.transaction.transactions?.filter(Boolean)

const landTransactionsLoadingSelector = (state) =>
  state.transaction.loading

const landTransactionCountSelector = (state) =>
  state.transaction.transactions.length || 0

const landTransactionSetSelector = createSelector(
  landTransactionsSelector,
  (transactions = []) =>
    transactions.filter(
      (v, i, a) => a.findIndex((t) => t?.official_id === v?.official_id) === i,
    ),
)

const landTransactionSinceDateSelector = createSelector(
  landTransactionsSelector,
  (transactions) =>
    transactions?.length &&
    Math.min(
      ...transactions.map((transaction) =>
        new Date(transaction?.date)?.getFullYear()
      )
    )
)

const transactionsAroundSelector = (state) =>
  state.transaction.transactionsAround

const transactionsAroundCountSelector = (state) =>
  state.transaction.totalAround || 0

const transactionSinceSelector = createSelector(
  transactionsAroundSelector,
  (transactions = []) =>
    transactions.length > 0
      ? Math.min(
        ...transactions.map((transaction) => new Date(transaction.date))
      )
      : undefined
)

const transactionSinceYearSelector = createSelector(
  transactionSinceSelector,
  (date) => (date ? new Date(date).getFullYear() : undefined)
)

const transactionsByLandIdSelector = createSelector(
  transactionsAroundSelector,
  (transactions = []) => groupBy('land_id', transactions)
)

const transactionSetSelector = createSelector(
  transactionsByLandIdSelector,
  (transactions = {}) => Object.values(transactions)
)

const transactionRangeSelector = (state) => state.transaction.transactionRange

const transactionAroundLayersSelector = createSelector(
  transactionSetSelector,
  transactionRangeSelector,
  transactionSinceSelector,
  getTransactionAroudLayers
)

const transactionAggsSelector = (state) => state.transaction.aggregations

const transactionReasonsStatsSelector = (state) =>
  state.transaction.reasonsStats

const transactionOwnershipTypesStatsSelector = (state) =>
  state.transaction.ownershipStats

const transactionGeoPointSelector = (state) => state.transaction.geoPoint

const transactionGeoPolygonSelector = (state) => state.transaction.geoPolygon

const transactionsReasonsPercentSelector = (state) =>
  state.transaction.reasonsPercent

const transactionsownershipTypesPercentSelector = (state) =>
  state.transaction.ownershipTypesPercent

export {
  landTransactionSetSelector,
  landTransactionCountSelector,
  landTransactionSinceDateSelector,
  transactionsAroundSelector,
  transactionSinceSelector,
  transactionSinceYearSelector,
  transactionsByLandIdSelector,
  transactionSetSelector,
  transactionRangeSelector,
  transactionAroundLayersSelector,
  transactionsAroundCountSelector,
  landTransactionsSelector,
  transactionAggsSelector,
  transactionReasonsStatsSelector,
  transactionOwnershipTypesStatsSelector,
  transactionGeoPointSelector,
  transactionGeoPolygonSelector,
  transactionsReasonsPercentSelector,
  transactionsownershipTypesPercentSelector,
  landTransactionsLoadingSelector
}
