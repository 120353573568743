import { Badge, Box } from '@mui/material'
import { ReactNode } from 'react'

interface IProps {
  show?: boolean
  icon: ReactNode
  count: number
}
const CountChip = ({ show = true, icon, count }: IProps) => {
  return (
    <Box>
      {count && show ? (
        <Badge
          badgeContent={count}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              top: 6,
              right: -8,
            },
          }}
          max={1000}
        >
          {icon}
        </Badge>
      ) : (
        icon
      )}
    </Box>
  )
}

export default CountChip
