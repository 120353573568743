import { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {
  I18nProvider,
  ThemeProvider,
  WrongScaleModal,
} from '@popety_io/popety-io-lib'
import { GoogleOAuthProvider } from '@react-oauth/google'

import Routes from './navigation'
import { store } from './redux'
import Layout from './components/Layout'

import '@popety_io/popety-io-lib/dist/styles/GlobalStyle.css'
import './styles/GlobalStyle.css'
import { GOOGLE_CLIENT_ID, PAGES } from './config'

const App = () => {
  const [open, setOpen] = useState(false)
  const showWrongScaleModal = localStorage.getItem('showWrongScaleModal')

  useEffect(() => {
    const openTimeout = setTimeout(() => {
      if (
        !showWrongScaleModal &&
        !open &&
        /Win/i.test(navigator.userAgent) &&
        window.devicePixelRatio >= 1.6
      ) {
        setOpen(true)
        localStorage.setItem('showWrongScaleModal', 'true')
      }
    }, 300)

    return () => {
      clearTimeout(openTimeout)
    }
  }, [])

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <I18nProvider appPages={PAGES}>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <Layout>
                <Routes />
              </Layout>
              <WrongScaleModal onClose={onClose} open={open} />
            </GoogleOAuthProvider>
          </I18nProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  )
}

export default App
