import { myTagsReducer } from './tags'
import { accountReducer } from './accountSlice'

export * from './accountSelector'
export * from './accountSlice'
export * from './tags'
export { default as accountService } from './accountService'

export const myAccountReducer = {
  ...myTagsReducer,
  ...accountReducer,
}
