/* eslint-disable prettier/prettier */
const favoritesStateSelector = (state) => state.favorites

const favoritesSelector = (state) => state.favorites.favorites

const favoriteBuildingSelector = (state) =>
    state.favorites.favoriteBuildingIds

const userFavoriteLandsSelector = (state) =>
    state.favorites.userFavoriteLandsIds

const userFavoriteBuildingsSelector = (state) =>
    state.favorites.userFavoriteBuildingsIds

export {
    favoritesStateSelector,
    favoritesSelector,
    favoriteBuildingSelector,
    userFavoriteLandsSelector,
    userFavoriteBuildingsSelector
}
