import { ApiClient } from '../../../../services'

const client = new ApiClient()

const fetchFavorites = async () => {
  return client.get(`/developerMap/land/favorites`)
}

const getFavoriteBuildings = async () => {
  return client.get(`/developerMap/building/favorites`)
}

const getUserFavoriteLands = async () => {
  return client.get(`/developerMap/land/user-favorites`)
}

const getUserFavoriteBuildings = async () => {
  return client.get(`/developerMap/building/user-favorites`)
}

const favoritesService = {
  fetchFavorites,
  getFavoriteBuildings,
  getUserFavoriteLands,
  getUserFavoriteBuildings,
}

export default favoritesService
