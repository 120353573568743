import { useState } from 'react'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'

import { useI18n } from '@popety_io/popety-io-lib'
import TagModal from './TagModal'
import useTag from './useTag'

type useTagIconProps = {
  landId: string | string[]
  color?: string
}

const useTagIcon = ({ landId, color = 'primary' }: useTagIconProps) => {
  const { t } = useI18n()
  const tag = useTag({ landIds: landId, fetch: true })
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const Icon = tag.active ? (
    <LocalOfferIcon color={color as any} />
  ) : (
    <LocalOfferOutlinedIcon sx={{ opacity: 0.3 }} />
  )

  const Modal = (
    <TagModal
      anchorEl={anchorEl}
      onClose={handleClose}
      landIds={tag.sanitizedLandIds}
      view={0}
    />
  )

  const title = tag.active ? t('common.removeTag') : t('common.addTag')

  return { ...tag, Icon, onClick: handleOpen, title, Modal }
}

export default useTagIcon
