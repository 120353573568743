/* eslint-disable max-lines */
import { useState } from 'react'
import { Box, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { ConstraintField, useI18n } from '@popety_io/popety-io-lib'
import { landSelector } from '../redux'
import {
  AdditionalButton,
  DesignActionButton,
  DesignStepTwoRoot,
} from './Design.style'

interface IProps {
  handleContinue: (indices: any) => void
  handleBack: () => void
}
const DesignStepTwo = ({ handleBack, handleContinue }: IProps) => {
  const { t } = useI18n()
  const land: any = useSelector(landSelector)
  const biggestLup = land.land_use_plans?.filter(
    (landUsePlan: { cover_area_perc: number }) =>
      landUsePlan.cover_area_perc >= 0.5,
  )?.[0]

  const [zonesIndicesAndRatios, setZonesIndicesAndRatios] = useState({
    std_agsf: land?.std,
    cos: land?.cos,
    sbpu: land?.sbpu,
    max_floor_nb: land?.max_floor_nb,
    max_height: land?.max_height,
    default_floor_height: land?.default_floor_height || 3.2,
    cus: land?.cus,
  })
  const [ratiosAndIndices, setRatiosAndIndices] = useState({
    type: 'edges_limit',
    min: land?.minVolume || null,
    max: land?.maxVolume || null,
    distance_limit_land: biggestLup?.distance_limit,
    distance_limit_road: biggestLup?.distance_limit_road,
    edges_limit_min_max: Array(
      land?.geo_polygon?.coordinates?.[0]?.[0]?.length || 0,
    )?.fill([
      biggestLup?.distance_limit_land || 5,
      biggestLup?.distance_limit_road || 0,
    ]),
  })

  // const [distanceLimit] = useState({
  //   type: 'edges_limit',
  //   min: null,
  //   max: null,
  //   edges_limit_min_max: [
  //     [5, null],
  //     [5, null],
  //     [5, null],
  //     [5, null],
  //     [2, null],
  //   ],
  // })

  const handleContinueClick = () => {
    handleContinue({
      zone_indice_and_ratios: {
        std_agsf: zonesIndicesAndRatios?.std_agsf,
        cos: zonesIndicesAndRatios?.cos,
        sbpu: zonesIndicesAndRatios?.sbpu,
        max_floor_nb: zonesIndicesAndRatios?.max_floor_nb,
        max_height: zonesIndicesAndRatios?.max_height,
        cus: zonesIndicesAndRatios?.cus,
        default_floor_height: zonesIndicesAndRatios?.default_floor_height,
      },
      distance_limit: ratiosAndIndices,
    })
  }

  return (
    <DesignStepTwoRoot>
      <Box className="constrain-title">
        <b>{t('land.design.ratiosAndIndices')}</b>
        <div />
        <p>{t('land.design.authorised')}</p>
      </Box>
      <ConstraintField
        title="Surface de terrain déterminante (STd)"
        tooltip="Surface de terrain déterminante (STd)"
        authorized={`${zonesIndicesAndRatios?.std_agsf} m2`}
        value={zonesIndicesAndRatios?.std_agsf || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({ ...zonesIndicesAndRatios, std_agsf: e })
        }
        endAdornment="m2"
      />
      <ConstraintField
        title="Land occupation coefficient (COS)"
        tooltip="Land occupation coefficient (COS)"
        authorized={zonesIndicesAndRatios?.cos || 'N/A'}
        value={zonesIndicesAndRatios?.cos || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({ ...zonesIndicesAndRatios, cos: e })
        }
      />
      <ConstraintField
        title="Land Use Coefficient (CUS)"
        tooltip="Land Use Coefficient (CUS)"
        authorized={zonesIndicesAndRatios?.cus || 'N/A'}
        value={zonesIndicesAndRatios?.cus || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({ ...zonesIndicesAndRatios, cus: e })
        }
      />
      <ConstraintField
        title="Gross useful floor area (SBPu)"
        tooltip="Gross useful floor area (SBPu)"
        authorized={`${zonesIndicesAndRatios?.sbpu} m2`}
        value={zonesIndicesAndRatios?.sbpu || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({ ...zonesIndicesAndRatios, sbpu: e })
        }
      />
      <ConstraintField
        title="Building maximum floors"
        tooltip="Building maximum floors"
        authorized={zonesIndicesAndRatios?.max_floor_nb || 'N/A'}
        value={zonesIndicesAndRatios?.max_floor_nb || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({
            ...zonesIndicesAndRatios,
            max_floor_nb: e,
          })
        }
      />
      <ConstraintField
        title="Building maximum height"
        tooltip="Building maximum height"
        authorized={zonesIndicesAndRatios?.max_height || 'N/A'}
        value={zonesIndicesAndRatios?.max_height || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({ ...zonesIndicesAndRatios, max_height: e })
        }
      />
      <ConstraintField
        title="Default Floor height"
        tooltip="Default Floor height"
        authorized={zonesIndicesAndRatios?.default_floor_height || 'N/A'}
        value={zonesIndicesAndRatios?.default_floor_height || ''}
        onChangeValue={(e: any) =>
          setZonesIndicesAndRatios({
            ...zonesIndicesAndRatios,
            default_floor_height: e,
          })
        }
      />

      <AdditionalButton>
        {t('land.design.editAdditionalParameters')}
      </AdditionalButton>
      <Box className="constrain-title">
        <b>{t('land.design.limitsDistance')}</b>
        <div />
        <p>{t('land.design.authorised')}</p>
      </Box>

      <ConstraintField
        title="Distance to other lands"
        tooltip="Distance to the lands"
        authorized={ratiosAndIndices?.distance_limit_land || 'N/A'}
        value={ratiosAndIndices?.distance_limit_land}
        colorLine="#FCD923"
        onChangeValue={(e: any) =>
          setRatiosAndIndices({
            ...ratiosAndIndices,
            distance_limit_land: e,
          })
        }
      />
      <ConstraintField
        title="Distance to the road"
        tooltip="Distance to the road"
        authorized={ratiosAndIndices?.distance_limit_road || 'N/A'}
        value={ratiosAndIndices?.distance_limit_road}
        colorLine="#E63900"
        onChangeValue={(e: any) =>
          setRatiosAndIndices({
            ...ratiosAndIndices,
            edges_limit_min_max: ratiosAndIndices?.edges_limit_min_max?.map(
              (item) => {
                return [item?.[0], e && e?.includes('0') ? e : '']
              },
            ),
            distance_limit_road: e,
          })
        }
      />
      <DesignActionButton>
        <Button onClick={handleContinueClick} variant="contained">
          {t('land.design.continue')}
        </Button>
        <Button onClick={handleBack}>{t('land.design.back')}</Button>
      </DesignActionButton>
    </DesignStepTwoRoot>
  )
}

export default DesignStepTwo
