/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import listingInsightsService from './listingInsightsService'

const initialState = {
  rentData: undefined,
  buyData: undefined,
  loading: false,
}

export const getListingRentAndBuyInsights = createAsyncThunk(
  'listingAnalytics/getListingRentAndBuyInsights',
  listingInsightsService.getListingRentAndBuyInsights,
)

const listingInsights = createSlice({
  name: 'listingInsights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListingRentAndBuyInsights.pending, (state) => {
        state.loading = true
      })
      .addCase(getListingRentAndBuyInsights.rejected, (state) => {
        state.loading = false
      })
      .addCase(getListingRentAndBuyInsights.fulfilled, (state, action) => {
        const [rent, buy] = action.payload

        state.rentData = rent?.data
        state.buyData = buy?.data
        state.loading = false
      })
  },
})

export const listingInsightsReducer = {
  listingInsights: listingInsights.reducer,
}
