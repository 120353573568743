import React, { useState } from 'react'
import type { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import format from 'date-fns/format'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred'
import { LandOwnerLinks, useAsync } from '@popety_io/popety-io-lib'
import summaryService from '../../redux/summary/summaryService'
import OwnerLandsModal from './OwnerLandsModal'

import { LandOwnersItemRoot, LandOwnersRoot } from './LandOwners.style'

export type LandOwnersItem = Omit<BoxProps, 'title'> & {
  type?: string
  name: string
  birthdate?: Date | string
  actions?: React.ReactNode
}

export type LandOwnersProps = BoxProps & {
  data: LandOwnersItem[]
  modal?: boolean
  canAnalyse?: boolean
}

const LandOwners = ({
  data: owners,
  className = '',
  modal = true,
  canAnalyse,
  ...other
}: LandOwnersProps) => {
  const [owner, setOwner] = useState<string>('')
  const { data, loading, ...async } = useAsync()

  const handleClose = () => {
    setOwner('')
  }

  const handleClick = (name: string) => () => {
    const body = { value: name }

    setOwner(name)
    async.dispatch(() => summaryService.getLandsOwner(body) as any)
  }

  return (
    <LandOwnersRoot {...other} className={`LandOwners ${className}`}>
      {canAnalyse ? (
        owners
          .filter(Boolean)
          .map(({ name, type, actions, birthdate, ...rest }, i) => (
            <LandOwnersItemRoot key={i} active={modal} {...rest}>
              <Typography className="ellipsis">
                <span onClick={handleClick(name)}>{name}</span>
                {type === 'private' && birthdate && (
                  <span>({format(new Date(birthdate), 'PPP')})</span>
                )}

                {type === 'institutional' && (
                  <Typography
                    component="a"
                    display="flex"
                    alignItems="center"
                    ml={0.5}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.zefix.ch/en/search/entity/list?name=${name}`}
                  >
                    <ContactPhoneIcon color="secondary" fontSize="small" />
                  </Typography>
                )}
              </Typography>
              {type === 'private' && (
                <LandOwnerLinks name={name}>{actions}</LandOwnerLinks>
              )}
            </LandOwnersItemRoot>
          ))
      ) : (
        <Typography
          variant="body2"
          style={{
            display: 'flex',
            border: '1px solid #00ade6',
            color: '#00ade6',
            padding: '5px',
            borderRadius: '5px',
            alignItems: 'center',
          }}
        >
          <NoEncryptionGmailerrorredIcon />
          <span>Afficher avec un compte premium</span>
        </Typography>
      )}
      {modal && (
        <OwnerLandsModal
          open={!!owner}
          loading={loading}
          onClose={handleClose}
          owner={owner}
          landList={data as any}
        />
      )}
    </LandOwnersRoot>
  )
}

export default LandOwners
