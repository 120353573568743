/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import React, { useEffect, Suspense, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import {
  ProgressIndicator,
  useRouter,
  useI18n,
  FullScreenIcon,
  Percent30ScreenIcon,
  Percent42ScreenIcon,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import { PopetyMapsScreenButtonRoot } from '../LandDetails/LandDetails.style'
import { toggleSizeMap } from '../LandDetails/LandDetails'
import {
  estimateGeoCenterSelector,
  estimateSelector,
  getEstimateSummary,
} from './redux'
import EstimateMap from './components/EstimateMap'
import EstimateSearch from './components/EstimateSearch'
import { getEstimateMenuData } from './components/EstimateMenu'

import { EstimateRoot, LeftContainer, RightContainer } from './Estimate.style'
import useGetEstimateData from './Estimate.data'

const Estimate = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const dispatch = useDispatch()
  const geoCenterEstimate = useSelector(estimateGeoCenterSelector)
  const estimateGeoCenterStr = useSelector(estimateGeoCenterSelector)
  const estimate = useSelector(estimateSelector)
  const openSearch = query?.openNewReport === 'true'

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const isHomePage = query?.tab === 'home'

  const pages: any = getEstimateMenuData(estimate, isHomePage, t)

  const geoCenterChanged = query?.geoCenter !== estimateGeoCenterStr

  const screenSizeMap = JSON.parse(
    localStorage.getItem('screenSizeMap') || '{}',
  )
  const screenSizeMapEstimate = screenSizeMap?.estimate
  const [size, setSize] = useState(screenSizeMapEstimate || 'default')

  const content = useMemo(() => {
    if (!query || !pages?.length) return

    if (query?.subTab) {
      return pages
        ?.find((p: any, i: number) =>
          !query?.tab
            ? i === 0
            : p?.key === query?.tab ||
              p?.subMenus?.find((s: any) => s?.key === query?.tab),
        )
        ?.subMenus?.find((s: any) => s?.key === query?.subTab)?.page
    }

    const page: any = pages
      .filter((menu: any) => menu?.show || menu?.key === 'history')
      .find((p: any, i: number) =>
        !query?.tab ? i === 0 : p?.key === query?.tab,
      )

    if (page?.subMenus?.length && !query?.subTab) {
      updateQuery({
        subTab: page?.subMenus?.[0]?.key,
      })
    }

    return page?.page
  }, [query?.tab, query?.subTab, pages])

  useGetEstimateData()

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      if (rightContainerRef.current) {
        rightContainerRef.current.style.top = `${rect.top}px`
      }
    }
  }, [geoCenterEstimate])

  useEffect(() => {
    if (query?.geoCenter && geoCenterChanged) {
      updateQuery({ month: 24, dealType: 'purchase' })
      dispatch(
        getEstimateSummary({
          geoCenter: query?.geoCenter,
          addressId: query?.addressId,
          estimateId: query?.estimateId,
        }) as any,
      )
    }
  }, [query?.geoCenter, geoCenterChanged])

  const fullscreen = size === 'large'

  const handleCancelModal = () => {
    const keys = Object.keys(query)

    keys.forEach((key) => {
      if (key !== 'tab') {
        updateQuery({ [key]: undefined })
      }
    })
  }

  const handleClose = () => {
    updateQuery({ openNewReport: undefined, createEstimate: undefined })
  }

  const sizeMap = toggleSizeMap(size)

  const handleToggleScreen = (value: string) => {
    setSize(value)

    const newScreenSizeMap = {
      ...(screenSizeMap || {}),
      estimate: value || 'default',
    }

    localStorage.setItem('screenSizeMap', JSON.stringify(newScreenSizeMap))
  }

  useEffect(() => {
    setSize('default')
  }, [query.tab, query.subTab])

  return (
    <EstimateRoot bgcolor="background.paper">
      {openSearch && (
        <EstimateSearch
          showCloseIcon={!!geoCenterEstimate}
          open={openSearch}
          onClose={handleClose}
          onCancelModal={handleCancelModal}
        />
      )}

      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        <LeftContainer
          bgcolor="background.paper"
          width={{
            xs: '100%',
            lg:
              sizeMap && typeof sizeMap === 'number'
                ? `${100 - sizeMap}%`
                : '58%',
          }}
          display={fullscreen ? 'none' : undefined}
          data-cy="land-detail-left-container"
          id="estimate-scroll-left"
        >
          <Suspense fallback={<ProgressIndicator circular />}>
            {content}
          </Suspense>
        </LeftContainer>

        <RightContainer
          height={{ xs: '100%', lg: '100%' }}
          width={{
            xs: '100%',
            lg: sizeMap && typeof sizeMap === 'number' ? `${sizeMap}%` : '42%',
          }}
          ref={rightContainerRef}
        >
          <Box position="relative" height="100%">
            {content && (
              <PopetyMapsScreenButtonRoot>
                <FullScreenIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                  fillColor={size === 'large' ? '#007eb4' : '#5f6368'}
                  onClick={() => handleToggleScreen('large')}
                />
                <Percent42ScreenIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                  fillColor={
                    !size || size === 'default' ? '#007eb4' : '#5f6368'
                  }
                  onClick={() => handleToggleScreen('default')}
                />
                <Percent30ScreenIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                  fillColor={size === 'small' ? '#007eb4' : '#5f6368'}
                  onClick={() => handleToggleScreen('small')}
                />
              </PopetyMapsScreenButtonRoot>
            )}
            <EstimateMap fullscreen={fullscreen} />
          </Box>
        </RightContainer>
      </Box>
    </EstimateRoot>
  )
}

export default Estimate
