import { geoReducer } from './geo'
import { faoReducer } from './fao'
import { financialAnalysisReducer } from './financialAnalysis'
import { summaryReducer } from './summary'
import { listingReducer } from './listing'
import { livabilityReducer } from './livability'
import { transactionReducer } from './transaction'
import { ownersReducer } from './owners'
import { buildingReducer } from './building'
import { underExploitedReducer } from './underExploited'
import { sunlightReducer } from './sunlight'
import { searchHistoryReducer } from './searchHistory'
import { generativeDesignReducer } from './generativeDesign'

export * from './summary'
export * from './financialAnalysis'
export * from './fao'
export * from './listing'
export * from './transaction'
export * from './livability'
export * from './geo'
export * from './owners'
export * from './building'
export * from './underExploited'
export * from './sunlight'
export * from './searchHistory'
export * from './generativeDesign'

export const landDetailsReducer = {
  ...summaryReducer,
  ...financialAnalysisReducer,
  ...faoReducer,
  ...listingReducer,
  ...transactionReducer,
  ...livabilityReducer,
  ...geoReducer,
  ...ownersReducer,
  ...buildingReducer,
  ...underExploitedReducer,
  ...sunlightReducer,
  ...searchHistoryReducer,
  ...generativeDesignReducer,
}
