import Tooltip from '@mui/material/Tooltip'
import { useSelector } from 'react-redux'
import WarningIcon from '@mui/icons-material/Warning'
import { toReadableNumber, useI18n, InfoBox } from '@popety_io/popety-io-lib'

import { landSelector } from '../redux'
import CollapseList from './CollapseList'

const LandInfo = () => {
  const land: any = useSelector(landSelector)
  const { t } = useI18n()

  const data = [
    { label: t('land.devScore.EGRID'), value: land.egrid_nb },
    { label: t('land.devScore.type'), value: land.type },
    {
      label: t('land.devScore.area'),
      value: land.official_area ? (
        <>
          {toReadableNumber(land.official_area)} m<sup>2</sup>
        </>
      ) : (
        <Tooltip title={t('land.devScore.estimatedByPopety')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            <span>
              {toReadableNumber(land.area)} m<sup>2</sup>
            </span>
            <WarningIcon fontSize="small" />
          </div>
        </Tooltip>
      ),
    },
    {
      label: t('land.devScore.communeNumber'),
      value: land?.city_ofs_number,
    },
  ]

  const data2 = [
    {
      label: t('common.addresses'),
      value: (
        <CollapseList
          items={land.addresses}
          moreKey="common.seeMoreAddresses"
        />
      ),
    },
  ]

  return (
    <InfoBox
      title={t('land.Informations')}
      data={data}
      data2={data2}
      id="land-summary-info"
    />
  )
}

export default LandInfo
