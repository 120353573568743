import { useI18n } from '@popety_io/popety-io-lib'

import SelectorLegend from '../../../../components/SelectorLegend/SelectorLegend'
import { getIndexData } from './EstimateAirQuality.utils'

const EstimateAirQualityLegend = () => {
  const { t } = useI18n()

  const {
    items,
  }: {
    items: {
      color: string
      text: string
    }[]
  } = getIndexData(t)

  const options = [
    {
      value: 'air-quality-index',
      label: 'Air Quality Index',
    },
  ]

  const pricingLegend =
    items && items.length
      ? [
          ...items.map((item) => ({
            color: item.color,
            value: item.text,
          })),
        ].filter(Boolean)
      : []

  return (
    <div>
      <SelectorLegend
        options={options}
        legends={pricingLegend}
        value="air-quality-index"
        keyValue="airQualityLayer"
        legendValue="displayAirQualityLegend"
        description=""
      />
    </div>
  )
}

export default EstimateAirQualityLegend
