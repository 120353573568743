/* eslint-disable max-lines */
export const getPriceData = (
  data: any,
  coef = 1,
  pricePerSquareMeter = 'price_per_square_meter',
) => {
  const formatNum = (price: number): number => {
    if (!price) return price

    return Math.round(price * coef)
  }

  return data?.map((stats: any, index: number, array: Array<any>) => {
    const divider =
      1 * stats.doc_count +
      (array[index - 1] ? array[index - 1].doc_count : 0) +
      (array[index - 2] ? array[index - 2].doc_count : 0)

    const min =
      ((array[index - 1]
        ? array[index - 1].median_price.values['25.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['25.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['25.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const max =
      ((array[index - 1]
        ? array[index - 1].median_price.values['75.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['75.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['75.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const priceM2 =
      ((array[index - 1]
        ? array[index - 1].median_price.values['50.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['50.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['50.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const price_per_square_meter = divider < 5 ? null : priceM2
    const price_per_square_meter_range = divider < 5 ? null : [min, max]

    return {
      date: stats.key_as_string.substring(0, 10),
      listings: stats.doc_count,
      [pricePerSquareMeter]: price_per_square_meter
        ? formatNum(price_per_square_meter)
        : null,
      price_per_square_meter_range: price_per_square_meter_range?.map((i) =>
        formatNum(i),
      ),
    }
  })
}

export const getPriceHistoryMinusMonth = (month: number, data: Array<any>) => {
  const denum = data[data.length - 1 - month]?.priceM2

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 1]?.priceM2 / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const getListingHistoryMinusMonth = (
  month: number,
  data: Array<any>,
) => {
  const denum = data[data.length - 2 - month]?.listings

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 2]?.listings / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const getConfidenceScore = (array: Array<any>) => {
  const listingsNumber =
    (array[array.length - 1] ? array[array.length - 1].listings : 0) +
    (array[array.length - 2] ? array[array.length - 2].listings : 0) +
    (array[array.length - 3] ? array[array.length - 3].listings : 0)

  // eslint-disable-next-line no-nested-ternary
  return listingsNumber > 50 ? 3 : listingsNumber > 20 ? 2 : 1
}

export const mutatePurchaseData = (
  data: any,
  pricePerSquareMeter = 'price_per_square_meter',
) => {
  const dataBuyAllYearlyHistory: any =
    getPriceData(data, 1, pricePerSquareMeter) || 0

  if (!dataBuyAllYearlyHistory?.length)
    return {
      dataBuyAllYearlyHistory: 0,
      dataBuyAllPrice: 0,
      dataBuyAllPriceEvolution: 0,
    }

  const dataBuyAllPrice: number =
    dataBuyAllYearlyHistory?.length > 0
      ? Math.round(
          dataBuyAllYearlyHistory[dataBuyAllYearlyHistory.length - 1]?.[
            pricePerSquareMeter
          ] || 0,
        )
      : 0

  const previoudDate =
    (dataBuyAllYearlyHistory &&
      dataBuyAllYearlyHistory[dataBuyAllYearlyHistory.length - 5] &&
      dataBuyAllYearlyHistory[dataBuyAllYearlyHistory.length - 5]?.[
        pricePerSquareMeter
      ]) ||
    0

  const currentDate =
    (dataBuyAllYearlyHistory &&
      dataBuyAllYearlyHistory[dataBuyAllYearlyHistory.length - 1] &&
      dataBuyAllYearlyHistory[dataBuyAllYearlyHistory.length - 1]?.[
        pricePerSquareMeter
      ]) ||
    0

  const dataBuyAllPriceEvolution: number = previoudDate
    ? (currentDate / previoudDate - 1) * 100
    : 0

  return {
    dataBuyAllYearlyHistory,
    dataBuyAllPrice,
    dataBuyAllPriceEvolution: Number.isNaN(dataBuyAllPriceEvolution)
      ? 0
      : dataBuyAllPriceEvolution,
  }
}

export const mutateRentData = (
  listingAggsRent: any,
  pricePerSquareMeter = 'price_per_square_meter',
) => {
  const dataRentAllYearlyHistory: any =
    getPriceData(listingAggsRent, 12, pricePerSquareMeter) || 0

  const dataRentAllPrice: number =
    dataRentAllYearlyHistory && dataRentAllYearlyHistory?.length > 0
      ? Math.round(
          dataRentAllYearlyHistory[dataRentAllYearlyHistory.length - 1]?.[
            pricePerSquareMeter
          ],
        )
      : 0

  const previoudDate =
    (dataRentAllYearlyHistory &&
      dataRentAllYearlyHistory[dataRentAllYearlyHistory.length - 5] &&
      dataRentAllYearlyHistory[dataRentAllYearlyHistory.length - 5]?.[
        pricePerSquareMeter
      ]) ||
    0

  const currentDate =
    (dataRentAllYearlyHistory &&
      dataRentAllYearlyHistory[dataRentAllYearlyHistory.length - 1] &&
      dataRentAllYearlyHistory[dataRentAllYearlyHistory.length - 1]?.[
        pricePerSquareMeter
      ]) ||
    0

  const dataRentAllPriceEvolution: number = previoudDate
    ? (currentDate / previoudDate - 1) * 100
    : 0

  return {
    dataRentAllYearlyHistory,
    dataRentAllPrice,
    dataRentAllPriceEvolution,
  }
}
