import { ApiClient } from '../../../../services'

const client = new ApiClient()

const addFavorite = async (landId: string | string[]) => {
  return client.post(`/dashboard/favorites/lands/add`, { landId })
}

const removeFavorite = async (landId: string) => {
  return client.delete(`/dashboard/favorites/lands/${landId}`)
}

const getFavoriteByLandIds = (landId: string | string[]) => {
  return client.get(`/dashboard/favorites/lands/${landId}`)
}

const addGrouping = (landsIds: any) => {
  return client.post(`/dashboard/favorites/grouping`, landsIds)
}

const getFavoriteGroupingLands = async (search = '') => {
  return client.get(`/dashboard/favorites/grouping${search}`)
}

const getMyFavoriteLands = async (search = '') => {
  return client.get(`/dashboard/favorites/lands${search}`)
}

const getCompanyFavoriteLands = async (search = '') => {
  return client.get(`/dashboard/favorites/lands${search}`)
}

const favoriteService = {
  getFavoriteByLandIds,
  removeFavorite,
  addFavorite,
  addGrouping,
  getFavoriteGroupingLands,
  getMyFavoriteLands,
  getCompanyFavoriteLands,
}

export default favoriteService
