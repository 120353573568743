/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapBy } from '@popety_io/popety-io-lib'
import { getCurrentUserDetails } from '../../Account/redux'
import { getCurrentUserId } from '../../../utils/user'
import {
  updateUsersById,
  updateOfficesById,
  addUserById,
  activateOrInactivateUsersById,
  addOfficesById,
} from './CompanyDashboard.utils'

import companyDashboardService from './CompanyDashboardService'

interface IUserCompany {
  key: string
  status: string
  last_name: string
  id: string
}
interface IState {
  usersLoading: boolean
  officesLoading: boolean
  users: Array<IUserCompany>
  usersById: Record<string, IUserCompany>
  officesById: Record<
    string,
    {
      key?: string
      [k: string]: any
    }
  >
  officesCount: number
  usersCount: number
}
const initialState: IState = {
  usersLoading: false,
  officesLoading: false,
  users: [],
  usersById: {},
  officesById: {},
  officesCount: 1,
  usersCount: 1,
}

export const getAllUsersByCompany = createAsyncThunk(
  'companyDashboard/getAllUsersByCompany',
  companyDashboardService.getAllUsersByCompany,
)

export const getAllOfficesByCompany = createAsyncThunk(
  'companyDashboard/getAllOfficesByCompany',
  companyDashboardService.getAllOfficesByCompany,
)

export const activeOrInactivate = createAsyncThunk(
  'companyDashboardService/activeOrInactivate',
  companyDashboardService.activeOrInactivate,
)

export const activeOrInactivateOffice = createAsyncThunk(
  'companyDashboardService/activeOrInactivateOffice',
  companyDashboardService.activeOrInactivateOffice,
)

export const addUser = createAsyncThunk(
  'companyDashboardService/addUser',
  async (input: any) => {
    const res = await companyDashboardService.addUser(input)

    return res
  },
)

export const updateUser = createAsyncThunk(
  'companyDashboardService/updateUser',
  async (input: any, thunkAPI) => {
    const res = await companyDashboardService.updateUser(input)

    if (input?.id === getCurrentUserId()) {
      thunkAPI.dispatch(getCurrentUserDetails())
    }

    return res
  },
)

export const addOffice = createAsyncThunk(
  'companyDashboardService/addOffice',
  companyDashboardService.addOffice,
)

export const updateOffice = createAsyncThunk(
  'companyDashboardService/updateOffice',
  companyDashboardService.updateOffice,
)

export const updateMainOffice = createAsyncThunk(
  'companyDashboardService/updateMainOffice',
  companyDashboardService.updateMainOffice,
)

export const companyDashboard = createSlice({
  name: 'companyDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersByCompany.pending, (state) => {
        state.usersLoading = true
      })
      .addCase(getAllUsersByCompany.rejected, (state) => {
        state.usersLoading = false
      })
      .addCase(getAllUsersByCompany.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.usersLoading = false

        if (data.data) {
          state.usersById = mapBy('id', data.data)
          state.usersCount = data.count
        }
      })
      .addCase(getAllOfficesByCompany.pending, (state) => {
        state.officesLoading = false
      })
      .addCase(getAllOfficesByCompany.rejected, (state) => {
        state.officesLoading = false
      })
      .addCase(getAllOfficesByCompany.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.officesLoading = false

        if (data) {
          state.officesById = mapBy('id', data)
        }
      })
      .addCase(updateMainOffice.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.officesById[data.id] = data

        Object.keys(state.officesById).forEach((key) => {
          if (key !== data.id) {
            state.officesById[key].main_office = false
          }
        })
      })
      .addCase(activeOrInactivate.fulfilled, activateOrInactivateUsersById)
      .addCase(activeOrInactivateOffice.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.officesById[data.id] = data
      })
      .addCase(updateUser.fulfilled, updateUsersById)
      .addCase(addUser.fulfilled, addUserById)
      .addCase(updateOffice.fulfilled, updateOfficesById)
      .addCase(addOffice.fulfilled, addOfficesById)
  },
})

export const companyDashboardReducer = {
  companyDashboard: companyDashboard.reducer,
}
