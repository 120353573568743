const getNoiseColor = (noise: number | string) => {
  if (typeof noise === 'string') {
    return '#000000'
  }

  switch (true) {
    case noise < 30:
      return '#E0F3F8'
    case noise < 40:
      return '#ABD9EA'
    case noise < 50:
      return '#C5D564'
    case noise < 60:
      return '#FCD923'
    case noise <= 70:
      return '#FCC423'
    case noise < 80:
      return '#FC5E22'
    case noise < 90:
      return '#F14C11'
    case noise < 100:
      return '#F14C11'
    case noise === 100:
      return '#E63900'
    default:
      return '#E0F3F8'
  }
}

export { getNoiseColor }
