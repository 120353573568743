import { useEffect } from 'react'
import { MapLegend, useRouter, useI18n } from '@popety_io/popety-io-lib'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

const SunlightBuildingHeghit = () => {
  const { query, updateQuery } = useRouter()

  const { t } = useI18n()

  const handleBuildingHeightChange = (event: any) => {
    const { value } = event.target

    updateQuery({
      buildingHeight: value,
    })
  }

  useEffect(() => {
    return () => {
      updateQuery({
        buildingHeight: undefined,
      })
    }
  }, [])

  return (
    <MapLegend
      title={t('common.Surélevation')}
      id="building-height-legend"
      top="50px"
      left="auto"
      right="10px"
      icon={EditIcon}
      defaultOpen={false}
      zIndex={1000}
      style={{
        overflowX: 'hidden',
      }}
      padding="2.5px"
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} />
        <Box
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            paddingBottom: 2,
            paddingTop: 2,
            minWidth: 300,
          }}
        >
          <TextField
            size="small"
            id="height"
            label={t('common.New building height')}
            value={query.buildingHeight}
            onChange={handleBuildingHeightChange}
            name="height"
            fullWidth
            required
          />
        </Box>
      </Box>
    </MapLegend>
  )
}

export default SunlightBuildingHeghit
