import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { Box } from '@mui/material'
import { Map } from '@popety_io/popety-io-lib'
import { LngLatLike } from 'mapbox-gl'

interface IProps {
  center?: any[]
  zoom?: number
  map?: Map
  loaded?: boolean
}
const GeolocMapControls = ({ map, loaded, zoom, center }: IProps) => {
  const onReFocus = () => {
    if (map && loaded) {
      map.setZoom(zoom || 18)
      try {
        map.jumpTo({ center: center as LngLatLike })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <Box
      onClick={onReFocus}
      position="absolute"
      sx={{
        cursor: 'pointer',
        borderRadius: 2,
        padding: '4.5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:active': {
          opacity: 0.8,
        },
      }}
      bgcolor="background.paper"
      right={11}
      left="auto"
      bottom={358}
      zIndex={999}
    >
      <LocationSearchingIcon fontSize="small" />
    </Box>
  )
}

export default GeolocMapControls
