import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type HistorySearchOpts = Record<string, any>

const getTransactions = (filter: HistorySearchOpts) => {
  return client.post('/transactions/search', filter)
}

const countTransactions = (filter: HistorySearchOpts) => {
  return client.post('/transactions/count', filter)
}

const alertTransactionService = {
  getTransactions,
  countTransactions,
}

export default alertTransactionService
