/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react'
import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import { useDispatch, useSelector } from 'react-redux'

import { landSelector, recalculateScore } from '../../redux'
import { getLandUsedIndice } from '../../../../utils'
import BuildingArea from '../BuildingArea'
// import { LandUsePlanRoot } from './LandUsePlan.style'

export type LandUsePlansOpts = {
  id: string | number
  editId: string | number
  plan_type?: string
  adoption_date?: string
  status?: string
  plan_name?: string
  name?: string
  building_area?: boolean
  cover_area_perc: number
  purpose?: string
  construction_arrangement?: string
  construction_arrangement_desc?: string
  cus_overide?: number
  ius_overide?: number
  // cos_overide?: number
  ios_overide?: number
  ibus_overide?: number
  planType?: string
  cos_estimate?: number
  cos?: number
  cos_overide?: { value?: number; current?: number }
  typeZone: any
  overide_json?: any
}

type Props = {
  lup: LandUsePlansOpts
  landUsePlanStatus?: string
}

export const LandUsePlan = ({ lup, landUsePlanStatus = 'now' }: Props) => {
  const dispatch = useDispatch()
  const land: any = useSelector(landSelector)

  useEffect(() => {
    if (lup?.cover_area_perc >= 0.5) {
      const landIndice = getLandUsedIndice(land, lup)

      dispatch(recalculateScore({ lup, land, landIndice } as any))
    }
  }, [
    lup,
    land?.sbOverride,
    land?.sbdOverride,
    land?.sbpuOverride,
    land?.spdOverride,
    land?.spOverride,
    land.existing_score,
  ])

  return (
    <Card sx={{ border: 'none', boxShadow: 'none' }}>
      {lup.building_area && (
        <BuildingArea lup={lup} landUsePlanStatus={landUsePlanStatus} />
      )}
    </Card>
  )
}

export default LandUsePlan
