import { getUserId } from '../../../../services'

export type SearchHistory = {
  id?: string
  land_id: string
  location: string
  update_timestamp?: string
  user_id?: string
}

export const getItem = (landId: string, land: any) => {
  const lands: any[] = land.lands || [land]
  const cities = Array.from(new Set(lands.map((l) => l.city_name)))

  let location

  if (cities.length > 1) {
    location = lands.map((l) => `${l.city_name} ${l.code_number}`).join(', ')
  } else {
    const landCode = Array.from(new Set(lands.map((l) => l.code_number)))

    location = `${cities} ${landCode}`
  }

  return { location, land_id: `${landId}`, user_id: getUserId() }
}
