/* eslint-disable prettier/prettier */
import { createSelector } from '@reduxjs/toolkit'
import { buildingsSelector } from '../building'

import { landSelector } from '../summary'

import { getAltimetryData } from './altimetryDataUtils'
import { getGeoLayers } from './geo.layers'
import { calculateGeoScore } from './geo.util'

const altimetrySelector = (state) => state.geo.altimetryGrid

const geoLoadingSelector = (state) => state.geo.loading

const altimetryerrorSelector = (state) => state.geo.errors

const altimetryDataSelector = createSelector(altimetrySelector, (altimetry) => {
  if (!altimetry?.altimetry_grid) return null

  return getAltimetryData(altimetry?.altimetry_grid, altimetry?.precision)
})

const geoScoreSelector = createSelector(
  altimetrySelector,
  landSelector,
  (altimetry, land) => {
    const canCalcutate = land.land_id?.length && altimetry

    if (!canCalcutate) return land.geo_shape_score

    const area = land.official_area || land.area
    const maxLength = Math.max(...land.arrayLength) - 50
    const altitudeMin = altimetry.altitude_min || land.altitude_min
    const altitudeMax = altimetry.altitude_max || land.altitude_max

    return calculateGeoScore({ altitudeMax, altitudeMin, area, maxLength })
  },
)

const displayGeoBuildingsSelector = (state) => state.geo.displayBuildings

const tiltAverageSelector = createSelector(
  altimetrySelector,
  landSelector,
  (altimetry, land) => {
    if (!(altimetry?.altimetry_grid && land.mbb_length_orthogonal)) return null

    return Math.round(
      (((altimetry.altitude_max - altimetry.altitude_min) * 100) /
        land.mbb_length_parallel +
        ((altimetry.altitude_max - altimetry.altitude_min) * 100) /
        land.mbb_length_orthogonal) /
      2,
    )
  },
)

const geoLayersSelector = createSelector(
  landSelector,
  buildingsSelector,
  displayGeoBuildingsSelector,
  getGeoLayers,
)

export {
  altimetrySelector,
  altimetryDataSelector,
  geoLoadingSelector,
  altimetryerrorSelector,
  geoScoreSelector,
  displayGeoBuildingsSelector,
  tiltAverageSelector,
  geoLayersSelector,
}
