/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import landPreviewService from './landPreviewService'

const initialState = {
  landPreviewById: {},
  rentData: undefined,
  buyData: undefined,
  rentAppartmentData: undefined,
  buyAppartmentData: undefined,
  rentHouseData: undefined,
  buyHouseData: undefined,
  isLoading: false,
}

export const getLandPreview = createAsyncThunk(
  'landPreview/getLandPreview',
  landPreviewService.getLandPreview,
)

export const getListingRentAndBuyInsights = createAsyncThunk(
  'landPreview/getListingRentAndBuyInsights',
  landPreviewService.getListingRentAndBuyInsights,
)

export const getListingRentAndBuyInsightsForAppartmentAndHouses =
  createAsyncThunk(
    'landPreview/getListingRentAndBuyInsightsForAppartmentAndHouses',
    landPreviewService.getListingRentAndBuyInsightsForAppartmentAndHouses,
  )

export const getLandFaos = createAsyncThunk(
  'landPreview/getLandFaos',
  landPreviewService.getLandFaos,
)

export const getLandTransactions = createAsyncThunk(
  'landPreview/getLandTransactions',
  landPreviewService.getLandTransactions,
)

const landPreview = createSlice({
  name: 'landPreview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLandPreview.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.landPreviewById[data.land_id] = data
        }
      })
      .addCase(getListingRentAndBuyInsights.fulfilled, (state, action) => {
        const [rent, buy] = action.payload

        state.rentData = rent?.data
        state.buyData = buy?.data
      })
      .addCase(
        getListingRentAndBuyInsightsForAppartmentAndHouses.pending,
        (state) => {
          state.isLoading = true
        },
      )
      .addCase(
        getListingRentAndBuyInsightsForAppartmentAndHouses.fulfilled,
        (state, action) => {
          const [rentAppartment, buyAppartment, rentHouse, buyHouse] =
            action.payload

          state.rentAppartmentData = rentAppartment?.data
          state.buyAppartmentData = buyAppartment?.data
          state.rentHouseData = rentHouse?.data
          state.buyHouseData = buyHouse?.data

          state.isLoading = false
        },
      )
      .addCase(getLandFaos.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.faos = data
      })
      .addCase(getLandTransactions.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.transactions = data
      })
  },
})

export const landPreviewReducer = {
  landPreview: landPreview.reducer,
}
