/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import financialAnalysisService from './financialAnalysisService'

const defaultConfig = {
  wall: 22,
  demolitionPrice: 140,
  preparationPrice: 80,
  correlativeHeight: 4,
  landscapingPrice: 80,
  buildingPrice: 680,
  ownFunds: 15,
  interestRate: 1,
  constructionTime: 18,
  sellingPrice: 9000,
  salesCommissions: 3.5,
  notaryFee: 0.017,
  transfertRight: 0.033,
  authorisationAndTaxe: 0.04,
  managementFee: 0.03,
  othersAdditional: 0.02,
}

const defaultAnalysis = {
  id: '0',
  name: 'New Draft',
  analysis_data: {
    currentLandPrice: 700,
    currentBuildingPrice: 8000,
    cfcZeroAdditionalCodes: [],
    cfcOneAdditionalCodes: [],
    cfcTwoAdditionalCodes: [],
    cfcFiveAdditionalCodes: [],
    sellingAdditionalCodes: [],
  },
}

const initialState = {
  analysisById: { 0: defaultAnalysis },
  selectedAnalysis: defaultAnalysis,
  hasAnalysis: false,
  config: defaultConfig,
}

export const getFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/getFinancialAnalysis',
  financialAnalysisService.getFinancialAnalysis,
)

export const addFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/addFinancialAnalysis',
  financialAnalysisService.addFinancialAnalysis,
)

export const updateFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/updateFinancialAnalysis',
  financialAnalysisService.updateFinancialAnalysis,
)

export const removeFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/removeFinancialAnalysis',
  financialAnalysisService.removeFinancialAnalysis,
)

// config

export const updateFinancialDefault = createAsyncThunk(
  'financialAnalysis/updateFinancialDefault',
  financialAnalysisService.updateFinancialDefault,
)

export const getFinancialDefault = createAsyncThunk(
  'financialAnalysis/getFinancialDefault',
  financialAnalysisService.getFinancialDefault,
)

const financialAnalysis = createSlice({
  name: 'financialAnalysis',
  initialState,
  reducers: {
    changeSelectedAnalysis: (state, action) => {
      state.selectedAnalysis = state.analysisById[action.payload]
    },
    updateSelectedAnalysisData: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.selectedAnalysis.analysis_data[key] = action.payload[key]
      })
    },
    addCFC: (state, action) => {
      const { cfcKey, ...input } = action.payload
      const cfcCodes = state.selectedAnalysis.analysis_data[cfcKey]

      cfcCodes.push(input)
    },
    updateCFC: (state, action) => {
      const { cfcKey, index, ...input } = action.payload
      const cfcCodes = state.selectedAnalysis.analysis_data[cfcKey]

      if (input.value !== undefined) {
        input.value = parseFloat(input.value || 0, 10)
      }

      Object.keys(input).forEach((key) => {
        cfcCodes[index][key] = input[key]
      })
    },
    deleteCFC: (state, action) => {
      const { cfcKey, index } = action.payload

      delete state.selectedAnalysis.analysis_data[cfcKey][index]

      state.selectedAnalysis.analysis_data[cfcKey] =
        state.selectedAnalysis.analysis_data[cfcKey].filter(Boolean)
    },
  },
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder
      .addCase(getFinancialAnalysis.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.analysisById = { 0: defaultAnalysis }

          data.forEach((analysis) => {
            state.analysisById[analysis.id] = analysis
          })

          state.hasAnalysis = data.length > 0

          state.selectedAnalysis =
            state.analysisById[state.selectedAnalysis.id] ||
            state.analysisById['0']
        }
      })
      .addCase(addFinancialAnalysis.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.analysisById[data.id] = data
          state.selectedAnalysis = data
        }
      })
      .addCase(removeFinancialAnalysis.pending, (state, action) => {
        state.selectedAnalysis = defaultAnalysis
        const input = action.meta.arg

        delete state.analysisById[input.id]
      })
      .addCase(updateFinancialAnalysis.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data) {
          state.analysisById[input.id] = input
          state.selectedAnalysis = input
        }
      })
      .addCase(getFinancialDefault.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data && Object.keys(data).length > 0) {
          data.date = new Date().toISOString()
          state.config = data
        } else {
          state.config = defaultConfig
        }
      })
      .addCase(updateFinancialDefault.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data) state.config = { ...state.config, ...input, ...data }
      })
  },
})

export const {
  changeSelectedAnalysis,
  updateSelectedAnalysisData,
  addCFC,
  updateCFC,
  deleteCFC,
} = financialAnalysis.actions

export const financialAnalysisReducer = {
  financialAnalysis: financialAnalysis.reducer,
}
