import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getAirQualityData = (address: string) => {
  return client.get(`/sparrowanalytics/aqi-address/${address}`)
}

const estimateAirQualityService = { getAirQualityData }

export default estimateAirQualityService
