/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getIsochrone } from '../../../../utils'
import formService from './formService'

const initialState = {
  geojsonByLocations: {
    npa: [],
    municipality: [],
    district: [],
    region: [],
  },
  locationGeojson: [],
  isochrone: undefined,
  openFilter: false,
  selectedLands: [],
  selectedBuildings: [],
  loading: false,
}

export const getMunicipalityById = createAsyncThunk(
  'developerMapForm/getMunicipalityById',
  formService.getMunicipalityById,
)

export const getCityById = createAsyncThunk(
  'developerMapForm/getCityById',
  formService.getCityById,
)

export const getNpaById = createAsyncThunk(
  'developerMapForm/getNpaById',
  formService.getNpaById,
)

export const getDistrictById = createAsyncThunk(
  'developerMapForm/getDistrictById',
  formService.getDistrictById,
)

export const getRegionById = createAsyncThunk(
  'developerMapForm/getRegionById',
  formService.getRegionById,
)

export const getIsochroneLayer = createAsyncThunk(
  'developerMapForm/getIsochroneLayer',
  getIsochrone,
)

export const saveFilter = createAsyncThunk(
  'developerMapForm/saveFilter',
  formService.saveFilter,
)

export const removeFilter = createAsyncThunk(
  'developerMapForm/removeFilter',
  formService.removeFilter,
)

export const saveBuildingFilter = createAsyncThunk(
  'developerMapForm/saveBuildingFilter',
  formService.saveBuildingFilter,
)

export const removeBuildingFilter = createAsyncThunk(
  'developerMapForm/removeBuildingFilter',
  formService.removeBuildingFilter,
)

export const getFilters = createAsyncThunk(
  'developerMapForm/getFilters',
  formService.getFilters,
)

export const getBuildingFiltersInfo = createAsyncThunk(
  'developerMapForm/getBuildingFiltersInfo',
  formService.getBuildingFiltersInfo,
)

export const submitForm = createAsyncThunk(
  'developerMapForm/submitForm',
  formService.submitForm,
)

export const getSelectedLands = createAsyncThunk(
  'developerMapForm/getSelectedLands',
  formService.getSelectedLands,
)

export const getSelectedBuildings = createAsyncThunk(
  'developerMapForm/getSelectedBuildings',
  formService.getSelectedBuildings,
)

export const countDeveloperMap = createAsyncThunk(
  'developerMapForm/countDeveloperMap',
  formService.countDeveloperMap,
)

export const saveUserLogsSearchExplore = createAsyncThunk(
  'developerMapForm/saveUserLogsSearchExplore',
  formService.saveUserLogsSearchExplore,
)

export const form = createSlice({
  name: 'developerMapForm',
  initialState,
  reducers: {
    clearLocationGeoJson: (state) => {
      state.locationGeojson = undefined
    },
    clearLocationGeoJsonByKey: (state, action) => {
      state.geojsonByLocations[action.payload] = []
    },
    setOpenFilter: (state, action) => {
      state.openFilter = action.payload
    },
    clearSelectedLands: (state) => {
      state.selectedLands = []
    },
    clearSelectedBuildings: (state) => {
      state.selectedBuildings = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCityById.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.locationGeojson = undefined

        if (data?.length) {
          data.forEach((r, k) => {
            if (k === 0) {
              state.locationGeojson = JSON.parse(r.geojson_polygon)
            } else {
              state.locationGeojson?.coordinates.push(
                JSON.parse(r.geojson_polygon)?.coordinates[0],
              )
            }
          })
        }
      })
      .addCase(getMunicipalityById.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { locations } = action.meta.arg || {}

        const copyLocations = [...locations]

        state.geojsonByLocations.municipality = []
        state.locationGeojson = undefined

        if (data?.data?.length) {
          data?.data.forEach((r, k) => {
            if (k === 0) {
              state.locationGeojson = JSON.parse(r.geojson_polygon)
            } else {
              state.locationGeojson?.coordinates.push(
                JSON.parse(r.geojson_polygon)?.coordinates[0],
              )
            }

            copyLocations.forEach((location) => {
              const loc = { ...location }

              if (loc.location_type === 'municipality') {
                loc.geojson = JSON.parse(r.geojson_polygon)

                state.geojsonByLocations.municipality.push(loc)
              }
            })
          })
        }
      })
      .addCase(getDistrictById.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { locations } = action.meta.arg || {}

        const copyLocations = [...locations]

        state.geojsonByLocations.district = []
        state.locationGeojson = undefined

        if (data?.data?.length) {
          data?.data.forEach((r, k) => {
            if (k === 0) {
              state.locationGeojson = JSON.parse(r.geojson_polygon)
            } else {
              state.locationGeojson?.coordinates.push(
                JSON.parse(r.geojson_polygon)?.coordinates[0],
              )
            }

            copyLocations.forEach((location) => {
              const loc = { ...location }

              if (loc.location_type === 'district') {
                loc.geojson = JSON.parse(r.geojson_polygon)

                state.geojsonByLocations.district.push(loc)
              }
            })
          })
        }
      })
      .addCase(getRegionById.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { locations } = action.meta.arg || {}

        const copyLocations = [...locations]

        state.geojsonByLocations.region = []
        state.locationGeojson = undefined

        if (data?.data?.length) {
          data?.data?.forEach((r, k) => {
            if (k === 0) {
              state.locationGeojson = JSON.parse(r.geojson_polygon)
            } else {
              state.locationGeojson?.coordinates.push(
                JSON.parse(r.geojson_polygon)?.coordinates[0],
              )
            }

            copyLocations.forEach((location) => {
              const loc = { ...location }

              if (loc.location_type === 'region') {
                loc.geojson = JSON.parse(r.geojson_polygon)

                state.geojsonByLocations.region.push(loc)
              }
            })
          })
        }
      })
      .addCase(getIsochroneLayer.fulfilled, (state, action) => {
        state.isochrone = action.payload || {}
      })
      .addCase(saveFilter.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data)
          state.userFilter = state.userFilter.concat(data).filter(Boolean)
      })
      .addCase(removeFilter.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          const id = action.meta.arg

          state.userFilter = state.userFilter?.filter((filter) => {
            return filter.id.toString() !== id.toString()
          })
        }
      })
      .addCase(getNpaById.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { locations } = action.meta.arg || {}

        const copyLocations = [...locations]

        state.geojsonByLocations.npa = []
        state.locationGeojson = undefined

        if (data?.data?.length) {
          data?.data.forEach((r, k) => {
            if (k === 0) {
              state.locationGeojson = JSON.parse(r.geojson_polygon)
            } else {
              state.locationGeojson?.coordinates.push(
                JSON.parse(r.geojson_polygon)?.coordinates[0],
              )
            }

            copyLocations.forEach((location) => {
              const loc = { ...location }

              if (loc.location_type === 'npa') {
                loc.geojson = JSON.parse(r.geojson_polygon)

                state.geojsonByLocations.npa.push(loc)
              }
            })
          })
        }
      })
      .addCase(saveBuildingFilter.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data)
          state.buildingUserFilter = state.buildingUserFilter
            .concat(data)
            .filter(Boolean)
      })
      .addCase(removeBuildingFilter.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          const id = action.meta.arg

          state.buildingUserFilter = state.buildingUserFilter?.filter(
            (filter) => {
              return filter.id.toString() !== id.toString()
            },
          )
        }
      })
      .addCase(getFilters.fulfilled, (state, action) => {
        const { userFilter, companyFilter, buildingUserFilter } =
          action.payload || {}

        state.userFilter = userFilter
        state.companyFilter = companyFilter
        state.buildingUserFilter = buildingUserFilter
      })
      .addCase(getBuildingFiltersInfo.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.buildingFilters = data
      })
      .addCase(submitForm.pending, (state) => {
        state.loading = true
      })
      .addCase(submitForm.rejected, (state) => {
        state.loading = false
      })
      .addCase(submitForm.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.tableData = data
        state.loading = false
      })
      .addCase(getSelectedLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.selectedLands = data
      })
      .addCase(getSelectedBuildings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.selectedBuildings = data
      })
      .addCase(countDeveloperMap.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.tableDataCount = data
      })
  },
})

export const formReducer = {
  developerMapForm: form.reducer,
}

export const {
  clearLocationGeoJson,
  clearLocationGeoJsonByKey,
  setOpenFilter,
  clearSelectedLands,
  clearSelectedBuildings,
} = form.actions
