import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const Title = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 5px;
`

export const StyledOwners = styled(Box)`
  display: flex;
  flex-direction: column;
`
