/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import ownersService from './ownersService'

const initialState = {
  ownerLands: null,
  hasRegionOwner: false,
}

export const getOwnerLands = createAsyncThunk(
  'owners/getOwnerLands',
  ownersService.getOwnerLands,
)

const owners = createSlice({
  name: 'owners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOwnerLands.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.ownerLands = data
    })
  },
})

export const ownersReducer = {
  owners: owners.reducer,
}
export default owners.reducer
