/* eslint-disable max-lines */
import { useSelector, useDispatch } from 'react-redux'
import { useI18n, FilterChips, useRouter } from '@popety_io/popety-io-lib'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useMemo } from 'react'
import {
  estimateSelector,
  estimateLoadingSelector,
  clearEstimate,
} from '../../redux'
import EstimateSearchMap from '../EstimateSearchMap'
import {
  EstimateSearchRoot,
  SearchForm,
  FloorContainer,
} from './EstimateSearch.style'

export type EstimateDwellingsProps = {
  value: any
  onClose: any
  handleChangeIndex: (newIndex: number) => void
  setValue: any
  geoCenter: string
  landsLayers: any
  showCloseIcon?: boolean
}

const EstimateDwellings = ({
  value,
  onClose,
  handleChangeIndex,
  setValue,
  geoCenter,
  landsLayers,
  showCloseIcon,
}: EstimateDwellingsProps) => {
  const { t } = useI18n()
  const { updateQuery } = useRouter()

  const dispatch = useDispatch()

  const estimate: any = useSelector(estimateSelector)
  const loading = useSelector(estimateLoadingSelector)
  const geoCenterSplit = geoCenter?.split(',')
  const address = useMemo(() => {
    return value?.address?.split(',')
  }, [value])

  const handleClick = (dwelling: any) => {
    handleChangeIndex(2)
    setValue(dwelling)
    updateQuery({ dwellingId: dwelling?.id })
  }

  const handleNoFloorClick = () => {
    handleChangeIndex(2)
  }

  const handleCloseStep = () => {
    setValue(undefined)

    dispatch(clearEstimate())
    handleChangeIndex(0)
    updateQuery({
      propertyType: undefined,
      addressId: undefined,
      dwellingId: undefined,
      zoom: undefined,
    })
    onClose?.()
  }

  if (!geoCenter) return null

  return (
    <div>
      {!loading && (
        <EstimateSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
          <Paper className="Title">
            <Typography
              component="h2"
              fontWeight="700"
              fontSize={16}
              textTransform="uppercase"
            >
              {t('estimate.Sélectionner le logement à estimer')}
            </Typography>

            {showCloseIcon && (
              <IconButton
                aria-label="close"
                onClick={handleCloseStep}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Paper>
          <Box
            sx={{
              padding: '16px 24px',
            }}
          >
            <Typography fontSize={14} sx={{ paddingBottom: '8px' }}>
              {t(
                'estimate.dwellings.Nous avons trouvé plusieurs logements à cette adresse. Sélectionner celui à estimer',
              )}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100px !important',
                  }}
                >
                  <EstimateSearchMap
                    lat={Number(geoCenterSplit[1])}
                    lon={Number(geoCenterSplit[0])}
                    data={landsLayers}
                    zoom={16}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <Typography fontWeight={600} fontSize={15}>
                    {address?.[0]}
                  </Typography>
                  <Typography fontSize={13}>{address?.[1]}</Typography>
                </Box>
              </Grid>
            </Grid>
            <SearchForm component="form">
              {estimate?.buildings_administrative?.map((building: any) => (
                <div
                  key={building?.id}
                  style={{
                    display: 'flex',
                    lineHeight: 3,
                    flexDirection: 'column',
                  }}
                >
                  {building?.addresses
                    ?.filter(
                      (add: any) =>
                        Number(add?.address_id || add?.id) ===
                          Number(estimate?.addressId) || !estimate?.addressId,
                    )
                    ?.map((address: any) => {
                      const copyDwellings = address.dwellings?.length
                        ? [...address.dwellings]
                        : []

                      return (
                        <div key={address.id}>
                          {copyDwellings
                            ?.sort((a: any, b: any) => a.floor - b.floor)
                            ?.map((dwelling: any) => {
                              const chips = [
                                {
                                  label: `${dwelling?.room_nb} ${t(
                                    'common.rooms',
                                  )}`,
                                  value: dwelling?.room_nb,
                                  key: 'rooms_nb',
                                },
                                {
                                  label: `${dwelling?.area} m2`,
                                  value: dwelling?.area,
                                  key: 'area',
                                },
                              ].filter((i) => i.value)

                              return (
                                <FloorContainer
                                  key={dwelling?.id || dwelling?.dwelling_id}
                                  onClick={() =>
                                    handleClick({
                                      ...dwelling,
                                      address: `${address?.street} ${address?.street_nb}, ${address?.npa} ${address?.locality}`,
                                    })
                                  }
                                >
                                  <h4>
                                    {dwelling?.floor === 0
                                      ? t(
                                          'building.configuration.Rez de chaussée',
                                        )
                                      : dwelling?.floor}
                                    {dwelling?.floor === 1
                                      ? t('estimate.er')
                                      : ''}
                                    {dwelling?.floor === 2
                                      ? t('estimate.nd')
                                      : ''}
                                    {dwelling?.floor === 3
                                      ? t('estimate.rd')
                                      : ''}
                                    {dwelling?.floor > 3
                                      ? t('estimate.th')
                                      : ''}{' '}
                                    {dwelling?.floor === 0
                                      ? ''
                                      : t('building.Etage')}
                                  </h4>
                                  <FilterChips
                                    items={chips}
                                    size="small"
                                    sx={{
                                      flexWrap: 'wrap',
                                    }}
                                    getChipKey={(item: any) => item.key}
                                  />
                                </FloorContainer>
                              )
                            })}
                        </div>
                      )
                    })}
                  <FloorContainer onClick={() => handleNoFloorClick()}>
                    <h4>{t('estimate.unknown_floor')}</h4>
                  </FloorContainer>
                </div>
              ))}
            </SearchForm>
          </Box>
        </EstimateSearchRoot>
      )}
    </div>
  )
}

export default EstimateDwellings
