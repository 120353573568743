import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from '@popety_io/popety-io-lib'

import {
  authorizedLimitLayersSelector,
  // buildingLayersSelector,
  geoLayersSelector,
  landIdSelector,
  landLayersSelector,
  poisIsochroneLayersSelector,
  summaryMapSelectedLayersSelector,
  landLayersOutlinedSelector,
  geoCenterSelector,
  // transactionGeoPointSelector,
  rdppfsSelector,
  transactionGeoPolygonSelector,
  landLayersOutlinedDasharraySelector,
  customSunlightBuildingSelector,
  generatedBuildingSelector,
  buildingsSelector,
} from '../redux'

import { getRestrictionLayers } from '../Restriction/Restriction.layers'
import { getBuildingLayers } from '../redux/building/building.layers'
import { getPlotRadius, getPlotLocation } from './utils'

const useLandDetailsMapLayers = () => {
  const { query } = useRouter()
  const landLayer = useSelector(landLayersSelector)

  const { radius, rdppfCategories, dimension = '3D' } = query

  const geoPolygon = useSelector(transactionGeoPolygonSelector)
  const landId = useSelector(landIdSelector)
  const geoCenter = useSelector(geoCenterSelector)
  const livabilityIsochrone = useSelector(poisIsochroneLayersSelector)
  const geo = useSelector(geoLayersSelector)
  const rdppfs = useSelector(rdppfsSelector)
  // const restrictions = useSelector(restrictionLayersSelector)
  const authorizedLimit = useSelector(authorizedLimitLayersSelector)
  const buildingsLayers = useSelector(buildingsSelector)
  const summaryMapSelectedLayers = useSelector(summaryMapSelectedLayersSelector)
  const landLayersOutlined = useSelector(landLayersOutlinedSelector)
  const landLayersOutlinedDasharray = useSelector(
    landLayersOutlinedDasharraySelector,
  )
  const customSunlightBuilding = useSelector(customSunlightBuildingSelector)
  const generatedBuilding = useSelector(generatedBuildingSelector)

  const radiusLayer = getPlotRadius(geoCenter, radius)
  const locationLayer = getPlotLocation(geoPolygon)
  const restrictions = useMemo(() => {
    if (rdppfs) {
      return getRestrictionLayers(rdppfs, rdppfCategories)
    }

    return []
  }, [rdppfs, rdppfCategories])

  const buildings = useMemo(() => {
    if (buildingsLayers) {
      return getBuildingLayers(buildingsLayers, dimension)
    }

    return []
  }, [buildingsLayers, dimension])

  if (!landId) return { data: [] }

  const summary = [
    summaryMapSelectedLayers.includes('authorizedLimit') && authorizedLimit,
    summaryMapSelectedLayers.includes('land') && landLayer,
    summaryMapSelectedLayers.includes('buildings') && buildings,
  ].filter(Boolean)

  const keepRadius =
    !!query.city_history || !!query.district_history || !!query.npa_history

  const history = [
    landLayer,
    query.city_history && locationLayer,
    query.district_history && locationLayer,
    query.npa_history && locationLayer,
    !keepRadius && radiusLayer,
  ].filter(Boolean)

  const sourcesByPage: Record<string, any[][]> = {
    summary,
    building: [landLayersOutlined, buildings],
    transaction: history,
    fao: history,
    listing: history,
    livability: [livabilityIsochrone, landLayersOutlined],
    geo: [geo],
    lupa: [landLayersOutlined],
    sunlight: [
      landLayersOutlined,
      customSunlightBuilding.length > 0 &&
        customSunlightBuilding?.map((x: any) => x?.data),
    ],
    restriction: [restrictions, landLayersOutlined],
    designLab: [landLayersOutlined],
    'design-2': [landLayersOutlinedDasharray, generatedBuilding],
    history: [landLayersOutlined],
  }

  const layers =
    sourcesByPage[query.subTab || query.tab] || sourcesByPage.summary

  const data = layers.flat().filter(Boolean)

  return { data }
}

export { useLandDetailsMapLayers }
