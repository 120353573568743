/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import landuseplanService from './landuseplanService'

const initialState = {
  loadPrincipalType: false,
  loadLandUsePlan: false,
  loadLandUsePlanAssignement: false,
  principalTypes: [],
  landUsePlan: [],
  futureLandUsePlan: [],
  landUsePlanAssignement: [],
  futureLandUsePlanAssignement: [],
  overideLandUsePlans: [],
  futureOverideLandUsePlans: [],
}

export const fetchPrincipalType = createAsyncThunk(
  'landuseplan/fetchPrincipalType',
  landuseplanService.fetchPrincipalType,
)

export const fetchPrincipalTypeByMunicipality = createAsyncThunk(
  'landuseplan/fetchPrincipalTypeByMunicipality',
  landuseplanService.fetchPrincipalTypeByMunicipality,
)

export const fetchPrincipalTypeByRegion = createAsyncThunk(
  'landuseplan/fetchPrincipalTypeByRegion',
  landuseplanService.fetchPrincipalTypeByRegion,
)

export const fetchPrincipalTypeByDistrict = createAsyncThunk(
  'landuseplan/fetchPrincipalTypeByDistrict',
  landuseplanService.fetchPrincipalTypeByDistrict,
)

export const fetchPrincipalTypeByNpa = createAsyncThunk(
  'landuseplan/fetchPrincipalTypeByNpa',
  landuseplanService.fetchPrincipalTypeByNpa,
)

export const fetchLandUsePlan = createAsyncThunk(
  'landuseplan/fetchLandUsePlan',
  landuseplanService.fetchLandUsePlan,
)

export const fetchLandUsePlanByMunicipality = createAsyncThunk(
  'landuseplan/fetchLandUsePlanByMunicipality',
  landuseplanService.fetchLandUsePlanByMunicipality,
)

export const fetchFutureLandUsePlan = createAsyncThunk(
  'landuseplan/fetchFutureLandUsePlan',
  landuseplanService.fetchFutureLandUsePlan,
)

export const fetchLandUsePlanAssignement = createAsyncThunk(
  'landuseplan/fetchLandUsePlanAssignement',
  landuseplanService.fetchLandUsePlanAssignement,
)

export const fetchFutureLandUsePlanAssignement = createAsyncThunk(
  'landuseplan/fetchFutureLandUsePlanAssignement',
  landuseplanService.fetchFutureLandUsePlanAssignement,
)

export const fetchUsersFutureOverideLands = createAsyncThunk(
  'landuseplan/fetchUsersFutureOverideLands',
  landuseplanService.fetchUsersFutureOverideLands,
)

export const fetchUsersExtendedOverideLandsOnMap = createAsyncThunk(
  'landuseplan/fetchUsersExtendedOverideLandsOnMap',
  landuseplanService.fetchUsersExtendedOverideLandsOnMap,
)

export const fetchLandUsePlanByNpa = createAsyncThunk(
  'landuseplan/fetchLandUsePlanByNpa',
  landuseplanService.fetchLandUsePlanByNpa,
)

export const fetchLandUsePlanAssignementByNpa = createAsyncThunk(
  'landuseplan/fetchLandUsePlanAssignementByNpa',
  landuseplanService.fetchLandUsePlanAssignementByNpa,
)

export const fetchLandUsePlanByDistricts = createAsyncThunk(
  'landuseplan/fetchLandUsePlanByDistricts',
  landuseplanService.fetchLandUsePlanByDistricts,
)

export const fetchLandUsePlanByRegions = createAsyncThunk(
  'landuseplan/fetchLandUsePlanByRegions',
  landuseplanService.fetchLandUsePlanByRegions,
)

export const fetchLandUsePlanByMunicipalities = createAsyncThunk(
  'landuseplan/fetchLandUsePlanByMunicipalities',
  landuseplanService.fetchLandUsePlanByMunicipalities,
)

export const fetchLandUsePlanAssignementByMunicipalities = createAsyncThunk(
  'landuseplan/fetchLandUsePlanAssignementByMunicipalities',
  landuseplanService.fetchLandUsePlanAssignementByMunicipalities,
)

export const fetchLandUsePlanAssignementByDistricts = createAsyncThunk(
  'landuseplan/fetchLandUsePlanAssignementByDistricts',
  landuseplanService.fetchLandUsePlanAssignementByDistricts,
)

export const fetchLandUsePlanAssignementByRegions = createAsyncThunk(
  'landuseplan/fetchLandUsePlanAssignementByRegions',
  landuseplanService.fetchLandUsePlanAssignementByRegions,
)

const landuseplan = createSlice({
  name: 'landuseplan',
  initialState,
  reducers: {
    resetLandUsePlan: (state) => {
      state.landUsePlan = []
      state.futureLandUsePlan = []
    },
    resetLandUsePlanAssignement: (state) => {
      state.landUsePlanAssignement = []
      state.futureLandUsePlanAssignement = []
    },
    resetPrincipalTypes: (state) => {
      state.principalTypes = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrincipalTypeByMunicipality.pending, (state) => {
        state.loadPrincipalType = true
      })
      .addCase(fetchPrincipalTypeByMunicipality.rejected, (state) => {
        state.loadPrincipalType = false
      })
      .addCase(fetchPrincipalTypeByMunicipality.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadPrincipalType = false
        state.principalTypes = data
      })
      .addCase(fetchPrincipalType.pending, (state) => {
        state.loadPrincipalType = true
      })
      .addCase(fetchPrincipalType.rejected, (state) => {
        state.loadPrincipalType = false
      })
      .addCase(fetchPrincipalType.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadPrincipalType = false
        state.principalTypes = data
      })
      .addCase(fetchPrincipalTypeByRegion.pending, (state) => {
        state.loadPrincipalType = true
      })
      .addCase(fetchPrincipalTypeByRegion.rejected, (state) => {
        state.loadPrincipalType = false
      })
      .addCase(fetchPrincipalTypeByRegion.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadPrincipalType = false
        state.principalTypes = data
      })
      .addCase(fetchPrincipalTypeByDistrict.pending, (state) => {
        state.loadPrincipalType = true
      })
      .addCase(fetchPrincipalTypeByDistrict.rejected, (state) => {
        state.loadPrincipalType = false
      })
      .addCase(fetchPrincipalTypeByDistrict.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadPrincipalType = false
        state.principalTypes = data
      })
      .addCase(fetchLandUsePlan.pending, (state) => {
        state.loadLandUsePlan = true
      })
      .addCase(fetchLandUsePlan.rejected, (state) => {
        state.loadLandUsePlan = false
      })
      .addCase(fetchLandUsePlan.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlan = false
        state.landUsePlan = data
      })
      .addCase(fetchLandUsePlanByNpa.pending, (state) => {
        state.loadLandUsePlan = true
      })
      .addCase(fetchLandUsePlanByNpa.rejected, (state) => {
        state.loadLandUsePlan = false
      })
      .addCase(fetchLandUsePlanByNpa.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlan = false
        state.landUsePlan = data
      })
      .addCase(fetchLandUsePlanByDistricts.pending, (state) => {
        state.loadLandUsePlan = true
      })
      .addCase(fetchLandUsePlanByDistricts.rejected, (state) => {
        state.loadLandUsePlan = false
      })
      .addCase(fetchLandUsePlanByDistricts.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlan = false
        state.landUsePlan = data
      })
      .addCase(fetchLandUsePlanByRegions.pending, (state) => {
        state.loadLandUsePlan = true
      })
      .addCase(fetchLandUsePlanByRegions.rejected, (state) => {
        state.loadLandUsePlan = false
      })
      .addCase(fetchLandUsePlanByRegions.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlan = false
        state.landUsePlan = data
      })
      .addCase(fetchLandUsePlanByMunicipalities.pending, (state) => {
        state.loadLandUsePlan = true
      })
      .addCase(fetchLandUsePlanByMunicipalities.rejected, (state) => {
        state.loadLandUsePlan = false
      })
      .addCase(fetchLandUsePlanByMunicipalities.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlan = false
        state.landUsePlan = data
      })
      .addCase(fetchPrincipalTypeByNpa.pending, (state) => {
        state.loadPrincipalType = true
      })
      .addCase(fetchPrincipalTypeByNpa.rejected, (state) => {
        state.loadPrincipalType = false
      })
      .addCase(fetchPrincipalTypeByNpa.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadPrincipalType = false
        state.principalTypes = data
      })
      .addCase(fetchFutureLandUsePlan.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.futureLandUsePlan = data
      })
      .addCase(fetchLandUsePlanAssignement.pending, (state) => {
        state.loadLandUsePlanAssignement = true
      })
      .addCase(fetchLandUsePlanAssignement.rejected, (state) => {
        state.loadLandUsePlanAssignement = false
      })
      .addCase(fetchLandUsePlanAssignement.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlanAssignement = false
        state.landUsePlanAssignement = data
      })
      .addCase(fetchLandUsePlanAssignementByMunicipalities.pending, (state) => {
        state.loadLandUsePlanAssignement = true
      })
      .addCase(
        fetchLandUsePlanAssignementByMunicipalities.rejected,
        (state) => {
          state.loadLandUsePlanAssignement = false
        },
      )
      .addCase(
        fetchLandUsePlanAssignementByMunicipalities.fulfilled,
        (state, action) => {
          const { data = [] } = action.payload || {}

          state.loadLandUsePlanAssignement = false
          state.landUsePlanAssignement = data
        },
      )
      .addCase(fetchLandUsePlanAssignementByDistricts.pending, (state) => {
        state.loadLandUsePlanAssignement = true
      })
      .addCase(fetchLandUsePlanAssignementByDistricts.rejected, (state) => {
        state.loadLandUsePlanAssignement = false
      })
      .addCase(
        fetchLandUsePlanAssignementByDistricts.fulfilled,
        (state, action) => {
          const { data = [] } = action.payload || {}

          state.loadLandUsePlanAssignement = false
          state.landUsePlanAssignement = data
        },
      )
      .addCase(fetchLandUsePlanAssignementByRegions.pending, (state) => {
        state.loadLandUsePlanAssignement = true
      })
      .addCase(fetchLandUsePlanAssignementByRegions.rejected, (state) => {
        state.loadLandUsePlanAssignement = false
      })
      .addCase(
        fetchLandUsePlanAssignementByRegions.fulfilled,
        (state, action) => {
          const { data = [] } = action.payload || {}

          state.loadLandUsePlanAssignement = false
          state.landUsePlanAssignement = data
        },
      )
      .addCase(fetchLandUsePlanAssignementByNpa.pending, (state) => {
        state.loadLandUsePlanAssignement = true
      })
      .addCase(fetchLandUsePlanAssignementByNpa.rejected, (state) => {
        state.loadLandUsePlanAssignement = false
      })
      .addCase(fetchLandUsePlanAssignementByNpa.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loadLandUsePlanAssignement = false
        state.landUsePlanAssignement = data
      })
      .addCase(fetchFutureLandUsePlanAssignement.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.futureLandUsePlanAssignement = data
      })
  },
})

export const {
  resetLandUsePlan,
  resetLandUsePlanAssignement,
  resetPrincipalTypes,
} = landuseplan.actions

export const landuseplanReducer = {
  landuseplan: landuseplan.reducer,
}
