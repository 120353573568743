import { useEffect } from 'react'

const useClearUpEffect = (action: () => void, deps: any[] = []) => {
  useEffect(() => {
    const request = setTimeout(() => {
      action()
    }, 100)

    return () => {
      clearTimeout(request)
    }
  }, deps)
}

export default useClearUpEffect
