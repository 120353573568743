/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'

import { useSelector } from 'react-redux'

import { useI18n } from '@popety_io/popety-io-lib'

import { Tooltip } from '@mui/material'
import { listTagsSelector } from '../../pages/Dashboard/redux'

import { TagChooserRoot } from './Tag.style'
import useTag from './useTag'

type TagChooserProps = {
  landIds: string | string[]
  handleTagToggle: (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
  }) => () => void
  canToggle: (_tag: Record<string, any>) => boolean
  handleAddTag: (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
  }) => void
  handleRemoveTag: (newVal: {
    tag_id: string
    newPrimary?: boolean
    newSecondary?: boolean
    tagLandId?: string
  }) => void
}

const TagChooser = ({
  landIds,
  handleAddTag,
  handleRemoveTag,
}: TagChooserProps) => {
  const { t } = useI18n()
  const uniqueTags = useSelector(listTagsSelector)
  const { activeLandTags, tagsbyId } = useTag({ landIds })
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [notSelectedTags, setNotSelectedTags] = useState<any[]>(uniqueTags)

  const height = Math.min(250, 16 + 32 * uniqueTags.length)

  const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    const tags = (uniqueTags?.length ? uniqueTags : [])
      ?.map((item: any) => {
        const primaryLandTag = activeLandTags?.find(
          (a) => Number(a?.tag_id) === Number(item?.id) && a?.primary,
        )

        const secondaryLandTag = activeLandTags?.find(
          (a: any) => Number(a?.tag_id) === Number(item?.id) && !a?.primary,
        )

        return {
          ...item,
          secondary: !!secondaryLandTag?.active,
          tagLandIdSecondary: secondaryLandTag?.id,
          primary: primaryLandTag?.primary,
          tagLandIdPrimary: primaryLandTag?.id,
        }
      })
      ?.filter(Boolean)

    setSelectedTags(
      tags
        ?.filter((item: any) => item?.secondary || item?.primary)
        ?.sort((prev: any, curr: any) => {
          if (prev?.primary && curr?.secondary) {
            return -1
          }
          if (prev?.secondary && curr?.primary) {
            return 1
          }

          return 0
        }),
    )

    setNotSelectedTags(
      tags?.filter((item: any) => !item?.secondary && !item?.primary),
    )
  }, [uniqueTags, tagsbyId, activeLandTags])

  useEffect(() => {
    if (!searchQuery) {
      const tags = (uniqueTags?.length ? uniqueTags : [])
        ?.map((item: any) => {
          const primaryLandTag = activeLandTags?.find(
            (a) => Number(a?.tag_id) === Number(item?.id) && a?.primary,
          )

          const secondaryLandTag = activeLandTags?.find(
            (a: any) => Number(a?.tag_id) === Number(item?.id) && !a?.primary,
          )

          return {
            ...item,
            secondary: !!secondaryLandTag?.active,
            tagLandIdSecondary: secondaryLandTag?.id,
            primary: primaryLandTag?.primary,
            tagLandIdPrimary: primaryLandTag?.id,
          }
        })
        ?.filter(Boolean)

      setSelectedTags(
        tags
          ?.filter((item: any) => item?.secondary || item?.primary)
          ?.sort((prev: any, curr: any) => {
            if (prev?.primary && curr?.secondary) {
              return -1
            }
            if (prev?.secondary && curr?.primary) {
              return 1
            }

            return 0
          }),
      )

      setNotSelectedTags(
        tags?.filter((item: any) => !item?.secondary && !item?.primary),
      )
    } else {
      const tags = uniqueTags
        .filter((tag: { value: string }) =>
          tag.value.toLowerCase().includes(searchQuery.toLowerCase()),
        )
        ?.map((item: any) => {
          const primaryLandTag = activeLandTags?.find(
            (a) => Number(a?.tag_id) === Number(item?.id) && a?.primary,
          )

          const secondaryLaneTag = activeLandTags?.find(
            (a: any) => Number(a?.tag_id) === Number(item?.id) && !a?.primary,
          )

          return {
            ...item,
            secondary: !!secondaryLaneTag?.active,
            tagLandIdSecondary: secondaryLaneTag?.id,
            primary: primaryLandTag?.primary,
            tagLandIdPrimary: primaryLandTag?.id,
          }
        })

      setSelectedTags(
        tags
          ?.filter((item: any) => item?.secondary || item?.primary)
          ?.sort((prev: any, curr: any) => {
            if (prev?.primary && curr?.secondary) {
              return -1
            }
            if (prev?.secondary && curr?.primary) {
              return 1
            }

            return 0
          }),
      )

      setNotSelectedTags(
        tags?.filter((item: any) => !item?.secondary && !item?.primary),
      )
    }
  }, [searchQuery, activeLandTags])

  return (
    <TagChooserRoot>
      <Box sx={{ px: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <TextField
          placeholder={t('common.enterTagName')}
          name="value"
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          size="small"
          fullWidth
          data-cy="search-input-tag-land"
        />
      </Box>
      <Box
        height={`${height}px`}
        flexGrow={1}
        sx={{ overflowY: 'auto' }}
        marginBottom={1}
      >
        {!!selectedTags?.length && (
          <>
            <Stack spacing={1} p={2}>
              {selectedTags?.map((_tag) => (
                <Stack
                  key={_tag.id}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                  color={_tag?.color || '#00ade6'}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="ellipsis"
                    sx={{
                      background: _tag?.color || '#00ade6',
                      color: '#fff',
                      p: '6px 8px',
                      fontWeight: 400,
                      lineHeight: '12px',
                      width: 'fit-content',
                      borderRadius: '4px',
                    }}
                  >
                    {_tag.value}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '16px',
                    }}
                  >
                    <Box
                      onClick={() => {
                        if (_tag.primary) {
                          handleRemoveTag({
                            ..._tag,
                            tagLandId: _tag?.tagLandIdPrimary,
                          })
                        } else {
                          handleAddTag({
                            ..._tag,
                            newPrimary: true,
                          })
                        }
                      }}
                    >
                      <Tooltip
                        title={
                          _tag?.active && !_tag.primary
                            ? t('common.tag_land_modal.star')
                            : ''
                        }
                      >
                        <WhereToVoteIcon
                          className="checkIcon"
                          sx={{
                            color:
                              _tag?.active && _tag.primary
                                ? '#00ade6'
                                : '#00000028',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Box
                      onClick={() => {
                        if (_tag.secondary) {
                          handleRemoveTag({
                            ..._tag,
                            tagLandId: _tag?.tagLandIdSecondary,
                          })
                        } else {
                          if (_tag.primary) {
                            handleRemoveTag({
                              ..._tag,
                              tagLandId: _tag?.tagLandIdPrimary,
                            })
                          }
                          handleAddTag({
                            ..._tag,
                            newPrimary: false,
                          })
                        }
                      }}
                    >
                      <Tooltip
                        title={
                          _tag?.active && !_tag?.secondary
                            ? t('common.tag_land_modal.check')
                            : ''
                        }
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <SellOutlinedIcon
                          className="checkIcon"
                          sx={{
                            color:
                              _tag?.active && _tag?.secondary
                                ? '#00ade6'
                                : '#00000028',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </Stack>
            <Box
              sx={{
                borderTop: '1px solid #818b981f',
                borderBottom: '1px solid #818b981f',
                backgroundColor: '#f6f8fa',
                color: '#59636e',
                padding: '8px 16px',
                fontWeight: 500,
              }}
            >
              {t('common.Other tags available')}
            </Box>
          </>
        )}
        <Stack spacing={1} p={2}>
          {notSelectedTags?.map((_tag) => (
            <Stack
              key={_tag.id}
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              color={_tag?.color || '#00ade6'}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="ellipsis"
                sx={{
                  background: _tag?.color || '#00ade6',
                  color: '#fff',
                  p: '6px 8px',
                  fontWeight: 400,
                  lineHeight: '12px',
                  width: 'fit-content',
                  borderRadius: '4px',
                }}
              >
                {_tag.value}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Box
                  onClick={() => {
                    if (_tag.primary) {
                      handleRemoveTag({
                        ..._tag,
                        tagLandId: _tag?.tagLandIdPrimary,
                      })
                    } else {
                      handleAddTag({
                        ..._tag,
                        newPrimary: true,
                      })
                    }
                  }}
                >
                  <Tooltip
                    title={
                      _tag?.active && !_tag.primary
                        ? t('common.tag_land_modal.star')
                        : ''
                    }
                  >
                    <WhereToVoteIcon
                      className="checkIcon"
                      sx={{
                        color:
                          _tag?.active && _tag.primary
                            ? '#00ade6'
                            : '#00000028',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  onClick={() => {
                    if (_tag.secondary) {
                      handleRemoveTag({
                        ..._tag,
                        tagLandId: _tag?.tagLandIdSecondary,
                      })
                    } else {
                      if (_tag.primary) {
                        handleRemoveTag({
                          ..._tag,
                          tagLandId: _tag?.tagLandIdPrimary,
                        })
                      }
                      handleAddTag({
                        ..._tag,
                        newPrimary: false,
                      })
                    }
                  }}
                >
                  <Tooltip
                    title={
                      _tag?.active && !_tag?.secondary
                        ? t('common.tag_land_modal.check')
                        : ''
                    }
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <SellOutlinedIcon
                      className="checkIcon"
                      sx={{
                        color:
                          _tag?.active && _tag?.secondary
                            ? '#00ade6'
                            : '#00000028',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Box>
    </TagChooserRoot>
  )
}

export default TagChooser
