import { Select, formatPrice } from '@popety_io/popety-io-lib'
import { ValueRoot, ValueFieldBoxRoot } from './SelectField.style'

type SelectFieldProps = {
  options: any[]
  value: any
  isEdit: boolean
  startAdornment?: string
  isFormatPrice?: boolean
  valueDefault?: number | string | undefined
  endAdornment?: string
  sx?: any
  labelValue?: string
  onChangeValue: any
}

const SelectField = ({
  options,
  value,
  isEdit,
  startAdornment,
  isFormatPrice,
  valueDefault,
  endAdornment,
  labelValue,
  onChangeValue,
  ...other
}: SelectFieldProps) => {
  if (!isEdit) {
    return (
      <ValueRoot {...other} edited={!!value && value !== valueDefault}>
        {startAdornment}
        {isFormatPrice ? formatPrice(value) : value || '-'}
        {endAdornment}
      </ValueRoot>
    )
  }

  return (
    <ValueFieldBoxRoot {...other} width="100%">
      <Select
        onChange={onChangeValue}
        sx={{ 'MuiInputBase-root-MuiOutlinedInput-root': { height: 25 } }}
        size="small"
        options={options}
        value={labelValue}
        fullWidth
      />
    </ValueFieldBoxRoot>
  )
}

export default SelectField
