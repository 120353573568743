import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Caption, useI18n, Driver, fetchOnce } from '@popety_io/popety-io-lib'

import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import UnderExploitedContent from './UnderExploitedContent'
import getDriverInstructions from './driverInstructions'

const UnderExploited = () => {
  const [displayDriver, setDisplayDriver] = useState(false)
  const user = useSelector(userSelector)

  const { t } = useI18n()
  const dispatch = useDispatch<any>()

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedLandUnderExploitedDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedLandUnderExploitedDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Box>
      <UnderExploitedContent />

      <Box p={2} pt={0} pb={{ xs: 8, lg: 4 }}>
        <Caption>{t('land.underExploited.legendValue')}</Caption>
        <Caption color="#00ade6">
          {t('land.underExploited.legendEstimatedValue')}
        </Caption>
        <Caption color="#8ed1a5">
          {t('land.existingScore.legendOverideValue')}
        </Caption>
        <div style={{ marginTop: 10 }}>
          <Caption color="#9095A2">
            {t('land.source')}
            {t('land.underExploited.sources')}
          </Caption>
        </div>
      </Box>
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Box>
  )
}

export default UnderExploited
