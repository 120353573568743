// import { createSelector } from '@reduxjs/toolkit'

const projectsSelector = (state) => state.generativeDesign.projects

const generatedProjectsSelector = (state) =>
  state.generativeDesign.generatedProjects

const selectedDesignSelector = (state) => state.generativeDesign.selectedProject

export { projectsSelector, generatedProjectsSelector, selectedDesignSelector }
