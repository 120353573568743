/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import analizedLandService from './analizedLandService'

const initialState = {
  analyzedLands: undefined,
  analyzedGrouping: undefined,
  companyAnalyzedGrouping: undefined,
  companyAnalyzedLands: undefined,
  users: undefined,
  loading: false,
}

export const getAnalyzedLands = createAsyncThunk(
  'analizedLand/getAnalyzedLands',
  analizedLandService.getAnalyzedLands,
)

export const getAnalyzedLandsByUser = createAsyncThunk(
  'analizedLand/getAnalyzedLandsByUser',
  analizedLandService.getAnalyzedLandsByUser,
)

export const getAnalyzedGrouping = createAsyncThunk(
  'analizedLand/getAnalyzedGrouping',
  analizedLandService.getAnalyzedGrouping,
)

export const getCompanyAnalyzedGrouping = createAsyncThunk(
  'analizedLand/getCompanyAnalyzedGrouping',
  analizedLandService.getAnalyzedGrouping,
)

export const getCompanyUsers = createAsyncThunk(
  'analizedLand/getCompanyUsers',
  analizedLandService.getCompanyUsers,
)

const analizedLand = createSlice({
  name: 'analizedLand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder
      .addCase(getAnalyzedLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnalyzedLands.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAnalyzedLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.analyzedLands = data

        state.loading = false
      })
      .addCase(getCompanyUsers.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.users = data
      })
      .addCase(getAnalyzedLandsByUser.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnalyzedLandsByUser.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAnalyzedLandsByUser.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.companyAnalyzedLands = data

        state.loading = false
      })
      .addCase(getAnalyzedGrouping.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnalyzedGrouping.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAnalyzedGrouping.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.analyzedGrouping = data
        state.loading = false
      })
      .addCase(getCompanyAnalyzedGrouping.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanyAnalyzedGrouping.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCompanyAnalyzedGrouping.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.companyAnalyzedGrouping = data

        state.loading = false
      })
  },
})

export const analizedLandReducer = {
  analizedLand: analizedLand.reducer,
}
