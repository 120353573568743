import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import type { LandUsePlansOpts } from '../LandUsePlan'

import BuildingAreaValues from './BuildingAreaValues'
import BuildingAreaHeader from './BuildingAreaHeader'
import BuildingAreaInformations from './BuildingAreaInformations'
import { TableRoot } from './BuildingArea.style'

type BuildingAreaBodyProps = {
  open: boolean
  rowsArea: any
  selected: any
  handleEdit: (e: Event) => void
  std: number
  handleClick: () => void
  handleSave: (bool: boolean) => void
  handleChangeRadio: (e: React.ChangeEvent<HTMLInputElement>) => void
  rowsInformations: any
  lup: LandUsePlansOpts
}

const BuildingAreaBody = ({
  open,
  rowsArea,
  selected,
  handleEdit,
  std,
  handleClick,
  handleSave,
  handleChangeRadio,
  rowsInformations,
  lup,
}: BuildingAreaBodyProps) => {
  const stopPropagation = (func: () => void) => (e: Event) => {
    e.stopPropagation()
    func()
  }

  return (
    <TableContainer
      sx={{
        background: '#f9f9f9',
      }}
      component={Paper}
      elevation={0}
      data-cy="table-building-area-body"
    >
      <TableRoot $open={open} aria-label="simple table">
        <TableHead>
          <BuildingAreaHeader
            open={open}
            handleClick={stopPropagation(handleClick)}
            handleSave={handleSave}
            name={lup.name}
            planName={lup.plan_name}
          />
        </TableHead>
      </TableRoot>

      <TableRoot
        $open={open}
        sx={{ border: 'hidden' }}
        aria-label="simple table"
      >
        <TableBody>
          {rowsArea.map((row: any, i: number) => (
            <BuildingAreaValues
              key={i}
              open={open}
              row={row}
              selected={selected}
              std={std}
              handleEdit={handleEdit}
              handleChangeRadio={handleChangeRadio}
            />
          ))}
          <BuildingAreaInformations
            open={open}
            rows={rowsInformations}
            handleEdit={handleEdit}
          />
        </TableBody>
      </TableRoot>
    </TableContainer>
  )
}

export default BuildingAreaBody
