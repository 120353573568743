import { useRouter } from '@popety_io/popety-io-lib'
import { useEffect } from 'react'

const useRemoveFooter = () => {
  const { query, updateQuery } = useRouter()
  const fs = query.fs === '1'

  useEffect(() => {
    if (fs) return

    updateQuery({ fs: '1' }, true)
  }, [fs])
}

export { useRemoveFooter }
