import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const LandSearchRoot = styled(Paper)`
  width: 100%;

  > .Title {
    flex-grow: 1;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;

    > button {
      margin-right: -8px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }
`

export const SearchForm = styled(Box)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
`
