import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useI18n } from '@popety_io/popety-io-lib'

import { getIndexData } from '../EstimateAirQuality/EstimateAirQuality.utils'
import { estimateAirQualityDataSelector } from '../../redux'

const useAirQualityLayers = () => {
  const estimateAirQualityData = useSelector(estimateAirQualityDataSelector)

  const getColorByAQI = (aqi: number) => {
    const { t } = useI18n()

    const { items } = getIndexData(t)
    const matchedItem = items.find((item) => aqi <= item.max)

    return matchedItem ? matchedItem.color : '#000' // Default to black if no match
  }

  const airQualityPolygon = useMemo(() => {
    if (!estimateAirQualityData) {
      return null
    }

    const featuresWithColors =
      estimateAirQualityData?.data?.aqiGeojson.features.map((feature: any) => ({
        ...feature,
        properties: {
          ...feature.properties,
          color: getColorByAQI(feature.properties.aqi),
        },
      }))

    return {
      id: 'airQualityPolygon',
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: featuresWithColors,
      },
      layers: [
        {
          id: 'airQualityLayer',
          type: 'fill',
          paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.6,
          },
        },
      ],
    }
  }, [estimateAirQualityData])

  const layers = airQualityPolygon ? [airQualityPolygon] : []

  return { airQualityLayers: layers }
}

export { useAirQualityLayers }
