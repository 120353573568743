import Box from '@mui/material/Box'
import { Feature } from '@popety_io/popety-io-lib'

const RestrictionHover = ({ name }: Feature) => (
  <Box maxWidth="300px" padding="4px" bgcolor="rgba(255, 255, 255, 0.8)">
    {name}
  </Box>
)

export default RestrictionHover
