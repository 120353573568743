const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.transactions.startTitle'),
          description: t('driver.transactions.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#region-transaction-filter',
        popover: {
          title: t('driver.transactions.regionFilterTitle'),
          description: t('driver.transactions.regionFilterDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#transaction-filters',
        popover: {
          title: t('driver.transactions.transactionFilterTitle'),
          description: t('driver.transactions.transactionFilterDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#export-transactions',
        popover: {
          title: t('driver.transactions.exportTransactionsTitle'),
          description: t('driver.transactions.exportTransactionsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#alert-transactions',
        popover: {
          title: t('driver.transactions.alertTransactionsTitle'),
          description: t('driver.transactions.alertTransactionsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#view-transactions',
        popover: {
          title: t('driver.transactions.viewTransactionsTitle'),
          description: t('driver.transactions.viewTransactionsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
