import { ApiClient } from '../../../services'

const client = new ApiClient()

const getLandPreview = ({ landId }: { landId: string | string[] }) => {
  return client.get(`/landPreview/${landId}`)
}

const getListingInsights = (filter: Record<string, any>) => {
  return client.post('/listing/insights', filter)
}

const getListingRentAndBuyInsights = (filter: Record<string, any>) => {
  return Promise.all([
    getListingInsights({ ...filter, dealType: ['rent'] }),
    getListingInsights({ ...filter, dealType: ['purchase'] }),
  ])
}

const getListingRentAndBuyInsightsForAppartmentAndHouses = (
  filter: Record<string, any>,
) => {
  return Promise.all([
    getListingInsights({
      ...filter,
      dealType: ['rent'],
      propertyType: ['Apartment'],
    }),
    getListingInsights({
      ...filter,
      dealType: ['purchase'],
      propertyType: ['Apartment'],
    }),
    getListingInsights({
      ...filter,
      dealType: ['rent'],
      propertyType: ['House'],
    }),
    getListingInsights({
      ...filter,
      dealType: ['purchase'],
      propertyType: ['House'],
    }),
  ])
}

const getLandFaos = ({ landId }: { landId: string | string[] }) => {
  return client.get(`/land/${landId}/faos`)
}

const getLandTransactions = ({ landId }: { landId: string | string[] }) =>
  client.get(`/land/${landId}/transactions`)

const landPreviewService = {
  getLandPreview,
  getListingInsights,
  getListingRentAndBuyInsights,
  getLandFaos,
  getLandTransactions,
  getListingRentAndBuyInsightsForAppartmentAndHouses,
}

export default landPreviewService
