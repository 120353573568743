import { useEffect, useState, forwardRef } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Zoom from '@mui/material/Zoom'
import {
  Button,
  Modal,
  useMediaQuery,
  useI18n,
  ModalProps,
} from '@popety_io/popety-io-lib'

import { getDataList } from './HistoryMapClickModal.util'
import Description from './Descriptions'

export type DescriptionSwiperModalProps = ModalProps & {
  dataByLandId: any
  landId: string
  isRent?: boolean
  isPurchase?: boolean
  devOnly?: boolean
  value?: any
}

const Transition = forwardRef(function Transition(
  props: { children: any },
  ref: React.Ref<unknown>,
) {
  return <Zoom ref={ref} {...props} />
})

const DescriptionSwiperModal = (props: DescriptionSwiperModalProps) => {
  const {
    dataByLandId,
    landId,
    onClose,
    isRent,
    isPurchase,
    devOnly,
    value,
    ...other
  } = props
  const dataList: any[] = dataByLandId?.[landId]

  const filterDataList: any[] = getDataList(
    dataList,
    value,
    isRent,
    isPurchase,
    devOnly,
  )

  const { t } = useI18n()

  const [index, setIndex] = useState(0)
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleChangeIndex = (newIndex: number) => {
    setIndex(newIndex)
  }

  const handlePrevious = () => {
    setIndex((i) => i - 1)
  }

  const handleNext = () => {
    setIndex((i) => i + 1)
  }

  useEffect(() => {
    setIndex(0)
  }, [other.open])

  return (
    <Modal
      fullScreen={!onDesktop}
      {...other}
      onClose={onClose}
      TransitionComponent={Transition}
      transitionDuration={{ enter: 400, exit: 200 }}
      actions={
        <>
          <Button color="secondary" onClick={onClose}>
            {t('common.close')}
          </Button>

          {filterDataList?.length > 1 && (
            <>
              <Button
                color="secondary"
                onClick={handlePrevious}
                disabled={index === 0}
              >
                {t('common.previous')}
              </Button>

              <Button
                color="secondary"
                onClick={handleNext}
                disabled={index === filterDataList.length - 1}
              >
                {t('common.next')}
              </Button>
            </>
          )}
        </>
      }
    >
      {filterDataList?.length > 0 && (
        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          {filterDataList.map((data, i) => (
            <Description data={data} key={i} />
          ))}
        </SwipeableViews>
      )}
    </Modal>
  )
}

export default DescriptionSwiperModal
