import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

export const NoteBox = styled(Box)`
  height: calc(100% - 80px);

  > h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    position: sticky;
    top: 0;
    background: white;
    padding: 16px 8px;
    margin: 0;
  }
`

export const Note = styled(Box)`
  padding: 12px 12px 12px 0;

  &.grouping {
    box-shadow:
      0px 2px 6px 0px rgb(0 173 230),
      0px 2px 10px 0px rgb(0 173 230 / 90%),
      0px 4px 20px -2px rgb(0 173 230 / 80%);
  }
`

export const NoteInputBox = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;

  .MuiInputBase-root {
    width: 100%;
    margin-left: 8px;
    padding: 28px 0 7px;
  }

  .InputWrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
`

export const InputContainer = styled(Box)`
  width: 100%;
  overflow-y: auto;
  height: 360px;
`

export const NoteBadge = styled(Badge)`
  cursor: pointer;

  .MuiBadge-badge {
    border: 2px solid #eeeeee;
  }
`
