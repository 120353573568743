import { ApiClient } from '../../../../services'

const client = new ApiClient()

const addFavoriteBuilding = async (buildingId: string | string[]) => {
  return client.post(`/dashboard/favorites/buildings/add`, { buildingId })
}

const removeFavoriteBuilding = async (buildingId: string) => {
  return client.delete(`/dashboard/favorites/buildings/${buildingId}`)
}

const getFavoriteByBuildingIds = (buildingId: string | string[]) => {
  return client.get(`/dashboard/favorites/buildings/${buildingId}`)
}

const getMyFavoriteBuildings = async (search = '') => {
  return client.get(`/dashboard/favorites/buildings${search}`)
}

const getCompanyFavoriteBuildings = async (search = '') => {
  return client.get(`/dashboard/favorites/buildings${search}`)
}

const favoriteBuildingService = {
  addFavoriteBuilding,
  removeFavoriteBuilding,
  getFavoriteByBuildingIds,
  getMyFavoriteBuildings,
  getCompanyFavoriteBuildings,
}

export default favoriteBuildingService
