import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { useSelector } from 'react-redux'
import { toReadableNumber, useI18n } from '@popety_io/popety-io-lib'

import {
  altimetrySelector,
  geoLoadingSelector,
  landSelector,
  tiltAverageSelector,
} from '../redux'
import { StyledGridLabel, StyledGridValue } from './GeoDetails.style'

const GeoDetails = () => {
  const { t } = useI18n()
  const altimetry = useSelector(altimetrySelector)
  const land = useSelector(landSelector)
  const loading = useSelector(geoLoadingSelector)
  const tiltaverage = useSelector(tiltAverageSelector)

  return (
    <Grid container spacing={1}>
      <StyledGridLabel item xs={7}>
        {t('land.geo.areaEstimatedBy')}
      </StyledGridLabel>
      <StyledGridValue item xs={5}>
        {toReadableNumber(land.area)} m<sup>2</sup>
      </StyledGridValue>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {altimetry?.altitude_min && !loading && (
        <>
          <StyledGridLabel item xs={7}>
            {t('land.geo.minAltitude')}
          </StyledGridLabel>
          <StyledGridValue item xs={5}>
            {altimetry?.altitude_min} m
          </StyledGridValue>
        </>
      )}

      {altimetry?.altitude_max && !loading && (
        <>
          <StyledGridLabel item xs={7}>
            {t('land.geo.maxAltitude')}
          </StyledGridLabel>
          <StyledGridValue item xs={5}>
            {altimetry?.altitude_max} m
          </StyledGridValue>
        </>
      )}

      {!!tiltaverage && !loading && (
        <>
          <StyledGridLabel item xs={7}>
            {t('land.geo.averageSlope')}
          </StyledGridLabel>
          <StyledGridValue item xs={5}>
            {tiltaverage}
            {tiltaverage ? ' %' : ''}
          </StyledGridValue>
        </>
      )}
    </Grid>
  )
}

export default GeoDetails
