import Box from '@mui/material/Box'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Slide, Typography } from '@mui/material'
import React from 'react'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import EstimateTransactionDetailBody from './EstimateTransactionDetailComponent/EstimateTransactionDetailBody'

export type ListingListProps = {
  transaction: Record<string, any>
  setTransaction: React.Dispatch<React.SetStateAction<Record<string, any>>>
  onSave?: (listings: any) => void
  transactionsIds?: string[]
  onRemove?: (listing: any) => void
}

const EstimateTransactionDetail = ({
  transaction,
  setTransaction,
  onSave,
  onRemove,
  transactionsIds,
}: ListingListProps) => {
  const { t } = useI18n()
  const { updateQuery } = useRouter()

  const handleBackTransactions = () => {
    setTransaction({})
    updateQuery({
      transactionId: null,
    })
  }

  return (
    <Box
      mt={2}
      width="100%"
      sx={{
        display: transaction?.id || transaction?._id ? '' : 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          cursor: 'pointer',
        }}
        onClick={handleBackTransactions}
      >
        <ArrowBackIosIcon
          sx={{
            width: '0.8em',
            height: '0.8em',
          }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '1.1rem',
          }}
        >
          {t('estimate.comparables.retour à la liste')}
        </Typography>
      </Box>
      <Slide
        direction="left"
        timeout={1000}
        in={!!(transaction?.id || transaction?._id)}
        style={{
          width: '100%',
        }}
      >
        <Box>
          <Box
            data-cy="land-detail-accordionDetails"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <EstimateTransactionDetailBody
              transaction={transaction}
              transactionsIds={transactionsIds}
              onSave={onSave}
              onRemove={onRemove}
            />
          </Box>
        </Box>
      </Slide>
    </Box>
  )
}

export default EstimateTransactionDetail
