import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  openSideBar: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    handleSideBar: (state, { payload }: PayloadAction<boolean>) => {
      state.openSideBar = payload
    },
  },
})

export const { handleSideBar } = appSlice.actions

export const appReducer = { app: appSlice.reducer }
