import { ApiClient } from '../../../../services'

const client = new ApiClient()

type GetAltimetryInput = {
  landId: string | number
  precision?: number
  region?: string
}

const getAltimetry = async (input: GetAltimetryInput) => {
  const { landId, precision, region } = input

  return client.get(`/land/${landId}/altimetry/${region}/${precision}`)
}

const geoService = {
  getAltimetry,
}

export default geoService
