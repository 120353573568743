import { useEffect, useState } from 'react'
import { Box, LinearProgress, Typography, IconButton } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useResize } from '@popety_io/popety-io-lib'
import {
  ExcelIcon,
  PdfIcon,
  ImageFileIcon,
  GenericFileIcon,
} from '../../../../../components/FilesDialog/Icons'
import { formatSize } from '../../../../../components/FilesDialog/utils'
import { FileProps } from '../../../../../components/FilesDialog/types'

type EstimateFileItemProps = {
  file: FileProps
  onDeleteClick: (file: FileProps) => void
  handleDlFile: (fileId: FileProps['id'], name: any) => void
  loading: boolean
}

const fileIconMap = {
  '.png': ImageFileIcon,
  '.jpg': ImageFileIcon,
  '.jpeg': ImageFileIcon,
  '.ico': ImageFileIcon,
  '.tiff': ImageFileIcon,
  '.icon': ImageFileIcon,
  '.pdf': PdfIcon,
  '.xlsx': ExcelIcon,
  '.xls': ExcelIcon,
  '.csv': ExcelIcon,
}

const EstimateFileItem = ({
  file,
  onDeleteClick,
  handleDlFile,
  loading,
}: EstimateFileItemProps) => {
  const FileIcon =
    fileIconMap[file?.type as keyof typeof fileIconMap] || GenericFileIcon
  const [loadingProgress, setLoadingProgress] = useState(0)
  const { width, ref } = useResize()

  useEffect(() => {
    setLoadingProgress(10)
    if (loading) {
      const intervalId = setInterval(() => {
        setLoadingProgress((prevProgress) =>
          prevProgress < 100 ? prevProgress + 1 : prevProgress,
        )
      }, 60000 / 100)

      return () => clearInterval(intervalId)
    }
  }, [loading])

  return (
    <Box
      id="estimateFileItem"
      gap={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        gap={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          color: '#00ADE6',
          fontSize: '12px',
          ':hover': {
            color: '#00ADE6',
            '.file-item-subject': {
              color: '#041725 !important',
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            cursor: 'pointer',
          }}
          onClick={() => handleDlFile(file?.id, file?.name)}
          ref={ref}
        >
          <FileIcon width="12px" height="18px" />
          <Typography fontWeight={500}>{file.name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: '#969696' }}>
            {formatSize(file?.size || 0)}
          </Typography>
          <IconButton
            onClick={() => onDeleteClick(file)}
            disabled={loading}
            sx={{ paddingBottom: '0px', paddingTop: '0px' }}
          >
            <DeleteForeverIcon
              sx={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                color: '#969696',
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography
          component="span"
          variant="body2"
          fontSize="12px"
          className="file-item-subject"
        >
          {file?.subject || ''}
        </Typography>
        {loading && file.isPending ? (
          <LinearProgress
            sx={{
              width: `${width}px`,
              height: 2,
              marginTop: '2px',
            }}
            variant="determinate"
            value={loadingProgress}
          />
        ) : null}
      </Box>
    </Box>
  )
}

export default EstimateFileItem
