import { Modal, Table, useI18n, ModalProps } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import { buildColumns } from './LandOwners.utils'

export type OwnerLandsModalProps = ModalProps & {
  owner: string
  landList?: { result: any[] }
}

const OwnerLandsModal = ({
  owner,
  loading,
  landList,
  ...props
}: OwnerLandsModalProps) => {
  const { t } = useI18n()
  const columns = buildColumns({ t })

  return (
    <Modal {...props} maxWidth="sm" fullWidth>
      <Typography variant="h5" component="h2">
        {owner}
      </Typography>

      <Box color="#9095a2" mb={2}>
        {t('common.ownership.propertyFound')}
      </Box>
      <Table
        loading={loading}
        columns={columns as any}
        rows={landList?.result}
        count={landList?.result?.length}
        labelRowsPerPage={t('myTags.table.perPage')}
        title=" "
        sx={{
          '& .MuiTable-root': {
            minWidth: '100%',
          },
        }}
      />
      {loading && (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Modal>
  )
}

export default OwnerLandsModal
