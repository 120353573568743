import { Tag, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import useBuildingTagIcon from './useBuildingTagIcon'

type TagIconProps = {
  buildingIds: string | string[]
  display?: boolean
}

const BuildingTagIcon = ({ buildingIds, display }: TagIconProps) => {
  const { t } = useI18n()

  const tag = useBuildingTagIcon({ buildingId: buildingIds })

  return (
    <Box sx={{ mt: 0.5 }}>
      {display && (
        <Tag
          bgColor="#fff"
          onClick={tag.onClick}
          textColor="grey"
          style={{
            border: '1px solid grey',
          }}
        >
          {t('common.addLabel')}
        </Tag>
      )}

      {tag.Modal}
    </Box>
  )
}

export default BuildingTagIcon
