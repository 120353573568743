import { groupBy, useI18n, InfoBox } from '@popety_io/popety-io-lib'

import CollapseList from '../../../../LandDetails/Summary/CollapseList'

const LandSummaryRestrictions = ({ land }: any) => {
  const { t } = useI18n()

  const { unAffectedRdppf } = land
  const { regionRdppf } = land

  const rdppfs = Object.values(groupBy('rdppf_type_id', land?.rdppfs))

  const restrictionTypes = rdppfs
    ?.map((affection: any) => affection?.[0]?.name)
    .filter(Boolean)
    .map((v) => t(`land.restrictions.zone.${v}`))

  const data = [
    { label: t('land.affectedRestriction'), value: rdppfs?.length || '0' },
    {
      label: t('land.unAffectedRestriction'),
      value: unAffectedRdppf?.length || '0',
    },
    {
      label: t('land.notAvailableRestriction'),
      value:
        regionRdppf?.length && rdppfs?.length
          ? (regionRdppf?.length || 0) - (rdppfs?.length || 0)
          : '0',
    },
  ]

  const data2 = [
    {
      label: t('land.affectedRestrictionTypes'),
      value: (
        <CollapseList
          items={restrictionTypes}
          moreKey="common.seeMoreRestrictions"
        />
      ),
    },
  ]

  return (
    <InfoBox title={t('land.restrictions.title')} data={data} data2={data2} />
  )
}

export default LandSummaryRestrictions
