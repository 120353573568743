import ListingClickModal from './ListingClickModal'
// import ListingMapContent from './ListingMapContent'
import ListingMapHover from './ListingMapHover'
import ListingTiles from './ListingTiles'

export const ListingMapConfig = {
  Click: ListingClickModal,
  Hover: ListingMapHover,
  // Content: ListingMapContent,
  Controller: ListingTiles,
}
