/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { locationService } from './locationService'

const initialState = {
  cities: [],
  zones: [],
  regions: [],
  allAddresses: [],
  addresses: [],
  allAddressesCount: 0,
}

export const getZones = createAsyncThunk(
  'location/getZones',
  locationService.getZones,
)

export const getRegions = createAsyncThunk(
  'location/getRegions',
  locationService.getRegions,
)

export const getCities = createAsyncThunk(
  'location/getCities',
  locationService.getCities,
)

export const searchAllAddresses = createAsyncThunk(
  'searchAllAddresses',
  locationService.searchAllAddresses,
)

export const searchAddress = createAsyncThunk(
  'searchAddress',
  locationService.searchAddress,
)

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {
    resetAllAddresses: (state) => {
      state.allAddresses = []
      state.allAddressesCount = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getZones.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.zones = data
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.regions = data
      })
      .addCase(getCities.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.cities = data.map((city) => {
            return { ...city, geo_center: JSON.parse(city.geo_center) }
          })
        }
      })
      .addCase(searchAllAddresses.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.allAddresses = data.result
          state.allAddressesCount = data.total
        }
      })
      .addCase(searchAddress.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) state.addresses = data.data
      })
  },
})

// Action creators are generated for each case reducer function
export const { resetAllAddresses } = location.actions

export const locationReducer = { location: location.reducer }
