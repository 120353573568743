import React, { Fragment } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import WarningIcon from '@mui/icons-material/Home'
import { isValidHttpUrl, useI18n } from '@popety_io/popety-io-lib'

import { BuildingInfo, BuildingIconContainer } from './Building.style'

type ProtectedBuildingInformationsProps = {
  building: any
  open: boolean
  handleClose: () => void
  land: any
}

const InfoValue = ({ value, title }: { value: string; title: string }) =>
  isValidHttpUrl(value) ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={value}
      title={value}
      className="ellipsis"
    >
      {title}
    </a>
  ) : (
    <span dangerouslySetInnerHTML={{ __html: value }} />
  )

const ProtectedBuildingInformations = ({
  building,
  open,
  handleClose,
  land,
}: ProtectedBuildingInformationsProps) => {
  const { t } = useI18n()

  if (!building) return null

  const clickHere = t('common.clickHere')

  const chCoordinates =
    building?.center_ch_projection?.replace(';', ',') ||
    land?.center_ch_projection?.replace(';', ',')

  let infos = t('land.existingScore.noAssessment')

  if (Array.isArray(building?.extra_information_json)) {
    infos = building?.extra_information_json?.map(
      ({ title, data }: { title: string; data: any }) => {
        if (title !== 'Bâtiments recensés') return null

        let text =
          building.protected_status_description ||
          t('land.existingScore.unassessed')

        if (
          building.protected_status_description ===
          'Building without interest or disturbing.'
        ) {
          text = t('land.existingScore.without_interest_or_disturbing')
        } else if (
          building.protected_status_description ===
          'Building unprotected but well integrated.'
        ) {
          text = t('land.existingScore.unprotected_well_integrated')
        } else if (
          building.protected_status_description === 'Protected building.'
        ) {
          text = t('land.existingScore.protected')
        } else if (
          building.protected_status_description ===
          'Building unprotected but presenting qualities and defects.'
        ) {
          text = t('land.existingScore.unprotected_with_quality_and_defect')
        }

        return (
          <BuildingInfo key={building.id}>
            <BuildingIconContainer>
              <WarningIcon />
            </BuildingIconContainer>
            <h5>{text}</h5>
            <List>
              {data?.map((obj: any) => (
                <Fragment key={7}>
                  {Object?.entries(obj)?.map(([key, value]) => (
                    <ListItem key={key}>
                      <span>{key}</span>
                      {key !== 'Détails' ? (
                        <InfoValue
                          value={(value !== 'Null' ? value : 0) as string}
                          title={clickHere}
                        />
                      ) : (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`http://www.recensementarchitectural.vd.ch/territoire/recensementarchitectural/default.aspx?center=${chCoordinates}&scale=800`}
                        >
                          {t('common.clickHere')}
                        </a>
                      )}
                    </ListItem>
                  ))}
                </Fragment>
              ))}
            </List>
          </BuildingInfo>
        )
      },
    )
  }

  return (
    <Dialog
      aria-labelledby="building-extra-information-title"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="building-extra-information-title">
        {t('land.existingScore.extraInfo')}
      </DialogTitle>
      <DialogContent>{infos}</DialogContent>
    </Dialog>
  )
}

export default ProtectedBuildingInformations
