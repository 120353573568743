import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { NoteRichTextInput } from '../../../../../components/NoteRichTextInput'
import {
  estimateIdSelector,
  addEstimateNote,
  saveEditedEstimate,
  estimateEditedSelector,
} from '../../../redux'
import useEstimateNote from './useEstimateNotes'
import EstimateNoteList from './EstimateNoteList'

export const StyledSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
`

const EstimateNotes = () => {
  const estimateId = useSelector(estimateIdSelector)
  const estimateEdited = useSelector(estimateEditedSelector)

  const dispatch = useDispatch<any>()
  const ref = useRef<HTMLDivElement>()

  const { t } = useI18n()

  const note = useEstimateNote({ estimateId })

  const scrollToBottom = (opts?: any) => {
    const noteInput = ref.current?.querySelector<HTMLInputElement>('#noteInput')

    ref.current?.scrollTo({ top: noteInput?.offsetTop, ...opts })
  }

  const handleNoteAdd = async (content: string) => {
    const res = await dispatch(addEstimateNote({ estimateId, content }))

    if (res?.payload?.data) {
      dispatch(
        saveEditedEstimate({
          id: estimateId,
          body: { ...estimateEdited, notes: [res.payload.data?.[0]?.note_id] },
        }) as any,
      )
    }
    setTimeout(() => scrollToBottom({ behavior: 'smooth' }), 300)
  }

  return (
    <Box
      ref={ref}
      sx={{
        marginTop: '20px',
        marginBottom: '20px',
      }}
    >
      <StyledSpan>{t('estimate.commentaire')}</StyledSpan>
      <EstimateNoteList estimateId={estimateId} onMore={note.onClick}>
        {note.count < 1 && <NoteRichTextInput onConfirm={handleNoteAdd} />}
      </EstimateNoteList>
    </Box>
  )
}

export default EstimateNotes
