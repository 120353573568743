import React, { useEffect } from 'react'
import { Map, useRouter } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateSelector } from '../../Estimate/redux'
import { buildingSelector } from '../redux'
import { getBuildingTilesEnergy } from '../../LandDetails/Sunlight/BuildingShadow/BuildingTile.util'

interface EnergyMapControllerProps {
  map: Map
  loaded?: boolean
}

const EnergyMapController = ({ map, loaded }: EnergyMapControllerProps) => {
  const building = useSelector(buildingSelector)
  const estimate: any = useSelector(estimateSelector)
  const { query } = useRouter()
  const isEnergyTab = query.tab === 'energy'

  const addTiles = () => {
    if (building?.id || estimate?.id) {
      getBuildingTilesEnergy({
        map,
        buildingId: building?.id || estimate?.id,
        isEnergyTab,
        energyLayer: query?.energyLayer || '',
      })
    } else {
      getBuildingTilesEnergy({
        map,
        isEnergyTab,
        energyLayer: query?.energyLayer || '',
      })
    }
  }

  useEffect(() => {
    if (!map || !loaded) return

    addTiles()

    map.on('load', addTiles)

    return () => {
      map.off('load', addTiles)
    }
  }, [isEnergyTab, loaded, building, query.energyLayer, estimate])

  if (!isEnergyTab || !map || !loaded) return null

  return <></>
}

export default EnergyMapController
