import { useSelector } from 'react-redux'
import { ContentTitle, Score, useI18n } from '@popety_io/popety-io-lib'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { landSelector, tiltAverageSelector } from '../redux'
import LandScore from '../Summary/LandScore'

const GeoHeader = () => {
  const { t } = useI18n()
  const land = useSelector(landSelector)
  const tiltaverage = useSelector(tiltAverageSelector)

  const data = [
    {
      title: (
        <div>
          <span
            style={{
              display: 'inline',
              fontSize: '2rem',
              color: '#000',
              fontWeight: 'bold',
            }}
          >
            {tiltaverage || 0}
          </span>
          %
        </div>
      ),
      subTitle: t('land.geo.averageSlope'),
    },
    {
      title: (
        <Score value={land.average_building_year} color="#000" by100={false} />
      ),
      subTitle: t('land.fao.constructionYearSection'),
    },
    {
      title: (
        <LandScore
          score={land.geo_shape_score}
          title={t('land.geoScore.geoDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.existingScore'),
    },
  ]

  return (
    <Box
      position="sticky"
      top={0}
      pt={1}
      zIndex={10}
      bgcolor="background.paper"
    >
      <ContentTitle m={1} data={data} />
      <Divider />
    </Box>
  )
}

export default GeoHeader
