import { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useDispatch } from 'react-redux'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import { deleteTag } from '../../redux/tags'

const TagCardDelete = ({ tagId }: { tagId: string }) => {
  const { t } = useI18n()
  const dispatch = useDispatch<any>()

  const [openDelete, setOpenDelete] = useState(false)

  const handleDelete = () => {
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleDeleteAlert = () => {
    dispatch(deleteTag({ id: tagId }))
    setOpenDelete(false)
  }

  return (
    <Box display="flex" justifyContent="center">
      <Tooltip placement="top" title={t('common.delete')}>
        <IconButton
          aria-label="delete"
          onClick={handleDelete}
          className="delete-tag"
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        open={openDelete}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onClose={handleCloseDelete}
        onOk={handleDeleteAlert}
        title={t('common.delete')}
      >
        {t('myTags.delete')}
      </Modal>
    </Box>
  )
}

export default TagCardDelete
