import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const EstimateSearchRoot = styled(Paper)`
  width: 100%;
  box-shadow: none;

  > .Title {
    flex-grow: 1;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;

    > button {
      margin-right: -8px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .mapboxgl-ctrl-group {
    display: none !important;
  }

  #estimate-form-select-label {
    top: -7px;
  }
`

export const SearchForm = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FloorContainer = styled(Box)`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid gainsboro;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    border: 1px solid #00ade6;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }
`
