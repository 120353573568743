import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Body = styled(Box)`
  display: flex;
`

export const ContainerLand = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`

export const Land = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`

export const PreviewHeaderRoot = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  position: sticky;
  top: 0px;
  padding: 6px;
  padding-top: 10px;
  height: auto;
  z-index: 10;
`

export const LandSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`
