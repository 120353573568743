const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.listings.startTitle'),
          description: t('driver.listings.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#litings-region-filter',
        popover: {
          title: t('driver.listings.regionFilterTitle'),
          description: t('driver.listings.regionFilterDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#litings-filters',
        popover: {
          title: t('driver.listings.listingsFilterTitle'),
          description: t('driver.listings.listingsFilterDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#export-listing',
        popover: {
          title: t('driver.listings.exportListingsTitle'),
          description: t('driver.listings.exportListingsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#alert-listing',
        popover: {
          title: t('driver.listings.alertListingsTitle'),
          description: t('driver.listings.alertListingsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#view-listing',
        popover: {
          title: t('driver.listings.viewListingsTitle'),
          description: t('driver.listings.viewListingsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
