import { createSelector } from '@reduxjs/toolkit'
// import { getBuildingLayers } from './building.layers'

const landUsePlansByIdSelector = (state) =>
  state.underExploited.landUsePlansById

const landUsePlansSelector = createSelector(
  landUsePlansByIdSelector,
  (landUsePlansById) =>
    Object.values(landUsePlansById).sort((a, b) => {
      if (a.cover_area_perc < b.cover_area_perc) return 1

      return a.cover_area_perc > b.cover_area_perc ? -1 : 0
    }),
)

const specialPlansSelector = createSelector(
  landUsePlansSelector,
  (landUsePlans) =>
    landUsePlans?.filter(
      (lup) =>
        lup.plan_type === 'PLQ' ||
        lup.plan_type === 'PQ' ||
        lup.plan_type === 'PAD',
    ),
)

const landUsePlansDocumentsSelector = (state) =>
  state.underExploited.landUsePlansDocuments

const futureLandUsePlansDocumentsSelector = (state) =>
  state.underExploited.futureLandUsePlansDocuments

const sortByThemeDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    Object.entries(
      documents?.reduce((acc, document) => {
        acc[document.theme] = acc[document.theme] || []
        acc[document.theme].push(document)

        return acc
      }, {}),
    )?.map(([label, options]) => ({
      label,
      options,
    })),
)

const sortByOfficialIdDocumentsSelector = createSelector(
  sortByThemeDocumentsSelector,
  (sortDocuments) =>
    sortDocuments.map((documents) => ({
      label:
        documents.label && documents.label !== 'null'
          ? documents.label
          : 'other',
      options: Object.entries(
        documents.options?.reduce((acc, doc) => {
          acc[doc.official_type_id] = acc[doc.official_type_id] || []

          acc[doc.official_type_id].push(doc)

          return acc
        }, {}),
      )?.map(([label, options]) => ({ label, options })),
    })),
)

const legalProvisionDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    documents.filter(
      (document) => document.official_type_name === 'LegalProvision',
    ),
)

const importantDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) => documents.filter((document) => document.important),
)

const lawDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    documents.filter((document) => document.official_type_name === 'Law'),
)

const otherDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    documents.filter((document) => document.official_type_name === null),
)

const bigestLandUsePlanSelector = createSelector(
  landUsePlansSelector,
  (landUsePlans) =>
    landUsePlans.filter(
      (landUsePlan) => landUsePlan.cover_area_perc >= 0.5,
    )?.[0],
)

export {
  landUsePlansSelector,
  landUsePlansByIdSelector,
  specialPlansSelector,
  futureLandUsePlansDocumentsSelector,
  sortByOfficialIdDocumentsSelector,
  legalProvisionDocumentsSelector,
  importantDocumentsSelector,
  lawDocumentsSelector,
  otherDocumentsSelector,
  bigestLandUsePlanSelector,
  landUsePlansDocumentsSelector,
}
