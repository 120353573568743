import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

const LivabilitySkeleton = () => {
  return (
    <Box
      sx={{
        marginBottom: 2,
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: '1.5rem', borderRadius: 2, width: '6%' }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1.5rem', borderRadius: 2, width: '18%' }}
        />
        <Skeleton
          variant="text"
          sx={{
            fontSize: '1.5rem',
            borderRadius: 2,
            width: '16%',
            marginLeft: 'auto',
            marginRight: 4,
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Skeleton
          variant="text"
          sx={{ fontSize: '1.5rem', borderRadius: 2, width: '15%' }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1.5rem', borderRadius: 2, width: '15%' }}
        />
        <Skeleton
          variant="text"
          sx={{ fontSize: '1.5rem', borderRadius: 2, width: '15%' }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          marginTop: 1,
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: 2,
            height: '6rem',
            marginRight: 4,
          }}
        />
      </Box>
    </Box>
  )
}

export default LivabilitySkeleton
