import Box from '@mui/material/Box'
import { Feature, format } from '@popety_io/popety-io-lib'

const ListingMapHover = ({ listing_timestamp, title }: Feature) => {
  const text = [format(listing_timestamp, 'P'), title]
    .filter(Boolean)
    .join(' - ')

  return (
    <Box maxWidth="300px" padding="4px" bgcolor="rgba(255, 255, 255, 0.8)">
      {text}
    </Box>
  )
}

export default ListingMapHover
