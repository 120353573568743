import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

export const ListingAnalyticCardRoot = styled(Card)`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-height: 130px;

  .gray {
    font-size: 0.8rem;
    color: rgb(154, 174, 187);
  }

  .title {
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(0, 173, 230);
  }
`

export const PriceContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 100%;

  .MuiCircularProgress-root {
    margin: 8px auto;
  }

  .priceCategory {
    font-size: 0.8rem;
    color: rgb(154, 174, 187);
  }

  .price {
    font-size: 1.1rem;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #00ade6;
  }
`

export const Location = styled(Box)`
  flex-grow: 1;
  text-align: center;
  width: 100%;

  div {
    font-weight: 600;
    font-size: 1rem;
    color: rgb(154, 174, 187);
    text-transform: uppercase;
  }

  span {
    font-size: 1rem;
    margin-bottom: 16px;
    margin-top: 8px;
  }
`

export const BulletItem = styled(Box)`
  height: 8px;
  width: 8px;
  border-style: solid;
  border-width: 0.5px;
  border-color: ${({ color = 'darkgrey' }) => color};
  margin-right: 4px;
  border-radius: 50px;
  background-color: ${({ color }) => color};
`

export const BulletContainer = styled(Box)`
  padding: 8px 0;

  display: flex;
  align-items: center;
`

export const BulletToolTipContainer = styled(Paper)`
  padding: 16px;

  > div {
    display: flex;
    align-items: flex-start;

    > span {
      margin-left: 16px;
    }
  }
`

export const BulletPopper = styled(Popper)`
  z-index: 1000;
`

export const CardHeaderRoot = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;

  > div:last-child {
    width: 160px;
  }

  span {
    font-size: 11px;
  }
`

export const PricePercentRoot = styled(Grid)`
  text-align: center;

  span {
    margin-bottom: 8px;
  }

  p {
    color: ${({ color }) => color};
  }
`

export const ChartContainer = styled(Box)`
  width: 100%;
  flex-grow: 1;
  margin-top: 16px;
`

export const NewListingsRoot = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > div {
    width: 160px;
  }
`

export const CustomTooltipPriceRoot = styled(Box)`
  background-color: #fff;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid rgb(154, 174, 187);

  > .price {
    font-size: 0.9rem;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #00ade6;
  }
`
