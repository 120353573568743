import {
  EventHistoryLegend,
  EventHistoryController,
} from '../../../components/EventHistory'

export { default } from './LandEventHistory'

export const LandEventHistoryMapConfig = {
  Content: EventHistoryLegend,
  Controller: EventHistoryController,
}
