import ClearIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useI18n, format } from '@popety_io/popety-io-lib'
import { getUserId } from '../../services'

export type Action = {
  id: string
  action: string
  first_name: string
  last_name: string
  alarm_date?: Date
  content?: string
  photo?: string
  update_timestamp?: Date
  user_id?: string
  create_timestamp: Date
}

export type NoteBodyProps = {
  action: Action
  onDelete: (action?: Action) => void
  onOpen: (action?: Action) => void
}

const NoteBody = ({ action, onDelete, onOpen }: NoteBodyProps) => {
  const { t } = useI18n()
  const isNote = action.action === 'note'
  const isReminder = action.action === 'reminder'
  const isFavorite = !isReminder && !isNote
  const isUser = getUserId() === action.user_id

  const handleOpen = () => onOpen(action)

  const handleDelete = () => onDelete(action)

  return (
    <Box flexGrow={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="32px"
      >
        <Typography
          className="subtitle"
          sx={{
            fontWeight: 500,
            fontSize: '1rem',
          }}
        >{`${action.first_name} ${action.last_name}`}</Typography>

        <Box display="flex" alignItems="center" mr={-1.5}>
          <Typography variant="body2" fontSize="12px" color="text.secondary">
            {format(action.update_timestamp || action.create_timestamp, 'PP')}
          </Typography>

          {isUser && (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <IconButton
                sx={{
                  visibility: !isNote ? 'hidden' : undefined,
                  px: 0.2,
                  py: 0.2,
                  ml: 0.8,
                }}
                aria-label="edit"
                onClick={handleOpen}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                sx={{ px: 0.2, py: 0.2 }}
                aria-label="close"
                onClick={handleDelete}
                data-cy="delete-note-land"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      <Typography
        variant="h6"
        sx={{
          fontWeight: 400,
          fontSize: '1rem',
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
          width: '100%',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          },
        }}
      >
        {isNote && (
          <div dangerouslySetInnerHTML={{ __html: action.content || '' }} />
        )}
        {isReminder &&
          action.alarm_date &&
          `${t('common.notes.sendMeReminder')} ${format(
            action.alarm_date,
            'PPP',
          )}`}
        {isFavorite && t('common.notes.addedToMyFavorite')}
      </Typography>
    </Box>
  )
}

export default NoteBody
