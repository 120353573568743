/* eslint-disable max-lines */
// TODO: fix issue with turf info
import * as turf from '@turf/turf'
import { AlertData } from '../components/AlertsCardActions/AlertsCardActions.utils'

export type Alert = AlertData & {
  multiPolygon: { coordinates: any[]; [k: string]: any }
  [k: string]: any
}

const featuresToMultiPolygon = (features: any[]) => {
  const feature = features.reduce(
    (acc, v) => [...acc, v.geometry.coordinates[0]],
    [],
  )

  const { geometry } = turf.multiPolygon(feature)

  const coordinates = geometry?.coordinates.reduce(
    (acc: any, coord: any) => [...acc, [coord]],
    [],
  )

  return { type: 'MultiPolygon', coordinates }
}

const multiPolygonToFeatures = (multiPolygon: any) => {
  const features =
    multiPolygon?.coordinates?.map((coordinates: any, i: number) => ({
      type: 'Feature',
      id: i + 1,
      geometry: { type: 'Polygon', coordinates },
    })) || []

  return { type: 'FeatureCollection', features }
}

export { featuresToMultiPolygon, multiPolygonToFeatures }
