const getLivabilityIsochroneLayers = (isochrone: Record<string, any>) => {
  if (!isochrone?.features) return []

  const features = isochrone.features.map((f: any) => ({
    ...f,
    properties: { ...f.properties, category: f.category },
  }))

  const data = { type: 'FeatureCollection', features }

  return [
    {
      id: 'livability-isochrone',
      type: 'geojson',
      data,
      layers: [
        {
          id: 'livability-isochrone-Layer',
          type: 'fill',
          paint: {
            'fill-opacity': 0.33,
            'fill-color': {
              property: 'category',
              default: 'rgba(106, 191, 64, 0.8)',
              type: 'categorical',
              stops: [
                ['walking', 'rgba(106, 191, 64, 0.8)'],
                ['bike', 'rgba(191, 170, 64, 0.8)'],
                ['drive', 'rgba(191, 64, 64, 0.8)'],
              ],
            },
          },
        },
        {
          id: 'livabilityIsochroneLineLayer',
          type: 'line',
          paint: {
            'line-width': 0.3,
          },
        },
      ],
    },
  ]
}

export { getLivabilityIsochroneLayers }
