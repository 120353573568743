import { Box } from '@mui/material'
import { PopetyMaps } from '@popety_io/popety-io-lib'
import { FC } from 'react'
import { MAPBOX_TOKEN } from '../../../../config'

interface IProps {
  lon: number
  lat: number
  data: any
  zoom: number
  pitch?: number
  dimension?: '2D' | '3D'
}

const EstimateSearchMap: FC<IProps> = ({
  lon,
  lat,
  data,
  zoom,
  pitch = 0,
  dimension = '2D',
  ...other
}) => {
  if (!lon || !lat) return null

  return (
    <Box width="auto" height="100px">
      <PopetyMaps
        token={MAPBOX_TOKEN}
        isDragPan={false}
        center={{ lon, lat }}
        data={data}
        zoom={zoom}
        isDimension={false}
        dimension={dimension}
        pitch={pitch}
        {...other}
      />
    </Box>
  )
}

export default EstimateSearchMap
