import { fromLocalStorage } from './storage'

// TODO: HANDLE APP USER (LANDDEV)

const stateKey = 'developerMap'

export const getMapDisplayState = () => {
  const user = fromLocalStorage('user', true)
  const displayMapState = fromLocalStorage(stateKey, true) || {}

  const isBroker = !user?.roles?.includes('User_landdev')

  if (displayMapState?.mapDisplay !== 'building_area' && isBroker) {
    displayMapState.mapDisplay = 'building_area'
    displayMapState.thematic = null
  }

  return displayMapState
}
