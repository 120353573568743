/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { getMapDisplayState, fromLocalStorage } from '../../../../utils'

const stateKey = 'developerMap'

const defaultThematic = { value: 'development_score' }

const initialState = {
  landThematic:
    getMapDisplayState()?.mapDisplay ||
    getMapDisplayState()?.landThematic ||
    'development_score',
  buildingLandThematic:
    getMapDisplayState()?.buildingLandThematic || 'popety_classification',
  thematic: fromLocalStorage('thematic', true) || null,
  buildingThematic: fromLocalStorage('buildingThematic', true) || null,
  thematicOpacity: fromLocalStorage('thematicOpacity')
    ? Number(fromLocalStorage('thematicOpacity'))
    : 80,
}

const thematic = createSlice({
  name: 'thematic',
  initialState,
  reducers: {
    changeLandThematic: (state, action) => {
      const { payload } = action
      const thematic = payload || defaultThematic

      state.landThematic = thematic.value
      state.thematic = thematic
      localStorage.setItem(stateKey, JSON.stringify(state))
      localStorage.setItem('thematic', JSON.stringify(thematic))
    },
    changeBuildingThematic: (state, action) => {
      const { payload } = action

      state.buildingLandThematic = payload.value
      state.buildingThematic = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
      localStorage.setItem('buildingThematic', JSON.stringify(payload))
    },
    setThematicOpacity: (state, { payload }) => {
      state.thematicOpacity = Number(payload)
      localStorage.setItem('thematicOpacity', String(payload))
    },
  },
})

export const thematicReducer = {
  thematic: thematic.reducer,
}

export const {
  changeLandThematic,
  changeBuildingThematic,
  setThematicOpacity,
} = thematic.actions
