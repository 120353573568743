/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import overridedLandUsePlanService from './overridedLandUsePlanService'

const initialState = {
  overrided: { developmentGroupedPlots: [], underExploitedGroupedPlots: [] },
}

export const getOverridedLandUsePlans = createAsyncThunk(
  'overridedLandUsePlan/getOverridedLandUsePlans',
  overridedLandUsePlanService.getOverridedLandUsePlans,
)

const overridedLandUsePlan = createSlice({
  name: 'overridedLandUsePlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOverridedLandUsePlans.fulfilled, (state, action) => {
      const { data = [] } = action.payload || {}

      state.overrided = data
    })
  },
})

export const overridedLandUsePlanReducer = {
  overridedLandUsePlan: overridedLandUsePlan.reducer,
}
