import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

export const StyledGridLabel = styled(Grid)`
  color: #9095a2;
`

export const StyledGridValue = styled(Grid)`
  color: #00ade6;
`
