import { i18n } from '@popety_io/popety-io-lib'

export interface HeatAndWaterKeyTypes {
  heatGenerator: string
  energySource: string
  informationSource: string
  updatedYear: string
}

export const HeatingKeys: HeatAndWaterKeyTypes = {
  heatGenerator: 'heater_generator',
  energySource: 'power_source_heater',
  informationSource: 'heater_datasource',
  updatedYear: 'last_update_timestamp_heater',
}

export const HotWaterKeys: HeatAndWaterKeyTypes = {
  heatGenerator: 'heater_water_generator',
  energySource: 'power_source_water_heater',
  informationSource: 'water_heater_datasource',
  updatedYear: 'last_update_timestamp_water_heater',
}

export const AdditionHeatingKeys: HeatAndWaterKeyTypes = {
  heatGenerator: 'additional_heater_generator',
  energySource: 'power_source_additional_heater',
  informationSource: 'additional_heater_datasource',
  updatedYear: 'last_update_timestamp_additional_heater',
}

export const AdditionHotWaterKeys: HeatAndWaterKeyTypes = {
  heatGenerator: 'additional_heater_water_generator',
  energySource: 'power_source_additional_water_heater',
  informationSource: 'additional_water_heater_datasource',
  updatedYear: 'last_update_timestamp_additional_water_heater',
}

export const getHWEditedValues = (
  values: any,
  defaultValues: any,
  valueEdited: any,
): any => {
  const obj: Record<string, string | number | null> = {}

  Object.keys(values).forEach((key: any) => {
    obj[key] = values?.[key] || null

    obj[`${key}_edited`] =
      obj[key] !== defaultValues?.[key]
        ? obj[key]
        : valueEdited?.[`${key}_edited`]
  })

  return obj
}

export const getHWEditedStates = (values: any, keys: HeatAndWaterKeyTypes) => {
  const obj: HeatAndWaterKeyTypes = {
    heatGenerator: '',
    energySource: '',
    informationSource: '',
    updatedYear: '',
  }

  Object.keys(keys).forEach((k: string) => {
    const key = k as keyof HeatAndWaterKeyTypes

    obj[key] = values?.[`${keys[key]}_edited`]
  })

  return obj
}

export const getHWDefaultStates = (values: any, keys: HeatAndWaterKeyTypes) => {
  const obj: HeatAndWaterKeyTypes = {
    heatGenerator: '',
    energySource: '',
    informationSource: '',
    updatedYear: '',
  }

  Object.keys(keys).forEach((k: string) => {
    const key = k as keyof HeatAndWaterKeyTypes

    obj[key] = values?.[keys[key]]
      ? i18n.t(`common.${values?.[keys[key]]}`)
      : ''

    if (key === 'updatedYear' && obj[key]) {
      obj[key] = values?.[keys[key]]
    }
  })

  return obj
}
