import { useI18n } from '@popety_io/popety-io-lib'
import { AddressesRoot, StyledSpan, TitleSpan } from './Adresses.style'

const Addresses = ({ feature, click }: any) => {
  const { t } = useI18n()
  const addresses: string[] = feature?.addresses || []

  if (!addresses.length) return null

  return (
    <AddressesRoot>
      {feature?.address_full || (addresses && addresses.length > 0) ? (
        <>
          {!click ? (
            <>
              <StyledSpan>{feature.address_full || addresses[0]}</StyledSpan>
              <i>
                {(feature.address_number > 1 || addresses?.length > 1) &&
                  `+ ${feature.address_number - 1 || addresses.length - 1} ${t(
                    'common.search.details.otherAdress',
                  )}`}
              </i>
            </>
          ) : (
            <>
              <TitleSpan>{t('common.search.details.address')}</TitleSpan>
              {addresses.map((adress: any, index: number) => (
                <span key={index}>{adress}</span>
              ))}
            </>
          )}
        </>
      ) : (
        <span>{t('common.search.details.noAdresses')}</span>
      )}
    </AddressesRoot>
  )
}

export default Addresses
