import Grid from '@mui/material/Grid'
import MSelect from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { useRouter } from '@popety_io/popety-io-lib'
import { FormRange } from '../../../../components/FormRange'

interface ListingMinMaxFilterProps {
  label: string
  labelId: string
  unit?: string
}

const ListingMinMaxFilter = ({
  label,
  labelId,
  unit,
}: ListingMinMaxFilterProps) => {
  const { query } = useRouter()

  const queryArray = query[labelId]?.split(',')
  let title = ''

  if (
    (queryArray && queryArray?.length === 1) ||
    (queryArray?.length > 1 && queryArray[1] === '')
  ) {
    title = queryArray[0]
  } else if (queryArray && queryArray?.length > 1) {
    title = queryArray.join(' - ')
  }

  return (
    <Grid
      item
      xs={12}
      sm={4}
      md={2}
      sx={{
        display: 'flex',
        pt: '16px',
      }}
    >
      <FormControl fullWidth>
        <InputLabel
          shrink={false}
          id={labelId}
          style={{
            marginLeft: 10,
            top: '50%',
            transform: 'translate(0,-50%',
            borderColor: query[labelId] && title !== '' ? '#00ace6' : 'grey',
            color: query[labelId] && title !== '' ? '#00ace6' : 'grey',
          }}
        >
          {query[labelId] && title !== '' ? `${title} ${unit}` : label}
        </InputLabel>
        <MSelect
          labelId={labelId}
          size="small"
          sx={{
            width: '100%',
            '& .MuiSelect-select': {
              py: '8px',
              border:
                query[labelId] && title !== ''
                  ? `1.5px solid #00ace6`
                  : 'default',
            },
            color: query[labelId] && title !== '' ? '#00ace6' : 'grey',
            // borderColor: query[labelId] ? 'black' : 'grey',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: {
              style: {
                padding: '8px',
              },
            },
            MenuListProps: {
              style: {
                padding: 0,
              },
            },
          }}
          displayEmpty
        >
          <FormRange name={labelId} />
        </MSelect>
      </FormControl>
    </Grid>
  )
}

export default ListingMinMaxFilter
