import { getYearColor, Map } from '@popety_io/popety-io-lib'
import { API_BASE_URL } from '../../../../services'

const renderMethod = 'aggs'

const sourceName = 'es_mvt'
const fillStyle = 'layer_fill'
const sourceBuildingName = 'es_mvt_building'
const fillBuildingStyle = 'layer_fill_building'

const removeFaoTiles = ({ map }: { map: Map }) => {
  if (map.getSource(sourceName)) {
    map.removeLayer(fillStyle)
    map.removeSource(sourceName)
  }

  if (map.getSource(sourceBuildingName)) {
    map.removeLayer(fillBuildingStyle)
    map.removeSource(sourceBuildingName)
  }
}

const buildingsFaoTiles = ({ map, query }: { map: Map; query: any }) => {
  try {
    if (map.getSource(sourceBuildingName)) {
      map.removeLayer(fillBuildingStyle)
      map.removeSource(sourceBuildingName)
    }

    const tiles = [
      `${API_BASE_URL}/developerMap/building/tiles?query=${query}&x={x}&y={y}&z={z}`,
    ]

    map.addSource(sourceBuildingName, {
      type: 'vector',
      tiles,
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer({
      id: fillBuildingStyle,
      type: 'fill-extrusion',
      source: sourceBuildingName,
      minzoom: 13,
      'source-layer': 'hits',
      paint: {
        'fill-extrusion-color': '#fff',
        'fill-extrusion-height': [
          'coalesce',
          ['get', 'height'],
          ['*', ['get', 'floor_nb'], 3],
        ],
      },
    })
  } catch (error) {
    console.error(error)
  }
}

const getFaoTiles = ({ map, query }: { map: Map; query: any }) => {
  try {
    if (map.getSource(sourceName)) {
      map.removeLayer(fillStyle)
      map.removeSource(sourceName)
    }

    map.addSource(sourceName, {
      type: 'vector',
      tiles: [
        `${API_BASE_URL}/land/faos/tiles?query=${query}&x={x}&y={y}&z={z}`,
      ],
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer({
      id: fillStyle,
      type: 'circle',
      source: sourceName,
      'source-layer': renderMethod,
      paint: {
        'circle-radius': 8,
        'circle-color': 'rgb(0, 173, 230)',
        'circle-opacity': 0.5,
        'circle-stroke-color': 'rgb(0, 173, 230)',
        'circle-stroke-opacity': 1,
        'circle-stroke-width': 1,
      },
    })

    map.setFilter(fillStyle, [
      'any',
      ['==', ['geometry-type'], 'Point'],
      ['==', ['geometry-type'], 'MultiPoint'],
    ])
  } catch (error) {
    console.error(error)
  }
}

const getFaoAroudLayers = (
  faos: any[],
  yearRange: number[],
  startDate?: Date | string,
  devOnly?: boolean,
) => {
  const data = {
    type: 'FeatureCollection',
    features:
      faos
        ?.map((fao: any[]) => {
          const years = fao?.map((f) =>
            Number(f.inquiry_start_date.substring(0, 4)),
          )
          const classifications = fao?.map((f) => f.classification)

          const faoYears = years?.filter(
            (year) => year >= yearRange[0] && year <= yearRange[1],
          )

          if (faoYears?.length <= 0) return null

          if (
            devOnly &&
            !(
              classifications?.includes('development') ||
              classifications?.includes('development_beta')
            )
          ) {
            return null
          }

          const index = years?.findIndex((year) => year === faoYears[0]) || 0

          const properties = JSON.parse(JSON.stringify(fao?.[index] || {}))

          const geometry = properties.land_geojson
            ? JSON.parse(properties.land_geojson)
            : []

          delete properties.land_geojson

          const date = properties?.inquiry_start_date
          const year = date ? new Date(date).getFullYear() : undefined

          properties.color = getYearColor(year, startDate)

          return { type: 'Feature', geometry, properties }
        })
        .filter(Boolean) || [],
  }

  return [
    {
      id: 'fao',
      type: 'geojson',
      data,
      layers: [
        {
          id: 'fao-layer',
          type: 'fill',
          paint: { 'fill-color': ['get', 'color'] },
          click: true,
          hover: true,
        },
      ],
    },
  ]
}

export { getFaoAroudLayers, getFaoTiles, removeFaoTiles, buildingsFaoTiles }
