/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { groupBy, useRouter, useI18n } from '@popety_io/popety-io-lib'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import LandScore from '../Summary/LandScore'

import {
  groupedPoisSelector,
  poiCategoriesSelector,
  updateLivabilityOptions,
} from '../redux'

import {
  LivabilityDescriptionRoot,
  TitleRoot,
  StyledViewMoreLess,
} from './LivabilityDescription.style'

const categoryOrder = [
  'transport',
  'grocery',
  'education',
  'dining',
  'healthcare',
  'culture',
  'shopping',
  'wellness',
  'amenities',
]

const LivabilityDescription = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const groupedPoiCategories = useSelector(groupedPoisSelector)
  const poiCategory = useSelector(poiCategoriesSelector)
  // const [showMore, setShowMore] = useState(false)
  const [sortedCategories, setSortedCategories] = useState<any>([])
  const { query, updateQuery } = useRouter()
  const { poisCategories, poiGroupName } = query

  const filteredPois = poisCategories || ''

  const poisCategoriesArray = poisCategories
    ?.replaceAll(', ', '%%')
    ?.split(',')
    ?.map((i: string) => i?.replaceAll('%%', ', '))

  const handleCheck = (e: any) => {
    let poisFiltered = ((poisCategories as string) || '')
      .replaceAll(`${e.target.name}`, '')
      .replace(/,*$/, '')

    if (!e.target.checked) {
      poisFiltered = poisFiltered
        ? `${poisFiltered},${e.target.name}`
        : e.target.name
    }
    updateQuery({ poisCategories: poisFiltered })
    dispatch(updateLivabilityOptions({ poisFiltered }))
  }

  const handleToggle = (key?: string) => () => {
    if (key === poiGroupName) return updateQuery({ poiGroupName: undefined })

    updateQuery({ poiGroupName: key })
  }

  useEffect(() => {
    const currentData = [...groupedPoiCategories]
    const sortedData = currentData.sort((a, b) => {
      const aCategoryIndex = categoryOrder.indexOf(a[0].categoryKey)
      const bCategoryIndex = categoryOrder.indexOf(b[0].categoryKey)

      return aCategoryIndex - bCategoryIndex
    })

    setSortedCategories(sortedData)
  }, [groupedPoiCategories])

  return (
    <LivabilityDescriptionRoot>
      {sortedCategories.map((category: any, index: number) => {
        const title = category?.[0]?.category || ''
        const subCategories = Object.keys(groupBy('subcategory', category))
        const categoryKey = category?.[0]?.categoryKey || ''

        const find = poiCategory.find(
          (cat: { name: string }) => title === cat.name,
        )

        const sortedCategory = category
          .sort(
            (a: { distance: number }, b: { distance: number }) =>
              a.distance - b.distance,
          )
          .filter(
            (cat: {
              geo_center_lat?: number
              geo_center_lon?: number
              geo_polygon_lat?: number
              geo_polygon_lon?: number
            }) =>
              (cat.geo_center_lat && cat.geo_center_lon) ||
              (cat.geo_polygon_lat && cat.geo_polygon_lon),
          )
          .filter(
            (poi: { subcategory: string }) =>
              !poisCategoriesArray?.includes(poi.subcategory),
          )

        const more = poiGroupName === title

        const content = more ? sortedCategory : sortedCategory?.slice(0, 3)

        const sortedLength = sortedCategory.length

        return (
          <div key={index} id="land-livability">
            <TitleRoot>
              <div className="imgRoot">
                <div className={`svgContent ${categoryKey}`}>
                  <img
                    alt={title}
                    src={`/pin/${categoryKey}.svg`}
                    width={20}
                    height={20}
                  />
                </div>
                <h3>{t(`land.livabilityScore.${title}`)}</h3>
              </div>
              <LandScore
                score={((Number(find?.score?.toFixed(1)) * 10) as any) || ''}
                subValue={100}
                showLegend={false}
                sx={{
                  '& h6': {
                    fontSize: '1.539rem',
                  },
                }}
              />
            </TitleRoot>
            <FormGroup className="formGroup paddingLeft" sx={{ mt: 1, mb: 1 }}>
              {subCategories.map((sub: string) => (
                <FormControlLabel
                  key={sub}
                  name={sub}
                  onChange={handleCheck}
                  control={
                    <Checkbox
                      checked={!filteredPois?.includes(sub)}
                      size="small"
                    />
                  }
                  label={t(`land.livability.subCategory.${sub}`)}
                  className="formGroup"
                  sx={{
                    height: '20px',
                  }}
                />
              ))}
            </FormGroup>
            <StyledViewMoreLess
              key={title}
              more={more}
              lessText={t('common.seeLess')}
              moreText={
                sortedLength - 3 < 1
                  ? ''
                  : t('common.commodities.seeMore', {
                      number: sortedLength - 3,
                    })
              }
              onToggle={handleToggle(title) as any}
            >
              {content.map(
                (poi: {
                  id: number | string
                  name?: string
                  distance: number
                  type: string
                }) => (
                  <div key={poi.id} className="spanRoot">
                    <span>
                      {t(`land.livability.type.${poi.type}`)}{' '}
                      {poi.name && `- ${poi.name}`}
                    </span>
                    <span className="spanGrey">{poi.distance}m</span>
                  </div>
                ),
              )}
            </StyledViewMoreLess>
          </div>
        )
      })}
    </LivabilityDescriptionRoot>
  )
}

export default LivabilityDescription
