import { Map } from '@popety_io/popety-io-lib'
import { API_BASE_URL } from '../../../../services'

const tileSource = 'tileSource'
const tileLayer = 'tileLayer'

const tileSourceEnergy = 'tileSourceEnergy'
const tileLayerEnergy = 'tileLayerEnergy'

const layerEnergy = (property: string) => ({
  id: tileLayerEnergy,
  type: 'fill-extrusion',
  source: tileSourceEnergy,
  minzoom: 13,
  'source-layer': 'hits',
  paint: {
    'fill-extrusion-color': {
      property,
      default: '#fff',
      type: 'categorical',
      stops: [
        ['Mazout', '#000000'],
        ['Gaz', '#1f78b4'],
        ['Indéterminé', '#ccc'],
        ['Bois (générique)', '#b15928'],
        ['Aucune', '#b2df8a'],
        ['Electricité', '#a6cee3'],
        ['Chaleur produite à distance (générique)', '#e31a1c'],
        ['Air', '#fdbf6f'],
        ['Sonde géothermique', '#ff7f00'],
        ['Soleil (thermique)', '#33a02c'],
      ],
    },
    'fill-extrusion-height': [
      'coalesce',
      ['get', 'height'],
      ['*', ['get', 'floor_nb'], 3],
      3,
    ],
  },
})

const layer = {
  id: tileLayer,
  type: 'fill-extrusion',
  source: tileSource,
  minzoom: 13,
  'source-layer': 'hits',
  paint: {
    'fill-extrusion-color': {
      property: 'popety_classification',
      default: '#fff',
      type: 'categorical',
      stops: [
        ['Retail', '#fff'],
        ['Residential', '#fff'],
        ['Public', '#fff'],
        ['Other', '#fff'],
        ['Offices', '#fff'],
        ['Not classified', '#fff'],
        ['Mixed_2', '#fff'],
        ['Mixed_1', '#fff'],
        ['Industrial', '#fff'],
        ['Garage', '#fff'],
      ],
    },
    'fill-extrusion-height': [
      'coalesce',
      ['get', 'height'],
      ['*', ['get', 'floor_nb'], 3],
      3,
    ],
  },
}

const getBuildingTilesEnergy = ({
  isEnergyTab,
  map,
  buildingId,
  energyLayer,
}: {
  map: Map
  energyLayer: string
  isEnergyTab?: boolean
  buildingId?: string
}) => {
  const tiles = [
    `${API_BASE_URL}/developerMap/building/tiles?&x={x}&y={y}&z={z}${
      buildingId ? `&building_id=${buildingId}` : ''
    }`,
  ]

  try {
    if (map.getLayer(tileLayerEnergy)) {
      map.removeLayer(tileLayerEnergy)
    }
    if (map.getSource(tileSourceEnergy)) {
      map.removeSource(tileSourceEnergy)
    }
  } catch (error) {
    console.error(error)
  }

  if (!isEnergyTab || energyLayer === 'solar_average_exposure') return

  try {
    map.addSource(tileSourceEnergy, {
      type: 'vector',
      tiles,
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer(layerEnergy(energyLayer) as any)
  } catch (error) {
    console.error(error)
  }
}

const getBuildingTiles = ({
  isSunlightTab,
  map,
  buildingId,
}: {
  map: Map
  isSunlightTab?: boolean
  buildingId?: string
}) => {
  const tiles = [
    `${API_BASE_URL}/developerMap/building/tiles?&x={x}&y={y}&z={z}${
      buildingId ? `&building_id=${buildingId}` : ''
    }`,
  ]

  try {
    if (map.getSource(tileSource)) {
      map.removeLayer(tileLayer)
      map.removeSource(tileSource)
    }
  } catch (error) {
    console.error(error)
  }

  if (!isSunlightTab) return

  try {
    map.addSource(tileSource, {
      type: 'vector',
      tiles,
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer(layer as any)
  } catch (error) {
    console.error(error)
  }
}

export { getBuildingTiles, getBuildingTilesEnergy }
