import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const getTagBuildings = async ({
  isShared,
  isMap,
}: {
  isShared: boolean
  isMap?: boolean
}) => {
  let request = `/dashboard/tags/buildings?preview=true&all=${isShared}`

  request += isMap ? `&userId=${getUserId()}` : ''

  return client.get(request)
}

const getDashboardTagBuildings = async (search = '') => {
  return client.get(`/dashboard/tags/buildings${search}&active=true`)
}

const addBuildingTag = async (tags: any) => {
  return client.post(`/dashboard/tags/buildings/add`, { tags })
}

const updateBuilding = async (data: any) => {
  const response = await client.put(
    `/dashboard/buildings/tags/${data.tag_id}`,
    data,
  )

  if (response.data) {
    response.data = { ...response.data, ...data }
  }

  return response
}

const removeBuildingTag = async ({
  tagBuildingId,
  buildingId,
}: {
  tagBuildingId: string
  buildingId: string
}) => {
  const data = await client.delete(`/dashboard/buildings/tags/${tagBuildingId}`)

  return { ...data, buildingId }
}

const deleteBuildingTag = async (tagId: string) => {
  return client.delete(`/dashboard/tags/${tagId}`)
}

const isTagBuilding = (buildingId: string) => {
  return client.get(`/dashboard/tags/buildings/${buildingId}`)
}

const getAllBuildingTagsName = async () => {
  return client.get(`/dashboard/tags/all`)
}

const updateBuildingTag = async ({
  tag,
  tagBuildingId,
}: {
  tag: { tag_id: string }
  tagBuildingId: string
}) => {
  return client.put(`/dashboard/buildings/tags/${tag.tag_id}/${tagBuildingId}`)
}

const buildingTagService = {
  getTagBuildings,
  getDashboardTagBuildings,
  addBuildingTag,
  updateBuilding,
  removeBuildingTag,
  deleteBuildingTag,
  isTagBuilding,
  getAllBuildingTagsName,
  updateBuildingTag,
}

export default buildingTagService
