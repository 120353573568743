import { fetchGeoJSON, ID } from '@popety_io/popety-io-lib'

import { ApiClient, API_BASE_URL } from '../../../../services'

const client = new ApiClient()

const getLandPreview = ({ landId }: { landId: ID | ID[] }) => {
  return client.get(`/developerMap/land/tiles/details?landId=${landId}`)
}

const getBuildingPreview = ({ buildingId }: { buildingId: ID | ID[] }) => {
  return client.get(
    `/developerMap/building/tiles/details?buildingId=${buildingId}`,
  )
}

type GetPreviewOptions = {
  id: ID | ID[]
  type?: 'building' | 'land'
}

const getMapTilePreview = ({ id, type = 'land' }: GetPreviewOptions) => {
  if (type === 'building') return getBuildingPreview({ buildingId: id })

  return getLandPreview({ landId: id })
}

type GetTilePreviewByGeocenterOpts = {
  center?: string[] | number[]
  type?: 'building' | 'land'
  searchType?: 'center' | 'address' | 'land' | 'building'
  address?: string
  search?: any
  source?: string
}

const getTilePreview = ({
  type = 'land',
  searchType = 'center',
  search,
  source = 'id,land_id,lands.id,egid',
}: GetTilePreviewByGeocenterOpts) => {
  const data = fetchGeoJSON(
    `${API_BASE_URL}/developerMap/${type}/tiles/details?${searchType}=${search}&select=${source}`,
    {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      locale: localStorage.getItem('language') || 'en',
    },
  )

  if (data) {
    const landId =
      data.land_id || data.lands?.map((l: any) => l.id).filter(Boolean)

    const buildingId = type === 'building' ? data.id : undefined

    const previewId = type === 'building' ? data.buildingId : data.landId

    if (data.geo_center) {
      const { lat, lon } = data.geo_center

      return {
        landId,
        buildingId,
        previewId,
        lat,
        lon,
        location_type: type,
      }
    }

    return { landId, buildingId, previewId }
  }

  return undefined
}

const previewService = {
  getLandPreview,
  getTilePreview,
  getBuildingPreview,
  getMapTilePreview,
}

export default previewService
