import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

export const BuildingCardDetailsRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  line-height: 1rem;
  background: #f9f9f9;
  padding: 16px;
  margin-top: 8px;

  .TitleBox {
    h6 {
      font-weight: 500;
      font-size: 0.834rem;
      color: #041725;
    }
  }
`

export const Label = styled(Typography)`
  font-weight: 500;
  font-size: 0.769rem;
  display: block;
  width: 108px;
`

export const Value = styled(Typography)`
  font-weight: 500;
  font-size: 0.769rem;
  color: #041725;
  line-height: 0.5rem;
`

export const TitleBox = styled(Box)`
  h6 {
    font-weight: 500;
    font-size: 1rem;
    color: #041725;
  }

  p {
    font-style: italic;
  }
`
