const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.faoHistory.startTitle'),
          description: t('driver.faoHistory.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#fao-region-filter',
        popover: {
          title: t('driver.faoHistory.regionFilterTitle'),
          description: t('driver.faoHistory.regionFilterDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#fao-filters',
        popover: {
          title: t('driver.faoHistory.faoFilterTitle'),
          description: t('driver.faoHistory.faoFilterDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#export-fao',
        popover: {
          title: t('driver.faoHistory.exportFaoTitle'),
          description: t('driver.faoHistory.exportFaoDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#alert-fao',
        popover: {
          title: t('driver.faoHistory.alertFaoTitle'),
          description: t('driver.faoHistory.alertFaoDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#view-fao',
        popover: {
          title: t('driver.faoHistory.viewFaoTitle'),
          description: t('driver.faoHistory.viewFaoDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
