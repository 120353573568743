import { formatPrice } from '@popety_io/popety-io-lib'

const getQuarterDate = (dateString: string) => {
  const d = new Date(dateString)
  let m = Math.floor(d.getMonth() / 3) + 1

  m -= m > 4 ? 4 : 0
  const y = d.getFullYear().toString().substring(2, 4)

  return `Q${m}'${y}`
}

const CustomizedXQuartertoYearLabel = ({
  x,
  y,
  payload,
}: Record<string, any>) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={10}
      fontSize="0.7em"
      fill="#666"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {getQuarterDate(payload.value)}
    </text>
  </g>
)

export const CustomizedPriceLabel = ({
  x,
  y,
  payload,
}: Record<string, any>) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={10}
      fontSize="0.7em"
      fill="#666"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formatPrice(payload.value)}
    </text>
  </g>
)

export default CustomizedXQuartertoYearLabel
