/* eslint-disable prettier/prettier */
import { groupBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'

import { getListingAroudLayers } from './listing.layers'

const landListingsSelector = (state) => state.listing.listings?.filter(Boolean)

const landListingsTotalSelector = createSelector(
  landListingsSelector,
  (listings = []) => listings.length
)

const landListingsLoadingSelector = (state) => state.listing.loading

const listingsAroundSelector = (state) => state.listing.listingAround

const rentListingsAroundSelector = createSelector(
  listingsAroundSelector,
  (listings = []) => listings.filter((res) => res.deal_type === 'rent')
)

const purchaseListingsAroundSelector = createSelector(
  listingsAroundSelector,
  (listings = []) => listings.filter((res) => res.deal_type === 'purchase')
)

const listingsAroundCountSelector = (state) =>
  state.listing.listingAround?.length

const landListingsCountSelector = (state) => state.listing.count

const listingSinceSelector = createSelector(
  listingsAroundSelector,
  (listings = []) =>
    listings.length
      ? Math.min(
        ...listings.map((listing) => new Date(listing?.listing_timestamp))
      )
      : undefined
)

const listingsByLandIdSelector = createSelector(
  listingsAroundSelector,
  (listings = []) => groupBy('land_id', listings)
)

const listingsSetSelector = createSelector(
  listingsByLandIdSelector,
  (listings = {}) => Object.values(listings)
)

const listingSinceYearSelector = createSelector(listingSinceSelector, (date) =>
  date ? new Date(date).getFullYear() : undefined
)

const listingRangeSelector = (state) => state.listing.listingRange

const rentSelector = (state) => state.listing.rent

const purchaseSelector = (state) => state.listing.purchase

const listingAroundLayersSelector = createSelector(
  listingsSetSelector,
  listingRangeSelector,
  listingSinceSelector,
  rentSelector,
  purchaseSelector,
  getListingAroudLayers
)

const listingAggsSelector = (state) => state.listing.aggregations

const listingAggsRentSelector = createSelector(
  listingAggsSelector,
  (aggs) => aggs.price_over_time_rent?.price_over_time?.buckets
)

const listingAggsPurchaseSelector = createSelector(
  listingAggsSelector,
  (aggs) =>
    aggs.price_over_time_purchase_per_square_meter?.price_over_time?.buckets,
)

const listingAggsApartmentRentSelector =
  (state) => state.listing.apartmentAggs
    ?.price_over_time_rent?.price_over_time?.buckets


const listingAggsHouseRentSelector =
  (state) => state.listing.houseAggs
    ?.price_over_time_rent?.price_over_time?.buckets


const listingAggsApartmentPurchaseSelector =
  (state) => state.listing.apartmentAggs?.price_over_time_purchase
    ?.price_over_time?.buckets


const listingAggsHousePurchaseSelector =
  (state) => state.listing.houseAggs
    ?.price_over_time_purchase?.price_over_time?.buckets

const apartmentAggsLoadingSelector = (state) =>
  state.listing?.apartmentAggs?.isLoading

const HouseAggsLoadingSelector = (state) =>
  state.listing?.houseAggs?.isLoading

const selectedEstimateListingsSelector =
  (state) => state.listing.selectedEstimateListings

export {
  landListingsSelector,
  listingsAroundSelector,
  listingSinceSelector,
  listingsSetSelector,
  listingsByLandIdSelector,
  listingSinceYearSelector,
  listingAroundLayersSelector,
  listingRangeSelector,
  rentSelector,
  purchaseSelector,
  listingsAroundCountSelector,
  landListingsCountSelector,
  listingAggsSelector,
  listingAggsRentSelector,
  listingAggsPurchaseSelector,
  rentListingsAroundSelector,
  purchaseListingsAroundSelector,
  listingAggsApartmentPurchaseSelector,
  listingAggsApartmentRentSelector,
  listingAggsHouseRentSelector,
  listingAggsHousePurchaseSelector,
  apartmentAggsLoadingSelector,
  HouseAggsLoadingSelector,
  landListingsLoadingSelector,
  landListingsTotalSelector,
  selectedEstimateListingsSelector
}
