import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getIsochrone as getIsochroneLayer } from '../../../../utils'

import livabilityService from './livabilityService'

const initialState = {
  pois: [],
  isochrone: {},
  walkingIsochrone: {},
  bikingIsochrone: {},
  drivingIsochrone: {},
  poiCategories: [],
  category: 'All',
  display: true,
  transportType: 'walking',
  loading: true,
  poisAreLoading: true,
}

export const getPoisAround = createAsyncThunk(
  'livability/getPoisAround',
  livabilityService.getPoisAround,
)

export const getIsochrone = createAsyncThunk(
  'livability/getIsochrone',
  getIsochroneLayer,
)

export const getWalkingIsochrone = createAsyncThunk(
  'livability/getWalkingIsochrone',
  getIsochroneLayer,
)

export const getBikingIsochrone = createAsyncThunk(
  'livability/getBikingIsochrone',
  getIsochroneLayer,
)

export const getDrivingIsochrone = createAsyncThunk(
  'livability/getDrivingIsochrone',
  getIsochroneLayer,
)

export const getPoiCategories = createAsyncThunk(
  'livability/getPoiCategories',
  livabilityService.getPoiCategories,
)

const livability = createSlice({
  name: 'livability.',
  initialState,
  reducers: {
    updateLivabilityOptions: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPoisAround.pending, (state) => {
        state.poisAreLoading = true
      })
      .addCase(getPoisAround.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.pois = data
        state.poisAreLoading = false
      })
      .addCase(getPoisAround.rejected, (state) => {
        state.poisAreLoading = false
      })
      .addCase(getPoiCategories.pending, (state) => {
        state.loading = true
      })
      .addCase(getPoiCategories.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.poiCategories = data

        state.loading = false
      })
      .addCase(getPoiCategories.rejected, (state) => {
        state.loading = false
      })
      .addCase(getIsochrone.fulfilled, (state, action) => {
        const { payload } = action

        if (!payload) {
          state.isochrone = {}
        } else {
          state.isochrone = {
            ...payload,
            features: payload.features?.map((f, index) => {
              if (index === 0) return { ...f, category: 'drive' }
              if (index === 1) return { ...f, category: 'bike' }
              if (index === 2) return { ...f, category: 'walking' }

              return 0
            }),
          }
        }
      })
      .addCase(getWalkingIsochrone.fulfilled, (state, action) => {
        const { payload } = action

        if (!payload) {
          state.walkingIsochrone = {}
        } else {
          state.walkingIsochrone = {
            ...payload,
            features: payload.features?.map((f, index) => {
              if (index === 0) return { ...f, category: 'drive' }
              if (index === 1) return { ...f, category: 'bike' }
              if (index === 2) return { ...f, category: 'walking' }

              return 0
            }),
          }
        }
      })
      .addCase(getBikingIsochrone.fulfilled, (state, action) => {
        const { payload } = action

        if (!payload) {
          state.bikingIsochrone = {}
        } else {
          state.bikingIsochrone = {
            ...payload,
            features: payload.features?.map((f, index) => {
              if (index === 0) return { ...f, category: 'drive' }
              if (index === 1) return { ...f, category: 'bike' }
              if (index === 2) return { ...f, category: 'walking' }

              return 0
            }),
          }
        }
      })
      .addCase(getDrivingIsochrone.fulfilled, (state, action) => {
        const { payload } = action

        if (!payload) {
          state.drivingIsochrone = {}
        } else {
          state.drivingIsochrone = {
            ...payload,
            features: payload.features?.map((f, index) => {
              if (index === 0) return { ...f, category: 'drive' }
              if (index === 1) return { ...f, category: 'bike' }
              if (index === 2) return { ...f, category: 'walking' }

              return 0
            }),
          }
        }
      })
  },
})

export const livabilityReducer = {
  livability: livability.reducer,
}

export const { updateLivabilityOptions } = livability.actions
