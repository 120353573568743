import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@mui/material'

const FaoDetailFooter = ({
  transaction,
  transactionsIds,
  onSave,
  onRemove,
}: {
  transaction: any
  transactionsIds: string[] | undefined
  onSave?: (transaction: any) => void
  onRemove?: (transaction: any) => void
}) => {
  const { t } = useI18n()
  const transactionId = transaction?.id || transaction?._id || ''

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#ffffff',
        paddingTop: 1,
        paddingBottom: 1,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {transactionsIds?.find((id) => Number(id) === Number(transactionId)) ? (
          <Button
            startIcon={<RemoveIcon />}
            sx={{
              backgroundColor: '#FF0000',
              color: 'white',
              borderRadius: '2px',
              padding: '8px',
              '&:hover': { backgroundColor: '#CC0000' },
            }}
            onClick={() => onRemove?.(transaction)}
          >
            <Typography>{t('common.remove_from_comparables')}</Typography>
          </Button>
        ) : (
          <Button
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#00ade6',
              color: 'white',
              borderRadius: '2px',
              padding: '8px',
              '&:hover': { backgroundColor: '#0dbef8' },
            }}
            onClick={() => onSave?.(transaction)}
          >
            <Typography>{t('common.add_to_comparables')}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default FaoDetailFooter
