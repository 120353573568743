import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetBuildingOpts = {
  buildingId?: string | number | string[] | number[]
  json?: any
}

const getConfigurationEdited = async (opts: GetBuildingOpts) => {
  const { buildingId } = opts

  const data = await client.get(`/building/${buildingId}/configurationEdit`)

  return data
}

const updateConfigurationEdited = async (opts: GetBuildingOpts) => {
  const { buildingId, json } = opts

  await client.put(
    `/building/${buildingId}/detailedEditing/configuration`,
    json,
  )

  return json
}

const configurationServices = {
  getConfigurationEdited,
  updateConfigurationEdited,
}

export default configurationServices
