/* eslint-disable max-lines */
const iterateClassificationsOverTime = (obj: any, date?: Date) => {
  if (obj?.classifications_over_time?.classifications_over_time) {
    const { buckets } = obj.classifications_over_time.classifications_over_time

    const results: any = []

    buckets.forEach((item: any) => {
      const bucket = item
      const year = new Date(bucket?.key).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      })

      if (!date || date < new Date(bucket?.key)) {
        let developmentCount = 0
        let otherCount = 0

        for (let j = 0; j < bucket?.classifications?.buckets?.length; j += 1) {
          const classification = bucket?.classifications?.buckets[j]

          if (
            classification?.key === 'development' ||
            classification?.key === 'development_beta'
          ) {
            developmentCount += classification.doc_count
          } else if (
            classification?.key !== 'other' ||
            classification?.key !== 'other_beta'
          ) {
            otherCount += classification.doc_count
          }
        }

        const total = bucket.doc_count

        results.push({ year, total, developmentCount, otherCount })
      }
    })

    return results
  }
}

const iterateReasonsOverTime = (obj: any) => {
  if (obj?.reasons_over_time?.reasons_over_time) {
    const { buckets } = obj.reasons_over_time.reasons_over_time

    const results: any = []

    const availableReasons: string[] = []

    buckets.forEach((item: any) => {
      const bucket = item
      const year = new Date(bucket?.key).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      })
      const resultArray: any = []

      bucket?.reason?.buckets.forEach((obj: any) => {
        const existingObj = resultArray.find(
          (item: any) => item.key === obj.key,
        )
        const existingReason = availableReasons.find(
          (item: string) => item === obj.key,
        )

        if (!existingReason) {
          availableReasons.push(obj.key)
        }

        if (existingObj) {
          existingObj.totalCount += obj.doc_count
        } else {
          resultArray.push({
            [obj.key]: obj.doc_count,
          })
        }
      })

      const allAReasons = {}

      resultArray.forEach((item: any) => {
        Object.assign(allAReasons, item)
      })

      const total = bucket.doc_count

      results.push({ year, total, ...allAReasons })
    })

    const percent =
      obj?.reasons?.reasons?.buckets.map(
        (type: { key: string; doc_count: number }) => ({
          name: type?.key,
          value: Math.round((type.doc_count * 100) / obj.reasons.doc_count),
        }),
      ) || []

    return {
      title: 'reasons',
      stats: results,
      availableData: availableReasons.filter(Boolean),
      percent,
    }
  }
}

const iterateOwnershipOverTime = (obj: any) => {
  if (obj?.ownership_types_over_time?.ownership_types_over_time) {
    const { buckets } = obj.ownership_types_over_time.ownership_types_over_time

    const results: any = []

    const availableOwnerships: string[] = []

    buckets.forEach((item: any) => {
      const bucket = item
      const year = new Date(bucket?.key).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      })
      const resultArray: any = []

      bucket?.ownership_type?.buckets.forEach((obj: any) => {
        const existingObj = resultArray.find(
          (item: any) => item.key === obj.key,
        )
        const existingOwnerships = availableOwnerships.find(
          (item: string) => item === obj.key,
        )

        if (!existingOwnerships) {
          availableOwnerships.push(obj.key)
        }

        if (existingObj) {
          existingObj.totalCount += obj.doc_count
        } else {
          resultArray.push({
            [obj.key]: obj.doc_count,
          })
        }
      })

      const allAOwnerships = {}

      resultArray.forEach((item: any) => {
        Object.assign(allAOwnerships, item)
      })

      const total = bucket.doc_count

      results.push({ year, total, ...allAOwnerships })
    })

    const percent = obj?.ownership_types?.ownership_types?.buckets.map(
      (type: { key: string; doc_count: number }) => ({
        name: type?.key,
        value: Math.round(
          (type.doc_count * 100) / obj.ownership_types.doc_count,
        ),
      }),
    )

    return {
      title: 'ownerships_types',
      stats: results,
      availableData: availableOwnerships.filter(Boolean),
      percent,
    }
  }
}

export {
  iterateClassificationsOverTime,
  iterateReasonsOverTime,
  iterateOwnershipOverTime,
}
