import { ApiClient } from '../../../../services'

const client = new ApiClient()

type LandUsePlansOpts = {
  landUsePlans: Record<string, any>[]
}

const getLandUsePlansDetails = ({ landUsePlans }: LandUsePlansOpts) => {
  const landUsePlansIds = landUsePlans.map((landUsePlan: any) => landUsePlan.id)

  return client.get(`/land/landUsePlans/${landUsePlansIds}`)
}

// type OverideValuesProps = {
//   data: any
//   landId: number
//   landUsePlanAssignmentId: number
// }

type DifferenceValuesProps = {
  landUsePlans: { id: string }[]
  futureLandUsePlans: { id: string }[]
}

const addOverideValuesAssignment = async ({
  data,
  landId,
  landUsePlanAssignmentId,
  landUsePlanStatus,
}: any) => {
  return client.post(
    `/land/landUsePlans/overide/assignment/${landUsePlanStatus}/${landId}/${landUsePlanAssignmentId}`,
    data,
  )
}

const addGroupingOverideValuesAssignment = async ({
  data,
  groupingId,
  landUsePlanAssignmentId,
  landUsePlanStatus,
}: any) => {
  return client.post(
    `/land/landUsePlans/grouping/overide/assignment/${landUsePlanStatus}/${groupingId}/${landUsePlanAssignmentId}`,
    data,
  )
}

const updateGroupingOverideValuesAssignment = async ({
  data,
  id,
  landUsePlanStatus,
}: any) => {
  if (data.overide?.primaryIndice) {
    delete data.overide?.primaryIndice
  }
  if (data.overide?.secondaryIndice) {
    delete data.overide?.secondaryIndice
  }

  const res = await client.put(
    `/land/landUsePlans/grouping/overide/assignment/update/${landUsePlanStatus}/${id}`,
    data,
  )

  return {
    data: {
      ...data,
      land_use_plan_assignment_id: res.data?.land_use_plan_assignment_id,
    },
  }
}

const updateOverideValues = async ({ data, id, landUsePlanStatus }: any) => {
  if (data.overide?.primaryIndice) {
    delete data.overide?.primaryIndice
  }
  if (data.overide?.secondaryIndice) {
    delete data.overide?.secondaryIndice
  }

  const res = await client.put(
    `/land/landUsePlans/overide/assignment/update/${landUsePlanStatus}/${id}`,
    data,
  )

  return {
    data: {
      ...data,
      land_use_plan_assignment_id: res.data?.land_use_plan_assignment_id,
    },
  }
}

const getLandUsePlanDocuments = async (landUsePlans: { id: string }[]) => {
  return client.get(
    `/land/landUsePlans/${landUsePlans
      .map((plan) => plan.id)
      .join(',')}/documents`,
  )
}

const getFutureLandUsePlanDocuments = async (
  futureLandUsePlans: { id: string }[],
) => {
  return client.get(
    `/land/landUsePlans/${futureLandUsePlans
      .map((plan) => plan.id)
      .join(',')}/future/documents`,
  )
}

const getDifferenceLandUsePlanDocuments = async ({
  landUsePlans,
  futureLandUsePlans,
}: DifferenceValuesProps) => {
  let current = []
  let future = []

  future = await client.get(
    `/land/landUsePlans/${futureLandUsePlans
      .map((plan) => plan.id)
      .join(',')}/future/documents`,
  )

  current = await client.get(
    `/land/landUsePlans/${landUsePlans
      .map((plan) => plan.id)
      .join(',')}/documents`,
  )

  current = current?.data
  future = future?.data

  return [current, future]
}

type getAllAssignementsOverideByIdProps = {
  landUsePlanStatus: string
  landUsePlans: string[] | number[]
  landId?: number | string
  groupingId?: number | string
}

const getAllAssignementsOverideById = ({
  landUsePlanStatus = 'now',
  landUsePlans,
}: getAllAssignementsOverideByIdProps) => {
  const ids = landUsePlans?.map((lup: any) => lup.id)?.join(',')

  return client.get(
    `/land/landUsePlans/overide/assignment/${landUsePlanStatus}/${ids}`,
  )
}

const getAllGroupingAssignementsOverideById = ({
  landUsePlanStatus = 'now',
  landUsePlans,
}: getAllAssignementsOverideByIdProps) => {
  const ids = landUsePlans?.map((lup: any) => lup.id)?.join(',')

  return client.get(
    `/land/landUsePlans/grouping/overide/assignment/${landUsePlanStatus}/${ids}`,
  )
}

const underExploitedService = {
  getLandUsePlansDetails,
  getDifferenceLandUsePlanDocuments,
  getFutureLandUsePlanDocuments,
  getLandUsePlanDocuments,
  addOverideValuesAssignment,
  getAllAssignementsOverideById,
  updateOverideValues,
  getAllGroupingAssignementsOverideById,
  addGroupingOverideValuesAssignment,
  updateGroupingOverideValuesAssignment,
}

export default underExploitedService
