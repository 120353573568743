const tagsOnMapStateSelector = (state) => state.tagsOnMap

const buildingsTagsSelector = (state) => state.tagsOnMap.buildingsTags

const buildingTagSelector = (state) => state.tagsOnMap.buildingsTag

const landTagsSelector = (state) => state.tagsOnMap.landTags

const landTagSelector = (state) => state.tagsOnMap.landTag

export {
  tagsOnMapStateSelector,
  buildingsTagsSelector,
  buildingTagSelector,
  landTagsSelector,
  landTagSelector,
}
