const analyzedBuildingsSelector = (state) =>
  state.analizedBuilding.analizedBuildings

const companyAnalyzedBuildingsSelector = (state) =>
  state.analizedBuilding.companyAnalyzedBuildings

const companyUsersSelector = (state) => state.analizedBuilding.users

const companyAnalyzedBuildingAreLoadingSelector = (state) =>
  state.analizedBuilding.loading

export {
  analyzedBuildingsSelector,
  companyUsersSelector,
  companyAnalyzedBuildingsSelector,
  companyAnalyzedBuildingAreLoadingSelector,
}
