import Link from '@mui/material/Link'
import { toQueryParams, useI18n } from '@popety_io/popety-io-lib'

export type ListingLinkProps = {
  city: string[]
  propertyCategory: string
  children?: React.ReactNode
}

const ListingLink = ({
  city,
  propertyCategory,
  children,
}: ListingLinkProps) => {
  const { t } = useI18n()

  const search = toQueryParams(
    { city, propertyCategory, view: 'analytic' },
    (v) => v != null,
  )

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={`/listing-history${search}`}
      className="ListingLink"
      underline="none"
    >
      {children ?? t('common.seeMore')}
    </Link>
  )
}

export default ListingLink
