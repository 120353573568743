const favoriteBuildingsSelector = (state: any) =>
  state.favoriteBuildings.favoriteBuildings

const favoriteBuildingIdsSelector = (state: any) =>
  state.favoriteBuildings.favoriteBuildingsIds

const myFavoriteBuildingsSelector = (state: any) =>
  state.favoriteBuildings.myFavoriteBuildings

const myFavoriteBuildingIdsSelector = (state: any) =>
  state.favoriteBuildings.myFavoriteBuildingsIds

const companyFavoriteBuildingsIdsSelector = (state: any) =>
  state.favoriteBuildings.companyFavoriteBuildingsIds

const companyFavoriteBuildingsSelector = (state: any) =>
  state.favoriteBuildings.companyFavoriteBuildings

const favoriteBuildingsAreLoadingSelector = (state: any) =>
  state.favoriteBuildings.loading

export {
  favoriteBuildingsSelector,
  favoriteBuildingIdsSelector,
  myFavoriteBuildingsSelector,
  companyFavoriteBuildingsSelector,
  myFavoriteBuildingIdsSelector,
  companyFavoriteBuildingsIdsSelector,
  favoriteBuildingsAreLoadingSelector,
}
