/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { format, useI18n } from '@popety_io/popety-io-lib'
import Typography from '@mui/material/Typography'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/WarningOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'

const FaoDetailHeader = ({
  transaction,
  transactionsIds,
  onSave,
  onRemove,
}: {
  transaction: any
  transactionsIds: string[] | undefined
  onSave?: (listing: any) => void
  onRemove?: (listing: any) => void
}) => {
  const { t } = useI18n()
  const regex = /^[\s,]*$/
  const addresses = transaction?.address
    ?.split(';')
    ?.filter((item: string) => !regex.test(item))
  const isMultipleLands = transaction?.lands?.length > 1
  const transactionId = transaction?.id || transaction?._id || ''

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
        paddingTop: 1,
        paddingBottom: 1,
        width: '100%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#ffffff',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%',
          }}
        >
          <Typography fontSize="1.4rem" fontWeight={500}>
            {t('common.Transaction')} {transaction?.official_id || ''}
          </Typography>
          {(onSave || onRemove) && (
            <>
              {transactionsIds?.find(
                (id) => Number(id) === Number(transactionId),
              ) ? (
                <IconButton
                  aria-label="remove button"
                  sx={{
                    backgroundColor: '#FF0000',
                    color: 'white',
                    borderRadius: '2px',
                    padding: '3px',
                    '&:hover': { backgroundColor: '#CC0000' },
                  }}
                  onClick={(event) => {
                    event.stopPropagation()
                    onRemove?.(transaction)
                  }}
                >
                  <Tooltip title={t('common.remove_from_comparables')}>
                    <RemoveIcon />
                  </Tooltip>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="remove button"
                  sx={{
                    backgroundColor: '#00ade6',
                    color: 'white',
                    borderRadius: '2px',
                    padding: '3px',
                    '&:hover': { backgroundColor: '#0dbef8' },
                  }}
                  onClick={(event) => {
                    event.stopPropagation()
                    onSave?.(transaction)
                  }}
                >
                  <Tooltip title={t('common.add_to_comparables')}>
                    <AddIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </Box>
        {(addresses?.length ||
          transaction?.postal_codes_names?.length ||
          transaction?.postal_code_name) && (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              marginLeft: '-4px',
            }}
          >
            <LocationOnIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                width: '20px',
                height: '20px',
              }}
            />
            {addresses?.length ? (
              <Box>
                {addresses?.map((address: string) => (
                  <Typography
                    key={address}
                    fontSize="1rem"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {address}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography
                fontSize="1rem"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {transaction?.postal_codes_names?.[0] ||
                  transaction?.postal_code_name}
              </Typography>
            )}
          </Box>
        )}
      </Box>

      {isMultipleLands && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            color="rgb(251, 140, 0)"
            sx={{ display: 'flex', whiteSpace: 'nowrap' }}
          >
            <WarningIcon fontSize="small" />
            {t('common.transactions.multiple_lands_transactions')}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          color="rgb(141, 155, 163)"
          sx={{ display: 'flex', whiteSpace: 'nowrap' }}
        >
          {t('common.publishedOn')}
          {' : '}
        </Typography>
        <span style={{ color: '#000000', marginLeft: 2 }}>
          {format(transaction?.date, 'P')}
        </span>
      </Box>
    </Box>
  )
}

export default FaoDetailHeader
