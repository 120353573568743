import { ApiClient } from '../../../../services'

const client = new ApiClient()

type GetFaoOpts = {
  landId?: string | number | string[] | number[]
  size?: number
  [k: string]: any
}

const getLandFaos = ({ landId }: GetFaoOpts) => {
  return client.get(`/land/${landId}/faos`)
}

const getFaosAround = async ({ ...filters }: GetFaoOpts) => {
  delete filters.polygon

  return client.post(`/land/faos`, filters)
}

const getFaoAggs = (filter: Record<string, any>) => {
  return client.post('/faos/search', { ...filter, size: 1 }).then((res) => {
    return { ...res, filters: filter }
  })
}

const getFaosByIds = ({ faosIds }: any) => {
  return client.get(`/faos/${faosIds}`)
}

const faoService = {
  getLandFaos,
  getFaosAround,
  getFaoAggs,
  getFaosByIds,
}

export default faoService
