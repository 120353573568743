import { useEffect, useState, useMemo } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector, useDispatch } from 'react-redux'
import { useI18n } from '@popety_io/popety-io-lib'
import { LocationType } from '../../config'
import {
  allAddressesSelector,
  hasLocationsSelector,
  searchAllAddresses,
  resetAllAddresses,
} from './redux'
import ListboxComponent from './ListboxComponent'
import LocationOption, { filterOptions } from './LocationOption'

export type LocationsInputProps = {
  onChange: (v: any) => void
  types?: LocationType[]
  size?: number
  searchType?: string
  [k: string]: any
}

let timeoutId: any

export const debouncedDispatch = (actionCreator: any, delay = 100) => {
  return () => {
    return (dispatch: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        dispatch(actionCreator)
      }, delay)
    }
  }
}

const LocationsInput = ({
  onChange,
  types = [],
  size = 20,
  searchType,
  ...other
}: LocationsInputProps) => {
  const { t } = useI18n()
  const hasData = useSelector(hasLocationsSelector)
  const dispatch = useDispatch()

  const locations = useSelector(allAddressesSelector)

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (inputValue?.length >= 2) {
      const debouncedAction = debouncedDispatch(
        searchAllAddresses({ value: inputValue || '', types, size }) as any,
        500,
      ) as any

      dispatch(debouncedAction())
    }
  }, [inputValue])

  const handleInputChange = (e: any, newInputValue: string) => {
    setInputValue(newInputValue)
  }

  const handleChange = (e: any, newValue: any) => {
    onChange(newValue.filter(Boolean))
    setInputValue('')
  }

  useEffect(() => {
    dispatch(resetAllAddresses() as any)
  }, [])

  const filterdLocations = useMemo(() => {
    if (searchType === 'building') {
      return locations.filter((l: any) => l.location_type !== 'land')
    }

    return locations
  }, [locations])

  return (
    <Autocomplete
      size="small"
      fullWidth
      {...other}
      id="land-search-input"
      sx={{
        ...other.sx,
        '.MuiChip-root': { maxWidth: 200 },
      }}
      value={other.value || []}
      inputValue={inputValue}
      filterOptions={filterOptions}
      disableListWrap
      selectOnFocus
      autoSelect={false}
      autoHighlight
      ListboxComponent={ListboxComponent}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) => option.id === (val.id || val)}
      getOptionLabel={(option: any) =>
        option?.location_search || (typeof option === 'string' ? option : '')
      }
      onInputChange={handleInputChange}
      renderOption={(p, option) =>
        [p, <LocationOption key={option.id} option={option} />] as any
      }
      options={filterdLocations}
      multiple
      filterSelectedOptions
      loading={!hasData}
      freeSolo
      loadingText={t('common.loading')}
      style={other.style}
      renderInput={(params) => (
        <TextField
          {...params}
          color={other.color}
          variant="outlined"
          placeholder={other.placeholder ?? t('common.location')}
          inputProps={{
            ...params.inputProps,
            ...other.inputProps,
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
        />
      )}
    />
  )
}

export default LocationsInput
