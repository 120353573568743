import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import reportsService from './reportsService'

const initialState = {
  reports: [],
}

export const getAllReports = createAsyncThunk(
  'reports/getAllReports',
  reportsService.getAllReports,
)

const reportsActions = createSlice({
  name: 'reportsActions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAllReports.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { data = [] } = action.payload || {}

        state.reports = data
      },
    )
  },
})

export const reportsActionsReducer = {
  reportsActions: reportsActions.reducer,
}
