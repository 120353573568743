/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Fragment, ReactNode, useEffect, useState } from 'react'
import Divider from '@mui/material/Divider'
import { useDispatch, useSelector } from 'react-redux'
import Stack from '@mui/material/Stack'
import { Button, Modal, RichTextInput, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'

import {
  actionHistoriesSelector,
  removeNote,
  removeReminder,
  updateNote,
} from '../../pages/Dashboard/redux'
import { Note, NoteBox, InputContainer } from './LandNote.style'
import NoteHeader from './NoteHeader'
import NoteBody from './NoteBody'
import RememberDialog from './RememberDialog'

type NoteListProps = {
  landId: string | string[]
  children?: ReactNode
  onMore?: (e?: any) => void
  defaultExtend?: boolean
  listContentStyle?: SxProps
}

const NoteList = ({
  landId,
  children,
  onMore,
  defaultExtend = false,
  listContentStyle,
}: NoteListProps) => {
  const dispatch = useDispatch<any>()
  const { t } = useI18n()
  const actionHistories = useSelector(actionHistoriesSelector)

  const [openDelete, setOpenDelete] = useState<any>()
  const [action, setAction] = useState<any>()
  const [extended, setExtended] = useState<boolean>(defaultExtend)

  const landIds = (Array.isArray(landId) ? landId : [landId]).map(Number)

  const actions = actionHistories.filter(
    (n) => landIds.includes(+n.land_id) && n.action === 'note',
  )

  const handleDelete = () => {
    if (openDelete?.action === 'note') {
      dispatch(removeNote(openDelete.note_id))
    } else if (openDelete?.action === 'reminder') {
      dispatch(removeReminder(openDelete.id))
    }

    setOpenDelete(undefined)
  }

  const handleOpen = (newNote: any) => {
    setAction(newNote)
  }

  const handleNoteUpdate = () => {
    if (action) dispatch(updateNote(action))

    setAction(undefined)
  }

  const handleClose = () => {
    setAction(undefined)
  }

  const handleNoteChange = (value: string) => {
    setAction({ ...action, content: value })
  }

  const preview = !!onMore
  const actionList = actions.slice(0, actions.length)

  useEffect(() => {
    setExtended(defaultExtend)
  }, [JSON.stringify(actionList), defaultExtend])

  return (
    <NoteBox>
      {!extended ? (
        actionList?.length > 0 && (
          <Fragment
            key={
              actionList[0].uid ||
              actionList[0].action + (actionList[0].note_id || actionList[0].id)
            }
          >
            <Note>
              <Stack direction="row" spacing={2}>
                <NoteHeader action={actionList[0]} />
                <NoteBody
                  action={actionList[0]}
                  onDelete={setOpenDelete}
                  onOpen={handleOpen}
                />
              </Stack>
            </Note>
          </Fragment>
        )
      ) : (
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            ...listContentStyle,
          }}
        >
          {actionList?.map((item, i) => {
            return (
              <Fragment
                key={item.uid || item.action + (item.note_id || item.id)}
              >
                {i !== 0 && <Divider />}
                <Note>
                  <Stack direction="row" spacing={2}>
                    <NoteHeader action={item} />
                    <NoteBody
                      action={item}
                      onDelete={setOpenDelete}
                      onOpen={handleOpen}
                    />
                  </Stack>
                </Note>
              </Fragment>
            )
          })}
        </Box>
      )}
      {preview && actionList.length > 1 && (
        <Box display="flex" justifyContent="flex-end" mr={-0.5}>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setExtended(!extended)
            }}
          >
            {extended
              ? t('common.seeLess')
              : t('common.notes.seeMore', { count: actionList.length - 1 })}
          </Button>
        </Box>
      )}
      {children}
      <Modal
        onOk={handleDelete}
        open={!!openDelete}
        onClose={() => setOpenDelete(null)}
        okText={t('common.yes')}
        cancelText={t('common.no')}
        onCancel={() => setOpenDelete(null)}
        title={t('common.deleteNoteTitle')}
      >
        {t('common.deleteNotes')}
      </Modal>
      <Modal
        key={action?.id}
        onOk={handleNoteUpdate}
        onCancel={handleClose}
        open={action?.action === 'note'}
        onClose={handleClose}
        okText={t('common.update')}
        cancelText={t('common.cancel')}
        cancelButtonProps={{ color: 'inherit' }}
        title={t('common.updateNote')}
        fullWidth
      >
        <InputContainer>
          <RichTextInput
            id="update-note"
            style={{ height: '300px' }}
            placeholder={action?.content}
            value={action?.content || ''}
            onChange={handleNoteChange}
          />
        </InputContainer>
      </Modal>
      <RememberDialog
        open={action?.action === 'reminder'}
        onClose={handleClose}
        landId={landId}
        action={action}
      />
    </NoteBox>
  )
}

export default NoteList
