import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getGenerativeDesign = async (data: any) => {
  return client.post(`/epfl/generative_design`, data)
}

const generativeDesignService = { getGenerativeDesign }

export default generativeDesignService
