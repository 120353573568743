import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'

export const StyledToggle = styled(ToggleButton)`
  height: 30px;
  width: 75px;

  .selected {
    background-color: #00ade6 !important;
    color: #fff !important;
  }
`

export const StyledToggleTime = styled(ToggleButton)`
  font-size: 1rem;
  height: 25px;
  width: 75px;
  border: none;
  color: #fff !important;
  text-transform: capitalize;
`
