/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import tagService from './tagService'

const initialState = {
  listTags: [],
  tagsByLandId: {},
  dashboardTags: undefined,
  companyDashboardTags: undefined,
  refetchFlag: false,
  loading: false,
  landTags: [],
}

export const getTagLands = createAsyncThunk(
  'tag/getTagLands',
  tagService.getTagLands,
)

export const getDashboardTagLands = createAsyncThunk(
  'tag/getDashboardTagLands',
  tagService.getDashboardTagLands,
)

export const getCompanyDashboardTagLands = createAsyncThunk(
  'tag/getCompanyDashboardTagLands',
  tagService.getDashboardTagLands,
)

export const addTag = createAsyncThunk('tag/addTag', tagService.addTag)

export const removeLandTag = createAsyncThunk(
  'tag/removeLandTag',
  tagService.removeLandTag,
)

export const deleteTag = createAsyncThunk('tag/deleteTag', tagService.deleteTag)

export const isTagLand = createAsyncThunk('tag/isTagLand', tagService.isTagLand)

export const updateTag = createAsyncThunk('tag/updateTag', tagService.updateTag)

export const updateLandTag = createAsyncThunk(
  'tag/updateLandTag',
  tagService.updateLandTag,
)

export const getAllTagsName = createAsyncThunk(
  'tag/getAllTagsName',
  tagService.getAllTagsName,
)

const tag = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    updateRefetchFlag: (state, action) => {
      state.refetchFlag = action.payload
    },
    addTagToList: (state, action) => {
      state.listTags = [
        ...state.listTags,
        {
          id: action.payload.id,
          value: action.payload.value,
          color: action.payload.color,
          active: true,
          update_timestamp: new Date().toISOString(),
        },
      ]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTagLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getTagLands.rejected, (state) => {
        state.loading = false
      })
      .addCase(getTagLands.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.tagsByLandId = data.data
        state.landTags = data.flatData
      })
      .addCase(getDashboardTagLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getDashboardTagLands.rejected, (state) => {
        state.loading = false
      })
      .addCase(getDashboardTagLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.dashboardTags = data

        state.loading = false
      })
      .addCase(getCompanyDashboardTagLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanyDashboardTagLands.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCompanyDashboardTagLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.companyDashboardTags = data

        state.loading = false
      })
      .addCase(updateTag.pending, (state, action) => {
        const { tag_id, color, value } = action.meta.arg

        const tags = Object.values({ ...state.tagsByLandId })
          .flat()
          .filter((t) => String(t.tag_id) === String(tag_id))

        tags.forEach((_tag) => {
          _tag.color = color
          _tag.value = value
        })
      })
      .addCase(removeLandTag.fulfilled, (state, action) => {
        const input = action.meta.arg
        const { data } = action.payload || {}

        const newPrimaryTag = data?.newPrimaryTag

        const landIds = String(input.landId).includes(',')
          ? String(input.landId).split(',')
          : [String(input.landId)]

        landIds.forEach((landId) => {
          state.tagsByLandId[landId] = state.tagsByLandId[landId]?.map(
            (item) => {
              if (String(item.id) === String(input.tagLandId)) {
                return {
                  ...item,
                  active: false,
                }
              }

              if (
                newPrimaryTag &&
                String(item.id) === String(newPrimaryTag.id)
              ) {
                return newPrimaryTag
              }

              return item
            },
          )
        })
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        const tag_id = action.meta.arg

        Object.keys({ ...state.tagsByLandId }).forEach((land_id) => {
          state.tagsByLandId[land_id] = state.tagsByLandId[land_id].filter(
            (t) => String(t.tag_id) !== String(tag_id),
          )
        })
      })
      .addCase(addTag.pending, (state, action) => {
        const input = action.meta.arg
        const newTags = Array.isArray(input) ? input : [input]
        const { requestId } = action.meta

        newTags.forEach((t) => {
          if (Array.isArray(state.tagsByLandId[t.land_id])) {
            if (t?.primary) {
              const tagsIdByLandRemove = state.tagsByLandId[t.land_id]
                ?.filter(
                  (item) => String(item?.id) === String(t?.tagLandIdSecondary),
                )
                ?.map((i) => i?.id)
                ?.filter(Boolean)

              state.tagsByLandId[t.land_id] = state.tagsByLandId[t.land_id].map(
                (item) => {
                  if (tagsIdByLandRemove?.includes(item?.id)) {
                    return {
                      ...item,
                      primary: false,
                      active: false,
                    }
                  }

                  return {
                    ...item,
                    primary: false,
                  }
                },
              )
              state.tagsByLandId[t.land_id].unshift({ ...t, requestId })
            } else {
              state.tagsByLandId[t.land_id].unshift({ ...t, requestId })
            }
          } else {
            state.tagsByLandId[t.land_id] = [{ ...t, requestId }]
          }
        })
      })
      .addCase(addTag.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const tags = data
        const { requestId } = action.meta

        if (tags?.length) {
          tags.forEach((t) => {
            if (state.tagsByLandId[t.land_id]) {
              state.tagsByLandId[t.land_id] = state.tagsByLandId[
                t.land_id
              ].filter((tg) => tg.requestId !== requestId)
            }

            if (Array.isArray(state.tagsByLandId[t.land_id])) {
              if (t?.primary) {
                state.tagsByLandId[t.land_id] = state.tagsByLandId[
                  t.land_id
                ].map((item) => {
                  return {
                    ...item,
                    primary: false,
                  }
                })
                state.tagsByLandId[t.land_id].unshift(t)
              } else {
                state.tagsByLandId[t.land_id].unshift(t)
              }
            } else {
              state.tagsByLandId[t.land_id] = [t]
            }
          })
        }
      })
      .addCase(getAllTagsName.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.listTags = data
        state.legendTags = data
          .map((tag) => ({
            name: tag.value,
            color: tag.color,
          }))
          .filter((tag) => tag.color)
      })
      .addCase(updateLandTag.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}
        const input = action.meta.arg
        const { tag } = input

        state.tagsByLandId[data.land_id].forEach((_tag) => {
          _tag.tag_id = tag.tag_id
          _tag.color = tag.color
          _tag.value = tag.value
        })
      })
  },
})

// Action creators are generated for each case reducer function
export const { toggleTag, updateRefetchFlag, addTagToList } = tag.actions

export const tagReducer = {
  tag: tag.reducer,
}
