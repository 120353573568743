import { useEffect, memo } from 'react'
import { ProgressIndicator } from '@popety_io/popety-io-lib'
import { BoxProps } from '@mui/material/Box'

import { buildAltimetry, BuildAltimetryOpts } from './Altimetry.util'
// import { AltimetryContainer } from './Altimetry.style'

type AltimetryProps = BoxProps & BuildAltimetryOpts

const Altimetry = ({
  data = [],
  precision = 0,
  altitudeMin = 0,
  altitudeMax = 0,
}: AltimetryProps) => {
  const Plotly = (window as any)?.Plotly
  const loading = !Plotly

  useEffect(() => {
    if (!Plotly) return

    const props = buildAltimetry({ data, precision, altitudeMax, altitudeMin })

    Plotly.newPlot('Altimetry', {
      ...props,
      config: {
        modeBarButtonsToRemove: [
          'lasso2d',
          'select2d',
          'sendDataToCloud',
          'autoScale2d',
          'zoom2d',
          'pan2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
          'resetScale2d',
          'hoverClosestCartesian',
          'hoverCompareCartesian',
          // "zoom3d",
          'pan3d',
          'orbitRotation',
          // "tableRotation",
          'resetCameraDefault3d',
          'resetCameraLastSave3d',
          'hoverClosest3d',
          'zoomInGeo',
          'zoomOutGeo',
          'resetGeo',
          'hoverClosestGeo',
          'hoverClosestGl2d',
          'hoverClosestPie',
          'toggleHover',
          'resetViews',
          'toggleSpikelines',
        ],
        showLink: false,
        displaylogo: false,
        displayModeBar: 'hover',
        toImageButtonOptions: {
          format: 'png', // one of png, svg, jpeg, webp
          filename: 'popety-io-3d-land',
          // height: 500,
          // width: 1000,
          scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
        },
      },
      useResizeHandler: true,
      style: { width: '100%', height: '100%' },
    })
  }, [precision, altitudeMax, altitudeMin, loading])

  return (
    <>
      {loading && <ProgressIndicator circular />}

      <div id="Altimetry" />
    </>
  )
}

export default memo(Altimetry)
