import { createSelector } from '@reduxjs/toolkit'

const estimateActionHistorySelector = (state: any) =>
  state.estimateActionHistory.estimateActionHistory

const estimateActionHistoriesSelector = createSelector(
  estimateActionHistorySelector,
  (h) =>
    Object.values(h).sort(
      (a: any, b: any) =>
        new Date(b.create_timestamp).getTime() -
        new Date(a.create_timestamp).getTime(),
    ),
)

const estimateNotesSelector = createSelector(
  estimateActionHistoriesSelector,
  (h) =>
    h.filter(
      (estimateActionHistory: any) => estimateActionHistory.action === 'note',
    ),
)

export { estimateNotesSelector, estimateActionHistoriesSelector }
