import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const ContentTitleRoot = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  overflow: auto;
  overflow-y: hidden;
  height: fit-content;
`

export const ContentTitleItemRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  max-width: 120px;
  text-align: center;

  > h6 {
    font-weight: 500;
    font-size: 1.34rem;
    color: #041725;
    line-height: 16px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    max-height: 30px;
  }

  > p {
    color: #041725;
    width: 100%;
    padding-top: 4px;
  }
`
