import TransactionClickModal from './TransactionClickModal'
import TransactionMapContent from './TransactionMapContent'
import TransactionMapHover from './TransactionMapHover'
import TransactionTiles from './TransactionTiles'

export const TransactionMapConfig = {
  Click: TransactionClickModal,
  Hover: TransactionMapHover,
  Content: TransactionMapContent,
  Controller: TransactionTiles,
}
