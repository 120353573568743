/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DEFAULT_MAPSTYLE } from '../../../../config'
import { getMapDisplayState } from '../../../../utils'
import basemapService from './basemapService'
import { IBassMapState } from './basemapInterface'

const stateKey = 'developerMap'

const initialState: IBassMapState = {
  mapStyle: getMapDisplayState()?.mapStyle || DEFAULT_MAPSTYLE,
  layout: getMapDisplayState()?.mapStyle === 'popety/cl3a7v8hy001y14pejeo2af8o',
  ofsThematic: JSON.parse(localStorage.getItem('ofsThematic') || '{}') || {},
  ofsLayers: [],
  ofsLoading: false,
  defaultMapStyle: getMapDisplayState()?.defaultMapStyle || DEFAULT_MAPSTYLE,
}

export const getOfsLayers = createAsyncThunk(
  'basemap/getOfsLayers',
  basemapService.getOfsLayers,
)

const basemap = createSlice({
  name: 'basemap',
  initialState,
  reducers: {
    changeMapStyle: (state, action) => {
      const { payload } = action

      const layout =
        payload === 'popety/cl3a7v8hy001y14pejeo2af8o' ||
        payload === 'popety/cl9mkt6w3002714rsqw7qsz7g'

      if (payload !== 'popety/cl9mkt6w3002714rsqw7qsz7g') {
        state.defaultMapStyle = payload
        state.mapStyle = payload
      }

      localStorage.setItem(stateKey, JSON.stringify(state))
      state.layout = layout
      state.mapStyle = payload
    },
    changeOfsThematic: (state, action) => {
      state.ofsThematic = action.payload || {}
      if (state.ofsThematic?.name) {
        localStorage.setItem('ofsThematic', JSON.stringify(state.ofsThematic))
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOfsLayers.pending, (state) => {
      state.ofsLoading = true
    })
    builder.addCase(getOfsLayers.rejected, (state) => {
      state.ofsLoading = false
    })
    builder.addCase(getOfsLayers.fulfilled, (state, action) => {
      state.ofsLoading = false

      if (action.payload) {
        const { data } = action.payload

        if (data) {
          state.ofsLayers = data
        }
      }
    })
  },
})

export const basemapReducer = {
  basemap: basemap.reducer,
}

export const { changeMapStyle, changeOfsThematic } = basemap.actions
