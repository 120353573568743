/* eslint-disable max-lines */
// import { groupBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import buildingTagService from './buildingTagService'

const initialState = {
  listTags: [],
  tagsByBuildingId: {},
  legendTags: [],
  dashboardTags: undefined,
  companyDashboardTags: undefined,
  refetchFlag: false,
  buildingTags: [],
  groupByTagId: [],
  loading: false,
}

export const getTagBuildings = createAsyncThunk(
  'buildingTag/getTagBuildings',
  buildingTagService.getTagBuildings,
)

export const getDashboardTagBuildings = createAsyncThunk(
  'buildingTag/getDashboardTagBuildings',
  buildingTagService.getDashboardTagBuildings,
)

export const getCompanyDashboardTagBuildings = createAsyncThunk(
  'tag/getCompanyDashboardTagBuildings',
  buildingTagService.getDashboardTagBuildings,
)

export const addBuildingTag = createAsyncThunk(
  'buildingTag/addBuildingTag',
  buildingTagService.addBuildingTag,
)

export const updateBuilding = createAsyncThunk(
  'buildingTag/updateBuilding',
  buildingTagService.updateBuilding,
)

export const removeBuildingTag = createAsyncThunk(
  'buildingTag/removeBuildingTag',
  buildingTagService.removeBuildingTag,
)

export const deleteBuildingTag = createAsyncThunk(
  'buildingTag/deleteBuildingTag',
  buildingTagService.deleteBuildingTag,
)

export const isTagBuilding = createAsyncThunk(
  'buildingTag/isTagBuilding',
  buildingTagService.isTagBuilding,
)

export const getAllBuildingTagsName = createAsyncThunk(
  'buildingTag/getAllTagsName',
  buildingTagService.getAllBuildingTagsName,
)

export const updateBuildingTag = createAsyncThunk(
  'buildingTag/updateBuildingTag',
  buildingTagService.updateBuildingTag,
)

const buildingTagSlice = createSlice({
  name: 'buildingTag',
  initialState,
  reducers: {
    updateRefetchBuildingFlag: (state, action) => {
      state.refetchFlag = action.payload
    },
    addBuildingTagToList: (state: any, action) => {
      state.listTags = [
        ...state.listTags,
        {
          id: action.payload.id,
          value: action.payload.value,
          color: action.payload.color,
          active: true,
          update_timestamp: new Date().toISOString(),
        },
      ]
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getTagBuildings.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.tagsByBuildingId = data.data
        state.buildingTags = data.flatData
      })
      .addCase(getDashboardTagBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getDashboardTagBuildings.rejected, (state) => {
        state.loading = false
      })
      .addCase(getDashboardTagBuildings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.dashboardTags = data

        state.loading = false
      })
      .addCase(getCompanyDashboardTagBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanyDashboardTagBuildings.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCompanyDashboardTagBuildings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.companyDashboardTags = data

        state.loading = false
      })
      .addCase(updateBuilding.fulfilled, (state, action) => {
        const { tag_id, color, value } = action.meta.arg

        const tags = Object.values({ ...state.tagsByBuildingId })
          .flat()
          .filter((t: any) => String(t.tag_id) === String(tag_id))

        tags.forEach((_tag: any) => {
          _tag.color = color
          _tag.value = value
        })
      })
      .addCase(removeBuildingTag.fulfilled, (state: any, action) => {
        const input: any = action.meta.arg
        const { data } = action.payload || {}

        const newPrimaryTag = data?.newPrimaryTag

        state.tagsByBuildingId[input.buildingId] = state.tagsByBuildingId[
          input.buildingId
        ]?.map((item: any) => {
          if (String(item.id) === String(input.tagBuildingId)) {
            return {
              ...item,
              active: false,
            }
          }

          if (newPrimaryTag && String(item.id) === String(newPrimaryTag.id)) {
            return newPrimaryTag
          }

          return item
        })
      })
      .addCase(deleteBuildingTag.fulfilled, (state: any, action) => {
        const tag_id = action.meta.arg

        state.groupByTagId = state.groupByTagId.filter(
          (t: any) => String(t.tag_id) !== String(tag_id),
        )

        Object.keys({ ...state.tagsByBuildingId }).forEach((building_id) => {
          state.tagsByBuildingId[building_id] = state.tagsByBuildingId[
            building_id
          ].filter((t: any) => String(t.tag_id) !== String(tag_id))
        })
      })
      .addCase(addBuildingTag.pending, (state: any, action) => {
        const input = action.meta.arg
        const newTags = Array.isArray(input) ? input : [input]
        const { requestId } = action.meta

        newTags.forEach((t) => {
          if (Array.isArray(state.tagsByBuildingId[t.building_id])) {
            if (t?.primary) {
              const tagsIdBuildingRemove = state.tagsByBuildingId[t.building_id]
                ?.filter(
                  (item: any) =>
                    String(item?.id) === String(t?.tagBuildingIdSecondary),
                )
                ?.map((i: any) => i?.id)
                ?.filter(Boolean)

              state.tagsByBuildingId[t.building_id] = state.tagsByBuildingId[
                t.building_id
              ].map((item: any) => {
                if (tagsIdBuildingRemove?.includes(item?.id)) {
                  return {
                    ...item,
                    primary: false,
                    active: false,
                  }
                }

                return {
                  ...item,
                  primary: false,
                }
              })
              state.tagsByBuildingId[t.building_id].unshift({ ...t, requestId })
            } else {
              state.tagsByBuildingId[t.building_id].unshift({ ...t, requestId })
            }
          } else {
            state.tagsByBuildingId[t.building_id] = [{ ...t, requestId }]
          }
        })
      })
      .addCase(addBuildingTag.fulfilled, (state: any, action) => {
        const { data } = action.payload || {}
        const tags = data
        const { requestId } = action.meta

        if (tags?.length) {
          tags.forEach((t: any) => {
            if (state.tagsByBuildingId[t.building_id]) {
              state.tagsByBuildingId[t.building_id] = state.tagsByBuildingId[
                t.building_id
              ].filter((tg: any) => tg.requestId !== requestId)
            }

            if (Array.isArray(state.tagsByBuildingId[t.building_id])) {
              if (t?.primary) {
                state.tagsByBuildingId[t.building_id] = state.tagsByBuildingId[
                  t.building_id
                ].map((item: any) => {
                  return {
                    ...item,
                    primary: false,
                  }
                })
                state.tagsByBuildingId[t.building_id].unshift(t)
              } else {
                state.tagsByBuildingId[t.building_id].unshift(t)
              }
            } else {
              state.tagsByBuildingId[t.building_id] = [t]
            }
          })
        }
      })
      .addCase(getAllBuildingTagsName.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.listTags = data
        state.legendTags = data
          .map((tag: any) => ({
            name: tag.value,
            color: tag.color,
          }))
          .filter((tag: any) => tag.color)
      })
      .addCase(updateBuildingTag.fulfilled, (state: any, action: any) => {
        const { data = [] } = action.payload || {}
        const input = action.meta.arg
        const { tag } = input

        state.tagsByBuildingId[data.building_id] = tag
      })
  },
})

export const { updateRefetchBuildingFlag, addBuildingTagToList } =
  buildingTagSlice.actions

export const buildingTagReducer = {
  buildingTag: buildingTagSlice.reducer,
}
