/* eslint-disable max-lines */
export const getMinergieColor = (minergie: string) => {
  if (minergie === 'Minergie' || minergie === 'Minergie_Eco') {
    return '#A62115'
  }
  if (minergie === 'Minergie_P' || minergie === 'Minergie_P_Eco') {
    return '#265DAA'
  }
  if (minergie === 'Minergie_A' || minergie === 'Minergie_A_Eco') {
    return '#FAD978'
  }
}

export const getIdcColor = (idc: number) => {
  if (idc < 50) {
    return '#82C066'
  }
  if (idc >= 50 && idc < 100) {
    return '#93C669'
  }

  if (idc >= 100 && idc < 150) {
    return '#AACF6D'
  }
  if (idc >= 150 && idc < 200) {
    return '#BDD670'
  }
  if (idc >= 200 && idc < 250) {
    return '#DBDE75'
  }
  if (idc >= 250 && idc < 300) {
    return '#F8E97B'
  }
  if (idc >= 300 && idc < 350) {
    return '#F6DD77'
  }
  if (idc >= 350 && idc < 400) {
    return '#F2D273'
  }
  if (idc >= 400 && idc < 450) {
    return '#F1C96F'
  }
  if (idc >= 450 && idc < 500) {
    return '#EEBF6C'
  }
  if (idc >= 500 && idc < 550) {
    return '#EAB267'
  }
  if (idc >= 550 && idc < 600) {
    return '#E7A363'
  }
  if (idc >= 600 && idc < 650) {
    return '#E4945D'
  }
  if (idc >= 650 && idc < 700) {
    return '#E2895A'
  }
  if (idc >= 700 && idc < 750) {
    return '#DF8058'
  }
  if (idc >= 750 && idc < 800) {
    return '#DF7955'
  }
  if (idc >= 800 && idc < 850) {
    return '#DD6951'
  }
  if (idc >= 850 && idc < 900) {
    return '#DB5E4E'
  }
  if (idc >= 900 && idc < 950) {
    return '#BA403D'
  }

  return '#B32926'
}

export const getHeatConsumptionDesc = (idc: number) => {
  if (idc <= 450) {
    return ['between 0 and 450 MJ/m²∙an']
  }
  if (idc > 450 && idc <= 550) {
    return ['between 450 and 550 MJ/m²∙an']
  }
  if (idc > 550 && idc <= 650) {
    return ['between 450 and 550 MJ/m²∙an', 'between 550 and 650 MJ/m²∙an']
  }
  if (idc > 650 && idc <= 800) {
    return ['between 450 and 550 MJ/m²∙an', 'between 650 and 800 MJ/m²∙an']
  }
  if (idc > 800) {
    return ['over 800 MJ/m²∙an']
  }

  return ['between 0 and 450 MJ/m²∙an']
}

export const getIdcIconColor = (idc: number) => {
  if (idc > 450 && idc <= 800) {
    return '#FC8723'
  }
  if (idc > 800) {
    return '#E63900'
  }

  return '#FC8723'
}
