import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

export const UnitScoreSpan = styled('span')`
  text-align: center;
  color: #969696;
  font-size: 0.769rem;
`
export const UnitTotalSpan = styled(UnitScoreSpan)`
  color: #00ade6;
`
export const UnitTotalPercentSpan = styled(UnitScoreSpan)`
  color: #fc5e22;
`

export const UnitTotalTitleSpan = styled(UnitScoreSpan)`
  text-align: start;
  color: #041725;
  font-weight: 400;
`

export const MixOfUnitsBox = styled(Box)`
  display: grid;
  grid-template-columns: 90px repeat(5, 44px);
  align-items: center;
  gap: 25px;
  margin-bottom: 8px;
  font-size: 10px;

  .MuiSelect-select.MuiOutlinedInput-input {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .MuiTextField-root {
    width: 42px;
    /* padding: 5px; */
    input {
      padding: 2px;
      padding-left: 8px;
    }
  }

  .MuiTextField-root:first-of-type {
    width: 90px;
  }
  h3 {
    text-align: left;
    width: 120px;
    color: #041725;
    font-size: 0.923rem;
    font-weight: 500;
  }
`

export const ButtonAddNewUnit = styled(Button)`
  display: flex;
  gap: 5px;
  font-size: 0.77rem;
  text-transform: none;
  color: #2196f3;
  font-weight: 400;
  padding-left: 0;

  :hover {
    background-color: transparent;
  }
`
