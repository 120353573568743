import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import configurationService from './configurationService'

export type ConfigurationState = {
  loading: boolean
  configuration: any
}

const initialState: ConfigurationState = {
  loading: false,
  configuration: [],
}

export const getConfigurationEdited = createAsyncThunk(
  'buildingConfiguration/getConfigurationEdited',
  configurationService.getConfigurationEdited,
)

export const saveConfigurationEdited = createAsyncThunk(
  'buildingConfiguration/saveConfigurationEdited',
  configurationService.updateConfigurationEdited,
)

const buildingConfiguration = createSlice({
  name: 'buildingConfiguration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigurationEdited.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data?.configuration) {
        state.configuration = JSON.parse(data.configuration)
      }
    })
    builder.addCase(saveConfigurationEdited.fulfilled, (state, action) => {
      const data = action.payload

      state.configuration = data
    })
  },
})

export const buildingConfigurationReducer = {
  buildingConfiguration: buildingConfiguration.reducer,
}
