import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRouter, Feature } from '@popety_io/popety-io-lib'

import HistoryMapClickModal from '../../components/HistoryMapClickModal'
import { faoRangeSelector, listingsByLandIdSelector } from '../../redux'

export type ListingClickModalProps = Feature & {
  setClickFeature?: (feature?: Feature) => void
}

const ListingClickModal = ({
  properties,
  setClickFeature,
}: ListingClickModalProps) => {
  const listingByLandId = useSelector(listingsByLandIdSelector)
  const { query } = useRouter()

  const faoRange = useSelector(faoRangeSelector)

  const handleModalClose = () => {
    setClickFeature?.(undefined)
  }

  useEffect(() => {
    setClickFeature?.(undefined)
  }, [query.tab])

  return (
    <HistoryMapClickModal
      open={!!properties}
      onClose={handleModalClose}
      landId={properties?.land_id}
      dataByLandId={listingByLandId}
      value={faoRange}
    />
  )
}

export default ListingClickModal
