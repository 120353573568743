/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import NoteIcon from '@mui/icons-material/StickyNote2'
import { useSelector, useDispatch } from 'react-redux'
import {
  Modal,
  fetchOnce,
  useMediaQuery,
  useI18n,
} from '@popety_io/popety-io-lib'

import { NoteBadge } from '../../../../../components/LandNote/LandNote.style'
import { getEstimateActionHistory, estimateNotesSelector } from '../../../redux'
import EstimateNote from './EstimateNote'

export type EstimateNoteIconProps = {
  estimateId: string | string[]
}

const useEstimateNote = ({ estimateId }: EstimateNoteIconProps) => {
  const dispatch = useDispatch<any>()
  const [open, setOpen] = useState(false)
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const handleOpen = (e: any) => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const notes = useSelector(estimateNotesSelector)

  const estimateIds = (
    Array.isArray(estimateId) ? estimateId : [estimateId]
  ).map(Number)

  const count = notes.filter((n: any) =>
    estimateIds.includes(+n.estimate_id),
  ).length

  const { t } = useI18n()

  useEffect(() => {
    if (estimateId) {
      fetchOnce('getEstimateActionHistory', estimateId.toString(), () => {
        dispatch(getEstimateActionHistory(estimateId))
      })
    }
  }, [estimateId])

  const Icon = (
    <NoteBadge sx={{ ml: 0 }} badgeContent={count} color="primary">
      <NoteIcon
        color={count ? 'primary' : undefined}
        sx={{ opacity: count ? 1 : 0.3 }}
      />
    </NoteBadge>
  )

  const EstimateNoteModal = (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('common.notes.title')}
      okText={t('common.close')}
      onOk={handleClose}
      maxWidth="sm"
      fullScreen={!onDesktop}
      sx={{ '& .ModalContent': { paddingY: 0 } }}
    >
      <EstimateNote estimateId={estimateId} />
    </Modal>
  )

  const title = t('common.noteIcon')

  return { count, title, Icon, onClick: handleOpen, Modal: EstimateNoteModal }
}

export default useEstimateNote
