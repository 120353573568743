import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import favoriteBuildingService from './favoriteBuildingsService'

const initialState = {
  favoriteBuildings: undefined,
  myFavoriteBuildings: undefined,
  companyFavoriteBuildings: undefined,
  favoriteBuildingsIds: [],
  myFavoriteBuildingsIds: [],
  companyFavoriteBuildingsIds: [],
  loading: false,
}

export const getMyFavoriteBuildings = createAsyncThunk(
  'favoriteBuildings/getMyFavoriteBuildings',
  favoriteBuildingService.getMyFavoriteBuildings,
)

export const addFavoriteBuilding = createAsyncThunk(
  'favoriteBuildings/addFavoriteBuilding',
  favoriteBuildingService.addFavoriteBuilding,
)

export const removeFavoriteBuilding = createAsyncThunk(
  'favoriteBuildings/removeFavoriteBuilding',
  favoriteBuildingService.removeFavoriteBuilding,
)

export const getFavoriteByBuildingIds = createAsyncThunk(
  'favoriteBuildings/getFavoriteByBuildingIds',
  favoriteBuildingService.getFavoriteByBuildingIds,
)

export const getCompanyFavoriteBuildings = createAsyncThunk(
  'favoriteBuildings/getCompanyFavoriteBuildings',
  favoriteBuildingService.getCompanyFavoriteBuildings,
)

const favoriteBuildings = createSlice({
  name: 'favoriteBuildings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyFavoriteBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getMyFavoriteBuildings.rejected, (state) => {
        state.loading = false
      })
      .addCase(
        getMyFavoriteBuildings.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const { data } = action.payload || {}

          state.myFavoriteBuildings = data

          if (data) {
            state.myFavoriteBuildingsIds.push(
              ...data.data.map((building: any) => parseInt(building.id, 10)),
            )
          }

          state.loading = false
        },
      )
      .addCase(getCompanyFavoriteBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanyFavoriteBuildings.rejected, (state) => {
        state.loading = false
      })
      .addCase(
        getCompanyFavoriteBuildings.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const { data } = action.payload || {}

          state.companyFavoriteBuildings = data

          if (data) {
            state.companyFavoriteBuildingsIds.push(
              ...data.data.map((building: any) =>
                parseInt(building.building_id, 10),
              ),
            )
          }

          state.loading = false
        },
      )
      .addCase(
        getFavoriteByBuildingIds.fulfilled,
        (state: any, action: any) => {
          const { data } = action.payload || {}

          if (data?.length === 0) {
            state.favoriteBuildingsIds = state.favoriteBuildingsIds.filter(
              (id: any) => id !== action.meta.arg,
            )
          } else if (data?.length) {
            state.favoriteBuildingsIds = [
              ...state.favoriteBuildingsIds,
              ...data.map((building: any) => +building.building_id),
            ]
          }
        },
      )
      .addCase(addFavoriteBuilding.pending, (state: any, action: any) => {
        const buildingId = +action.meta.arg

        state.favoriteBuildingsIds = [...state.favoriteBuildingsIds, buildingId]
      })
      .addCase(removeFavoriteBuilding.pending, (state: any, action: any) => {
        const buildingId = +action.meta.arg

        state.favoriteBuildingsIds = state.favoriteBuildingsIds.filter(
          (id: any) => id !== buildingId,
        )
        if (state.companyFavoriteBuildings !== undefined) {
          state.companyFavoriteBuildings.data =
            state.companyFavoriteBuildings.data.filter((building: any) => {
              if (building.id) {
                return building.id !== buildingId
              }

              return building.building_id !== buildingId
            })
          state.companyFavoriteBuildings.total =
            state.companyFavoriteBuildings.length
        }
        if (state.myFavoriteBuildings) {
          state.myFavoriteBuildings.data =
            state.myFavoriteBuildings.data.filter(
              (building: any) => building.id !== buildingId,
            )
        }
      })
  },
})

export const favoriteBuildingsReducer = {
  favoriteBuildings: favoriteBuildings.reducer,
}
