/* eslint-disable max-lines */
import { isValidDate } from '@popety_io/popety-io-lib'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import sunlightService from './sunlightService'

const initialState = {
  date: new Date().toISOString(),
  showSunExposure: false,
  sunlightTimes: undefined,
  sunlightWinterTimes: undefined,
  sunlightSummerTimes: undefined,
  landSunLightData: undefined,
  buildingSunLightData: undefined,
  estimateSunLightData: undefined,
  monthlyAverageExposure: [],
  loading: false,
  customSunlightBuilding:
    JSON.parse(localStorage.getItem('customSunlightBuilding')) || [],
  customSunlightBuildingsPolygons:
    JSON.parse(localStorage.getItem('customSunlightBuildingsPolygons')) || {},
}

export const getPotentialSolar = createAsyncThunk(
  'sunlight/getPotentialSolar',
  sunlightService.getPotentialSolar,
)

export const getBuildingPotentialSolar = createAsyncThunk(
  'sunlight/getBuildingPotentialSolar',
  sunlightService.getBuildingPotentialSolar,
)

export const getEstimatePotentialSolar = createAsyncThunk(
  'sunlight/getEstimatePotentialSolar',
  sunlightService.getBuildingPotentialSolar,
)

export const getMonthlyExposureAverage = createAsyncThunk(
  'sunlight/getMonthlyExposureAverage',
  sunlightService.getMonthlyExposureAverage,
)

const sunlight = createSlice({
  name: 'sunlight',
  initialState,
  reducers: {
    updateSunlightOptions: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key]

        if (key === 'date' && !isValidDate(value)) return

        state[key] = key === 'date' ? new Date(value).toISOString() : value
      })
    },
    updateSunlightBuildingsPolygons: (state, action) => {
      if (action.payload?.landId) {
        state.customSunlightBuildingsPolygons[action.payload?.landId] =
          action.payload?.data

        localStorage.setItem(
          'customSunlightBuildingsPolygons',
          JSON.stringify(state.customSunlightBuildingsPolygons),
        )
      }
    },
    updateSelectedSunlightBuilding: (state, action) => {
      const buildingIndex = state?.customSunlightBuilding?.findIndex(
        (building) => building?.id === action.payload?.id,
      )

      if (buildingIndex !== -1) {
        const newBuildings = []
        const oldBuildings = state.customSunlightBuilding
        const newBuilding = state.customSunlightBuilding[buildingIndex]

        if (newBuilding) {
          newBuilding.data.layers[0].paint['fill-extrusion-color'] = '#32bcea'

          // state.customSunlightBuilding[buildingIndex] = newBuilding

          newBuildings.push(newBuilding)
        }

        oldBuildings.forEach((building, index) => {
          if (index !== buildingIndex) {
            building.data.layers[0].paint['fill-extrusion-color'] = [
              'get',
              'color',
            ]

            newBuildings.push(building)
          }
        })
      }
    },
    updateCustomSunlightBuilding: (state, action) => {
      const buildingIndex = state?.customSunlightBuilding?.findIndex(
        (building) => building?.id === action.payload?.id,
      )

      if (buildingIndex !== -1) {
        const oldBuilding = state.customSunlightBuilding[buildingIndex]
        const newBuilding = action.payload

        newBuilding.data.data.features[0].properties.height =
          oldBuilding.data.data.features[0].properties.height

        state.customSunlightBuilding[buildingIndex] = newBuilding
      } else {
        state.customSunlightBuilding.push(action.payload)
      }

      localStorage.setItem(
        'customSunlightBuilding',
        JSON.stringify(state.customSunlightBuilding),
      )
    },
    removeCustomSunlightBuilding: (state, action) => {
      state.customSunlightBuilding = state.customSunlightBuilding.filter(
        (building) => building.id !== action.payload?.id,
      )

      state.customSunlightBuildingsPolygons = {
        ...state.customSunlightBuildingsPolygons,
        [action.payload?.landId]: {
          ...state.customSunlightBuildingsPolygons[action.payload?.landId],
          features: state.customSunlightBuildingsPolygons[
            action.payload?.landId
          ].features.filter((feature) => feature.id !== action.payload?.id),
        },
      }

      localStorage.setItem(
        'customSunlightBuildingsPolygons',
        JSON.stringify(state.customSunlightBuildingsPolygons),
      )

      localStorage.setItem(
        'customSunlightBuilding',
        JSON.stringify(state.customSunlightBuilding),
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPotentialSolar.pending, (state) => {
        state.loading = true
      })
      .addCase(getPotentialSolar.rejected, (state) => {
        state.loading = false
      })
      .addCase(getPotentialSolar.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.landSunLightData = data

        state.loading = false
      })
      .addCase(getBuildingPotentialSolar.pending, (state) => {
        state.loading = true
      })
      .addCase(getBuildingPotentialSolar.rejected, (state) => {
        state.loading = false
      })
      .addCase(getBuildingPotentialSolar.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.buildingSunLightData = data

        state.loading = false
      })

      .addCase(getEstimatePotentialSolar.pending, (state) => {
        state.loading = true
      })
      .addCase(getEstimatePotentialSolar.rejected, (state) => {
        state.loading = false
      })
      .addCase(getEstimatePotentialSolar.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.estimateSunLightData = data
      })
      .addCase(getMonthlyExposureAverage.pending, (state) => {
        state.loading = true
      })
      .addCase(getMonthlyExposureAverage.rejected, (state) => {
        state.loading = false
      })
      .addCase(getMonthlyExposureAverage.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        state.monthlyAverageExposure = data

        state.loading = false
      })
  },
})

export const sunlightReducer = {
  sunlight: sunlight.reducer,
}

export const {
  updateSunlightOptions,
  updateCustomSunlightBuilding,
  removeCustomSunlightBuilding,
  updateSunlightBuildingsPolygons,
  updateSelectedSunlightBuilding,
} = sunlight.actions
