/* eslint-disable max-lines */
import { lazy } from 'react'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import MapIcon from '@mui/icons-material/MapsHomeWork'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  SummaryIcon,
  BuildingIcon2,
  PlotIconV2,
  EnergyIcon,
  SunlightIconV2,
  AmenitiesIcon,
} from '@popety_io/popety-io-lib'

import HistoryIcon from '@mui/icons-material/History'
import { PlotIcon } from '../../../LandDetails/components/LandDetailsMenu'
import { TransactionCountChip } from '../../../LandDetails/Transaction'
import ListingCountChip from '../../../LandDetails/Listing/ListingCountChip'
import FaoCountChip from '../../../LandDetails/Fao/FaoCountChip'

const BuildingDetailsSummary = lazy(() => import('../../Summary'))
const Configuration = lazy(
  () => import('../../Configuration/ConfigurationBuilding'),
)
const Listing = lazy(() => import('../../../LandDetails/Listing'))
const Transaction = lazy(() => import('../../../LandDetails/Transaction'))
const Fao = lazy(() => import('../../../LandDetails/Fao'))
const Livability = lazy(() => import('../../../LandDetails/Livability'))
const Sunlight = lazy(() => import('../../../LandDetails/Sunlight'))
const LandSummary = lazy(() => import('../../LandSummary'))
const Energy = lazy(() => import('../../Energy'))
const LandHistory = lazy(
  () => import('../../../LandDetails/components/LandHistory'),
)
const BuildingHistory = lazy(() => import('../BuildingHistory'))
const LandNoise = lazy(() => import('../../../LandDetails/LandNoise'))
const BuildingEventHistory = lazy(() => import('../../BuildingEventHistory'))

const getBuildingDetailsMenuData = (
  t = (k: string) => k,
  isHomePage?: boolean,
  firstTransactionDate?: string,
  firstFaoDate?: string,
  canAnalyse?: boolean,
) => {
  const pages: any = isHomePage
    ? [
        {
          icon: <PlotIcon />,
          href: '/land-detail/plot?tab=home&active=true',
          path: '/land-detail/plot',
          key: 'home',
          text: t('common.lands'),
          page: <LandHistory />,
          show: true,
        },
        {
          icon: <MapIcon />,
          href: '/land-detail/building?tab=home&active=true',
          path: '/land-detail/building',
          key: 'home',
          text: t('common.buildings'),
          page: <BuildingHistory />,
          show: true,
        },
        {
          icon: <AddCircleOutlineIcon />,
          href: '/land-detail/plot?tab=grouping&active=true',
          path: '/land-detail/plot',
          key: 'grouping',
          text: t('common.Grouping Plot'),
          page: <LandHistory />,
          show: true,
        },
      ]
    : [
        {
          icon: <SummaryIcon />,
          key: 'summary',
          text: t('building.summary'),
          page: <BuildingDetailsSummary />,
          show: true,
        },
        {
          icon: (
            <HistoryIcon
              viewBox="2 2 20 20"
              sx={{
                width: '30px',
                height: '30px',
              }}
            />
          ),
          key: 'history',
          text: t('common.historique'),
          page: <BuildingEventHistory />,
          show: true,
        },
        {
          icon: <BuildingIcon2 />,
          key: 'configuration',
          text: t('building.configuration'),
          page: <Configuration />,
          show: true,
        },
        {
          icon: <PlotIconV2 />,
          key: 'parcelle',
          text: t('building.info.parcelle'),
          page: <LandSummary />,
          show: true,
        },
        {
          icon: <EnergyIcon />,
          key: 'energy',
          text: t('building.livability.type.energy'),
          page: <Energy />,
          show: true,
        },
        {
          icon: <FaoCountChip show />,
          key: 'fao',
          text: t('land.fao'),
          page: <Fao show />,
          show: canAnalyse && !!firstFaoDate,
        },
        {
          icon: <TransactionCountChip show />,
          key: 'transaction',
          text: t('land.transaction'),
          page: <Transaction show />,
          show: canAnalyse && !!firstTransactionDate,
        },
        {
          icon: <ListingCountChip />,
          key: 'listing',
          text: t('land.listing'),
          page: <Listing />,
          show: true,
        },
        {
          icon: <CrisisAlertIcon />,
          key: 'enviroment',
          text: t('building.environment'),
          page: <div />,
          subMenus: [
            {
              icon: <AmenitiesIcon />,
              key: 'livability',
              text: t('common.amenities'),
              page: <Livability />,
              show: true,
            },
            {
              icon: <VolumeUpIcon />,
              key: 'noise',
              text: t('common.noise'),
              page: <LandNoise />,
              show: true,
            },
            {
              icon: <SunlightIconV2 />,
              key: 'sunlight',
              text: t('building.sunlight'),
              page: <Sunlight />,
              show: true,
            },
          ],
          show: true,
        },
      ].filter(Boolean)

  return pages
}

export { getBuildingDetailsMenuData }
