import { Box } from '@mui/material'
import { useState } from 'react'

import DesignProjectList from './DesignProjectList'
import DesignSteps from './DesignSteps'

const Design = () => {
  const [designMode, setDesignMode] = useState(false)

  return (
    <Box sx={{ paddingY: 3, paddingX: 1.5 }}>
      {!designMode ? (
        <DesignProjectList onAddNew={() => setDesignMode(true)} />
      ) : (
        <DesignSteps onBack={() => setDesignMode(false)} />
      )}
    </Box>
  )
}

export default Design
