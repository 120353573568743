/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import analizedBuildingService from './analizedBuildingService'

const initialState = {
  analizedBuildings: undefined,
  companyAnalyzedBuildings: undefined,
  users: undefined,
  loading: false,
}

export const getAnalyzedBuildings = createAsyncThunk(
  'analizedBuilding/getAnalyzedLands',
  analizedBuildingService.getAnalyzedBuildings,
)

export const getAnalyzedBuildingsByUser = createAsyncThunk(
  'analizedBuilding/getAnalyzedLandsByUser',
  analizedBuildingService.getAnalyzedBuildingsByUser,
)

export const getCompanyUsers = createAsyncThunk(
  'analizedBuilding/getCompanyUsers',
  analizedBuildingService.getCompanyUsers,
)

export const addAnalyzedBuilding = createAsyncThunk(
  'analizedBuilding/addAnalyzedBuilding',
  analizedBuildingService.addAnalyzedBuilding,
)

const analizedBuilding = createSlice({
  name: 'analizedBuilding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAnalyzedBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnalyzedBuildings.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAnalyzedBuildings.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.analizedBuildings = data

        state.loading = false
      })
      .addCase(getCompanyUsers.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.users = data
      })
      .addCase(getAnalyzedBuildingsByUser.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnalyzedBuildingsByUser.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAnalyzedBuildingsByUser.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.companyAnalyzedBuildings = data

        state.loading = false
      })
  },
})

export const analizedBuildingReducer = {
  analizedBuilding: analizedBuilding.reducer,
}
