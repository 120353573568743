const favoriteLandsSelector = (state) => state.favorite.favoriteLands

const favoriteLandIdsSelector = (state) => state.favorite.favoriteLandIds

const favoriteGroupingLandsSelector = (state) =>
  state.favorite.favoriteGroupingLands

const myFavoriteLandsSelector = (state) => state.favorite.myFavoriteLands

const companyFavoriteLandsSelector = (state) =>
  state.favorite.companyFavoriteLands

const favoriteIsLoadingSelector = (state) => state.favorite.loading

export {
  favoriteLandsSelector,
  favoriteLandIdsSelector,
  favoriteGroupingLandsSelector,
  myFavoriteLandsSelector,
  companyFavoriteLandsSelector,
  favoriteIsLoadingSelector,
}
