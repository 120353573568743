import { mapBy } from '@popety_io/popety-io-lib'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import buildingActionHistoryService from './BuildingActionHisoryService'

const initialState = {
  buildingsActionHistory: {},
}

export const getBuildingsActionHistory = createAsyncThunk(
  'buildingActionHistory/getBuildingsActionHistory',
  buildingActionHistoryService.getBuildingsActionHistory,
)

export const addBuildingNote = createAsyncThunk(
  'buildingActionHistory/addBuildingNote',
  buildingActionHistoryService.addBuildingNote,
)

export const removeBuildingNote = createAsyncThunk(
  'buildingActionHistory/removeBuildingNote',
  buildingActionHistoryService.removeBuildingNote,
)

export const updateBuildingNote = createAsyncThunk(
  'buildingActionHistory/updateBuildingNote',
  buildingActionHistoryService.updateBuildingNote,
)

const buildingActionHistory = createSlice({
  name: 'buildingActionHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getBuildingsActionHistory.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { data = [] } = action.payload || {}

          state.buildingsActionHistory = {
            ...state.buildingsActionHistory,
            ...mapBy(
              'uid',
              data.map((r: any) => ({
                ...r,
                uid: `${r.action}_${r.note_id || r.id}`,
              })),
            ),
          }
        },
      )
      .addCase(
        addBuildingNote.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const { data = [] } = action.payload || {}

          if (data) {
            const note = data[0]

            if (note) {
              state.buildingsActionHistory[
                `${note.action || 'note'}_${note.note_id || note.id}`
              ] = note
            }
          }
        },
      )
      .addCase(
        removeBuildingNote.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const { noteId } = action.payload || {}

          if (noteId) {
            delete state.buildingsActionHistory[`note_${noteId}`]
          }
        },
      )
      .addCase(
        updateBuildingNote.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const value = action.payload || {}

          if (value) {
            state.buildingsActionHistory[`note_${value.note_id}`] = {
              ...state.buildingsActionHistory[`note_${value.note_id}`],
              ...value,
            }
          }
        },
      )
  },
})

export const buildingActionHistoryReducer = {
  buildingActionHistory: buildingActionHistory.reducer,
}
