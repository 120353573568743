import { Box, styled } from '@mui/material'

export const SummaryRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 16px 8px;
  h3 {
    color: #041725;
    font-size: 0.9231rem;
    font-weight: 500;
    text-transform: uppercase;
  }
`

export const BoxInfor = styled(Box)`
  width: 100%;
  height: 67px;
  border: 1px solid #f9f9f9;
  background-color: #fafafa;
`
