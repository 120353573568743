import { useState, useEffect } from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import {
  Caption,
  groupBy,
  useI18n,
  Driver,
  fetchOnce,
} from '@popety_io/popety-io-lib'

import { landSelector } from '../redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import RestrictionAffected from './RestrictionAffected'
import Restrictioncard from './Restrictioncard'
import { HeaderItemKey, headers } from './Restrictioncard/RestrictionCard.util'
import RestrictionUnAffected from './RestrictionUnAffected'
import { RestrictionContentContainer } from './Restriction.style'
import getDriverInstructions from './driverInstructions'

const Restriction = () => {
  const [displayDriver, setDisplayDriver] = useState(false)
  const dispatch = useDispatch<any>()
  const { t } = useI18n()
  const land: any = useSelector(landSelector)
  const user = useSelector(userSelector)

  const { unAffectedRdppf } = land
  const { regionRdppf } = land

  const rdppfs = Object.values(groupBy('rdppf_type_id', land.rdppfs))

  const getProps = (headerItemKey: HeaderItemKey) => ({
    id: headers[headerItemKey].id,
    title: t(headers[headerItemKey].key as any),
    icon: headers[headerItemKey].icon,
    isUnAffected: (headers[headerItemKey] as any)?.isUnAffected,
  })

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedRestrictionDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedRestrictionDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Box id="restrictions">
      <RestrictionContentContainer p={2}>
        {!rdppfs?.length ? (
          <Restrictioncard {...getProps('noAffected')} />
        ) : (
          <Restrictioncard
            {...getProps('affected')}
            numberOfPlot={`${rdppfs?.length}/`}
            allNumber={regionRdppf?.length}
          >
            {rdppfs?.length > 0 && (
              <RestrictionAffected affectedList={rdppfs} />
            )}
          </Restrictioncard>
        )}

        {!unAffectedRdppf?.length ? (
          <Restrictioncard {...getProps('noUnAffected')} />
        ) : (
          <Restrictioncard
            {...getProps('unAffected')}
            numberOfPlot={`${unAffectedRdppf?.length}/`}
            allNumber={regionRdppf?.length}
          >
            {unAffectedRdppf && (
              <RestrictionUnAffected unAffectedList={unAffectedRdppf} />
            )}
          </Restrictioncard>
        )}
      </RestrictionContentContainer>

      <Box p={2} pb={{ xs: 8, lg: 4 }}>
        {!!land.rdppf_land_link && (
          <Typography
            gap={1}
            component="a"
            display="flex"
            href={land.rdppf_land_link}
            color="primary.main"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DescriptionIcon />
            <span>{t('land.restriction.Télécharger le cadastre')}</span>
          </Typography>
        )}
        <Caption>{t('land.restriction.restriction explanation guide')}</Caption>
      </Box>
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Box>
  )
}

export default Restriction
