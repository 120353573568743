import { ApiClient } from '../../../services'

const client = new ApiClient()

type PauseAlertProps = { id: string | number; pause: boolean }

const getAlerts = async () => client.get(`/alerts/getAllAlerts`)

const pauseAlert = async ({ id, pause }: PauseAlertProps) =>
  client.get(`/alerts/${id}/paused/${pause}`)

const getCityById = async (cityIds: string[]) =>
  client.get(`/cities/${cityIds}/geojson`)

const getZonesByCity = async (cityNames: string[]) =>
  client.get(`/zones/primary/${cityNames}`)

const getDistrictById = async (zoneIds: string[]) =>
  client.get(`/district/${zoneIds}/geojson`)

const getZonesByDistrict = async (zoneNames: string[]) =>
  client.get(`/zones/primary/district/${zoneNames}`)

const getRegionById = async (regionIds: string[]) =>
  client.get(`/region/${regionIds}/geojson`)

const getZonesByRegion = async (regionNames: string[]) =>
  client.get(`/zones/primary/region/${regionNames}`)

const getDistrictGeojsonByZone = async ({ zoneIds, landUsePlan }: any) => {
  return client.get(
    `/district/${zoneIds}/landscapingareaprincipaltype/${landUsePlan}/geojson`,
  )
}

const getRegionGeojsonByZone = async ({ regionIds, landUsePlan }: any) => {
  return client.get(
    `/region/${regionIds}/landscapingareaprincipaltype/${landUsePlan}/geojson`,
  )
}

const getCityGeojsonByZone = async ({ cityIds, landUsePlan }: any) => {
  return client.get(
    `/cities/${cityIds}/landscapingareaprincipaltype/${landUsePlan}/buildinglandsgeojson`,
  )
}

const createAlert = async (input: Record<string, any>) => {
  return client.post(`/alerts/add`, input)
}

const updateAlert = async (data: Record<string, any>) => {
  return client.put(`/alerts/${data.id}/update`, data)
}

const deleteAlert = async (id: string | number) =>
  client.put(`/alerts/${id}/delete`)

const faoHistoryService = {
  getAlerts,
  pauseAlert,
  getCityById,
  getRegionById,
  getDistrictById,
  getZonesByCity,
  getZonesByDistrict,
  getZonesByRegion,
  createAlert,
  updateAlert,
  deleteAlert,
  getDistrictGeojsonByZone,
  getRegionGeojsonByZone,
  getCityGeojsonByZone,
}

export default faoHistoryService
