import { BoxProps } from '@mui/material/Box'
import { useState } from 'react'
import Typography from '@mui/material/Typography'
import { ViewMoreLess, useI18n } from '@popety_io/popety-io-lib'

export type CollapseListProps = Omit<BoxProps, 'onChange'> & {
  min?: number
  max?: number
  moreKey?: string
  items: string[]
}

const CollapseList = ({
  min = 3,
  max = 1000,
  items = [],
  moreKey = 'common.seeMore',
  ...other
}: CollapseListProps) => {
  const { t } = useI18n()

  const [more, setMore] = useState(
    max && min ? items.length < (min || 0) : false,
  )

  const list = max && min ? items.slice(0, more ? max : min) : items

  const disableExpansion =
    max && min ? items.length <= list.length && items.length <= min : true

  const handleToggle = () => {
    setMore((value) => !value)
  }

  return (
    <ViewMoreLess
      {...other}
      more={more}
      onToggle={handleToggle}
      disable={disableExpansion}
      moreText={t(moreKey, { count: items.length - 3 })}
      lessText={t('common.seeLess')}
    >
      {list?.map((item, i) => <Typography key={i}>{item}</Typography>)}
    </ViewMoreLess>
  )
}

export default CollapseList
