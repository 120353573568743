// TODO: move to a folder
// TODO: move to lib
import { download } from '@popety_io/popety-io-lib'

import { EXCEL_TYPE } from '../config'
import { API_BASE_URL } from '../services/apiClient/ApiClient'

/**
 * Generate history excel
 */
const generateHistoryExcel = async (data: Record<string, any>) => {
  try {
    data.url = window.location.href
    const fileName = data.fileName || `${data.type || 'history'}Report.xlsx`

    const response = await fetch(`${API_BASE_URL}/report/history/excel`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/json',
        locale: localStorage.getItem('language'),
      } as any,
      body: JSON.stringify(data),
    })

    if (response.ok) {
      const blob = await response.blob()
      const reportName = fileName + (fileName.endsWith('.xlsx') ? '' : '.xlsx')

      return download(blob.slice(0, blob.size, EXCEL_TYPE), reportName)
    }

    console.error(`Failed to download file. Status code: ${response.status}`)
  } catch (error) {
    console.error(error)
  }
}

const generateListingPdf = async (data: any, type = 'pdf') => {
  try {
    data.url = window.location.href
    const fileName = data.fileName || `${data.type || 'history'}Report.pdf`

    const response = await fetch(`${API_BASE_URL}/report/listing/${type}`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/json',
        locale: localStorage.getItem('language'),
      } as any,
      body: JSON.stringify({ data }),
    })

    if (response.ok) {
      const blob = await response.blob()
      const reportName = fileName + (fileName.endsWith('.pdf') ? '' : '.pdf')

      return download(blob, reportName)
    }

    console.error(`Failed to download file. Status code: ${response.status}`)
  } catch (error) {
    console.error(error)
  }
}

/**
 * transformTileRequest
 */
const transformTileRequest = (url: string, resourceType?: string) => {
  try {
    const accessToken = localStorage.getItem('jwt')
    const headers = { Authorization: `JWT ${accessToken}` }

    if (resourceType && url.startsWith(API_BASE_URL as string)) {
      return { url, headers }
    }
  } catch (error) {
    console.error(error)
  }
}

export { generateHistoryExcel, transformTileRequest, generateListingPdf }
