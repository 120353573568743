import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const TransactionFilters = lazy(
  () => import('../../pages/TransactionHistory/components/TransactionFilters'),
)

const FaoFilters = lazy(
  () => import('../../pages/FaoHistory/components/FaoFilters'),
)

const LandDetailsMenu = lazy(
  () => import('../../pages/LandDetails/components/LandDetailsMenu'),
)

const BuildingDetailsMenu = lazy(
  () => import('../../pages/BuildingDetails/components/BuildingDetailsMenu'),
)

const AlertsFilters = lazy(
  () => import('../../pages/Alerts/components/AlertsFilters'),
)

const ListingFilters = lazy(
  () => import('../../pages/ListingHistory/components/ListingFilters'),
)

const DashboardMenu = lazy(
  () => import('../../pages/Dashboard/components/DashboardMenu'),
)

const BuildingDashboardMenu = lazy(
  () => import('../../pages/BuildingDashboard/components/DashboardMenu'),
)

const CompanyDashboardMenu = lazy(
  () => import('../../pages/CompanyDashboard/components/CompanyDashboardMenu'),
)

const DeveloperTableFilters = lazy(
  () => import('../../pages/DeveloperMap/components/DeveloperTableFilters'),
)

const AccountMenu = lazy(
  () => import('../../pages/Account/components/AccountMenu'),
)

const ElearningMenu = lazy(
  () => import('../../pages/Elearning/components/ElearningMenu'),
)

const EstimateMenu = lazy(
  () => import('../../pages/Estimate/components/EstimateMenu'),
)

const SideBox = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/transaction-history" element={<TransactionFilters />} />
        <Route path="/fao-history" element={<FaoFilters />} />
        <Route path="/land-detail/plot" element={<LandDetailsMenu />} />
        <Route path="/land-detail/building" element={<BuildingDetailsMenu />} />
        <Route path="/my-alerts" element={<AlertsFilters />} />
        <Route path="/listing-history" element={<ListingFilters />} />
        <Route path="/dashboard" element={<DashboardMenu />} />
        <Route path="/building-dashboard" element={<BuildingDashboardMenu />} />
        <Route path="/company-dashboard" element={<CompanyDashboardMenu />} />
        <Route path="/developer-map/list" element={<DeveloperTableFilters />} />
        <Route path="/developer-map" element={<DeveloperTableFilters />} />
        <Route path="/" element={<DeveloperTableFilters />} />
        <Route path="/account" element={<AccountMenu />} />
        <Route path="/e-learning" element={<ElearningMenu />} />
        <Route path="/estimate" element={<EstimateMenu />} />
        <Route path="*" element={null} />
      </Routes>
    </Suspense>
  )
}

export default memo(SideBox)
