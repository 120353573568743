/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import restrictionsService from './restrictionsService'

const initialState = {
  loading: false,
  restrictions: [],
}

export const fetchRestrictionsByCity = createAsyncThunk(
  'restrictions/fetchRestrictionsByCity',
  restrictionsService.fetchRestrictionsByCity,
)

export const fetchRestrictionsByRegion = createAsyncThunk(
  'restrictions/fetchRestrictionsByRegion',
  restrictionsService.fetchRestrictionsByRegion,
)

export const fetchRestrictionsByDistrict = createAsyncThunk(
  'restrictions/fetchRestrictionsByDistrict',
  restrictionsService.fetchRestrictionsByDistrict,
)

export const fetchRestrictionsByNpa = createAsyncThunk(
  'restrictions/fetchRestrictionsByNpa',
  restrictionsService.fetchRestrictionsByNpa,
)

export const fetchRestrictionsByMunicipalities = createAsyncThunk(
  'restrictions/fetchRestrictionsByMunicipalities',
  restrictionsService.fetchRestrictionsByMunicipalities,
)

const restrictions = createSlice({
  name: 'restrictions',
  initialState,
  reducers: {
    clearRestrictions: (state) => {
      state.restrictions = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRestrictionsByRegion.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRestrictionsByDistrict.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRestrictionsByCity.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRestrictionsByNpa.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRestrictionsByMunicipalities.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRestrictionsByRegion.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchRestrictionsByDistrict.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchRestrictionsByCity.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchRestrictionsByNpa.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchRestrictionsByMunicipalities.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchRestrictionsByRegion.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        state.restrictions = data
      })
      .addCase(fetchRestrictionsByDistrict.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        state.restrictions = data
      })
      .addCase(fetchRestrictionsByCity.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        state.restrictions = data
      })
      .addCase(fetchRestrictionsByNpa.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        state.restrictions = data
      })
      .addCase(fetchRestrictionsByMunicipalities.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false
        state.restrictions = data
      })
  },
})

export const { clearRestrictions } = restrictions.actions

export const restrictionsReducer = {
  restrictions: restrictions.reducer,
}
