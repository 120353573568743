// TODO: ADD USER WHEN CREATED ON ARRAY

const activateOrInactivateUsersById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload
  const input = action.meta.arg

  state.usersById[input.id] = {
    ...state.usersById[input.id],
    ...data,
  }
}

const updateUsersById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const input = action.meta.arg
  const { data } = action.payload

  if (data) {
    state.usersById[input.id] = {
      ...state.usersById[input.id],
      ...input,
      ...data,
    }
  }
}

const addUserById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload
  const input = action.meta.arg

  if (data) {
    const { id } = data

    if (id) {
      state.usersById[id] = {
        ...data,
        ...input,
        status: 'active',
        create_timestamp: new Date(),
      }
    }
  }
}

const updateOfficesById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload
  const input = action.meta.arg

  if (data) {
    state.officesById[input.id] = {
      ...state.officesById[input.id],
      ...input,
      ...data,
    }
  }
}

const addOfficesById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const data = action.payload
  const { input } = action.meta.arg

  if (data) {
    state.officesById[data.id] = {
      ...state.officesById[data.id],
      ...input,
      ...data,
      status: true,
      active: true,
    }
  }
}

const activateOrInactivateOfficesById = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { data } = action.payload
  const input = action.meta.arg

  state.officesById[input.id] = {
    ...state.officesById[input.id],
    ...data,
  }
}

export {
  updateUsersById,
  updateOfficesById,
  addUserById,
  activateOrInactivateUsersById,
  activateOrInactivateOfficesById,
  addOfficesById,
}
