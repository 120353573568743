import Grid from '@mui/material/Grid'
import { Button, Select, useRouter, useI18n } from '@popety_io/popety-io-lib'
// import FaoFilters from './FaoFilters'
// import ListingFilters from './ListingFilters'
// import TransactionAroundFilters from './TransactionAroundFilters'
// import LocationsFilter from './locationsFilter'
// import ListingMinMaxFilter from './ListingMinMaxFilter'
import ListingMinMaxFilter from '../../../LandDetails/components/HistoryAroundFilters/ListingMinMaxFilter'

const EstimateHistoryFilters = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const filters = {
    years: query.years,
    price: query.price,
    rooms: query.rooms,
    area: query.area,
    propertyCategory: query.propertyCategory,
  }

  const hasFilters = !!Object.values(filters).filter(Boolean).length

  const handleReset = () => {
    const newQuery: any = {}

    Object.keys(filters).forEach((k) => {
      newQuery[k] = undefined
    })

    updateQuery(newQuery)
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        pt: 1,
      }}
    >
      <Grid item xs={12} sm={4} md={3}>
        <Select
          fullWidth
          options={[
            { label: t('common.Apartment'), value: 'Apartment' },
            { label: t('common.House'), value: 'House' },
          ]}
          placeholder={t('listing.property_type')}
          value={query.propertyCategory}
          onChange={(newValue: any) =>
            updateQuery({ propertyCategory: newValue?.value })
          }
          variant="outlined"
          label={t('listing.property_type')}
        />
      </Grid>
      <ListingMinMaxFilter
        label={t('common.rooms')}
        labelId="rooms"
        unit="rooms"
      />
      <ListingMinMaxFilter
        label={t('common.landRadarInfo.area')}
        labelId="area"
        unit="m2"
      />
      <ListingMinMaxFilter
        label={t('common.price')}
        labelId="price"
        unit="CHF"
      />
      <ListingMinMaxFilter
        label={t('land.fao.constructionYearSection')}
        labelId="years"
      />

      <Grid item xs={12} pt="0 !important">
        {hasFilters && (
          <Button
            variant="text"
            sx={{ ml: -1, textTransform: 'none', color: '#E97B0E' }}
            onClick={handleReset}
          >
            {t('land.history.clearFilters')}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default EstimateHistoryFilters
