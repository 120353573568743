import { createSelector } from '@reduxjs/toolkit'

const tagsByBuildingIdSelector = (state: any) =>
  state.buildingTag.tagsByBuildingId

const dashboardBuildingsTagsSelector = (state: any) =>
  state.buildingTag.dashboardTags

const companyDashboardBuildingsTagsSelector = (state: any) =>
  state.buildingTag.companyDashboardTags

const refetchBuildingFlagSelector = (state: any) =>
  state.buildingTag.refetchFlag

const buildingsTagsSelector = (state: any) => state.buildingTag.buildingTags

const uniqueBuildingsTagsSelector = createSelector(
  buildingsTagsSelector,
  (tags: any) =>
    tags.filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((tg: any) => tg.value === value.value),
    ),
)

const listBuildingsTagsSelector = createSelector(
  (state: any) => state.buildingTag.listTags,
  (listTags = []) =>
    listTags.map((tag: any) => ({
      ...tag,
      tag_id: tag.id,
      active: true,
    })),
)

const legendBuildingsTagsSelector = (state: any) => state.buildingTag.legendTags

const buildingTagsaAreLoadingSelector = (state: any) =>
  state.buildingTag.loading

export {
  tagsByBuildingIdSelector,
  dashboardBuildingsTagsSelector,
  companyDashboardBuildingsTagsSelector,
  refetchBuildingFlagSelector,
  buildingsTagsSelector,
  uniqueBuildingsTagsSelector,
  listBuildingsTagsSelector,
  legendBuildingsTagsSelector,
  buildingTagsaAreLoadingSelector,
}
