import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  LabelList,
  Cell,
} from 'recharts'
import { useI18n } from '@popety_io/popety-io-lib'

const COLORS = [
  '#00ADE6',
  '#374955',
  '#9AAEBB',
  '#FFEBCD',
  '#CE9659',
  '#946329',
  '#753500',
  '#8D7257',
  '#ED982A',
]

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props
  const radius = -10

  return (
    <g>
      <text
        x={x + width + 20}
        y={y - radius}
        fontSize="0.7em"
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}%
      </text>
    </g>
  )
}

const TransactionsChart = ({
  title,
  width,
  transactionStats,
}: {
  title: string
  width: number
  transactionStats: any
}) => {
  const { t } = useI18n()

  return (
    <Box width="100%">
      <Box>
        <Typography variant="h6" ml="32px" pt={2} pb={2}>
          {t(`land.title.${title}`)}
        </Typography>
        <Box display="flex" flexDirection="row">
          <AreaChart
            width={width - 200}
            height={250}
            data={transactionStats?.stats}
          >
            {transactionStats?.availableData?.map(
              (reason: string, i: number) => (
                <Area
                  key={i}
                  type="monotone"
                  dataKey={reason}
                  stackId="1"
                  stroke="none"
                  fill={COLORS[i]}
                />
              ),
            )}

            <XAxis
              style={{ fontSize: '0.7rem' }}
              dataKey="year"
              tickLine={false}
            />
            <YAxis
              style={{ fontSize: '0.7rem' }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip />
          </AreaChart>
          <div>
            <BarChart
              layout="vertical"
              width={200}
              height={220}
              data={transactionStats?.percent}
            >
              <YAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                type="category"
                reversed
                style={{ fontSize: '0.8rem' }}
              />
              <XAxis
                type="number"
                hide
                axisLine={false}
                tickLine={false}
                domain={[0, (dataMax: number) => dataMax * 1.5]}
              />
              <Bar dataKey="value" barSize={20} fill="#00ADE6">
                {transactionStats?.percent?.map((entry: any, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    opacity={0.8}
                  />
                ))}
                <LabelList dataKey="value" content={renderCustomizedLabel} />
              </Bar>
            </BarChart>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default TransactionsChart
