import Typography from '@mui/material/Typography'
import { formatUnderscore } from '@popety_io/popety-io-lib'

export const getColorLimit = (nb: number, limitNb: number) => {
  let colorLimit = 'rgba(127, 188, 83, 0.7)'
  const counter = Number(nb) || 0
  const limit = Number(limitNb) || 0

  if (counter && limit && counter / limit > 0.9) {
    colorLimit = '#E27670'
  } else if (counter && counter / limit > 0.7) {
    colorLimit = 'orange'
  }

  if (counter > limit) {
    colorLimit = '#E27670'
  }

  return colorLimit
}

const getUserPlanItems = ({ t, user, roles }: Record<string, any>) => {
  const analysisNbLimit =
    user.subscription_frequency === 'yearly'
      ? user.analysis_nb_limit * 12
      : user.analysis_nb_limit

  const colorLimit = getColorLimit(user, analysisNbLimit)

  const items = [
    { title: t('account.planName'), value: user.plan_name },
    {
      title: t('account.planStartDate'),
      value: user.subscription_start_date?.substring(0, 10),
    },
    {
      title: t('account.planEndDate'),
      value: user.subscription_end_date?.substring(0, 10),
    },
    // { title: t('account.companyCity'), value: user.company_city },
    {
      title: t('account.planFrequency'),
      value: t(`account.${user.subscription_frequency}`),
    },
    {
      title: t('account.monthlyAnalysisLimit'),
      value: (
        <>
          <Typography component="span" fontWeight={500} color={colorLimit}>
            {user.analysis_counter || user.monthly_analysis_counter || 0}
          </Typography>{' '}
          /{' '}
          {user.plan_name !== 'Ultimate'
            ? analysisNbLimit
            : t('account.unlimited')}
        </>
      ),
    },
    {
      title: t('account.role'),
      value: roles?.map(formatUnderscore).join(', '),
    },
  ]

  return items
}

export { getUserPlanItems }
