import React, { useMemo } from 'react'
import CropLandscapeIcon from '@mui/icons-material/CropLandscape'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ApartmentIcon from '@mui/icons-material/Apartment'
import MapIcon from '@mui/icons-material/Map'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useMediaQuery, useI18n } from '@popety_io/popety-io-lib'
import { matchSorter } from 'match-sorter'
import { LocationType } from './LocationsInput.style'

interface OptionIconType {
  [key: string]: JSX.Element
}

const optionIcon: OptionIconType = {
  address: <LocationOnIcon fontSize="small" />,
  land: <CropLandscapeIcon fontSize="small" />,
  city: <LocationCityIcon fontSize="small" />,
  municipality: <LocationCityIcon fontSize="small" />,
  default: <MapIcon fontSize="small" />,
  npa: <LocationCityIcon fontSize="small" />,
  egid: <ApartmentIcon fontSize="small" />,
}

interface OptionProps {
  id: string
  location_search: string
  location_type: string
  // Add any other properties that the 'option' object may have
}

interface LocationOptionProps {
  option: OptionProps
}

export const filterOptions = (
  options: OptionProps[],
  { inputValue }: { inputValue: string },
) => {
  const opts = matchSorter(options, inputValue, {
    keys: [(option) => option.location_search],
  })

  return opts.length ? opts : options
}

const LocationOption = ({ option }: LocationOptionProps) => {
  const { t } = useI18n()
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const Icon = useMemo(
    () => optionIcon[option.location_type] || optionIcon.default,
    [option.location_type],
  )

  return (
    <Stack
      key={option.id}
      direction="row"
      spacing={1}
      fontSize={!onDesktop ? '0.7rem' : '1rem'}
      alignItems="center"
      className="ellipsis"
    >
      {Icon}

      <Typography
        className="ellipsis"
        fontSize={!onDesktop ? '0.7rem' : '1rem'}
        title={option.location_search}
      >
        {option.location_search}
      </Typography>
      {onDesktop && (
        <LocationType>({t(`search.${option.location_type}`)})</LocationType>
      )}
    </Stack>
  )
}

export default React.memo(LocationOption)
