import { Link, Navigate, useNavigate } from 'react-router-dom'
import { LoginForm, Alert, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'

import { useRemoveFooter, useThunk } from '../../hooks'
import { login, needVerifySelector } from './redux'
import { LoginRoot } from './Login.style'

const Login = () => {
  const { t } = useI18n()

  useRemoveFooter()
  const { dispatch, loading, errors, called } = useThunk()
  const { query } = useRouter()

  const navigate = useNavigate()

  const jwt = typeof localStorage !== 'undefined' && localStorage.getItem('jwt')
  const twoFAToken =
    typeof localStorage !== 'undefined' && localStorage.getItem('twoFAToken')
  const needVerify = useSelector(needVerifySelector)

  const from = query.from || '/'

  const handleSubmit = (values: any) => {
    dispatch(() => login({ ...values, email: values?.email.toLowerCase() }))
  }

  if (twoFAToken && needVerify && !loading && called) {
    navigate({ pathname: '/2fa', search: `?from=${from}` }, { replace: true })
  }

  if (jwt && !loading && !needVerify) {
    if (from?.startsWith('http')) {
      window.location.href = from

      return null
    }

    return <Navigate to={from || '/'} />
  }

  return (
    <LoginRoot bgcolor="background.default">
      <LoginForm
        passwordLabel={t('login.password')}
        emailLabel={t('common.email')}
        loading={loading}
        submitText={t('login.signin')}
        onSubmit={handleSubmit}
        emailRequired={t('common.email.required')}
        passwordRequired={t('common.password.required')}
        invalidEmail={t('login.email.invalid')}
      >
        <Link
          color="secondary"
          to="/password-reset"
          data-cy="goto-forgot-pw"
          style={{ marginTop: 16 }}
        >
          {t('common.passwordReset')}
        </Link>

        <Alert error open={!!errors}>
          {errors?.[0]?.message}
        </Alert>
      </LoginForm>
    </LoginRoot>
  )
}

export default Login
