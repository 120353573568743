import { fromFilter, parseGeoJSON } from '@popety_io/popety-io-lib'

/**
 * Get listing filters from url query
 * @param {Object} query url query param
 */
const getListingFilters = (query: Record<string, any>) => {
  let { value } = query
  const { orderBy = 'listing_timestamp:desc' } = query

  if (query.landId) {
    value = `LAND_ID:${query.landId}`
  } else if (query.id) {
    value = `ID:${query.id}`
  }

  return {
    value,
    date: fromFilter(query.date, true),
    area: fromFilter(query.area, true),
    dealType: fromFilter(query.dealType),
    propertyCategory: fromFilter(query.propertyCategory),
    room: fromFilter(query.room, true),
    propertyType: fromFilter(query.propertyType),
    vendorType: fromFilter(query.vendorType),
    floor: fromFilter(query.floor, true),
    constructionYear: fromFilter(query.constructionYear, true),
    price: fromFilter(query.price, true),
    priceM2: fromFilter(query.priceM2, true),
    features: fromFilter(query.features),
    landId: fromFilter(query.landId),
    id: fromFilter(query.id),
    adStatus: (fromFilter(query.adStatus) as string[])?.map(Number),
    status: fromFilter(query.status, true),
    keyword: fromFilter(query.keyword, true),
    reason: fromFilter(query.reason),
    region: fromFilter(query.region),
    zone: fromFilter(query.zone),
    city: fromFilter(query.city),
    npa: fromFilter(query.npa),
    polygon: parseGeoJSON(query.polygon),
    size: +query.size || 25,
    page: +query.page || 0,
    view: query.view,
    orderBy,
    exactLocation:
      query.exactLocation || query.view === 'map'
        ? query.exactLocation === 'true'
        : undefined,
    bbox: fromFilter(query.bbox),
    listingDuration: fromFilter(query.listingDuration, true),
    mandateType: fromFilter(query.mandateType),
  }
}

export { getListingFilters }
