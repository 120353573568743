import { GridProps } from '@mui/material/Grid'
import { PricePercentRoot } from './ListingAnalyticCard.style'

type PricePercentProps = GridProps & {
  label: string
  score: number
  children?: React.ReactNode
}

const PricePercent = ({
  label,
  score,
  children,
  ...other
}: PricePercentProps) => {
  const color = score > 0 ? 'rgba(127, 188, 83, 0.7)' : 'rgba(177, 73, 69, 0.7)'

  return (
    <PricePercentRoot item xs="auto" {...({ color } as any)} {...other}>
      <span>{label}</span>
      {(!!score || score === 0) && (
        <p>
          {score}% {children}
        </p>
      )}
    </PricePercentRoot>
  )
}

export default PricePercent
