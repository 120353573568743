/* eslint-disable no-param-reassign */
import { mapBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import prospectService from './prospectService'

const initialState = {
  prospectLands: undefined,
  prospectByLandId: {},
}

export const getProspectLands = createAsyncThunk(
  'prospect/getProspectLands',
  prospectService.getProspectLands,
)

export const addProspect = createAsyncThunk(
  'prospect/addProspect',
  prospectService.addProspect,
)

export const removeProspect = createAsyncThunk(
  'prospect/removeProspect',
  prospectService.removeProspect,
)

export const getProspectByLandIds = createAsyncThunk(
  'prospect/getProspectByLandIds',
  prospectService.getProspectByLandIds,
)

const prospect = createSlice({
  name: 'prospect',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProspectLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.prospectLands = data

        if (data) {
          state.prospectByLandId.push(
            ...data.data.map((land) => parseInt(land.land_id, 10)),
          )
        }
      })
      .addCase(getProspectByLandIds.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.prospectByLandId = mapBy(
            'land_id',
            data.map((l) => ({
              land_id: l.land_id,
              name: `${l.prospect_user_first_name} ${l.prospect_user_last_name}`,
              date: l.create_timestamp,
            })),
          )
        }
      })
      .addCase(addProspect.pending, (state, action) => {
        const landId = +action.meta.arg

        if (landId) {
          state.prospectByLandId[landId] = {
            land_id: landId,
            name: `${JSON.parse(localStorage.user).first_name} ${
              JSON.parse(localStorage.user).last_name
            }`,
            date: new Date(),
          }
        }
      })
      .addCase(removeProspect.pending, (state, action) => {
        const landId = +action.meta.arg

        delete state.prospectByLandId[landId]
      })
  },
})

export const prospectReducer = {
  prospect: prospect.reducer,
}
