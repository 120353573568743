import { useSelector } from 'react-redux'
import OkButton from '@mui/icons-material/CheckCircleOutline'
import NotOkButton from '@mui/icons-material/RemoveCircleOutline'

import { useI18n, InfoBox } from '@popety_io/popety-io-lib'
import { landUsePlansSelector } from '../redux'
import CollapseList from './CollapseList'

const ZoneInfo = () => {
  const { t } = useI18n()
  const landUsePlans = useSelector(landUsePlansSelector)
  const lup = landUsePlans?.[0] || {}
  const zones = landUsePlans?.map((l: any) => l.name)

  const data = [
    { label: t('land.zone.number'), value: landUsePlans?.length || 0 },
    {
      label: t('land.underExploited.planType'),
      value: lup.plan_type,
    },
    {
      label: t('land.devScore.constructible'),
      value: (
        <>
          {lup.building_area ? (
            <OkButton fontSize="small" sx={{ color: '#8ed1a5' }} />
          ) : (
            <NotOkButton fontSize="small" sx={{ color: 'red' }} />
          )}
        </>
      ),
    },
  ]

  const data2 = [
    {
      label: t('land.zone.name'),
      value: <CollapseList items={zones} moreKey="common.seeMoreZones" />,
    },
  ]

  return (
    <InfoBox
      title={t('land.lupa')}
      data={data}
      data2={data2}
      id="land-zone-info"
    />
  )
}

export default ZoneInfo
