const unionArray = <T extends Record<string, any>>(
  arr1: Array<T>,
  arr2: Array<T>,
  key: string,
): Array<T> => {
  const newArr = arr1.concat(arr2)

  return newArr.reduce((pre: Array<T>, cur: T) => {
    if (pre.every((i) => i[key] !== cur[key])) {
      pre.push(cur)
    } else {
      return pre.map((i) => {
        if (i[key] === cur[key]) {
          return cur
        }

        return i
      })
    }

    return pre
  }, [])
}

export { unionArray }
