import React, { useEffect } from 'react'
import Box from '@mui/material/Box'

import { useRouter } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateEditedSelector } from '../../redux'
import ImageCarousel from './ImageCarousel'
import EstimatedPrice from './EstimatedPrice'
import EstimationDetails from './EstimationDetails'
import EstimateNotes from './EstimateNotes'
import EstimateFiles from './EstimateFiles'

const EstimateSummary = () => {
  const { query, updateQuery } = useRouter()
  const estimateEdited: any = useSelector(estimateEditedSelector)
  const propertyType = (estimateEdited?.type as string)
    ? estimateEdited.type.charAt(0).toUpperCase() + estimateEdited.type.slice(1)
    : undefined

  useEffect(() => {
    updateQuery({
      livingArea: estimateEdited?.area || query.livingArea,
      constructionYear:
        estimateEdited.constructionYear ||
        estimateEdited?.building_year ||
        query.constructionYear,
      renovationYear: estimateEdited?.renovation_year || query.renovationYear,
      rooms: estimateEdited?.rooms || query.roomsNb,
      propertyType: propertyType || query?.propertyType,
    })
  }, [estimateEdited])

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <ImageCarousel />
      <EstimatedPrice />
      <EstimationDetails />
      <EstimateFiles />
      <EstimateNotes />
    </Box>
  )
}

export default EstimateSummary
