import { ApiClient, getUserId, getCompanyId } from '../../../../services'

const client = new ApiClient()

const getTags = async ({ size, page }: { size: number; page: number }) => {
  return client.post(`/tags/all`, {
    userId: getUserId(),
    companyId: getCompanyId(),
    size,
    page,
  })
}

const addTag = async ({ value, color }: { value: string; color: string }) => {
  return client
    .post(`/tags/tag`, {
      userId: getUserId(),
      companyId: getCompanyId(),
      value,
      color,
    })
    .then((res) => {
      return res.data.result
    })
}

const updateTag = async ({
  value,
  color,
  tag_id,
}: {
  value: string
  color: string
  tag_id: string
}) => {
  return client
    .put(`/tags/tag/${tag_id}`, {
      value,
      color,
    })
    .then(() => {
      return {
        tag_id,
        value,
        color,
      }
    })
}

const deleteTag = async ({ id }: { id: string }) => {
  return client
    .delete(`/tags/tag/${id}`, {
      userId: getUserId(),
      companyId: getCompanyId(),
      id,
    })
    .then(() => {
      return {
        id,
      }
    })
}

const tagService = {
  getTags,
  addTag,
  updateTag,
  deleteTag,
}

export default tagService
