/* eslint-disable @typescript-eslint/no-unused-vars */
import { getBuildingColor, parseGeoJSON, i18n } from '@popety_io/popety-io-lib'
import chroma from 'chroma-js'

const getBuildingLayers = (buildings: any[], dimension = '2D') => {
  const is3D = dimension === '3D'

  const buildingList =
    buildings
      ?.sort((a, b) => a.id - b.id)
      ?.map((b, i) => ({
        ...b,
        title: `${i18n.t('dashboard.table.buildings')} ${i + 1}`,
      })) || []

  const buildingsCenters = buildingList
    .filter(
      (building) =>
        building.geo_center && building.classification !== 'underground',
    )
    .map((building) => {
      return {
        geometry: parseGeoJSON(building.geo_center),
        type: 'Feature',
        properties: { ...building },
      }
    })

  const buildingNamesGeoJSON = !!buildingList?.length && {
    type: 'FeatureCollection',
    features: buildingsCenters || [],
  }

  const buildingsGeoJSON = {
    type: 'FeatureCollection',
    features: buildingList
      .filter(
        (building) =>
          building.geo_polygon && building.classification !== 'underground',
      )
      .map((building) => {
        const floorNb = building.floorNbOveride || building.floor_nb

        return {
          geometry: parseGeoJSON(building.geo_polygon),
          type: 'Feature',
          properties: {
            // TODO: fix this issue
            // eslint-disable-next-line no-nested-ternary
            height: floorNb ? floorNb * 3 : 3,
            color: chroma(
              getBuildingColor(
                building.protected_rank,
                building.classification,
              ),
            ).hex(),
          },
        }
      }),
  }

  const undergroundBuildingsGeoJSON = {
    type: 'FeatureCollection',
    features: buildingList
      .filter(
        (building) =>
          building.geo_polygon && building.classification === 'underground',
      )
      .map((building) => {
        return {
          geometry: parseGeoJSON(building.geo_polygon),
          type: 'Feature',
          properties: {
            color: '#00ade6',
          },
        }
      }),
  }

  return [
    {
      id: 'underground-buildings',
      type: 'geojson',
      data: undergroundBuildingsGeoJSON,
      layers: [
        {
          id: 'underground-buildings-layer',
          type: 'fill',
          paint: {
            'fill-pattern': 'pattern',
          },
          image: true,
        },
      ],
    },
    {
      id: 'buildings',
      type: 'geojson',
      data: buildingsGeoJSON,
      layers: [
        is3D
          ? {
              id: 'buildings-layer',
              type: 'fill-extrusion',
              paint: {
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': ['get', 'height'],
              },
            }
          : {
              id: 'buildings-layer',
              type: 'fill',
              paint: {
                'fill-color': ['get', 'color'],
              },
            },
      ],
    },
    {
      id: 'building-names',
      type: 'geojson',
      data: buildingNamesGeoJSON,
      layers: [
        {
          id: 'building-names-layer',
          type: 'symbol',
          layout: {
            'text-field': ['get', 'title'],
            'text-size': 11,
            'text-font': ['Roboto Regular'],
            'text-anchor': 'center',
          },
        },
      ],
    },
  ]
}

export { getBuildingLayers }
