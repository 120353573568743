/* eslint-disable max-lines */
// import { featuresToMultiPolygon } from '../../../Alerts/redux'

const getLandLayers = (land: Record<string, any>) => {
  if (!land.geo_polygon) return []

  const landGeoJSON = {
    type: 'FeatureCollection',
    features: [
      {
        geometry: land.geo_polygon.geometry || land.geo_polygon,
        type: 'Feature',
        properties: {
          name: `${land.city_name} N° ${land.code_number}`,
        },
      },
    ],
  }

  return [
    {
      id: 'land',
      type: 'geojson',
      data: landGeoJSON,
      layers: [
        {
          id: 'landLayer',
          type: 'fill',
          paint: { 'fill-color': 'rgba(0, 173, 230, 0.4)' },
        },
      ],
    },
  ]
}

const getMultipleLandLayersOultined = (
  lands: Record<string, any>,
  properties = {},
) => {
  if (!lands?.length) return []

  const landGeoJSON = {
    type: 'FeatureCollection',
    features: lands.map((land: any) => ({
      geometry: land?.geo_polygon,
      type: 'Feature',
      properties,
    })),
  }

  return [
    {
      id: 'lands',
      type: 'geojson',
      data: landGeoJSON,
      layers: [
        {
          id: 'landLayer',
          type: 'line',
          paint: { 'line-color': 'rgb(0, 173, 230)', 'line-width': 3 },
        },
      ],
    },
  ]
}

const getLandLayersOultined = (land: Record<string, any>) => {
  if (!land.geo_polygon) return []

  const landGeoJSON = {
    type: 'FeatureCollection',
    features: [
      {
        geometry: land.geo_polygon.geometry || land.geo_polygon,
        type: 'Feature',
        properties: {
          name: `${land.city_name} N° ${land.code_number}`,
        },
      },
    ],
  }

  return [
    {
      id: 'land',
      type: 'geojson',
      data: landGeoJSON,
      layers: [
        {
          id: 'landLayer',
          type: 'line',
          paint: { 'line-color': 'rgb(0, 173, 230)', 'line-width': 3 },
        },
      ],
    },
  ]
}

const getLandsLayersOultined = (lands: Record<string, any>[]) => {
  if (!lands.length) return []

  const landsLayers: any[] = []

  lands.forEach((land: any) => {
    const landGeoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          geometry: land.geo_polygon.geometry || land.geo_polygon,
          type: 'Feature',
          properties: {
            name: `${land.city_name} N° ${land.code_number}`,
          },
        },
      ],
    }

    landsLayers.push({
      id: `land-${land.id}`,
      type: 'geojson',
      data: landGeoJSON,
      layers: [
        {
          id: `landLayer-${land.id}`,
          type: 'line',
          paint: {
            'line-color': 'rgb(0, 173, 230)',
            'line-width': 3,
            'line-dasharray': [2, 2],
          },
        },
      ],
    })
  })

  return landsLayers
}

const getLandLayersOutlinedDasharray = (land: Record<string, any>) => {
  if (!land.geo_polygon) return []

  const landGeoJSON = {
    type: 'FeatureCollection',
    features: [
      {
        geometry: land.geo_polygon.geometry || land.geo_polygon,
        type: 'Feature',
        properties: {
          name: `${land.city_name} N° ${land.code_number}`,
        },
      },
    ],
  }

  return [
    {
      id: 'land',
      type: 'geojson',
      data: landGeoJSON,
      layers: [
        {
          id: 'landLayer',
          type: 'line',
          paint: {
            'line-color': '#00ade6',
            'line-width': 3,
            'line-dasharray': [3, 1],
          },
        },
      ],
    },
  ]
}

export {
  getLandLayers,
  getLandLayersOultined,
  getLandsLayersOultined,
  getLandLayersOutlinedDasharray,
  getMultipleLandLayersOultined,
}
