const thematicStateSelector = (state) => state.thematic

const landThematicSelector = (state) => state.thematic.landThematic

const buildingLandThematicSelector = (state) =>
  state.thematic.buildingLandThematic

const thematicSelector = (state) => state.thematic.thematic

const buildingThematicSelector = (state) => state.thematic.buildingThematic
const thematicOpacitySelector = (state) => state.thematic.thematicOpacity

export {
  thematicStateSelector,
  landThematicSelector,
  buildingLandThematicSelector,
  buildingThematicSelector,
  thematicSelector,
  thematicOpacitySelector,
}
