import { useSelector } from 'react-redux'

import { useI18n, InfoBox } from '@popety_io/popety-io-lib'
import {
  landFaoCountSelector,
  landListingsCountSelector,
  landTransactionCountSelector,
} from '../redux'

const HistoryInfo = () => {
  const { t } = useI18n()

  const faoCount = useSelector(landFaoCountSelector)
  const transactionCount = useSelector(landTransactionCountSelector)
  const listingCount = useSelector(landListingsCountSelector)

  const rentListingCount = listingCount?.rent || 0
  const buyListingCount = listingCount?.buy || 0

  const data = [
    { label: t('common.search.details.fao'), value: faoCount },
    {
      label: t('common.search.details.transaction'),
      value: transactionCount,
    },
  ]

  const data2 = [
    { label: t('land.history.rentFound'), value: rentListingCount },
    {
      label: t('land.history.buyFound'),
      value: buyListingCount,
    },
  ]

  return (
    <InfoBox
      title={t('land.history.infoTitle')}
      data={data}
      data2={data2}
      id="land-hitory-info"
    />
  )
}

export default HistoryInfo
