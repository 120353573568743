import { Box, Typography, styled } from '@mui/material'
import { FormField } from '@popety_io/popety-io-lib'

export const ValueRoot = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'edited',
})<{ edited?: boolean }>`
  color: ${({ edited }) => (edited ? '#8ed1a5' : '#041725')};
`

export const ValueFieldRoot = styled(FormField)`
  .MuiInputBase-root.MuiOutlinedInput-root {
    height: unset;
    padding-right: 0;
  }
  .MuiInputBase-root .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 2px 3px 1.5px 5px;
    /* font-size: 0.923rem; */
  }
`

export const ValueFieldBoxRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'retrieve',
})<{ retrieve?: boolean }>`
  display: flex;
  align-items: center;

  svg {
    transform: ${({ retrieve }) => (retrieve ? 'rotateY(180deg)' : 'unset')};
  }
`
