import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom'
import { authRolesSelector } from '../../pages/Login'

import {
  getCities,
  getRegions,
  getZones,
  citiesSelector,
  regionsSelector,
  zonesSelector,
} from './redux'

const SKIP_PATHNAME_LIST = [
  '/2fa',
  '/company-dashboard',
  '/account',
  '/dashboard',
  '/e-learning',
]
const useLocations = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const { pathname } = useLocation()

  const isSkipFetchLocation = SKIP_PATHNAME_LIST.includes(pathname)
  const roles = useSelector(authRolesSelector)
  const isLoggedIn = roles?.length

  const zones = useSelector(zonesSelector)
  const regions = useSelector(regionsSelector)
  const cities = useSelector(citiesSelector)

  const isEnableRequest = isLoggedIn && !isSkipFetchLocation

  useEffect(() => {
    const timerRegion = setTimeout(() => {
      if (!regions?.length && isEnableRequest) {
        dispatch(getRegions())
      }
    }, 100)

    return () => {
      clearTimeout(timerRegion)
    }
  }, [regions?.length, isEnableRequest])

  useEffect(() => {
    const timerZone = setTimeout(() => {
      if (!zones?.length && isEnableRequest) dispatch(getZones())
    }, 100)

    return () => {
      clearTimeout(timerZone)
    }
  }, [zones?.length, isEnableRequest])

  useEffect(() => {
    const timerCity = setTimeout(() => {
      if (!cities?.length && isEnableRequest) dispatch(getCities())
    }, 100)

    return () => {
      clearTimeout(timerCity)
    }
  }, [cities?.length, isEnableRequest])

  return { zones, regions, cities }
}

export default useLocations
