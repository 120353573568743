/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'

import { NoteRichTextInput } from '../../../../../components/NoteRichTextInput'
import { addEstimateNote } from '../../../redux/estimateNotes'
import EstimateNoteList from './EstimateNoteList'

export type EstimateNoteProps = {
  estimateId: string | string[]
}

const EstimateNote = ({ estimateId }: EstimateNoteProps) => {
  const ref = useRef<HTMLDivElement>()
  const dispatch = useDispatch<any>()

  const scrollToBottom = (opts?: any) => {
    const noteInput = ref.current?.querySelector<HTMLInputElement>('#noteInput')

    ref.current?.scrollTo({ top: noteInput?.offsetTop, ...opts })
  }

  const handleNoteAdd = (content: string) => {
    dispatch(addEstimateNote({ estimateId, content }))
    setTimeout(() => scrollToBottom({ behavior: 'smooth' }), 300)
  }

  useEffect(() => {
    scrollToBottom()
  }, [estimateId])

  return (
    <Box width="100%" maxHeight="800px" ref={ref}>
      <EstimateNoteList estimateId={estimateId} defaultExtend />
      <Box position="sticky" sx={{ bottom: '-17px' }} bgcolor="white">
        <NoteRichTextInput onConfirm={handleNoteAdd} />
      </Box>
    </Box>
  )
}

export default EstimateNote
