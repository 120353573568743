import React from 'react'

interface ImageFileIconProps {
  width?: string
  height?: string
  color?: string
}

const ImageFileIcon: React.FC<ImageFileIconProps> = ({
  width = '8.99824253px',
  height = '12px',
  color = '#00ADE6',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8.99824253 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="popetyio_apps_v3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="files"
          transform="translate(-25, -167)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="remindercard" transform="translate(12, 160)">
            <g id="Group" transform="translate(13, 7)">
              <path
                d="M8.66783831,2.29642648 L6.70181605,0.330404218 C6.49091974,0.119507909 6.20503808,-1.33330826e-16 5.90743995,-1.33330826e-16 L1.12478032,-1.33330826e-16 C0.50380785,0.00234329233 0,0.506151142 0,1.12712361 L0,10.8752197 C0,11.4961921 0.50380785,12 1.12478032,12 L7.87346221,12 C8.49443468,12 8.99824253,11.4961921 8.99824253,10.8752197 L8.99824253,3.09314587 C8.99824253,2.79554774 8.87873462,2.50732279 8.66783831,2.29642648 Z M7.78207381,3.00175747 L5.99882835,3.00175747 L5.99882835,1.21851201 L7.78207381,3.00175747 Z M1.12478032,10.8752197 L1.12478032,1.12712361 L4.87404804,1.12712361 L4.87404804,3.56414763 C4.87404804,3.87580551 5.12478032,4.12653779 5.4364382,4.12653779 L7.87346221,4.12653779 L7.87346221,10.8752197 L1.12478032,10.8752197 Z M1.87463386,9.75043937 L7.12360867,9.75043937 L7.12360867,6.75102519 L6.57293497,6.20035149 C6.46280023,6.09021675 6.28471002,6.09021675 6.17457528,6.20035149 L4.12419449,8.25073228 L3.19859402,7.32513181 C3.08845929,7.21499707 2.91036907,7.21499707 2.80023433,7.32513181 L1.87463386,8.25073228 L1.87463386,9.75043937 Z M2.99941418,4.12653779 C2.37844171,4.12653779 1.87463386,4.63034564 1.87463386,5.2513181 C1.87463386,5.87229057 2.37844171,6.37609842 2.99941418,6.37609842 C3.62038664,6.37609842 4.12419449,5.87229057 4.12419449,5.2513181 C4.12419449,4.63034564 3.62038664,4.12653779 2.99941418,4.12653779 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ImageFileIcon
