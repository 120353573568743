import { Box, Skeleton } from '@mui/material'

const EventHistorySkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {Array.from({ length: 6 }).map((_, i) => (
        <Box
          key={i}
          sx={{
            width: '100%',
            mt: 1.5,
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: '16%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton
              variant="rounded"
              sx={{
                fontSize: '1rem',
                borderRadius: 2,
                height: '15px',
                width: '80px',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <Skeleton
                variant="rounded"
                sx={{
                  fontSize: '0.8rem',
                  borderRadius: 2,
                  height: '15px',
                  width: '4px',
                }}
              />
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton
                variant="rounded"
                sx={{
                  fontSize: '0.8rem',
                  borderRadius: 2,
                  height: '15px',
                  width: '4px',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '84.5%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 2,
            }}
          >
            <Skeleton
              variant="rounded"
              sx={{
                fontSize: '1rem',
                borderRadius: 2,
                height: '50px',
                width: '100%',
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export { EventHistorySkeleton }
