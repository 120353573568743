/* eslint-disable prettier/prettier */
import { groupBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'

import { getLivabilityIsochroneLayers } from './livability.layers'

const poisSelector = (state) => state.livability.pois

const poisAreLoadingSelector = (state) => state.livability.poisAreLoading

const poiIsochroneSelector = (state) => state.livability.isochrone || {}

const poiWalkingIsochroneSelector = (state) =>
  state.livability.walkingIsochrone || {}

const poiBikingIsochroneSelector = (state) =>
  state.livability.bikingIsochrone || {}

const poiDrivingIsochroneSelector = (state) =>
  state.livability.drivingIsochrone || {}

const poiCategoriesSelector = (state) => state.livability.poiCategories || []

const displayPoiSelector = (state) => state.livability.display

const livabilityLoadingSelector = (state) => state.livability.loading

const poiCategorySelector = (state) => state.livability.category

const poiTransportTypeSelector = (state) => state.livability.transportType

const filteredPoisSelector = createSelector(
  poisSelector,
  poiCategorySelector,
  (pois, category) =>
    pois.filter((poi) => category === 'All' || category === poi.category),
)

const poisNumberSelector = createSelector(
  filteredPoisSelector,
  (pois) => pois?.length,
)

const poisIsochroneLayersSelector = createSelector(
  poiIsochroneSelector,
  getLivabilityIsochroneLayers,
)

const walkingIsochroneLayersSelector = createSelector(
  poiWalkingIsochroneSelector,
  getLivabilityIsochroneLayers,
)

const bikingIsochroneLayersSelector = createSelector(
  poiBikingIsochroneSelector,
  getLivabilityIsochroneLayers,
)

const drivingIsochroneLayersSelector = createSelector(
  poiDrivingIsochroneSelector,
  getLivabilityIsochroneLayers,
)

const driveIsochroneLayersSelector = createSelector(
  poiIsochroneSelector,
  (poiIsochrone) =>
    poiIsochrone.type
      ? {
        type: poiIsochrone.type,
        features: poiIsochrone?.features?.filter(
          (layer) => layer.category === 'drive',
        ),
      }
      : null,
)

const groupedPoisSelector = createSelector(poisSelector, (pois) => {
  return Object.values(groupBy('category', pois))
})

export {
  poisSelector,
  poiIsochroneSelector,
  poiCategoriesSelector,
  displayPoiSelector,
  poiCategorySelector,
  poiTransportTypeSelector,
  filteredPoisSelector,
  poisIsochroneLayersSelector,
  poisNumberSelector,
  groupedPoisSelector,
  livabilityLoadingSelector,
  poiWalkingIsochroneSelector,
  poiBikingIsochroneSelector,
  poiDrivingIsochroneSelector,
  walkingIsochroneLayersSelector,
  bikingIsochroneLayersSelector,
  drivingIsochroneLayersSelector,
  driveIsochroneLayersSelector,
  poisAreLoadingSelector,
}
