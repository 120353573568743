import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const ChartContainer = styled(Box)`
  width: 100%;
  flex-grow: 1;
  margin-top: 16px;
`

export const CustomTooltip = styled(Box)`
  background-color: #fff;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid rgb(154, 174, 187);

  > .price {
    font-size: 0.9rem;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #00ade6;
  }
`
