import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const AddressesRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;

  i {
    font-size: 0.8rem;
    color: #8d9ba3;
    font-weight: 500;
  }
`

export const StyledSpan = styled(Box)`
  margin-bottom: -5px;
  font-weight: 500;
  font-size: 0.8rem;
`

export const TitleSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`
