const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.landSearch.startTitle'),
          description: t('driver.landSearch.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#land-search-input',
        popover: {
          title: t('driver.landSearch.landSearchInputTitle'),
          description: t('driver.landSearch.landSearchInputDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#searchHistory',
        popover: {
          title: t('driver.landSearch.searchHistoryTitle'),
          description: t('driver.landSearch.searchHistoryDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
