import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getPotentialSolar = (landId: string[]) => {
  return client.get(`/land/sunlight/${landId}`)
}

const getBuildingPotentialSolar = (buildingId: string[]) => {
  return client.get(`/building/sunlight/${buildingId}`)
}

const getMonthlyExposureAverage = (buildingId: string) => {
  return client.get(`/building/average/${buildingId}`)
}

const sunlightService = {
  getPotentialSolar,
  getBuildingPotentialSolar,
  getMonthlyExposureAverage,
}

export default sunlightService
