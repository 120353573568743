import { formatPrice } from '@popety_io/popety-io-lib'
import * as turf from '@turf/turf'

const source = 'estimate-history'

export const addClusters = (map: any, estimateHistory: any) => {
  if (map.getSource(source)) {
    map.removeLayer('clusters')
    map.removeLayer('cluster-count')
    map.removeLayer('unclustered-point')
    map.removeSource(source)
  }

  const items = estimateHistory?.map((element: any) => {
    const geo_center =
      element?.default_value?.geo_center || element?.value?.geoCenter
    const value =
      element?.value_edited || element?.value || element?.default_value

    const price =
      value?.price ||
      ((element?.price?.valueRange?.lower || 0) +
        (element?.price?.valueRange?.upper || 0)) /
        2

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [geo_center?.lon, geo_center?.lat],
      },
      properties: {
        id: element?.id,
        geo_center,
        title: value?.title || 'Estimation',
        price: formatPrice(price),
      },
    }
  })

  const collection = turf.featureCollection(items) as any

  map.addSource(source, {
    type: 'geojson',
    data: collection,
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
  })
  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source,
    filter: ['has', 'point_count'],
    paint: {
      // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#00abe6',
        100,
        '#f1f075',
        750,
        '#f28cb1',
      ],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      // 'text-color': '#fff',
    },
  })
  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 14,
    },
    paint: {
      'text-color': '#fff', // Change the text color to red
    },
  })
  map.addLayer({
    id: 'unclustered-point',
    type: 'circle',
    source,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#00ade6',
      'circle-radius': 6,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
    },
  })
}
