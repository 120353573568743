import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

export const UnderExploitedRoot = styled(Grid)`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 8px;

  .label {
    color: #9095a2;
  }

  .valueEstimated {
    color: #00ade6;
  }

  .sublabel {
    color: #9095a2;
    padding: 4px 0px 0px 16px;

    > li {
      display: flex;
      align-items: center;
    }
  }

  .subvalue {
    color: #00ade6;
    padding: 4px 0px 0px 8px;
  }

  .searchIcon {
    font-size: 15px;
    margin-left: 8px;
  }
`
