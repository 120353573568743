import { styled, css } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

export const ValueGridRoot = styled(Grid)<{
  color?: string
  size?: string
}>`
  padding: 4px;
  color: ${({ color }) => color};

  .MuiIconButton-root {
    padding: 0 0 0 4px;

    svg {
      font-size: ${({ size = '16px' }) => size};
      padding-bottom: 4px;
      color: ${({ color }) => color};
    }
  }
`

export const TextFieldRoot = styled('div')<{ isNative: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isNative }) =>
    isNative &&
    css`
      width: 130%;
    `};
`

export const TextFieldComponent = styled(TextField)`
  &,
  input {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .MuiOutlinedInput-root {
    border-radius: 5px;
  }
`
