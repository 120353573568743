import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import { TableBody, TableCell } from '@mui/material'
import { TreeMap } from '@popety_io/popety-io-lib'

export const GridRoot = styled(Grid)`
  span {
    display: flex;
    white-space: nowrap;
  }

  .text {
    display: flex;
    white-space: nowrap;
  }

  .greyColor {
    color: rgb(144, 149, 162);
  }

  .blueColor {
    color: #00ade6;
  }

  .greenColor {
    color: rgb(142, 209, 165);
  }
`
export const DividerRoot = styled(Divider)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const TableRoot = styled(Table, {
  shouldForwardProp: (prop) => prop !== '$open',
})<{ $open?: boolean }>`
  .MuiTableCell-root {
    /* border-bottom: ${({ $open }) => $open && 'none'}; */
  }
`

export const TableCellRoot = styled(TableCell)`
  span {
    font-size: 0.923rem;
    opacity: 0.8;
    color: rgba(150, 150, 150, 1);
    font-weight: 400;
    font-style: normal;
  }
`

export const TreeMapRoot = styled(TreeMap)`
  text {
    font-size: 0.715rem;
    font-weight: 300;
    color: #ffffff;
  }
`
export const TableBodyExpand = styled(TableBody)`
  height: 0;
  overflow-y: hidden;
  transition: all 300ms;
`
