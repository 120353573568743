/* eslint-disable no-nested-ternary */
import { Score, useI18n, Bullet } from '@popety_io/popety-io-lib'
import { memo, useEffect } from 'react'
import { Box, SxProps } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import {
  getLandUsedIndice,
  getScoreColore,
  getScoreConfidenceData,
} from '../../../utils'
import {
  bigestLandUsePlanSelector,
  landSelector,
  recalculateScore,
} from '../redux'
import ScoreInfoLegend from './ScoreInfoLegend'
import ScoreLegend from './ScoreLegend'

export type LandScoreProps = {
  score?: number
  scoreOverride?: number
  subValue?: number
  showLegend?: boolean
  showBullet?: boolean
  showScoreLegend?: boolean
  title?: string
  sx?: SxProps
}

const LandScore = ({
  score,
  scoreOverride,
  subValue = 100,
  showLegend = false,
  showBullet = false,
  showScoreLegend = false,
  title,
  sx,
}: LandScoreProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const value = scoreOverride ?? score
  const color = getScoreColore(value, subValue)
  const land: any = useSelector(landSelector)
  const bigestLandUsePlan = useSelector(bigestLandUsePlanSelector)

  const landIndice = getLandUsedIndice(land, bigestLandUsePlan)
  const confidenceData = getScoreConfidenceData(landIndice)

  useEffect(() => {
    if (bigestLandUsePlan?.cover_area_perc >= 0.5) {
      dispatch(
        recalculateScore({ lup: bigestLandUsePlan, land, landIndice } as any),
      )
    }
  }, [
    bigestLandUsePlan,
    land?.sbOverride,
    land?.sbdOverride,
    land?.sbpuOverride,
    land?.spdOverride,
    land?.spOverride,
    land.existing_score,
  ])

  const bulletValue = confidenceData.value
  const scoreBullet = confidenceData.data

  const isPq = landIndice === 'from_pq'

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 2,
        }}
      >
        <Box display="flex">
          <Score value={value} color={color} />
          {showScoreLegend && <ScoreLegend title={title} />}
        </Box>

        {showBullet && scoreBullet && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '2px',
            }}
          >
            <Bullet
              title={t('common.score.bullet.text')}
              data={scoreBullet}
              value={bulletValue}
            />
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.9rem',
                color: 'rgb(150, 150, 150)',
              }}
            >
              {!isPq
                ? t(
                    `common.underExploited.score.Indice${
                      landIndice?.endsWith('estimate')
                        ? '.estimate'
                        : landIndice?.endsWith('overide')
                          ? '.overide'
                          : ''
                    }`,
                    { indice: landIndice?.split('_')[0]?.toUpperCase() },
                  )
                : t('common.underExploited.score.Indice.from_pq')}
            </span>
          </div>
        )}
      </Box>
      {showLegend && scoreBullet && (
        <ScoreInfoLegend
          data={scoreBullet}
          title={t('common.score.bullet.text')}
        />
      )}
    </Box>
  )
}

export default memo(LandScore)
