import { ChangeEvent, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/WarningOutlined'
import ReplayIcon from '@mui/icons-material/Replay'
import { useMediaQuery, useI18n } from '@popety_io/popety-io-lib'

import {
  ValueGridRoot,
  TextFieldRoot,
  TextFieldComponent,
} from './ValueGrid.style'

type ValueGridProps = Record<string, any>

const ValueGrid = (props: ValueGridProps) => {
  const {
    text,
    comment,
    color,
    iconSize,
    value,
    onChange,
    open,
    keyType = '',
    selectedType,
    currentValue,
    isOveride,
    ...other
  } = props
  const { t } = useI18n()
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [overideValue, setOverideValue] = useState(
    Number(isOveride) || Number(value) || '',
  )

  const handleReset = () => {
    setOverideValue(Number(value) === 0 ? '' : Number(value))
    onChange?.({ [keyType]: Number(value) === 0 ? '' : Number(value) })
  }

  const handleSave = (e: ChangeEvent<HTMLInputElement>) => {
    setOverideValue(e.target.value)
    onChange?.({
      [keyType]: {
        value: Number(e.target.value),
        current: Number(currentValue),
      },
    })
  }

  return (
    <ValueGridRoot item {...other} color={color || undefined} size={iconSize}>
      {!open ? (
        text
      ) : (
        <TextFieldRoot isNative={!onDesktop}>
          <TextFieldComponent
            id="outlined-basic"
            fullWidth
            variant={!onDesktop ? 'outlined' : 'standard'}
            size="small"
            type="number"
            value={overideValue}
            placeholder={overideValue as string}
            onChange={handleSave}
            disabled={!selectedType}
            InputProps={{ inputProps: { min: 0 } }}
          />
          <Tooltip placement="top" title={t('land.underExploited.reset')}>
            <IconButton disabled={!selectedType || !overideValue}>
              <ReplayIcon
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                onClick={handleReset}
              />
            </IconButton>
          </Tooltip>
        </TextFieldRoot>
      )}
      {comment && (
        <Tooltip title={comment}>
          <IconButton aria-label="Description">
            <WarningIcon />
          </IconButton>
        </Tooltip>
      )}
    </ValueGridRoot>
  )
}

export default ValueGrid
