const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.landSunlight.startTitle'),
          description: t('driver.landSunlight.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#plot-sunlight',
        popover: {
          title: t('driver.landSunlight.plotSunlightTitle'),
          description: t('driver.landSunlight.plotSunlightDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#daily-shadow',
        popover: {
          title: t('driver.landSunlight.dailyShadowTitle'),
          description: t('driver.landSunlight.dailyShadowDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#daily-sunlight',
        popover: {
          title: t('driver.landSunlight.dailySunlightTitle'),
          description: t('driver.landSunlight.dailySunlightDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#annual-sunlight',
        popover: {
          title: t('driver.landSunlight.annualSunlightTitle'),
          description: t('driver.landSunlight.annualSunlightDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
