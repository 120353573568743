import Tooltip from '@mui/material/Tooltip'

import useFavoriteBuilding, {
  FavoriteBuildingProps,
} from './useFavoriteBuilding'

const BuildingFavoriteIcon = ({
  buildingId,
  opacity,
}: FavoriteBuildingProps) => {
  const { Icon, title, onClick } = useFavoriteBuilding({ buildingId, opacity })

  return (
    <Tooltip
      title={title}
      placement="top"
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {Icon}
    </Tooltip>
  )
}

export default BuildingFavoriteIcon
