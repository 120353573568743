/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { updateLocalUser } from '../../Login'
import accountService from './accountService'

export const defaultFinancialConfig = {
  wall: 22,
  demolitionPrice: 140,
  preparationPrice: 80,
  correlativeHeight: 4,
  landscapingPrice: 80,
  buildingPrice: 680,
  ownFunds: 15,
  interestRate: 1,
  constructionTime: 18,
  sellingPrice: 9000,
  salesCommissions: 3.5,
  notaryFee: 0.017,
  transfertRight: 0.033,
  authorisationAndTaxe: 0.04,
  managementFee: 0.03,
  othersAdditional: 0.02,
}

const initialState: any = {
  user: JSON.parse(localStorage.getItem('user-details') || '{}'),
  roles: null,
  config: defaultFinancialConfig,
  userPreferences: {},
  loadingPreferences: false,
  errorLoadingPreferences: false,
  errorUpdatingPreferences: false,
  zones: null,
  subscription: null,
  isLoading: false,
  securityStatus: null,
  securityStatusLoading: false,
  tutorialsLoading: false,
}

export const getCurrentUserDetails = createAsyncThunk(
  'account/getCurrentUserDetails',
  async (_data, thunkAPI) => {
    return accountService.getCurrentUserDetails().then((res) => {
      if (res.data) {
        thunkAPI.dispatch(updateLocalUser({ ...res.data }))
      }

      return res
    })
  },
)

export const getFinancialDefault = createAsyncThunk(
  'account/getFinancialDefault',
  accountService.getFinancialDefault,
)

export const updateLanguage = createAsyncThunk(
  'account/updateLanguage',
  accountService.updateLanguage,
)

export const updateFinancialDefault = createAsyncThunk(
  'account/updateFinancialDefault',
  accountService.updateFinancialDefault,
)

export const terminatePlan = createAsyncThunk(
  'account/terminatePlan',
  accountService.terminatePlan,
)

export const updatePassword = createAsyncThunk(
  'account/updatePassword',
  accountService.updatePassword,
)

export const updateUser = createAsyncThunk(
  'account/updateUser',
  accountService.updateUser,
)

export const updateUserPhoto = createAsyncThunk(
  'account/updateUserPhoto',
  accountService.updateUserPhoto,
)

export const updateCompany = createAsyncThunk(
  'account/updateCompany',
  accountService.updateCompany,
)

export const updateUserOffice = createAsyncThunk(
  'account/updateUserOffice',
  accountService.updateUserOffice,
)

export const getUserPreferences = createAsyncThunk(
  'account/getUserPreferences',
  accountService.getUserPreferences,
)

export const updateTagsPermissions = createAsyncThunk(
  'account/updateTagsPermissions',
  accountService.updateTagsPermissions,
)

export const getSecurityStatus = createAsyncThunk(
  'account/getSecurityStatus',
  accountService.getSecurityStatus,
)

export const updateSecurityStatus = createAsyncThunk(
  'account/updateSecurityStatus',
  accountService.updateSecurityStatus,
)

export const verifySecurityStatus = createAsyncThunk(
  'account/verifySecurityStatus',
  accountService.verifySecurityStatus,
)

export const updateTutorials = createAsyncThunk(
  'account/updateTutorials',
  accountService.updateTutorials,
)

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    forgetUser: (state) => {
      state.user = null
      state.zones = null
      state.roles = null
      state.config = defaultFinancialConfig
      state.subscription = null

      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
      localStorage.removeItem('user-details')
    },
    updateLocalUserDetails: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      localStorage.setItem('user-details', JSON.stringify(state.user))
    },
    updateUserOfficeColor: (state, action) => {
      state.userPreferences = {
        ...state.userPreferences,
        office: {
          ...state.userPreferences?.office,
          colors: action.payload,
        },
      }
    },
    clearTwoFa: (state) => {
      state.securityStatus = {
        enable_sms: state.securityStatus?.enable_sms,
        enable_authentification: state.securityStatus?.enable_authentification,
        user_id: state.securityStatus?.user_id,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCurrentUserDetails.fulfilled, (state, action) => {
        const { data } = action.payload

        state.isLoading = false
        if (data) state.user = data
        localStorage.setItem('user-details', JSON.stringify(state.user))
      })
      .addCase(getCurrentUserDetails.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getFinancialDefault.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data && Object.keys(data).length > 0) {
          if (data?.date) data.date = new Date().toISOString()
          state.config = data
        }
      })
      .addCase(updateFinancialDefault.fulfilled, (state, action) => {
        const { data } = action.payload
        const input = action.meta.arg

        if (data) state.config = { ...state.config, ...input, ...data }
      })
      .addCase(terminatePlan.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data) {
          state.user = { ...state.user, subscription_end_date: data }
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const { data } = action.payload
        const res = { ...state.user, ...data }

        state.user = res
        localStorage.setItem('user-details', JSON.stringify(res))
      })
      .addCase(updateUserPhoto.fulfilled, (state, action) => {
        const { data } = action.payload
        const res = { ...state.user, ...data }

        state.user = res
        localStorage.setItem('user-details', JSON.stringify(res))
      })
      .addCase(updateUserOffice.fulfilled, (state, action) => {
        const { data } = action.payload

        const res = { ...state.user, ...data }

        state.user = res
        localStorage.setItem('user-details', JSON.stringify(res))
      })
      .addCase(getUserPreferences.pending, (state) => {
        state.loadingPreferences = true
      })
      .addCase(getUserPreferences.fulfilled, (state, action) => {
        const { data } = action.payload

        state.userPreferences = data
        state.loadingPreferences = false
      })
      .addCase(getUserPreferences.rejected, (state) => {
        state.loadingPreferences = false
        state.errorLoadingPreferences = true
      })
      .addCase(updateTagsPermissions.pending, (state) => {
        state.loadingPreferences = true
        state.errorUpdatingPreferences = false
      })
      .addCase(updateTagsPermissions.fulfilled, (state, action) => {
        const { data } = action.payload

        state.userPreferences = {
          ...state.userPreferences,
          companyTagsCanBeEdited: data,
        }

        state.loadingPreferences = false
      })
      .addCase(updateTagsPermissions.rejected, (state) => {
        state.loadingPreferences = false
        state.errorUpdatingPreferences = true
      })
      .addCase(getSecurityStatus.pending, (state) => {
        state.securityStatusLoading = true
      })
      .addCase(getSecurityStatus.fulfilled, (state, action) => {
        const { data } = action.payload

        state.securityStatus = data
        state.securityStatusLoading = false
      })
      .addCase(getSecurityStatus.rejected, (state) => {
        state.securityStatusLoading = false
      })
      .addCase(updateSecurityStatus.pending, (state) => {
        state.securityStatusLoading = true
      })
      .addCase(updateSecurityStatus.fulfilled, (state, action) => {
        const { data } = action.payload

        state.securityStatus = { ...state.securityStatus, ...data }
        state.securityStatusLoading = false
      })
      .addCase(updateSecurityStatus.rejected, (state) => {
        state.securityStatusLoading = false
      })
      .addCase(verifySecurityStatus.pending, (state) => {
        state.securityStatusLoading = true
      })
      .addCase(verifySecurityStatus.fulfilled, (state, action) => {
        const { data } = action.payload

        state.securityStatus = { ...state.securityStatus, ...data }
        state.securityStatusLoading = false
      })
      .addCase(verifySecurityStatus.rejected, (state) => {
        state.securityStatusLoading = false
      })
      .addCase(updateTutorials.pending, (state) => {
        state.tutorialsLoading = true
      })
      .addCase(updateTutorials.fulfilled, (state, action) => {
        const input = action.meta.arg || {}

        Object.entries(input).forEach(([key, value]) => {
          localStorage.setItem(key, value)
        })
        state.tutorialsLoading = false
      })
      .addCase(updateTutorials.rejected, (state) => {
        state.tutorialsLoading = false
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  forgetUser,
  updateLocalUserDetails,
  updateUserOfficeColor,
  clearTwoFa,
} = account.actions

export const accountReducer = {
  account: account.reducer,
}
