import { createSelector } from '@reduxjs/toolkit'
import { getCurrentLivingArea } from '../summary/landHelper'
import { getBuildingLayers } from './building.layers'

const buildingsByIdSelector = (state) => state.building.buildingsById

const buildingLoadingSelector = (state) => state.building.loading

const buildingsSelector = createSelector(
  buildingsByIdSelector,
  (buildingsById) => Object.values(buildingsById),
)

const buildingsLengthSelector = createSelector(
  buildingsByIdSelector,
  (buildingsById) =>
    Object.values(buildingsById).filter(
      (building) => building?.buildings_administrative?.length > 0,
    ).length,
)

const buildingLayersSelector = createSelector(
  buildingsSelector,
  getBuildingLayers,
)

const protectedBuildingsSelector = createSelector(
  buildingsSelector,
  (buildings) => buildings.filter((building) => building.protected_rank !== 0),
)

const currentLivingAreaSelector = createSelector(
  buildingsSelector,
  getCurrentLivingArea,
)

const isBuildingEditedSelector = createSelector(
  buildingsSelector,
  (buildings) =>
    buildings?.map((building) => building.overridedId).filter(Boolean),
)

export {
  buildingsSelector,
  buildingsByIdSelector,
  buildingLayersSelector,
  protectedBuildingsSelector,
  currentLivingAreaSelector,
  isBuildingEditedSelector,
  buildingLoadingSelector,
  buildingsLengthSelector,
}
