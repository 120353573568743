import { Box, Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import { Warning } from '@mui/icons-material'
import { getIdcColor, getHeatConsumptionDesc, getIdcIconColor } from './utils'

const EnergyHeatConsumptionDesc = ({ current }: { current: number }) => {
  const { t } = useI18n()
  const idcColor = getIdcColor(current)
  const description = getHeatConsumptionDesc(current)
  const iconColor = getIdcIconColor(current)

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        marginBottom: '20px',
      }}
    >
      <Box display="flex" gap={1}>
        <Typography color={idcColor} variant="h3" fontWeight={500}>
          {current}
        </Typography>
        <Typography
          mb="5px"
          color="#969696"
          variant="body2"
          display="flex"
          alignItems="flex-end"
        >
          {t('common.MJ/m²an')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" gap={0.5}>
          <Warning fontSize="small" sx={{ color: iconColor }} />
          <Box display="flex" flexDirection="column">
            {description.map((desc, index) => {
              return (
                <Typography
                  key={index}
                  variant="body2"
                  fontSize="1rem"
                  display="flex"
                  alignItems="flex-end"
                >
                  {t(`common.energy.${desc}`)}
                </Typography>
              )
            })}
          </Box>
        </Box>
        <Typography variant="body2" color="#969696">
          {t('common.heat_consumption_sub_desc')}
        </Typography>
      </Box>
    </Box>
  )
}

export default EnergyHeatConsumptionDesc
