import { parseGeoJSON, i18n } from '@popety_io/popety-io-lib'

const restrictionsList: any = {
  reserved_zone: 'rgba(150, 150, 150, 0.3)',
  polluted_zone: 'rgba(194, 46, 0, 0.3)',
  underwater_protected_zone: 'rgba(0, 147, 156, 0.3)',
  forest_zone: 'rgba(153, 204, 153, 0.3)',
  built_sites_protected_zone: 'rgba(51, 51, 51, 0.3)',
  watercourse_limit_zone: 'rgba(0, 51, 102, 0.3)',
  forest_limit_zone: 'rgba(204, 204, 153, 0.3)',
  not_yet_defined_special_zone: 'rgba(0, 0, 0, 0.3)',
  mandatory_planning_zone: 'rgba(255, 204, 0, 0.3)',
  archaeological_zone: 'rgba(255, 204, 0, 0.3)',
  nature_protection_zone: 'rgba(255, 204, 0, 0.3)',
  no_max_cos_zone: 'rgba(255, 204, 0, 0.3)',
  other_zone: 'rgba(255, 204, 0, 0.3)',
  protected_objects: 'rgba(255, 204, 0, 0.3)',
  danger_zone: 'rgba(255, 204, 0, 0.3)',
  building_right: 'rgba(255, 204, 0, 0.3)',
  multiple: 'rgba(248, 104, 51, 0.3)',
  servitude: 'rgba(230, 57, 0, 0.3)',
}

const getRestrictionLayers = (restrictions: any[], rdppfCategories = '') => {
  const rdppfsArray = rdppfCategories?.split(',')
  const rdppfs = Array.isArray(restrictions)
    ? restrictions?.filter(
        (rdppf) => rdppf.geojson && !rdppfsArray?.includes(rdppf.name),
      )
    : []

  const nameGeoJSON = {
    type: 'FeatureCollection',
    features: rdppfs?.map((rdppf) => ({
      type: 'Feature',
      geometry: parseGeoJSON(rdppf.geo_center),
      properties: {
        title: rdppf?.title,
      },
    })),
  }

  const rdppfsGeoJSON = {
    type: 'FeatureCollection',
    features: rdppfs?.map((r) => ({
      geometry: parseGeoJSON(r.geojson),
      type: 'Feature',
      properties: {
        name: i18n.t(`land.restrictions.zone.${r.name}`),
        color: restrictionsList[r.name],
      },
    })),
  }

  return [
    {
      id: 'restrictions',
      type: 'geojson',
      data: rdppfsGeoJSON,
      layers: [
        {
          id: 'restrictions-layer',
          type: 'fill',
          paint: { 'fill-color': ['get', 'color'] },
          hover: true,
        },
        {
          id: `restrictions-layers-outline`,
          type: 'line',
          paint: {
            'line-width': 1,
          },
        },
      ],
    },
    {
      id: 'building-names',
      type: 'geojson',
      data: nameGeoJSON,
      layers: [
        {
          id: 'building-names-layer',
          type: 'symbol',
          layout: {
            'text-field': ['get', 'title'],
            'text-size': 11,
            'text-font': ['Roboto Regular'],
            'text-anchor': 'center',
          },
        },
      ],
    },
  ]
}

export { getRestrictionLayers }
