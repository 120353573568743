export type BuildAltimetryOpts = {
  data: any[][]
  precision: number
  altitudeMin: number
  altitudeMax: number
}

export const buildAltimetry = ({
  data = [],
  precision = 0,
  altitudeMin = 0,
  altitudeMax = 0,
}: BuildAltimetryOpts) => {
  const layout = {
    scene: {
      hovermode: false,
      aspectmode: 'data',
      camera: {
        up: { x: 0, y: 0, z: 1 },
        center: { x: 0, y: 0, z: -0.2 },
        eye: { x: 0, y: -1.8, z: 0.5 },
      },
      xaxis: {
        showspikes: false,
        tickmode: 'array',
        title: '',
        tickvals: data[0]?.map((el, index) => index),
        ticktext: data[0]?.map((el, index) => `${index * precision}m`),
      },
      yaxis: {
        showspikes: false,
        tickmode: 'array',
        title: '',
        tickvals: data?.map((el, index) => index),
        ticktext: data?.map((el, index) => `${index * precision}m`),
      },
      zaxis: {
        showspikes: false,
        tickmode: 'array',
        title: '',
        // eslint-disable-next-line prefer-spread
        tickvals: Array.apply(null, {
          length: Math.round((altitudeMax - altitudeMin) / precision),
        } as any)
          ?.map(Number.call, Number)
          ?.map((el, index) => index),
        // eslint-disable-next-line prefer-spread
        ticktext: Array.apply(null, {
          length: Math.round((altitudeMax - altitudeMin) / precision),
        } as any)
          ?.map(Number.call, Number)
          ?.map((el, index) => `${index * precision + altitudeMin}m`),
      },
    },
  }

  return {
    layout,
    data: [
      {
        hoverinfo: 'none',
        hovermode: false,
        showlegend: false,
        showscale: false,
        z: data,
        type: 'surface',
        colorscale: [
          [0, 'rgb(0, 173, 230)'],
          [0.5, 'rgb(255,231,104)'],
          [1, 'rgb(255,191,191)'],
        ],
        cmin: 0,
        cmax: Math.min(data?.length, data[0]?.length) * 0.2,
        contours: {
          x: { highlight: false },
          y: { highlight: false },
          z: { highlight: false },
        },
      },
    ],
  }
}
