/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { Suspense, useMemo, useRef, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import {
  ProgressIndicator,
  useRouter,
  useI18n,
  fetchOnce,
  FullScreenIcon,
  Percent30ScreenIcon,
  Percent42ScreenIcon,
} from '@popety_io/popety-io-lib'
import { useSelector, useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { userSelector } from '../Login'
import { regionsByNameSelector } from '../../components/LocationsInput'
import {
  LandDetailsRoot,
  LeftContainer,
  PopetyMapsScreenButtonRoot,
  RightContainer,
} from '../LandDetails/LandDetails.style'
import { buildingsByIdSelector } from '../LandDetails'
import { addAnalyzedBuilding } from '../BuildingDashboard'
import { toggleSizeMap } from '../LandDetails/LandDetails'
import BuildingDetailsMap from './BuildingDetailsMap'
import useGetBuildingDetailsData from './BuildingDetails.data'
import { getBuildingDetailsMenuData } from './components/BuildingDetailsMenu'
import BuildingSearch from './components/BuildingSearch/BuildingSearch'
import {
  getBuildingSearchHistory,
  getBuildingSummary,
  buildingRegionSelector,
} from './redux'

const BuildingDetails = () => {
  const { query, updateQuery } = useRouter()
  const buildingIdStr = useSelector(buildingsByIdSelector)
  const [openSearch, setOpenSearch] = useState(!!query?.openNewAnalysis)
  const { t } = useI18n()

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const dispatch: any = useDispatch()

  const user = useSelector(userSelector)
  const region = useSelector(buildingRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)

  const firstTransactionDate = regionsByName[region]?.first_transaction_date
  const firstFaoDate = regionsByName[region]?.first_fao_date
  const { landId } = query
  const isHomePage = query?.tab === 'home'
  const location = useLocation()

  const screenSizeMap = JSON.parse(
    localStorage.getItem('screenSizeMap') || '{}',
  )
  const screenSizeMapBuildingDetail = screenSizeMap?.buildingDetail
  const [size, setSize] = useState(screenSizeMapBuildingDetail || 'default')

  const canAnalyse = useMemo(() => {
    return (
      user?.can_exceed_max_analysis ||
      Number(user?.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis && user?.listsAnalysis?.includes(landId))
    )
  }, [user])

  const pages = getBuildingDetailsMenuData(
    t,
    isHomePage,
    firstTransactionDate,
    firstFaoDate,
    canAnalyse,
  )

  const buildingChanged = query.buildingId !== buildingIdStr

  useGetBuildingDetailsData()

  useEffect(() => {
    setSize('default')
  }, [query.tab, query.subTab])

  const content = useMemo(() => {
    if (!query || !pages?.length) return

    if (query?.subTab) {
      return pages
        ?.find((p: any, i: number) =>
          !query?.tab
            ? i === 0
            : p?.key === query?.tab ||
              p?.subMenus?.find((s: any) => s?.key === query?.tab),
        )
        ?.subMenus?.find((s: any) => s?.key === query?.subTab)?.page
    }
    const page: any = pages
      .filter((menu: any) => menu?.show || menu?.key === 'history')
      .find((p: any, i: number) =>
        !query.tab
          ? i === 0
          : isHomePage
            ? p?.key === query.tab && location.pathname === p?.path
            : p?.key === query.tab,
      )

    if (page?.subMenus?.length && !query?.subTab) {
      updateQuery({
        subTab: page?.subMenus?.[0]?.key,
      })
    }

    return page?.page
  }, [query.tab, query?.subTab, pages])

  const fullscreen = size === 'large' || !content

  const handleClose = () => {
    setOpenSearch(false)
    updateQuery({ openNewAnalysis: undefined })
  }

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      if (rightContainerRef.current) {
        rightContainerRef.current.style.top = `${rect.top}px`
      }
    }
  }, [buildingIdStr])

  const sizeMap = toggleSizeMap(size)

  const handleToggleScreen = (value: string) => {
    setSize(value)

    const newScreenSizeMap = {
      ...(screenSizeMap || {}),
      buildingDetail: value || 'default',
    }

    localStorage.setItem('screenSizeMap', JSON.stringify(newScreenSizeMap))
  }

  useEffect(() => {
    fetchOnce('getBuildingSearchHistory', null, () => {
      dispatch(getBuildingSearchHistory())
    })
  }, [])

  useEffect(() => {
    if (query.buildingId) {
      dispatch(
        addAnalyzedBuilding({
          buildingId: query.buildingId,
        }),
      )
    }
  }, [query.buildingId])

  useEffect(() => {
    if (query.buildingId && buildingChanged) {
      fetchOnce('getBuildingSummary', { buildingId: query.buildingId }, () => {
        dispatch(getBuildingSummary({ buildingId: query.buildingId }))
      })
    }
  }, [query.buildingId])

  useEffect(() => {
    setOpenSearch(!!query?.openNewAnalysis)
  }, [query?.openNewAnalysis])

  return (
    <LandDetailsRoot bgcolor="background.paper">
      <BuildingSearch
        showCloseIcon={!!buildingIdStr}
        open={openSearch}
        onClose={handleClose}
      />

      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        {content && (
          <LeftContainer
            bgcolor="background.paper"
            width={{
              xs: '100%',
              lg:
                sizeMap && typeof sizeMap === 'number'
                  ? `${100 - sizeMap}%`
                  : '58%',
            }}
            display={fullscreen ? 'none' : undefined}
            data-cy="land-detail-left-container"
          >
            <Suspense fallback={<ProgressIndicator circular />}>
              {content}
            </Suspense>
          </LeftContainer>
        )}

        <RightContainer
          height={{ xs: '100%', lg: '100%' }}
          width={{
            xs: '100%',
            lg: sizeMap && typeof sizeMap === 'number' ? `${sizeMap}%` : '42%',
          }}
          ref={rightContainerRef}
        >
          <Box position="relative" height="100%">
            {content && (
              <PopetyMapsScreenButtonRoot>
                <FullScreenIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                  fillColor={size === 'large' ? '#007eb4' : '#5f6368'}
                  onClick={() => handleToggleScreen('large')}
                />
                <Percent42ScreenIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                  fillColor={
                    !size || size === 'default' ? '#007eb4' : '#5f6368'
                  }
                  onClick={() => handleToggleScreen('default')}
                />
                <Percent30ScreenIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                  fillColor={size === 'small' ? '#007eb4' : '#5f6368'}
                  onClick={() => handleToggleScreen('small')}
                />
              </PopetyMapsScreenButtonRoot>
            )}
            <BuildingDetailsMap fullscreen={fullscreen} />
          </Box>
        </RightContainer>
      </Box>
    </LandDetailsRoot>
  )
}

export default BuildingDetails
