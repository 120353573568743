import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const MinMaxFieldsRoot = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;

  .ResetButton {
    margin-left: -12px;
  }

  > .MuiFormControl-root {
    flex-grow: 1;
  }
`
