import { createAsyncThunk } from '@reduxjs/toolkit'
import listingInsightsService from '../../../ListingHistory/redux/listingInsights/listingInsightsService'
import estimateMarketService from './estimateMarketService'

interface ThunkConfig {
  rejectValue: {
    errorMessage: string
  }
}

type FilterType = Record<string, any>

export const getEstimateListingBuyInsights = createAsyncThunk<
  any,
  FilterType,
  ThunkConfig
>('estimateMarket/getListingBuyInsights', async (filter, thunkAPI) => {
  try {
    return await listingInsightsService.getListingInsights(filter)
  } catch (error) {
    return thunkAPI.rejectWithValue({
      errorMessage: 'Failed to get listing buy insights',
    })
  }
})

export const getListingsAggsPriceAnnoncementsByPrice = createAsyncThunk<
  any,
  FilterType,
  ThunkConfig
>(
  'estimateMarket/getListingsAggsPriceAnnoncements',
  async (filter, thunkAPI) => {
    try {
      return await estimateMarketService.getListingsAggsPriceAnnoncements(
        filter,
      )
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: 'Failed to get listings aggs price announcements',
      })
    }
  },
)

export const getListingsAggsPriceAnnoncementsByPriceSquareMeter =
  createAsyncThunk<any, FilterType, ThunkConfig>(
    'estimateMarket/getListingsAggsPriceAnnoncementsByPriceSquareMeter',
    async (filter, thunkAPI) => {
      try {
        return await estimateMarketService.getListingsAggsPriceAnnoncements(
          filter,
        )
      } catch (error) {
        return thunkAPI.rejectWithValue({
          errorMessage:
            'Failed to get listings aggs price announcements by price square meter',
        })
      }
    },
  )

export const getAfterXYear = createAsyncThunk<any, FilterType, ThunkConfig>(
  'estimateMarket/afterXYear',
  async (filter, thunkAPI) => {
    try {
      return await estimateMarketService.getMarketAggs(filter)
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: 'Failed to get listing duration',
      })
    }
  },
)

export const getListingsAggsPurchasePerSquareMeter = createAsyncThunk<
  any,
  FilterType,
  ThunkConfig
>(
  'estimateMarket/getListingsAggsPurchasePerSquareMeter',
  async (filter, thunkAPI) => {
    try {
      return await estimateMarketService.getMarketAggs(filter)
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: 'Failed to get listings aggs purchase per square meter',
      })
    }
  },
)

export const getListingAggsPurchase = createAsyncThunk<
  any,
  FilterType,
  ThunkConfig
>('estimateMarket/getListingAggsPurchase', async (filter, thunkAPI) => {
  try {
    return await estimateMarketService.getMarketAggs(filter)
  } catch (error) {
    return thunkAPI.rejectWithValue({
      errorMessage: 'Failed to get listing aggs purchase',
    })
  }
})

export const getListingRoomsNb = createAsyncThunk<any, FilterType, ThunkConfig>(
  'estimateMarket/getListingRoomsNb',
  async (filter, thunkAPI) => {
    try {
      return await estimateMarketService.getListingsAggsPriceAnnoncements(
        filter,
      )
    } catch (error) {
      return thunkAPI.rejectWithValue({
        errorMessage: 'Failed to get listing rooms number',
      })
    }
  },
)

export const getListingDuration = createAsyncThunk<
  any,
  FilterType,
  ThunkConfig
>('estimateMarket/getListingDuration', async (filter, thunkAPI) => {
  try {
    return await estimateMarketService.getListingsAggsPriceAnnoncements(filter)
  } catch (error) {
    return thunkAPI.rejectWithValue({
      errorMessage: 'Failed to get listing duration',
    })
  }
})

export const getRoomsByPricePerSquareMeter = createAsyncThunk<
  any,
  FilterType,
  ThunkConfig
>('estimateMarket/getRoomsByPricePerSquareMeter', async (filter, thunkAPI) => {
  try {
    return await estimateMarketService.getMarketAggs(filter)
  } catch (error) {
    return thunkAPI.rejectWithValue({
      errorMessage: 'Failed to get rooms by price per square meter',
    })
  }
})
