import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FC, useState, useEffect } from 'react'
import { DesignGeneratorSteps, useI18n, Alert } from '@popety_io/popety-io-lib'
import LinearProgress from '@mui/material/LinearProgress'

import { getGenerativeDesign, landSelector } from '../redux'
import DesignStepOne from './DesignStepOne'
import DesignStepTwo from './DesignStepTwo'
import DesignStepThree from './DesignStepThree'
import DesignStepFour from './DesignStepFour'
import DesignComplete from './DesignComplete'

interface IProps {
  onBack: () => void
}

const DesignSteps: FC<IProps> = ({ onBack }) => {
  const dispatch = useDispatch()
  const land: any = useSelector(landSelector)
  const [project, setProject] = useState<any>({
    land: {
      id: land.land_id,
      area: land.area,
      geometry: land.geo_polygon,
      geo_center: land.geo_center,
      radius: 500,
    },
  })
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(false)
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1)
  const { t } = useI18n()

  const steps = ['Collecting Data', 'Generating Project', 'Generating Building']

  const handleSave = (e: any) => {
    setLoading(true)
    setError(false)
    dispatch(getGenerativeDesign({ ...project, ...e }) as any)
      .then((data: any) => {
        if (data.payload?.errors?.length > 0) {
          setTimeout(() => {
            setLoading(false)
            setError(true)
          }, 3000)
        } else {
          setLoading(false)
          setStep(4)
          setProject({ ...project, ...e })
        }
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false)
          setError(true)
        }, 3000)
      })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % 3)
    }, 10000)

    return () => clearInterval(timer)
  }, [])

  return (
    <Box>
      {step !== 5 ? (
        <DesignGeneratorSteps
          step={step}
          description={t('land.design.designGeneratorDescription')}
          onBack={onBack}
          title={t('land.design.newDesignGenerator')}
          backLabel={t('land.design.backToProjectList')}
          steps={[
            {
              step: 1,
              title: t('land.design.whatTypeOfBuildingDoYouWantToBuild?'),
              content: (
                <DesignStepOne
                  onChose={(e: any) => {
                    setProject({
                      ...project,
                      user_input: { ...project.user_input, building_type: e },
                    })
                    setStep(2)
                  }}
                />
              ),
              completedTitle: t('land.design.chooseTheBuildingType'),
            },
            {
              step: 2,
              title: t('land.design.whatIsTheConstraints?'),
              content: (
                <DesignStepTwo
                  handleBack={() => setStep(1)}
                  handleContinue={(e) => {
                    setProject({
                      ...project,
                      user_input: { ...project.user_input, ...e },
                    })
                    setStep(3)
                  }}
                />
              ),
              completedTitle: t('land.design.editTheConstraints'),
            },
            {
              step: 3,
              title: t('land.design.whatMixOfUnitsDoYouWant?'),
              content: (
                <DesignStepThree
                  handleBack={() => setStep(2)}
                  handleContinue={(e: any) => handleSave(e)}
                  loading={loading}
                />
              ),
              completedTitle: t('land.design.enterTheMixOfUnitsYouWant'),
            },
            {
              step: 4,
              title: t('land.design.chooseTheBestProject'),
              content: (
                <DesignStepFour
                  handleBack={() => setStep(3)}
                  handleContinue={() => setStep(5)}
                />
              ),
            },
          ]}
        />
      ) : (
        <DesignComplete goBack={onBack} project={project} />
      )}
      {loading && (
        <Box sx={{ width: '100%', marginTop: '5px' }}>
          <LinearProgress />
          <div style={{ textAlign: 'center' }}>{steps[activeStep]}</div>
        </Box>
      )}
      {error && (
        <Box sx={{ marginTop: '5px' }}>
          <Alert error open>
            {t('common.error.500')}
          </Alert>
        </Box>
      )}
    </Box>
  )
}

export default DesignSteps
