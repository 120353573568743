import { createSelector } from '@reduxjs/toolkit'
import { groupBy } from '@popety_io/popety-io-lib'
import type { RootState } from '../../../../redux/reducers'

import { IOfsLayer } from './basemapInterface'

const basemapStateSelector = (state: RootState) => state.basemap

const mapStyleSelector = (state: RootState) => state.basemap.mapStyle

const layoutSelector = (state: RootState) => state.basemap.layout

const ofsLayersSelector = (state: RootState) => state.basemap.ofsLayers

const ofsThematicSelector = (state: RootState) => state.basemap.ofsThematic

const ofsLayersGroupedSelector = createSelector(
  ofsLayersSelector,
  (ofsLayers) => {
    return Object.values(
      groupBy('topic_name', ofsLayers) as Record<string, IOfsLayer[]>,
    ).map((group) => {
      return { group: group?.[0]?.topic_name, options: [...group] }
    })
  },
)

const defaultMapStyleSelector = (state: RootState) =>
  state.basemap.defaultMapStyle

const ofsLayersLoadingSelector = (state: {
  basemap: { ofsLoading: boolean }
}) => state.basemap.ofsLoading

export {
  basemapStateSelector,
  mapStyleSelector,
  layoutSelector,
  ofsLayersGroupedSelector,
  ofsThematicSelector,
  defaultMapStyleSelector,
  ofsLayersLoadingSelector,
}
