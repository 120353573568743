/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { useI18n } from '@popety_io/popety-io-lib'

import { ScaleDecibelsRoot } from '../EstimateNoise/components/ScaleDecibels/ScaleDecibles.style'
import { getIndexData } from './EstimateAirQuality.utils'

const Echelleindex = () => {
  const { t } = useI18n()
  const data = getIndexData(t)

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: '1.1rem',
          textTransform: 'uppercase',
          marginBottom: '20px',
        }}
      >
        {t('common.air_quality_echelle')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {data?.items?.map((item, key) => (
            <React.Fragment key={`scale_${key}`}>
              <ScaleDecibelsRoot>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 4,
                    marginBottom: '6px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 4,
                    }}
                  >
                    {item.explainText ? (
                      <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                        sx={{
                          width: '140px',
                        }}
                      >
                        <span>{item.explainText}</span>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: '140px',
                        }}
                      />
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 500,
                          backgroundColor: item.color,
                          padding: '8px',
                          width: '50px',
                          textAlign: 'center',
                          position: 'relative',
                        }}
                        className={item.explainText ? 'arrow-container' : ''}
                      >
                        {item.value}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{ color: '#969696', flex: 1, position: 'relative' }}
                    sx={{
                      width: '140px',
                    }}
                  >
                    {item.text}
                    {item.description && (
                      <>
                        <Box
                          sx={{
                            position: 'absolute',
                            width: '220px',
                            height: '2px',
                            backgroundColor: '#e5e5e5',
                            bottom: '-12px',
                            left: '-80px',
                            display: { xs: 'none', md: 'block' },
                          }}
                        />
                        <Box
                          sx={{
                            display: { xs: 'none', md: 'block' },
                            position: 'absolute',
                            left: '150px',
                            bottom: '5px',
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: '0.9rem',
                              marginBottom: '-100px',
                              color: 'black',
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </ScaleDecibelsRoot>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Typography>{t('common.air_quality_echelle_description')}</Typography>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://sparrow.city/"
        className="ListingLink"
        underline="none"
      >
        {t('common.air_quality_echelle_link')}
      </Link>
    </>
  )
}

export default Echelleindex
