import { Map } from '@popety_io/popety-io-lib'
import { API_BASE_URL } from '../../../../../services'

const renderMethod = 'aggs'

const sourceName = 'es_mvt_pricing'
const fillStyle = 'layer_fill_pricing'

const removePricingTiles = ({ map }: { map: Map }) => {
  if (map.getSource(sourceName)) {
    map.removeLayer(fillStyle)
    map.removeSource(sourceName)
  }
}

const getPricingTiles = ({
  map,
  indexName = 'estimate-purchase-apartment-v1',
  geometryFieldName = 'geo_center',
}: {
  map: Map
  indexName?: string
  geometryFieldName?: string
}) => {
  try {
    if (map.getSource(sourceName)) {
      map.removeLayer(fillStyle)
      map.removeSource(sourceName)
    }

    map.addSource(sourceName, {
      type: 'vector',
      tiles: [
        `${API_BASE_URL}/pricing/tiles?index=${indexName}&field=${geometryFieldName}&renderMethod=${renderMethod}&x={x}&y={y}&z={z}`,
      ],
      minzoom: 0,
      maxzoom: 24,
    })

    const fillOpacity = 0.5
    const min = 4000
    const max = 19000
    const diff = max - min

    map.addLayer({
      id: fillStyle,
      type: 'fill',
      source: sourceName,
      'source-layer': renderMethod,
      paint: {
        'fill-outline-color': 'transparent',
        'fill-opacity': fillOpacity,
        'fill-color': {
          property: 'avg_price_m2.value',
          stops: [
            [min, '#5e4fa2'],
            [min + Math.round(diff / 9) * 1, '#3288bd'],
            [min + Math.round(diff / 9) * 2, '#66c2a5'],
            [min + Math.round(diff / 9) * 3, '#abdda4'],
            [min + Math.round(diff / 9) * 4, '#e6f598'],
            [min + Math.round(diff / 9) * 5, '#fee08b'],
            [min + Math.round(diff / 9) * 6, '#fdae61'],
            [min + Math.round(diff / 9) * 7, '#f46d43'],
            [min + Math.round(diff / 9) * 8, '#d53e4f'],
            [max, '#9e0142'],
          ],
        },
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export { removePricingTiles, getPricingTiles }
