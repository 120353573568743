/* eslint-disable no-nested-ternary */
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined'
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import Crop54Icon from '@mui/icons-material/Crop54'
import { toReadableNumber, useI18n } from '@popety_io/popety-io-lib'

import {
  Container,
  ItemContainer,
  StyledDiv,
  SpanValue,
  SpanKey,
} from './LandDetails.style'

export type LandDetailsPros = {
  feature: any
  building?: boolean
}

const LandDetails = ({ feature, building }: LandDetailsPros) => {
  const { t } = useI18n()

  const area = feature?.area || feature.gross_floor || feature.lands?.[0]?.area

  const allFeature = feature.egid ? feature?.buildings : feature?.lands

  const data = [allFeature || feature].filter(Boolean)

  let minYear: any = null
  let maxYear: any = null

  data?.forEach((f: any) => {
    const buildings = !f.egid ? f?.buildings : [f]

    buildings?.forEach((building: any) => {
      building.buildings_administrative?.forEach((admin: any) => {
        const buildingMinYear =
          Number(admin?.building_period?.split('-')?.[0]) ||
          Number(admin.building_year)

        const buildingMaxYear =
          Number(admin?.building_period?.split('-')?.[1]) ||
          Number(admin.building_year)

        if (admin.building_period) {
          minYear =
            minYear === null
              ? buildingMinYear
              : minYear > buildingMinYear
                ? buildingMinYear
                : minYear

          maxYear =
            maxYear === null
              ? buildingMaxYear
              : maxYear < buildingMaxYear
                ? buildingMaxYear
                : maxYear
        }
      })
    })
  })

  return (
    <Container>
      <ItemContainer>
        <StyledDiv>
          <Crop54Icon />
          <SpanKey>
            {toReadableNumber(area)}m<sup>2</sup>
          </SpanKey>
        </StyledDiv>
        <SpanValue>{t('common.search.details.area')}</SpanValue>
      </ItemContainer>
      {building && (
        <ItemContainer>
          <StyledDiv>
            <RestoreOutlinedIcon />
            <SpanKey>
              {feature.building_year || feature.building_period}
            </SpanKey>
          </StyledDiv>
          <SpanValue>{t('common.search.details.construction')}</SpanValue>
        </ItemContainer>
      )}

      {(!building && !!minYear) || (!building && !!maxYear) ? (
        <ItemContainer>
          <StyledDiv>
            <RestoreOutlinedIcon />
            {minYear !== null && maxYear !== null && (
              <SpanKey>{`${minYear} - ${maxYear}`}</SpanKey>
            )}
            {minYear === null && maxYear !== null && (
              <SpanKey>{maxYear}</SpanKey>
            )}
            {maxYear === null && minYear !== null && (
              <SpanKey>{minYear}</SpanKey>
            )}
          </StyledDiv>
          <SpanValue>{t('common.search.details.construction')}</SpanValue>
        </ItemContainer>
      ) : (
        <></>
      )}

      {!feature?.egid && (
        <ItemContainer>
          <StyledDiv>
            <DomainOutlinedIcon />
            <SpanKey>{feature?.building_nb}</SpanKey>
          </StyledDiv>
          <SpanValue>{t('common.search.details.buildings')}</SpanValue>
        </ItemContainer>
      )}

      {feature?.floor_nb && (
        <ItemContainer>
          <StyledDiv>
            <DomainOutlinedIcon />
            <SpanKey>{feature?.floor_nb}</SpanKey>
          </StyledDiv>
          <SpanValue>{t('common.floors')}</SpanValue>
        </ItemContainer>
      )}

      <ItemContainer>
        <StyledDiv>
          <VpnKeyOutlinedIcon />
          <SpanKey>{feature?.unit_nb}</SpanKey>
        </StyledDiv>
        <SpanValue>{t('common.search.details.units')}</SpanValue>
      </ItemContainer>
    </Container>
  )
}

export default LandDetails
