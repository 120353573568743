import { ApiClient, API_BASE_URL } from '../../../services'

const client = new ApiClient()

type LoginInput = { email: string; password: string }

const login = async (input: LoginInput) => {
  try {
    const response = await client.post('/auth/login', input)
    const payload = response.data

    if (payload) {
      if (payload?.needVerify) {
        localStorage.setItem('twoFAToken', payload.token)
      } else {
        localStorage.setItem('jwt', payload.token)
      }
      localStorage.setItem('user', JSON.stringify(payload.user))

      // set driver
      const { tutorials } = payload.user

      Object.keys(tutorials).forEach((key: string) => {
        localStorage.setItem(key, tutorials?.[key])
      })
    }

    if (response.errors) {
      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
    }

    return response
  } catch (error) {
    console.error(error)
  }
}

const getPublicSupport = () => {
  return client.get(`/supports/public`)
}

const getSupports = ({ companyId }: { companyId: number }) => {
  return client.get(`/supports/${companyId}`)
}

const getApiVersion = async () => {
  const url = API_BASE_URL?.replace('api/v2', 'version') as string
  const response = await fetch(url, { method: 'GET' })

  const version = await response.text()

  return version
}

const authService = { login, getSupports, getApiVersion, getPublicSupport }

export default authService
