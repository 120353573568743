import { IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import { BulletProps } from '@popety_io/popety-io-lib'
import { memo, useState } from 'react'
import ScoreLegend from '../../../components/ScoreLegend'

export type ScoreInfoLegendProps = {
  title?: BulletProps['title']
}

const ScoreInfoLegend = ({ title }: ScoreInfoLegendProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'legend-popper' : undefined

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Tooltip
          enterDelay={0}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                padding: 0,
              },
            },
          }}
          title={<ScoreLegend title={title} />}
        >
          <InfoIcon sx={{ color: '#969696', fontSize: '0.969rem' }} />
        </Tooltip>
      </IconButton>
    </>
  )
}

export default memo(ScoreInfoLegend)
