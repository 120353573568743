import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getListingsAggsPriceAnnoncements = (filter: Record<string, any>) => {
  return client.post(
    `/estimate/market/price-annoncement?field=${filter.field}`,
    filter,
    filter?.signal && { signal: filter.signal },
  )
}

const getMarketAggs = (filter: Record<string, any>) => {
  return client.post(
    `/estimate/market/aggregations?aggs=${filter.aggs}`,
    filter,
    filter?.signal && { signal: filter.signal },
  )
}

const estimateMarketService = {
  getListingsAggsPriceAnnoncements,
  getMarketAggs,
}

export default estimateMarketService
