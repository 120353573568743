import { ID } from '@popety_io/popety-io-lib'
import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type UploadFileProps = {
  objectId: ID | ID[]
  files: File[] | FileList
  type: string
}

const getFilesLands = async ({ landIds }: { landIds: ID | ID[] }) => {
  return client.get(`/file/list/lands/${landIds}`)
}

const getFilesBuildings = async ({ buildingId }: { buildingId: ID | ID[] }) => {
  return client.get(`/file/list/buildings/${buildingId}`)
}

const uploadFile = async ({ files, objectId, type }: UploadFileProps) => {
  const idParam = Array.isArray(objectId) ? objectId.join(',') : objectId

  const formDataObj: { [key: string]: File } = {}

  Array.from(files).forEach((file, index) => {
    formDataObj[`file${index}`] = file
  })

  return client.post(`/file/${type}/${idParam}`, formDataObj, {
    formData: true,
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const removeFile = async ({ fileId, type }: { fileId: ID; type: string }) => {
  return client.delete(`/file/${fileId}`)
}

const getFile = async ({ fileId }: { fileId?: ID | ID[]; name: string }) => {
  return client.downloadFile(`/file/${fileId}`)
}

const getFilesEstimate = async ({ estimateId }: { estimateId: ID }) => {
  return client.get(`/file/list/estimates/${estimateId}`)
}

const filesService = {
  getFilesLands,
  uploadFile,
  removeFile,
  getFilesBuildings,
  getFile,
  getFilesEstimate,
}

export default filesService
