/* eslint-disable operator-assignment */
import Box, { BoxProps } from '@mui/material/Box'

import useTag from './useTag'
import PreviewLandChip from './PreviewLandChip'

type TagChipsProps = BoxProps & {
  landIds: string | string[]
}

const TagChips = ({ landIds, ...other }: TagChipsProps) => {
  const { activeLandTags, sanitizedLandIds } = useTag({
    landIds,
    fetch: true,
  })

  const isGroupignLands = Array.isArray(landIds) && landIds.length > 0

  if (!activeLandTags?.length) return null

  let filteredTags: any = []

  if (isGroupignLands) {
    const tagCounts: any = {}

    activeLandTags.forEach((tag) => {
      if (landIds.includes(tag.land_id)) {
        if (!tagCounts[tag.tag_id]) {
          tagCounts[tag.tag_id] = 1
        } else {
          tagCounts[tag.tag_id] = tagCounts[tag.tag_id] + 1
        }
      }
    })

    filteredTags = activeLandTags.filter(
      (tag) => tagCounts[tag.tag_id] === landIds.length,
    )

    filteredTags = Object.values(
      filteredTags.reduce((acc: any, current: any) => {
        acc[current.tag_id] = current

        return acc
      }, {}),
    )
  }

  return (
    <Box {...other} display="flex" alignItems="center" gap="8px">
      <PreviewLandChip
        tags={
          isGroupignLands && filteredTags?.length
            ? filteredTags
            : activeLandTags
        }
        landIds={sanitizedLandIds}
      />
    </Box>
  )
}

export default TagChips
