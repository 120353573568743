import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const LandDetailsActions = lazy(
  () => import('../../pages/LandDetails/components/LandDetailsActions'),
)

const BuildingDetailsActions = lazy(
  () => import('../../pages/BuildingDetails/components/BuildingDetailsActions'),
)

const HeaderActions = () => (
  <Suspense>
    <Routes>
      <Route path="/land-detail/plot" element={<LandDetailsActions />} />
      <Route
        path="/land-detail/building"
        element={<BuildingDetailsActions />}
      />
      <Route path="*" element={null} />
    </Routes>
  </Suspense>
)

export default memo(HeaderActions)
