/* eslint-disable max-lines */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { mapBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import underExploitedService from './underExploitedService'

const initialState = {
  futureLandUsePlansById: {},
  landUsePlansById: {},
  landUsePlansDocuments: [],
  futureLandUsePlansDocuments: [],
}

export const getLandUsePlansDetails = createAsyncThunk(
  'underExploited/getLandUsePlansDetails',
  underExploitedService.getLandUsePlansDetails,
)

export const getLandUsePlanDocuments = createAsyncThunk(
  'underExploited/getLandUsePlanDocuments',
  underExploitedService.getLandUsePlanDocuments,
)

export const getFutureLandUsePlanDocuments = createAsyncThunk(
  'underExploited/getFutureLandUsePlanDocuments',
  underExploitedService.getFutureLandUsePlanDocuments,
)

export const getDifferenceLandUsePlanDocuments = createAsyncThunk(
  'underExploited/getDifferenceLandUsePlanDocuments',
  underExploitedService.getDifferenceLandUsePlanDocuments,
)

export const getAllAssignementsOverideById = createAsyncThunk(
  'underExploited/getAllAssignementsOverideById',
  underExploitedService.getAllAssignementsOverideById,
)

export const addOverideValuesAssignment = createAsyncThunk(
  'underExploited/addOverideValuesAssignment',
  underExploitedService.addOverideValuesAssignment,
)

export const updateOverideValues = createAsyncThunk(
  'underExploited/updateOverideValues',
  underExploitedService.updateOverideValues,
)

export const getAllGroupingAssignementsOverideById = createAsyncThunk(
  'underExploited/getAllGroupingAssignementsOverideById',
  underExploitedService.getAllGroupingAssignementsOverideById,
)

export const addGroupingOverideValuesAssignment = createAsyncThunk(
  'underExploited/addGroupingOverideValuesAssignment',
  underExploitedService.addGroupingOverideValuesAssignment,
)

export const updateGroupingOverideValuesAssignment = createAsyncThunk(
  'underExploited/updateGroupingOverideValuesAssignment',
  underExploitedService.updateGroupingOverideValuesAssignment,
)

const underExploited = createSlice({
  name: 'underExploited',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLandUsePlansDetails.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}
        const input = action.meta.arg

        state.landUsePlansById = mapBy('id', data)

        input.landUsePlans.forEach((landUsePlan) => {
          state.landUsePlansById[landUsePlan.id] = {
            ...landUsePlan,
            ...state.landUsePlansById[landUsePlan.id],
          }
        })
      })
      .addCase(getLandUsePlanDocuments.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.landUsePlansDocuments = data
          state.futureLandUsePlansDocuments = []
        }
      })
      .addCase(getFutureLandUsePlanDocuments.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data) {
          state.futureLandUsePlansDocuments = data
          state.landUsePlansDocuments = []
        }
      })
      .addCase(getDifferenceLandUsePlanDocuments.fulfilled, (state, action) => {
        const data = action.payload || {}

        if (data) {
          state.landUsePlansDocuments = data?.[0]
          state.futureLandUsePlansDocuments = data?.[1]
        }
      })
      .addCase(getAllAssignementsOverideById.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}
        const { landId } = action.meta.arg

        if (data) {
          const { current, future } = data

          if (current?.length > 0) {
            current.forEach((lup) => {
              // We check if the land_id correspond
              // If not we check if this specific land use plan is extend
              // Which mean when you edit a land use plan in an another plot
              //  it will affect this plot if its the same land use plan
              if (Number(landId) === Number(lup.land_id) || lup.extend) {
                state.landUsePlansById[lup.land_use_plan_assignment_id] = {
                  ...state.landUsePlansById[lup.land_use_plan_assignment_id],
                  ...lup,
                  ...lup.overide_json,
                  id: lup.land_use_plan_assignment_id,
                  editId: lup.id,
                  typeZone: lup?.overide_json?.typeZone,
                }
              }
            })
          }

          if (future?.length > 0) {
            future.forEach((lup) => {
              // Same as above but for future land use plans
              if (Number(landId) === Number(lup.land_id) || lup.extend) {
                state.futureLandUsePlansById[lup.land_use_plan_assignment_id] =
                {
                  ...lup,
                  ...lup.overide_json,
                  ...state.futureLandUsePlansById[
                  lup.land_use_plan_assignment_id
                  ],
                }
              }
            })
          }
        }
      })
      .addCase(
        getAllGroupingAssignementsOverideById.fulfilled,
        (state, action) => {
          const { data = {} } = action.payload || {}
          const { groupingId } = action.meta.arg

          if (data) {
            const { current, future } = data

            if (current?.length > 0) {
              current.forEach((lup) => {
                // We check if the land_id correspond
                // If not we check if this specific land use plan is extend
                // Which mean when you edit a land use plan in an another plot
                //  it will affect this plot if its the same land use plan
                if (Number(groupingId) === Number(lup.land_id) || lup.extend) {
                  state.landUsePlansById[lup.land_use_plan_assignment_id] = {
                    ...state.landUsePlansById[lup.land_use_plan_assignment_id],
                    ...lup,
                    ...lup.overide_json,
                    id: lup.land_use_plan_assignment_id,
                    editId: lup.id,
                    typeZone: lup?.overide_json?.typeZone,
                  }
                }
              })
            }

            if (future?.length > 0) {
              future.forEach((lup) => {
                // Same as above but for future land use plans
                if (Number(groupingId) === Number(lup.land_id) || lup.extend) {
                  state.futureLandUsePlansById[
                    lup.land_use_plan_assignment_id
                  ] = {
                    ...lup,
                    ...lup.overide_json,
                    ...state.futureLandUsePlansById[
                    lup.land_use_plan_assignment_id
                    ],
                  }
                }
              })
            }
          }
        },
      )
      .addCase(addOverideValuesAssignment.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}
        const { landUsePlanAssignmentId } = action.meta.arg

        if (data) {
          state.landUsePlansById[landUsePlanAssignmentId] = {
            ...state.landUsePlansById[landUsePlanAssignmentId],
            ...data.overide_json,
            isExtend: data.isExtend,
            editId: data.id,
          }
        }
      })
      .addCase(
        addGroupingOverideValuesAssignment.fulfilled,
        (state, action) => {
          const { data = {} } = action.payload || {}
          const { landUsePlanAssignmentId } = action.meta.arg

          if (data) {
            state.landUsePlansById[landUsePlanAssignmentId] = {
              ...state.landUsePlansById[landUsePlanAssignmentId],
              ...data.overide_json,
              isExtend: data.isExtend,
              editId: data.id,
            }
          }
        },
      )
      .addCase(updateOverideValues.fulfilled, (state, action) => {
        const { data = {} } = action.payload || {}

        if (data) {
          state.landUsePlansById[data.land_use_plan_assignment_id] = {
            ...state.landUsePlansById[data.land_use_plan_assignment_id],
            ...data,
            ...data.overide,
          }
        }
      })
      .addCase(
        updateGroupingOverideValuesAssignment.fulfilled,
        (state, action) => {
          const { data = {} } = action.payload || {}

          if (data) {
            state.landUsePlansById[data.land_use_plan_assignment_id] = {
              ...state.landUsePlansById[data.land_use_plan_assignment_id],
              ...data,
              ...data.overide,
            }
          }
        },
      )
  },
})

// export const { updateTransationRange } = underExploited.actions

export const underExploitedReducer = {
  underExploited: underExploited.reducer,
}
