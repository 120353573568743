import { Fragment, memo } from 'react'

import { EstimateSummaryMapConfig } from '../EstimateSummary'
import { EstimateTransactionMapConfig } from '../EstimateTransactions/EstimateTransactionsConfig'
import { FaoMapConfig } from '../../../LandDetails/Fao'
import { LivabilityMapConfig } from '../../../LandDetails/Livability'
import { EstimateListingMapConfig } from '../EstimateComparables/ComparablesConfig'
import { LandSummaryMapConfig } from '../../../BuildingDetails/LandSummary'
import { SunlightMapConfig } from '../../../LandDetails/Sunlight'
import { EnergyMapConfig } from '../../../BuildingDetails/Energy'
import { NoiseMapConfig } from '../EstimateNoise'
import { EstimateHistoryMapConfig } from '../EstimateHistory'
import { MarketMapConfig } from '../EstimateMarket/components/EstimateMarketMap'
import { EstimateEventHistoryMapConfig } from '../EstimateEventHistory'
import { EstimateAirQualityMapConfig } from '../EstimateAirQuality'

type MapConfigType =
  | (Partial<typeof EstimateTransactionMapConfig> & {
      Controller?: React.ElementType<any>
      style?: string
    })
  | {
      Click?: (data: any) => JSX.Element
      Hover?: (data: any) => JSX.Element
      Content?: () => JSX.Element
      Controller: ({ map, loaded }: { map: any; loaded?: boolean }) => any
      style?: string
    }

const MapConfigByPage: Record<string, MapConfigType> = {
  home: EstimateHistoryMapConfig,
  summary: EstimateSummaryMapConfig,
  parcelle: LandSummaryMapConfig,
  energy: EnergyMapConfig,
  transaction: EstimateTransactionMapConfig,
  fao: FaoMapConfig,
  listing: EstimateListingMapConfig,
  livability: LivabilityMapConfig,
  sunlight: SunlightMapConfig,
  noise: NoiseMapConfig,
  market: MarketMapConfig,
  history: EstimateEventHistoryMapConfig,
  airQuality: EstimateAirQualityMapConfig,
}

/**
 * Map controllers have the full control of the map
 */
const MapControllers = memo((props: any) => (
  <>
    {Object.values(MapConfigByPage).map((comp, i) => {
      const { Controller } = comp

      if (!Controller) return <Fragment key={i} />

      return <Controller {...props} key={i} />
    })}
  </>
))

export { MapConfigByPage, MapControllers }
