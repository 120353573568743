import { ApiClient } from '../../../services'

const client = new ApiClient()

const getZones = async () => {
  return client.get(`/zones`)
}

const getRegions = async () => {
  return client.get(`/region`)
}

// TODO: try catch
const getCities = async () => {
  try {
    const response = await client.get('/cities')

    // To remove duplicate cities
    const cityByName: Record<string, any> = {}

    if (response.data) {
      response.data.forEach((city: any) => {
        if (city.name) cityByName[city.name] = city
      })

      response.data = Object.values(cityByName)
    }

    return response
  } catch (error) {
    // Handle the error here
    console.error('Error fetching cities:', error)

    return { data: [] }
  }
}

const searchAllAddresses = async ({ value, types, size }: any) => {
  return client.post('/addresses/locations', { value, size, types })
}

const searchAddress = async (address: string) => {
  return client.post(`/addresses/search`, { address })
}

export const locationService = {
  getZones,
  getRegions,
  getCities,
  searchAddress,
  searchAllAddresses,
}
