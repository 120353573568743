import Tooltip from '@mui/material/Tooltip'

import useFavoriteLand, { FavoriteLandOpts } from './useFavoriteLand'

export type FavoriteLandIconProps = FavoriteLandOpts

const FavoriteLandIcon = ({ landId, opacity }: FavoriteLandIconProps) => {
  const { Icon, title, onClick } = useFavoriteLand({ landId, opacity })

  return (
    <Tooltip
      title={title}
      placement="top"
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {Icon}
    </Tooltip>
  )
}

export default FavoriteLandIcon
