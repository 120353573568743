import { ApiClient } from '../../../../services'

const client = new ApiClient()

const fetchRestrictionsByCity = async (cities: string[]) => {
  return client.get(`/cities/rdppfs/${cities.map(encodeURIComponent)}`)
}

const fetchRestrictionsByDistrict = async (districts: string[]) => {
  return client.get(`/district/rdppfs/${districts.map(encodeURIComponent)}`)
}

const fetchRestrictionsByRegion = async (regions: string[]) => {
  return client.get(`/region/rdppfs/${regions.map(encodeURIComponent)}`)
}

const fetchRestrictionsByNpa = async (regions: string[]) => {
  return client.get(`/cities/npa/rdppfs/${regions.map(encodeURIComponent)}`)
}

const fetchRestrictionsByMunicipalities = async (municipalities: string[]) => {
  return client.get(
    `/municipality/rdppfs/${municipalities.map(encodeURIComponent)}`,
  )
}

const landuseplanService = {
  fetchRestrictionsByDistrict,
  fetchRestrictionsByRegion,
  fetchRestrictionsByCity,
  fetchRestrictionsByNpa,
  fetchRestrictionsByMunicipalities,
}

export default landuseplanService
