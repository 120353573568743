import FaoClickModal from './FaoClickModal'
import FaoMapContent from './FaoMapContent'
import FaoMapHover from './FaoMapHover'
import FaoTiles from './FaoTiles'

export const FaoMapConfig = {
  Click: FaoClickModal,
  Hover: FaoMapHover,
  Content: FaoMapContent,
  Controller: FaoTiles,
}
