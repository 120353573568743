import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchOnce } from '@popety_io/popety-io-lib'

import { getUserId } from '../../services'
import { sharedSelector } from '../../pages/Login'
import {
  getAllBuildingTagsName,
  getTagBuildings,
  listBuildingsTagsSelector,
  refetchBuildingFlagSelector,
  tagsByBuildingIdSelector,
  uniqueBuildingsTagsSelector,
  updateRefetchBuildingFlag,
} from '../../pages/BuildingDashboard/redux/buildingTag'
import useClearUpEffect from '../../hooks/useClearUpEffect'

type getTagForBuildingProps = {
  buildingIds: string | string[]
  tagsbyId?: Record<string, any>
}

const handleSortListTags = (listTags: any[]) => {
  return listTags?.sort((prev: any, next: any) => {
    let sort = 0

    if (prev?.primary && !next?.primary) {
      sort = -1
    }
    if (!prev?.primary && next?.primary) {
      sort = 1
    }

    if (!prev?.primary && !next?.primary) {
      if (prev?.secondary && !next?.secondary) {
        sort = -1
      }
      if (!prev?.secondary && next?.secondary) {
        sort = 1
      }
    }

    return sort
  })
}

const getTagForBuilding = ({
  buildingIds,
  tagsbyId = {},
}: getTagForBuildingProps) => {
  const userId = getUserId()

  const sanitizedBuildingIds = Array.isArray(buildingIds)
    ? buildingIds
    : [buildingIds].filter(Boolean)

  const activeBuildingTags =
    sanitizedBuildingIds
      .map((id) => tagsbyId[id])
      .flat()
      .filter((tg) => tg?.active) || []

  const activeBuildingTagIds = activeBuildingTags?.map((tg) => tg.tag_id)

  const myActiveBuildingTags = activeBuildingTags.filter(
    (tg) => String(tg.user_id) === userId,
  )

  const myActiveBuildingTagIds = myActiveBuildingTags?.map((tg) => tg.tag_id)

  const activeUniqueBuildingTags = activeBuildingTags.filter(
    (value, index, self) =>
      index === self.findIndex((tg) => tg.value === value.value),
  )

  const isTagOwner = (tag: any) => {
    return !!activeBuildingTags?.find(
      (tg) =>
        String(tg?.tag_id) === String(tag?.tag_id) &&
        (String(tg?.user_id) === userId || String(tg?.author) === userId),
    )
  }

  return {
    isTagOwner,
    activeBuildingTagIds,
    tags: tagsbyId,
    myActiveBuildingTags,
    activeBuildingTags,
    activeUniqueBuildingTags,
    sanitizedBuildingIds,
    myActiveBuildingTagIds,
  }
}

let fetched: any

type useBuildingTagProps = {
  buildingIds: string | string[]
  fetch?: boolean
}

const useBuildingTag = ({
  buildingIds,
  fetch = false,
}: useBuildingTagProps) => {
  const location = useLocation()
  const tagsbyId = useSelector(tagsByBuildingIdSelector)
  const uniqueTags = useSelector(uniqueBuildingsTagsSelector)
  const listTags = useSelector(listBuildingsTagsSelector)
  const shared = useSelector(sharedSelector)
  const needRefetch = useSelector(refetchBuildingFlagSelector)
  const res = getTagForBuilding({ buildingIds, tagsbyId })

  const userId = getUserId()
  const active = !!res.activeBuildingTags?.length

  const dispatch = useDispatch<any>()

  useClearUpEffect(() => {
    if (!fetch) return

    const session = userId + location.pathname

    if (session === fetched && !needRefetch) return

    dispatch(
      getTagBuildings({
        isShared: shared?.shareTaggedLands,
      }),
    )
    fetchOnce('listTagsBuilding', null, () =>
      dispatch(getAllBuildingTagsName() as any),
    )
    dispatch(updateRefetchBuildingFlag(false))
    fetched = session
  }, [userId, fetch])

  return { ...res, uniqueTags, active, listTags, tagsbyId, handleSortListTags }
}

export default useBuildingTag

export { getTagForBuilding }
