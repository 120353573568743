import { useState, useEffect, ChangeEvent } from 'react'

// import { GridProps } from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/WarningOutlined'
import ReplayIcon from '@mui/icons-material/Replay'

import { useMediaQuery, FormField, useI18n } from '@popety_io/popety-io-lib'

import { TextFieldRoot } from '../../../../components/ValueGrid'

const ValueGrid = (props: any) => {
  const {
    id,
    comment,
    val,
    onChange,
    open,
    keyType = '',
    isSelected,
    indiceName,
    type,
    defaultValue,
    ...other
  } = props
  const { t } = useI18n()
  const [value, setValue] = useState(val)
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleReset = () => {
    setValue(defaultValue)
    if (indiceName) {
      onChange?.({
        [indiceName]: { [`${keyType}Override`]: 0 },
      })
    } else {
      onChange?.({
        [`${keyType}Override`]: 0,
      })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)

    if (indiceName) {
      onChange?.({
        [indiceName]: { [`${keyType}Override`]: Number(e.target.value) },
      })
    } else
      onChange?.({
        [`${keyType}Override`]: Number(e.target.value),
      })
  }

  useEffect(() => {
    setValue(val)
  }, [])

  return (
    <>
      {!open ? (
        <span
          className={other.className}
          style={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {val}
          {val !== '-' && (id === 'height' || id === 'distanceLimit') && ' m'}
        </span>
      ) : (
        <TextFieldRoot key={keyType} isNative={!onDesktop}>
          <FormField
            style={{ minWidth: 40, height: '30px' }}
            variant="standard"
            size="small"
            onChange={handleChange}
            value={value}
            type={type}
            data-cy={`${`building-area-value-${keyType}`}`}
          />
          <Tooltip
            style={{ margin: 0, display: 'flex', padding: 0, height: '30px' }}
            placement="top"
            title={t('land.underExploited.reset')}
          >
            <IconButton disabled={!isSelected || !val}>
              <ReplayIcon
                style={{ width: 15, color: 'rgba(0, 0, 0, 0.54)' }}
                onClick={handleReset}
              />
            </IconButton>
          </Tooltip>
        </TextFieldRoot>
      )}
      {comment && (
        <Tooltip title={comment}>
          <IconButton aria-label="Description">
            <WarningIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default ValueGrid
