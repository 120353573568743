const filesStateSelector = (state: any) => state.files

const landFilesSelector = (state: any) => state.files.land

const buildingFilesSelector = (state: any) => state.files.building

const estimateFilesSelector = (state: any) => state.files.estimate

const totalLandFilesSelector = (state: any) => state.files.count

const loadingFilesSelector = (state: any) => state.files.loading

export {
  filesStateSelector,
  landFilesSelector,
  totalLandFilesSelector,
  buildingFilesSelector,
  estimateFilesSelector,
  loadingFilesSelector,
}
