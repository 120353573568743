import { useI18n } from '@popety_io/popety-io-lib'
import { LogoRoot } from './LandDetails.style'

interface BuildingIconProps {
  classification: string
  sx?: any
  archive?: boolean
}

const BuildingIcon = ({
  classification,
  archive = false,
  ...other
}: BuildingIconProps) => {
  const { t } = useI18n()

  let color = '#000000'
  let svg = ''

  if (classification === 'Retail') {
    color = '#D3B39C'
    svg = '/assets/img/building_icon/retail.svg'
  } else if (classification === 'Residential') {
    color = '#F9D518'
    svg = '/assets/img/building_icon/residential.svg'
  } else if (classification === 'Public') {
    color = '#8C8C8C'
    svg = '/assets/img/building_icon/public.svg'
  } else if (classification === 'Other') {
    color = '#CCCCCC'
    svg = '/assets/img/building_icon/other.svg'
  } else if (classification === 'Offices') {
    color = '#9ecae1'
    svg = '/assets/img/building_icon/office.svg'
  } else if (classification === 'Not classified') {
    color = '#CCCCCC'
    svg = '/assets/img/building_icon/not_classified.svg'
  } else if (classification === 'Mixed_2') {
    color = '#F3733C'
    svg = '/assets/img/building_icon/mixed_low.svg'
  } else if (classification === 'Mixed_1') {
    color = '#F3A83C'
    svg = '/assets/img/building_icon/mix_high.svg'
  } else if (classification === 'Industrial') {
    color = '#C39EF9'
    svg = '/assets/img/building_icon/Frame.svg'
  } else if (classification === 'Garage') {
    color = '#bbbbbb'
    svg = '/assets/img/building_icon/garage.svg'
  } else {
    color = '#CCCCCC'
    svg = '/assets/img/building_icon/other.svg'
  }

  if (archive) {
    color = 'rgba(0, 0, 0, 0.38)'
  }

  return (
    <LogoRoot color={color} className="custom-logo" {...other}>
      <img
        style={{
          color,
          filter: archive
            ? 'brightness(0) saturate(100%) opacity(0.38)'
            : 'none',
        }}
        src={svg}
        alt="Popety classification"
      />
      <span style={{ color }}>
        {t(`common.classification.${classification || 'Other'}`)}
      </span>
    </LogoRoot>
  )
}

export default BuildingIcon
