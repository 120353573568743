import Description from './Description'
import Information from './Information'
import Footer from './Footer'
import Header from './Header'
import MainInformation from './MainInformation'
import Details from './Details'

const EstimateTransactionDetailBody = ({
  transaction,
  children,
  transactionsIds,
  onSave,
  onRemove,
}: {
  transaction: any
  children?: any
  transactionsIds: any
  onSave: any
  onRemove: any
}) => (
  <>
    <Header
      transaction={transaction}
      transactionsIds={transactionsIds}
      onSave={onSave}
      onRemove={onRemove}
    />
    {children}
    <MainInformation transaction={transaction} />
    <Details transaction={transaction} />
    <Description transaction={transaction} />
    <Information transaction={transaction} />
    {(onSave || onRemove) && (
      <Footer
        transaction={transaction}
        transactionsIds={transactionsIds}
        onSave={onSave}
        onRemove={onRemove}
      />
    )}
  </>
)

export default EstimateTransactionDetailBody
