import { useRef, useState } from 'react'
import { Box } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Button, Modal, useI18n } from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  estimateFilesSelector,
  getFile,
  loadingFilesSelector,
  removeFile,
  uploadFile,
} from '../../../../DeveloperMap/redux'
import { estimateIdSelector } from '../../../redux'
import { FileProps } from '../../../../../components/FilesDialog/types'
import EstimateFileItem from './EstimateFileItem'

const EstimateFilesBody = () => {
  const files = useSelector(estimateFilesSelector)
  const estimateId = useSelector(estimateIdSelector)
  const loading = useSelector(loadingFilesSelector)
  const [open, setOpen] = useState(false)
  const [fileFocus, setFileFocus] = useState<FileProps | null>(null)
  const dispatch = useDispatch()
  const { t } = useI18n()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()

    if (e.target.files && e.target.files.length > 0) {
      dispatch(
        uploadFile({
          files: e.target.files,
          objectId: estimateId,
          type: 'estimate',
        }) as any,
      )
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleDeleteClick = (file: any) => {
    setFileFocus(file)
    setOpen(true)
  }

  const handleOk = () => {
    if (fileFocus?.id) {
      dispatch(removeFile({ fileId: fileFocus?.id, type: 'estimate' }) as any)
    }
    setOpen(false)
  }

  const handleDlFile = (fileId: FileProps['id'], name: any) => {
    dispatch(getFile({ fileId, name }) as any)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <Box id="estimate-files-body">
      {files?.map((file: FileProps, index: number) => {
        return (
          <EstimateFileItem
            key={file.id || index}
            file={file}
            onDeleteClick={handleDeleteClick}
            handleDlFile={handleDlFile}
            loading={loading}
          />
        )
      })}
      <Button startIcon={<CloudUploadIcon />} onClick={handleButtonClick}>
        {t('common.add_file')}
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadFile}
        multiple
        style={{ display: 'none' }}
      />
      <Modal
        title={t('common.Delete file')}
        open={open}
        okText={t('common.ok')}
        onOk={handleOk}
        onClose={handleOpen}
        onCancel={handleOpen}
        cancelText={t('common.cancel')}
      >
        {t('common.Are you sure you want to delete this file ?')}
      </Modal>
    </Box>
  )
}

export default EstimateFilesBody
