import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import energyService from './energyService'
import { ICECB, IHeatingAndHotWaterEditedValues } from './energyType'

export type EnergyState = {
  loading: boolean
  roofEnergy: any
  roofPolygon: any
  heatConsumptionAround: any
  loadHeatConsumptionAround: boolean
  userEdited: {
    isLoading: boolean
    heatingAndHotWater?: IHeatingAndHotWaterEditedValues
    cecb?: ICECB
  }
}

const initialState: EnergyState = {
  loading: false,
  roofEnergy: {},
  roofPolygon: {},
  heatConsumptionAround: {},
  loadHeatConsumptionAround: false,
  userEdited: {
    isLoading: false,
    heatingAndHotWater: undefined,
    cecb: undefined,
  },
}

export const getRoofBuildingPotentialSolar = createAsyncThunk(
  'energyService/getRoofBuildingPotentialSolar',
  energyService.getRoofBuildingPotentialSolar,
)

export const getRoofBuildingPolygon = createAsyncThunk(
  'energyService/getRoofBuildingPolygon',
  energyService.getRoofBuildingPolygon,
)

export const getUserDetailedEditing = createAsyncThunk(
  'energyService/getUserDetailedEditing',
  energyService.getUserDetailedEditing,
)

export const getHeatConsumptionAround = createAsyncThunk(
  'energyService/getHeatConsumptionAround',
  energyService.getHeatConsumptionAround,
)

const energy = createSlice({
  name: 'energy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoofBuildingPotentialSolar.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data) {
          state.roofEnergy = data
        }
      })
      .addCase(getRoofBuildingPolygon.fulfilled, (state, action) => {
        const { data } = action.payload

        if (data) {
          state.roofPolygon = data
        }
      })
      .addCase(getUserDetailedEditing.pending, (state) => {
        state.userEdited.isLoading = true
      })
      .addCase(getUserDetailedEditing.fulfilled, (state, action) => {
        const { data } = action.payload

        if (
          data &&
          data?.heating_hot_water &&
          Object.keys(data?.heating_hot_water).length
        ) {
          state.userEdited.heatingAndHotWater = data.heating_hot_water
        }

        if (data && data?.cecb && Object.keys(data?.cecb).length) {
          state.userEdited.cecb = data.cecb
        }
        state.userEdited.isLoading = false
      })
      .addCase(getUserDetailedEditing.rejected, (state) => {
        state.userEdited.isLoading = false
      })
      .addCase(getHeatConsumptionAround.pending, (state) => {
        state.loadHeatConsumptionAround = true
      })
      .addCase(getHeatConsumptionAround.rejected, (state) => {
        state.loadHeatConsumptionAround = false
      })
      .addCase(getHeatConsumptionAround.fulfilled, (state, action) => {
        const { data } = action.payload as any

        state.loadHeatConsumptionAround = false
        if (data) {
          state.heatConsumptionAround = {
            buckets:
              data?.aggs?.idc_per_buildings_around?.idc_per_buildings?.buckets,
            doc_count: data?.aggs?.idc_per_buildings_around?.doc_count,
          }
        }
      })
  },
})

export const energyReducer = {
  energy: energy.reducer,
}
