import { analizedBuildingReducer } from './analizedBuilding'
import { buildingTagReducer } from './buildingTag'
import { actionHistoryReducer } from './actionHistory'
import { prospectReducer } from './prospect'
import { favoriteBuildingsReducer } from './favoriteBuilding'
import { buildingActionHistoryReducer } from './BuildingActionHisory'

export * from './actionHistory'
export * from './analizedBuilding'
export * from './prospect'
export * from './buildingTag'
export * from './BuildingActionHisory'
export * from './favoriteBuilding'

export const buildingDashboardReducer = {
  ...analizedBuildingReducer,
  ...actionHistoryReducer,
  ...prospectReducer,
  ...buildingTagReducer,
  ...favoriteBuildingsReducer,
  ...buildingActionHistoryReducer,
}
