const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.summary.startTitle'),
          description: t('driver.summary.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#land-header-title',
        popover: {
          title: t('driver.summary.headerTitle'),
          description: t('driver.summary.headerDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-summary-header',
        popover: {
          title: t('driver.summary.subHeaderTitle'),
          description: t('driver.summary.subHeaderDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#favorite-land-button',
        popover: {
          title: t('driver.summary.landButtonTitle'),
          description: t('driver.summary.landButtonDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#tag-land-button',
        popover: {
          title: t('driver.summary.tagButtonTitle'),
          description: t('driver.summary.tagButtonDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#export-land-details',
        popover: {
          title: t('driver.summary.exportButtonTitle'),
          description: t('driver.summary.exportButtonDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#go-to-land-details',
        popover: {
          title: t('driver.summary.goToButtonTitle'),
          description: t('driver.summary.goToButtonDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-summary-info',
        popover: {
          title: t('driver.summary.landInfoTitle'),
          description: t('driver.summary.landInfoDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-zone-info',
        popover: {
          title: t('driver.summary.landZoneTitle'),
          description: t('driver.summary.landZoneDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-building-info',
        popover: {
          title: t('driver.summary.landBuildingTitle'),
          description: t('driver.summary.landBuildingDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-restirctions-info',
        popover: {
          title: t('driver.summary.landRestirctionsTitle'),
          description: t('driver.summary.landRestirctionsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-owners-info',
        popover: {
          title: t('driver.summary.landOwnersTitle'),
          description: t('driver.summary.landOwnersDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#land-hitory-info',
        popover: {
          title: t('driver.summary.landHistoryTitle'),
          description: t('driver.summary.landHistoryDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
