import { formatPrice } from '@popety_io/popety-io-lib'

const getPriceInfo = (priceData: any[]) => {
  if (!priceData?.length)
    return {
      priceM2: '',
      priceM2Max: '',
      priceM2Range: '',
      priceM2Min: '',
      maxDate: '',
    }

  const priceM2 = formatPrice(priceData[priceData.length - 1]?.priceM2, 0)

  const priceM2Min = priceData[priceData.length - 1]?.priceM2Range?.[0]
  const priceM2Max = priceData[priceData.length - 1]?.priceM2Range?.[1]

  const maxDate = priceData.slice(-1)[0]?.date

  let priceM2Range = `${priceM2Min ? formatPrice(priceM2Min, 0) : ''} - ${
    priceM2Max ? formatPrice(priceM2Max, 0) : ''
  }`

  if (!priceM2Min && !priceM2Max) priceM2Range = ''

  return { priceM2, priceM2Max, priceM2Range, priceM2Min, maxDate }
}

export { getPriceInfo }
