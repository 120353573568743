import Box from '@mui/material/Box'
import { Select, useI18n, useRouter } from '@popety_io/popety-io-lib'
import { getYear } from 'date-fns'
import LayersIcon from '@mui/icons-material/Layers'
import { styled } from '@mui/material'

export const MapLegendRoot = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  border-radius: ${({ theme }) => +theme.shape.borderRadius}px;
  max-height: calc(100% - 120px);
  overflow: auto;
  padding: 8px 16px 8px 12px;

  svg {
    cursor: pointer;
  }
`

const EventHistoryLegend = () => {
  const { t } = useI18n()

  const { updateQuery, query } = useRouter()

  const currentYear = getYear(new Date()) - 1

  const generateOptions = (optionYear: number) => {
    const options = []
    let year = optionYear

    while (year > 1959) {
      options.push({
        value: year,
        label: `${t('common.Carte satellite')} ${year}`,
        src: '/assets/img/buildingLayers/heater.png',
        group: 'energy',
      })

      year -= 1
    }

    return options || []
  }

  const options = [
    {
      value: '2024',
      label: t('common.Carte satellite actuel'),
      src: '/assets/img/buildingLayers/heater.png',
      group: 'energy',
    },
    ...(generateOptions(currentYear) || []),
  ]

  const onChange = (event: any) => {
    const { value } = event

    updateQuery({ historyLayer: value === '2024' ? currentYear : value })
  }

  return (
    <MapLegendRoot
      title={t('common.layers')}
      id="history"
      top="16px"
      zIndex={1000}
      right="16px"
      left="auto"
      padding="4px"
      position="absolute"
      className="print-legend"
    >
      <LayersIcon />
      <Select
        fullWidth
        options={options}
        value={query?.historyLayer || '2024'}
        onChange={onChange}
        sx={{
          position: 'relative',
        }}
      />
    </MapLegendRoot>
  )
}

export { EventHistoryLegend }
