/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Skeleton from '@mui/material/Skeleton'
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent'
import { StyledEngineProvider } from '@mui/material/styles'
import { format } from 'date-fns'
import { Box, BoxProps } from '@mui/material'
import {
  ComparableIcon,
  TransactionsIcon,
  FaoIconV2,
} from '@popety_io/popety-io-lib'
import useInfiniteScroll, {
  ScrollDirection,
} from 'react-easy-infinite-scroll-hook'

import FaoHistoryCard from '../FaoHistoryCard'
import TransactionHistoryCard from '../TransactionHistoryCard'
import ListingHistoryCard from '../ListingHistoryCard'

enum EVENT_HISTORY_CATEGORY {
  FAO = 'Fao',
  TRANSACTION = 'Transaction',
  RENTAL = 'Rental',
  SALE = 'Sale',
}

type IEventHistoryProps = {
  listEventHistory: Record<string, any>[]
  checked: Record<string, any>[]
  handleClickSeeMore: (data: any) => void
  event: Record<string, any>
  handleSelectEvent?: any
} & BoxProps

const EventHistory = ({
  listEventHistory,
  handleSelectEvent,
  ...other
}: IEventHistoryProps) => {
  const [data, setData]: any = useState([])

  useEffect(() => {
    setData([...listEventHistory.slice(0, 10)])
  }, [listEventHistory])

  const next = async (direction: ScrollDirection) => {
    if (direction === 'down' && data?.length < listEventHistory.length) {
      setData([
        ...data,
        ...listEventHistory.slice(data.length, data.length + 10),
      ])
    }
  }

  const ref: any = useInfiniteScroll({
    next,
    rowCount: data.length,
    hasMore: { down: true },
  })

  const renderTimelineItem = (event: any, indexEvent: number) => {
    if (event?.type === EVENT_HISTORY_CATEGORY.FAO) {
      return (
        <TimelineItem key={indexEvent}>
          <TimelineOppositeContent
            sx={{
              m: 'auto 0',
              fontSize: '1 rem',
              fontWeight: 500,
            }}
          >
            {event?.inquiry_start_date
              ? format(new Date(event?.inquiry_start_date), 'dd.LL.yyyy')
              : ''}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              style={{
                backgroundColor: 'rgba(0, 173, 230, 1)',
              }}
            >
              <FaoIconV2 width={14} height={14} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              py: '12px',
              px: 2,
            }}
          >
            <FaoHistoryCard
              fao={event}
              onClick={() => {
                handleSelectEvent(event)
              }}
            />
          </TimelineContent>
        </TimelineItem>
      )
    }

    if (event?.type === EVENT_HISTORY_CATEGORY.TRANSACTION) {
      return (
        <TimelineItem key={indexEvent}>
          <TimelineOppositeContent
            sx={{
              m: 'auto 0',
              fontSize: '1 rem',
              fontWeight: 500,
            }}
          >
            {event?.date ? format(new Date(event?.date), 'dd.LL.yyyy') : ''}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              style={{
                backgroundColor: 'rgba(0, 173, 230, 0.7)',
              }}
            >
              <TransactionsIcon width={16} height={16} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              py: '12px',
              px: 2,
            }}
          >
            <TransactionHistoryCard
              transaction={event as any}
              onClick={() => {
                handleSelectEvent(event)
              }}
            />
          </TimelineContent>
        </TimelineItem>
      )
    }

    if (
      event?.type === EVENT_HISTORY_CATEGORY.RENTAL ||
      event?.type === EVENT_HISTORY_CATEGORY.SALE
    ) {
      return (
        <TimelineItem key={indexEvent}>
          <TimelineOppositeContent
            sx={{
              m: 'auto 0',
              fontSize: '1 rem',
              fontWeight: 500,
            }}
          >
            {event?.listing_timestamp
              ? format(new Date(event?.listing_timestamp), 'dd.LL.yyyy')
              : ''}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              style={{
                backgroundColor: 'rgba(0, 173, 230, 0.3)',
              }}
            >
              <ComparableIcon width={16} height={16} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              py: '12px',
              px: 2,
            }}
          >
            <ListingHistoryCard
              listing={event}
              onClick={() => {
                handleSelectEvent(event)
              }}
            />
          </TimelineContent>
        </TimelineItem>
      )
    }

    return null
  }

  return (
    <Box width="100%" {...other}>
      <Box>
        <StyledEngineProvider>
          <Timeline
            id="event-history"
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.1,
              },
              padding: '0 0 12px 0',
            }}
          >
            <div
              ref={ref}
              className="List"
              style={{
                overflowY: 'auto',
                maxHeight: '66vh',
              }}
            >
              {[...data].map((event: any, indexEvent: number) =>
                renderTimelineItem(event, indexEvent),
              )}
              {!(data?.length >= listEventHistory.length) && (
                <Box
                  sx={{
                    width: '100%',
                    mt: 1.5,
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      width: '16%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{
                        fontSize: '1rem',
                        borderRadius: 2,
                        height: '15px',
                        width: '80px',
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{
                          fontSize: '0.8rem',
                          borderRadius: 2,
                          height: '15px',
                          width: '4px',
                        }}
                      />
                      <Skeleton variant="circular" width={30} height={30} />
                      <Skeleton
                        variant="rounded"
                        sx={{
                          fontSize: '0.8rem',
                          borderRadius: 2,
                          height: '15px',
                          width: '4px',
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '84.5%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: 2,
                    }}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{
                        fontSize: '1rem',
                        borderRadius: 2,
                        height: '50px',
                        width: '100%',
                      }}
                    />
                  </Box>
                </Box>
              )}
            </div>
          </Timeline>
        </StyledEngineProvider>
      </Box>
    </Box>
  )
}

export default EventHistory
