/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { fromLocalStorage, getMapDisplayState } from '../../../../utils'

const initialState = {
  thematic: fromLocalStorage('thematic', true) || null,
  mapDisplay: getMapDisplayState()?.landThematic || 'development_score',
  buildingMapDisplay:
    getMapDisplayState()?.buildingLandThematic || 'popety_classification',
}

const legend = createSlice({
  name: 'legend',
  initialState,
  reducers: {
    clearPreview: (state) => {
      state.preview = undefined
    },
  },
})

export const legendReducer = {
  developerMapLegend: legend.reducer,
}

export const { clearPreview } = legend.actions
