import { useI18n } from '@popety_io/popety-io-lib'
import { Fragment } from 'react'

import OkButton from '@mui/icons-material/CheckCircleOutline'
import NotOkButton from '@mui/icons-material/RemoveCircleOutline'
import Grid from '@mui/material/Grid'

import LandRadar from '../../../../../components/LandRadar/LandRadar'

import { GridContainer } from './LandSummatyTitle.style'

export type LandSummaryTitleProps = {
  land: any
}

const LandSummaryTitle = ({ land }: LandSummaryTitleProps) => {
  const { t } = useI18n()

  const summary = [
    {
      label: t('land.zone.number'),
      value: `${land?.city_name || ''} ${land?.code_number || ''}`,
    },
    {
      label: t('land.devScore.area'),
      value:
        land?.official_area || land?.area
          ? `${Number(land?.official_area || land?.area)} m2`
          : '-',
    },
    {
      label: t('land.underExploited.buildingArea'),
      value: (
        <>
          {land?.building_area ? (
            <OkButton fontSize="small" sx={{ color: '#8ed1a5' }} />
          ) : (
            <NotOkButton fontSize="small" sx={{ color: 'red' }} />
          )}
        </>
      ),
    },
  ]

  return (
    <GridContainer container justifyContent="space-between" gap="10px">
      <Grid
        item
        container
        xs={4}
        sx={{
          '@media (max-width: 638px)': {
            display: 'none',
          },
        }}
      >
        <LandRadar land={land} modal={false} />
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        sm={5}
        flex={1}
      >
        {summary.map((item) => (
          <Grid
            key={item.label as string}
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Grid className="label" item style={{ color: '#969696' }} xs={5}>
              {item.label}
            </Grid>
            <Grid item xs={7}>
              {item.value}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </GridContainer>
  )
}

export default LandSummaryTitle
