import React from 'react'

interface ExcelIconProps {
  width?: string
  height?: string
  color?: string
}

const ExcelIcon: React.FC<ExcelIconProps> = ({
  width = '8.99824253px',
  height = '12px',
  color = '#00ADE6',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8.99824253 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="popetyio_apps_v3"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="files"
          transform="translate(-25, -65)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="remindercard" transform="translate(12, 58)">
            <g id="Group" transform="translate(13, 7)">
              <path
                d="M8.66783831,2.29642648 L6.70181605,0.330404218 C6.49091974,0.119507909 6.20503808,-1.33330826e-16 5.90743995,-1.33330826e-16 L1.12478032,-1.33330826e-16 C0.50380785,0.00234329233 0,0.506151142 0,1.12712361 L0,10.8752197 C0,11.4961921 0.50380785,12 1.12478032,12 L7.87346221,12 C8.49443468,12 8.99824253,11.4961921 8.99824253,10.8752197 L8.99824253,3.09314587 C8.99824253,2.79554774 8.87873462,2.50732279 8.66783831,2.29642648 Z M7.78207381,3.00175747 L5.99882835,3.00175747 L5.99882835,1.21851201 L7.78207381,3.00175747 Z M1.12478032,10.8752197 L1.12478032,1.12712361 L4.87404804,1.12712361 L4.87404804,3.56414763 C4.87404804,3.87580551 5.12478032,4.12653779 5.4364382,4.12653779 L7.87346221,4.12653779 L7.87346221,10.8752197 L1.12478032,10.8752197 Z M6.09256005,5.2513181 L5.41769186,5.2513181 C5.31458699,5.2513181 5.2208553,5.30755712 5.17164616,5.39894552 C4.74985354,6.17457528 4.65143527,6.39250146 4.50146456,6.75102519 C4.17574692,6.06912712 4.33977739,6.34563562 3.83128295,5.39894552 C3.78207381,5.30755712 3.68599883,5.2513181 3.58289397,5.2513181 L2.90568248,5.2513181 C2.6877563,5.2513181 2.55418864,5.48564733 2.66198008,5.67311072 L3.74692443,7.50087873 L2.66198008,9.32864675 C2.55184534,9.51611013 2.6877563,9.75043937 2.90568248,9.75043937 L3.58289397,9.75043937 C3.68599883,9.75043937 3.77973052,9.69420035 3.82893966,9.60281195 C4.33743409,8.66549502 4.3678969,8.5483304 4.49912127,8.25073228 C4.84827182,8.95840656 4.63737551,8.62331576 5.16930287,9.60281195 C5.21851201,9.69420035 5.31458699,9.75043937 5.41769186,9.75043937 L6.09256005,9.75043937 C6.31048623,9.75043937 6.4440539,9.51611013 6.33626245,9.32864675 L5.24897481,7.50087873 C5.26537786,7.47510252 5.95899238,6.31751611 6.33391916,5.67311072 C6.4440539,5.48564733 6.30814294,5.2513181 6.09256005,5.2513181 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ExcelIcon
