import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box, { BoxProps } from '@mui/material/Box'

import {
  fetchByBuildingId,
  buildingTagSelector,
} from '../../pages/DeveloperMap/redux'
import PreviewBuildingTagChip from './PreviewBuildingTagChip'

type TagChipsProps = BoxProps & {
  buildingId: string
}

const PreviewBuildingTagsChips = ({ buildingId }: TagChipsProps) => {
  const dispatch = useDispatch()
  const tags = useSelector(buildingTagSelector)

  useEffect(() => {
    dispatch(fetchByBuildingId(buildingId) as any)
  }, [buildingId])

  if (!buildingId || !tags?.length) return null

  return (
    <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
      <PreviewBuildingTagChip tags={tags} buildingIds={buildingId} />
    </Box>
  )
}

export default PreviewBuildingTagsChips
