import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { BoxProps } from '@mui/material/Box'
import { useMediaQuery } from '@popety_io/popety-io-lib'

const isValidUrl =
  // eslint-disable-next-line max-len
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/

const RestrictionItemValue = ({ children }: BoxProps) => {
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  if (typeof children !== 'string') return children as any
  const regex = /href="([^"]+)"/
  const isHref = children.match(regex)
  const newChildren = isHref ? isHref[1] : children

  return (
    <>
      {newChildren.match(isValidUrl) ? (
        <>
          <a href={newChildren} target="_blank" rel="noopener noreferrer">
            {onDesktop ? newChildren : <OpenInNewIcon />}
          </a>
        </>
      ) : (
        newChildren
      )}
    </>
  )
}

export default RestrictionItemValue
