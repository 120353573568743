import { useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import {
  Map,
  useMediaQuery,
  useRouter,
  formatPrice,
} from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { estimateSelector } from '../../../redux'
import { getPricingTiles, removePricingTiles } from './utils'

const fillStyle = 'layer_fill_pricing'

interface EstimateMapControllerProps {
  map: Map
  loaded?: boolean
}

const EstimateMapController = ({ map, loaded }: EstimateMapControllerProps) => {
  const estimate: any = useSelector(estimateSelector)
  const { query } = useRouter()

  const [popup, setPopup] = useState<mapboxgl.Popup>()
  const isSummaryTab =
    query.tab === 'summary' || query.tab === 'market' || !query.tab
  const touchScreen = useMediaQuery('(hover: none)')

  useEffect(() => {
    if (touchScreen) setPopup(undefined)
  }, [touchScreen])

  useEffect(() => {
    setPopup(
      new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
    )
  }, [])

  useEffect(() => {
    if (!isSummaryTab && map && loaded) {
      removePricingTiles({ map })
    }
  }, [query.tab])

  const addTiles = () => {
    try {
      getPricingTiles({
        map,
        indexName: query.summaryLayer,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleMouseMove = (e: any) => {
    map.getCanvas().style.cursor = 'pointer'
    if (popup) {
      const feature = e?.features?.[0]

      if (!feature) return

      const str = `${formatPrice(
        Math.round(feature.properties['avg_price_m2.value']),
      )}/m2`

      popup.setLngLat(e.lngLat).setHTML(str).addTo(map)
    }
  }

  const handleMouseLeave = () => {
    map.getCanvas().style.cursor = ''

    if (popup) {
      popup.remove()
    }
  }

  useEffect(() => {
    if (!isSummaryTab || !map || !loaded) return

    addTiles()

    map.on('style.load', addTiles)
    map.on('mousemove', fillStyle, handleMouseMove)
    map.on('mouseleave', fillStyle, handleMouseLeave)

    return () => {
      map.off('style.load', addTiles)
      map.on('mousemove', fillStyle, handleMouseMove)
      map.on('mouseleave', fillStyle, handleMouseLeave)
    }
  }, [isSummaryTab, loaded, estimate, query.summaryLayer])

  if (!isSummaryTab || !map || !loaded) return null

  return null
}

export default EstimateMapController
