import Accordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'

export const HistoryAccordionRoot = styled(Accordion)`
  width: 100%;

  .AccordionSummary-gutters {
    padding: 0;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;
    gap: 24px;
    @media (max-width: 991px) {
      gap: 10px;
    }
  }

  .MuiAccordionSummary-content {
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  .MuiAccordionDetails-root {
    position: relative;

    > .SourceLink {
      position: absolute;
      top: -16px;
      right: 16px;
      z-index: 2;
    }
  }
`
