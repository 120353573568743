import { ApiClient } from '../../../../services'
import { livabilityDistance } from '../../../../config'

const client = new ApiClient()

type GetLivabilityOpts = {
  landId?: string | number | string[] | number[]
  size?: number
}

const getPoiCategories = async ({ landId }: GetLivabilityOpts) => {
  return client.get(`/land/${landId}/livability`)
}

const getPoisAround = async ({ landId }: GetLivabilityOpts) => {
  return client.get(`/land/${landId}/livability/${livabilityDistance}`)
}

const livabilityService = {
  getPoisAround,
  getPoiCategories,
}

export default livabilityService
