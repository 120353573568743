import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const getTagLands = async ({
  isShared,
  isMap,
}: {
  isShared: boolean
  isMap?: boolean
}) => {
  let request = `/dashboard/tags/lands?preview=true&all=${isShared}`

  request += isMap ? `&userId=${getUserId()}` : ''

  return client.get(request)
}

const getDashboardTagLands = async (search = '') => {
  return client.get(`/dashboard/tags/lands${search}&active=true`)
}

const addTag = async (tags: any) => {
  return client.post(`/dashboard/tags/lands/add`, { tags })
}

const updateTag = async (data: any) => {
  const response = await client.put(`/dashboard/tags/${data.tag_id}`, data)

  if (response.data) {
    response.data = { ...response.data, ...data }
  }

  return response
}

const removeLandTag = async ({
  tagLandId,
  landId,
}: {
  tagLandId: string
  landId: string
}) => {
  const data = await client.delete(`/dashboard/land/tags/${tagLandId}`)

  return { ...data, landId }
}

const deleteTag = async (tagId: string) => {
  return client.delete(`/dashboard/tags/${tagId}`)
}

const isTagLand = (landId: string) => {
  return client.get(`/dashboard/tags/lands/${landId}`)
}

const getAllTagsName = async () => {
  return client.get(`/dashboard/tags/all`)
}

const updateLandTag = async ({
  tag,
  tagLandId,
}: {
  tag: {
    tag_id: string
    primary?: boolean
    secondary?: boolean
    land_id?: string
  }
  tagLandId: string
}) => {
  return client.put(`/dashboard/land/tags/${tag.tag_id}/${tagLandId}`, {
    primary: tag?.primary,
    secondary: tag?.secondary,
    land_id: tag?.land_id,
  })
}

const tagService = {
  getTagLands,
  isTagLand,
  removeLandTag,
  addTag,
  updateTag,
  deleteTag,
  getDashboardTagLands,
  getAllTagsName,
  updateLandTag,
}

export default tagService
