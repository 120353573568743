import { mapBy } from '@popety_io/popety-io-lib'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import estimateActionHistoryService from './estimateNotesService'

const initialState = {
  estimateActionHistory: {},
}

export const getEstimateActionHistory = createAsyncThunk(
  'estimateActionHistory/getEstimateActionHistory',
  estimateActionHistoryService.getEstimateActionHistory,
)

export const addEstimateNote = createAsyncThunk(
  'estimateActionHistory/addEstimateNote',
  estimateActionHistoryService.addEstimateNote,
)

export const removeEstimateNote = createAsyncThunk(
  'estimateActionHistory/removeEstimateNote',
  estimateActionHistoryService.removeEstimateNote,
)

export const updateEstimateNote = createAsyncThunk(
  'estimateActionHistory/updateEstimateNote',
  estimateActionHistoryService.updateEstimateNote,
)

const estimateActionHistory = createSlice({
  name: 'estimateActionHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getEstimateActionHistory.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { data = [] } = action.payload || {}

          state.estimateActionHistory = {
            ...state.estimateActionHistory,
            ...mapBy(
              'uid',
              data.map((r: any) => ({
                ...r,
                uid: `${r.action}_${r.note_id || r.id}`,
              })),
            ),
          }
        },
      )
      .addCase(
        addEstimateNote.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const { data = [] } = action.payload || {}

          if (data) {
            const note = data[0]

            if (note) {
              state.estimateActionHistory[
                `${note.action || 'note'}_${note.note_id || note.id}`
              ] = note
            }
          }
        },
      )
      .addCase(
        removeEstimateNote.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const { noteId } = action.payload || {}

          if (noteId) {
            delete state.estimateActionHistory[`note_${noteId}`]
          }
        },
      )
      .addCase(
        updateEstimateNote.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          const value = action.payload || {}

          if (value) {
            state.estimateActionHistory[`note_${value.note_id}`] = {
              ...state.estimateActionHistory[`note_${value.note_id}`],
              ...value,
            }
          }
        },
      )
  },
})

export const estimateActionHistoryReducer = {
  estimateActionHistory: estimateActionHistory.reducer,
}
