import Box from '@mui/material/Box'
import { TagColor, tagColors } from '@popety_io/popety-io-lib'
import { ChangeEvent, useRef } from 'react'

type TagColorProps = {
  value?: string
  onChange: (val: string) => void
}

const TagColorSelect = ({ onChange, value }: TagColorProps) => {
  const colorInput = useRef<any>()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const openColor = () => {
    colorInput.current.click()
  }

  return (
    <Box display="flex" alignItems="center" gap="9px" pt={2} flexWrap="wrap">
      {tagColors.map((color) => (
        <TagColor
          color={color}
          checked={value === color}
          onChange={onChange}
          key={color}
        />
      ))}

      <Box
        onClick={openColor}
        position="relative"
        sx={{
          width: '36px',
          height: '36px',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        <Box
          component="img"
          src="/assets/color.png"
          alt={`custom color: ${value}`}
          position="absolute"
          onClick={openColor}
          sx={{
            width: '36px',
            height: '36px',
            borderRadius: '4px',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            transform: 'scale(1.2)',
          }}
        />
        <Box
          color={value || '#00ade6'}
          component="input"
          type="color"
          name="color"
          value={value || '#00ade6'}
          onChange={handleChange}
          ref={colorInput}
          position="absolute"
          sx={{
            width: '36px',
            height: '36px',
            borderRadius: '4px',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            opacity: 0,
          }}
        />
      </Box>
    </Box>
  )
}

export default TagColorSelect
