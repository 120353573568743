import { mapBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import buildingService from './buildingService'

const initialState = {
  buildingsById: {},
  loading: false,
}

export const getBuildings = createAsyncThunk(
  'building/getBuildings',
  buildingService.getBuildings,
)

export const addOrUpdateOverridedBuildings = createAsyncThunk(
  'building/addOrUpdateOverridedBuildings',
  buildingService.addOrUpdateOverridedBuildings,
)

const building = createSlice({
  name: 'building',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuildings.pending, (state) => {
        state.loading = true
      })
      .addCase(getBuildings.rejected, (state) => {
        state.loading = false
      })
      .addCase(getBuildings.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}
        const input = action.meta.arg

        state.buildingsById = mapBy('id', data)

        input.buildings.forEach((building) => {
          state.buildingsById[building.id] = {
            ...building,
            ...state.buildingsById[building.id],
          }
        })

        state.loading = false
      })
      .addCase(addOrUpdateOverridedBuildings.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const input = action.meta.arg

        if (data) {
          state.buildingsById[input.buildingId] = {
            ...state.buildingsById[input.buildingId],
            ...input.data,
            ...data,
          }
        }
      })
  },
})

export const buildingReducer = {
  building: building.reducer,
}
