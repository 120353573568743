import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

export const RestrictionAffectedContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  margin-top: 16px;
`

export const ListItemTitle = styled(Grid)`
  word-break: break-word;
  padding: 4px;
  color: #9095a2;

  @media (min-width: 1200px) {
    max-width: 30%;
    flex-basis: 30%;
  }
`

export const ListItemDescription = styled(Grid)`
  word-break: break-all;
  padding: 4px;

  @media (min-width: 1200px) {
    max-width: 60%;
    flex-basis: 60%;
  }
`

export const ListContainer = styled(Grid)`
  @media (max-width: 1490px) {
    flex-direction: column;
  }
`

export const StyledDivider = styled(Divider)`
  margin: 1rem -12px !important;
`
