import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getReminderLands = async (search = '') => {
  return client.get(`/dashboard/lands/reminders/${search}`)
}

const reminderService = {
  getReminderLands,
}

export default reminderService
