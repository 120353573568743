import { ApiClient } from '../../../../services'
import { IOfsLayer } from './basemapInterface'

const client = new ApiClient()

const getOfsLayers = async (): Promise<{ data: IOfsLayer[] }> => {
  return client.get('/company/ofs/layers')
}

const basemapService = {
  getOfsLayers,
}

export default basemapService
