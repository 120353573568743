import { lazy } from 'react'

// LOGIN
const Login = lazy(() => import('../pages/Login'))

// 2FA
const TwoFactorAuth = lazy(() => import('../pages/TwoFactorAuth'))

// PASSWORD RESET
const PasswordReset = lazy(() => import('../pages/PasswordReset'))

// TRANSACTION HISTORY
const TransactionHistory = lazy(() => import('../pages/TransactionHistory'))

// FAO HISTORY
const FaoHistory = lazy(() => import('../pages/FaoHistory'))

// LAND DETAILS
const LandDetails = lazy(() => import('../pages/LandDetails'))

// Building Details
const BuildingDetails = lazy(() => import('../pages/BuildingDetails'))

// ERROR
const ErrorPage = lazy(() => import('../pages/ErrorPage'))

// WIP
const DeveloperMap = lazy(() => import('../pages/DeveloperMap'))

const DeveloperTable = lazy(
  () => import('../pages/DeveloperMap/DeveloperTable'),
)

// ACCOUNT
const Account = lazy(() => import('../pages/Account'))

const Dashboard = lazy(() => import('../pages/Dashboard'))
const BuildingDashboard = lazy(() => import('../pages/BuildingDashboard'))
const Glossary = lazy(() => import('../pages/Glossary'))
const Alerts = lazy(() => import('../pages/Alerts'))
const CompanyDashboard = lazy(() => import('../pages/CompanyDashboard'))
const ListingHistory = lazy(() => import('../pages/ListingHistory'))
const Support = lazy(() => import('../pages/Support'))

// E-LEARNING
const Elearning = lazy(() => import('../pages/Elearning'))

// ESTIMATE
const Estimate = lazy(() => import('../pages/Estimate'))

/**
 * navigations
 */
const navigations = [
  { path: '/login', page: <Login /> },
  { path: '/2fa', page: <TwoFactorAuth /> },
  { path: '/password-reset', page: <PasswordReset /> },
  { path: '/', private: true, page: <DeveloperMap /> },
  { path: '/fao-history', private: true, page: <FaoHistory /> },
  { path: '/transaction-history', private: true, page: <TransactionHistory /> },
  {
    path: '/land-detail/plot',
    landdev: true,
    private: true,
    page: <LandDetails />,
  },
  {
    path: '/land-detail/building',
    landdev: true,
    private: true,
    page: <BuildingDetails />,
  },
  { path: '/developer-map', private: true, page: <DeveloperMap /> },
  { path: '/developer-map/list', private: true, page: <DeveloperTable /> },
  { path: '/help', private: false, page: <Support /> },
  { path: '/user-guide', private: false, page: <Support /> },
  { path: '/sources', private: false, page: <Support /> },
  { path: '/fao-history', private: true, page: <FaoHistory /> },
  { path: '/listing-history', private: true, page: <ListingHistory /> },
  { path: '/glossary', private: false, page: <Glossary /> },
  { path: '/my-alerts', private: true, page: <Alerts /> },
  {
    path: '/company-dashboard',
    private: true,
    admin: true,
    page: <CompanyDashboard />,
  },
  { path: '/account', private: true, page: <Account /> },
  { path: '/dashboard', private: true, page: <Dashboard /> },
  { path: '/building-dashboard', private: true, page: <BuildingDashboard /> },
  { path: '/e-learning', private: true, page: <Elearning /> },
  { path: '/estimate', private: true, estimate: true, page: <Estimate /> },
  { path: '*', page: <ErrorPage status="404" /> },
]

export { navigations }
