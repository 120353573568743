import Box from '@mui/material/Box'
import {
  useRouter,
  useI18n,
  useMediaQuery,
  PrintIcon,
  PopetyMaps,
} from '@popety_io/popety-io-lib'
import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MAPBOX_TOKEN, STYLE_PREFIX, DEFAULT_MAPSTYLE } from '../../../config'
import { transformTileRequest } from '../../../utils'
import {
  groupLandAnalysisHistorySelector,
  landGeoCenterSelector,
  searchHistorySelector,
} from '../redux'
import ControlMapStyleSwitch from '../Summary/ControlMapStyleSwitch'
import { buildOptions } from '../../DeveloperMap/components/Basemap/basemap.utils'
import { changeMapStyle, mapStyleSelector } from '../../DeveloperMap/redux'
import GeolocMapControls from '../../../components/MapControl/GeolocMapControls'
import { getHistoryBounds } from '../../Estimate/components/EstimateHistory/utils'
import { MapConfigByPage, MapControllers } from './LandDetailsMap.config'
import { useLandDetailsMapLayers } from './LandDetailsMap.layers'
import { getBbox } from './utils'
import DrawPolygonController from './DrawPolygonController'

export type LandDetailsMapProps = {
  fullscreen?: boolean
}

interface Land {
  archived: boolean
}

const LandDetailsMap = ({ fullscreen }: LandDetailsMapProps) => {
  const landHistory = useSelector(searchHistorySelector)
  const groupLandHistory = useSelector(groupLandAnalysisHistorySelector)
  const geoCenter = useSelector(landGeoCenterSelector)
  const [zoom, setZoom] = useState(18)
  const dispatch = useDispatch()

  const { query, updateQuery } = useRouter()
  const isGrouping = query?.tab === 'grouping'
  const isHomePage = query?.tab === 'home' || isGrouping
  const isArchived = query?.archived === 'true'
  const isActived = query?.active === 'true'

  const selectedHistory = useMemo(
    () => (isGrouping ? groupLandHistory || [] : landHistory || []),
    [isGrouping, landHistory, groupLandHistory],
  )

  const history = useMemo(() => {
    if (!isActived && !isArchived) {
      return []
    }

    return selectedHistory.filter(
      (land: Land) =>
        (isActived && !land.archived) || (isArchived && land.archived),
    )
  }, [selectedHistory, isActived, isArchived])

  const { t } = useI18n()

  const MapConfig = useMemo(
    () => MapConfigByPage[query.subTab || query.tab],
    [query.subTab, query.tab],
  )

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (query.zoom) {
      setZoom(Number(query.zoom))
    }
  }, [query.zoom])

  useEffect(() => {
    updateQuery({ zoom })
  }, [])

  const { data } = useLandDetailsMapLayers()
  const mapStyle = useSelector(mapStyleSelector)

  const minHeight = fullscreen
    ? 'calc(100vh - 160px)'
    : { xs: '300px', md: '40vh' }

  const bbox = useMemo(() => {
    return isHomePage && history?.length
      ? getHistoryBounds(history)
      : getBbox(data, query, geoCenter)
  }, [query, geoCenter, data, history])

  const mapProps: any = {
    zoom,
    bearing: 0,
    bearingSnap: 0,
    dragPan: true,
    dragRotate: true,
    maxZoom: 24,
    minZoom: 0,
    preserveDrawingBuffer: true,
    transformRequest: transformTileRequest as any,
    bbox,
  }

  const options = buildOptions(t, mapStyle)

  const mapConfigStyle = useMemo(() => {
    const style = mapStyle.startsWith(STYLE_PREFIX)
      ? mapStyle.substring(STYLE_PREFIX.length)
      : mapStyle

    return `${STYLE_PREFIX}${style}`
  }, [mapStyle])

  useEffect(() => {
    dispatch(changeMapStyle(MapConfig?.style || DEFAULT_MAPSTYLE))
  }, [MapConfig?.style, dispatch, query.tab, query.subTab])

  return (
    <Box height="100%" minHeight={minHeight}>
      <PopetyMaps
        dimension="3D"
        token={MAPBOX_TOKEN}
        data={data}
        center={geoCenter}
        isDragPan
        Hover={MapConfig?.Hover}
        ClickComponent={MapConfig?.Click}
        style={mapConfigStyle}
        Controller={isDesktop ? DrawPolygonController : undefined}
        {...mapProps}
        sx={{
          minHeight,
          maxHeight: fullscreen
            ? '100%'
            : { xs: 'calc(100% - 106px)', lg: '100%' },
          overflow: 'hidden',
        }}
        zoom={zoom}
        ofsYear={query?.historyLayer ? query.historyLayer : undefined}
      >
        <GeolocMapControls center={geoCenter} zoom={zoom} />
        {MapConfig?.Content && <MapConfig.Content />}
        <MapControllers />
        <PrintIcon name={t('common.myAnalysis.fileName.details')} />
        <ControlMapStyleSwitch options={options.slice(0, 2)} />
      </PopetyMaps>
    </Box>
  )
}

export default memo(LandDetailsMap)
