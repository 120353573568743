import Box, { BoxProps } from '@mui/material/Box'

import useBuildingTag from './useBuildingTag'

import PreviewBuildingTagChip from './PreviewBuildingTagChip'

type TagChipsProps = BoxProps & {
  buildingIds: string | string[]
}

const BuildingTagsChips = ({ buildingIds, ...other }: TagChipsProps) => {
  const { activeBuildingTags, sanitizedBuildingIds } = useBuildingTag({
    buildingIds,
    fetch: true,
  })

  if (!activeBuildingTags?.length) return null

  return (
    <Box {...other} display="flex" alignItems="center" gap="8px">
      <PreviewBuildingTagChip
        tags={activeBuildingTags}
        buildingIds={sanitizedBuildingIds}
      />
    </Box>
  )
}

export default BuildingTagsChips
