import { Map } from '@popety_io/popety-io-lib'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { geoCenterSelector, sunDateSelector } from '../../redux'
import { drawLines, LINES } from './SunDirection.util'

type SunDirectionProps = {
  map: Map
}

const SunDirection = ({ map }: SunDirectionProps) => {
  const sunDate = useSelector(sunDateSelector)
  const landCenter = useSelector(geoCenterSelector)
  const [center, setCenter] = useState(
    landCenter ? { ...landCenter, lng: landCenter.lon } : map.getCenter(),
  )

  const { lat, lng } = center

  useEffect(() => {
    map.on('move', () => {
      setCenter({ ...map.getCenter() })
    })
  }, [])

  const removeLineGeo = () => {
    try {
      for (const line of LINES) {
        if (map.getLayer(line.id)) {
          map.removeLayer(line.id)
        }
        if (map.getSource(line.id)) {
          map.removeSource(line.id)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const addLinesGeo = () => {
    try {
      removeLineGeo()

      LINES.forEach((line) => {
        map.addSource(line.id, {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [] },
        })

        map.addLayer({
          id: line.id,
          source: line.id,
          type: 'line',
          paint: {
            'line-color': line.color,
            'line-width': 5,
            'line-opacity': 0.85,
          },
        })
      })

      drawLines(map, lng, lat, new Date(sunDate))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    addLinesGeo()
    map.on('style.load', addLinesGeo)

    return () => {
      map.off('style.load', addLinesGeo)
    }
  }, [])

  useEffect(() => {
    drawLines(map, lng, lat, new Date(sunDate))
  }, [sunDate, center])

  return null
}

export default SunDirection
