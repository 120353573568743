/* eslint-disable max-lines */
import { lazy } from 'react'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HistoryIcon from '@mui/icons-material/History'
import {
  ComparableIcon,
  ConfigurationIcon,
  MarcheIcon,
  SummaryIcon,
  BuildingIcon2,
  PlotIconV2,
  TransactionsIcon,
  EnergyIcon,
  SunlightIconV2,
  AmenitiesIcon,
  FaoIconV2,
} from '@popety_io/popety-io-lib'

const Livability = lazy(() => import('../../../LandDetails/Livability'))
const Fao = lazy(() => import('../../../LandDetails/Fao'))
const EstimateTransactions = lazy(() => import('../EstimateTransactions'))
const EstimateSunlight = lazy(() => import('../EstimateSunlight'))
const EstimateLandSummary = lazy(() => import('../EstimateLandSummary'))
const EstimateEnergy = lazy(() => import('../EstimateEnergy'))
const Configuration = lazy(() => import('../EstimateConfiguration'))
const EstimateSummary = lazy(() => import('../EstimateSummary'))
const EstimateComparables = lazy(() => import('../EstimateComparables'))
const EstimateMarket = lazy(() => import('../EstimateMarket'))
const EstimateNoise = lazy(() => import('../EstimateNoise'))
const EstimateHistory = lazy(() => import('../EstimateHistory'))
const EstimateEventHistory = lazy(() => import('../EstimateEventHistory'))

const getEstimateMenuData = (
  estimate: any,
  isHomePage: boolean,
  t: Translator,
) => {
  const pages = !isHomePage
    ? [
        {
          icon: <SummaryIcon />,
          key: 'summary',
          text: t('building.summary'),
          page: <EstimateSummary />,
          show: true,
        },
        {
          icon: (
            <HistoryIcon
              viewBox="2 2 20 20"
              sx={{
                width: '30px',
                height: '30px',
              }}
            />
          ),
          key: 'history',
          text: t('common.historique'),
          page: <EstimateEventHistory />,
          show: true,
        },
        {
          icon: <ComparableIcon />,
          key: 'listing',
          text: t('common.comparables'),
          page: <EstimateComparables />,
          show: true,
        },
        estimate?.region_name === 'Genève' && {
          icon: <TransactionsIcon />,
          key: 'transaction',
          text: t('land.transaction'),
          page: <EstimateTransactions />,
          show: estimate?.region_name === 'Genève',
        },
        {
          icon: <MarcheIcon />,
          key: 'market',
          text: t('common.Market'),
          page: <EstimateMarket />,
          show: true,
        },
        {
          icon: <ConfigurationIcon />,
          key: 'configuration',
          text: t('building.configuration'),
          page: <div />,
          subMenus: [
            {
              icon: <BuildingIcon2 />,
              key: 'building',
              text: t('common.buildings'),
              page: <Configuration />,
              show: true,
            },
            {
              icon: <PlotIconV2 />,
              key: 'parcelle',
              text: t('building.info.parcelle'),
              page: <EstimateLandSummary />,
              show: true,
            },
          ],
          show: true,
        },
        {
          icon: <EnergyIcon />,
          key: 'energy',
          text: t('common.search.details.energy'),
          page: <EstimateEnergy />,
          show: true,
        },
        {
          icon: <CrisisAlertIcon />,
          key: 'enviroment',
          text: t('building.environment'),
          page: <div />,
          subMenus: [
            // {
            //   icon: <AirIcon />,
            //   key: 'airQuality',
            //   text: t('common.air_quality'),
            //   page: <EstimateAirQuality />,
            //   show: true,
            // },
            {
              icon: <AmenitiesIcon />,
              key: 'livability',
              text: t('common.amenities'),
              page: <Livability isEstimate />,
              show: true,
            },
            {
              icon: <VolumeUpIcon />,
              key: 'noise',
              text: t('common.noise'),
              page: <EstimateNoise />,
              show: true,
            },
            {
              icon: <SunlightIconV2 />,
              key: 'sunlight',
              text: t('building.sunlight'),
              page: <EstimateSunlight />,
              show: true,
            },
          ],
          show: true,
        },
        {
          icon: <FaoIconV2 />,
          key: 'fao',
          text: t('land.fao'),
          page: <Fao show={false} />,
          show: true,
        },
      ]
    : [
        {
          icon: <ErrorOutlineIcon />,
          key: 'home',
          text: t('common.Vente bien individuel'),
          page: <EstimateHistory />,
          show: true,
        },
      ]

  return pages.filter(Boolean)
}

export { getEstimateMenuData }
