/* eslint-disable max-lines */
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconBtton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { MapLegend, useI18n, useRouter, Select } from '@popety_io/popety-io-lib'
import LayersIcon from '../LayersIcon'
import { TitleRoot } from '../Legend/Legend.style'

type SelectorLegendProps = {
  legends: any
  value?: string
  keyValue: string
  legendValue: string
  options?: any
  description?: string
}

const SelectorLegend = ({
  legends,
  keyValue,
  legendValue,
  options,
  value,
  description,
}: SelectorLegendProps) => {
  const { t } = useI18n()

  const { updateQuery, query } = useRouter()

  useEffect(() => {
    return () => {
      updateQuery({ [keyValue]: undefined })
    }
  }, [])

  const handleDisplayLegend = (display: boolean) => {
    updateQuery({ [legendValue]: display })
  }

  return (
    <MapLegend
      defaultOpen
      title={t('common.layers')}
      id="noise-legend"
      top="16px"
      icon={LayersIcon}
      zIndex={1000}
      style={{
        width: 'auto',
        overflowX: 'hidden',
        maxWidth: '250px',
      }}
      right="16px"
      left="auto"
      padding="4px"
      className="print-legend"
    >
      <Box mb="4px">
        <Select
          fullWidth
          options={options}
          value={value}
          onChange={(e: any) => updateQuery({ [keyValue]: e?.value })}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: '#969696',
              fontSize: '0.9rem',
              fontWeight: 500,
              lineHeight: 1.66,
              textTransform: 'uppercase',
            }}
          >
            {t('land.livabilityScore.Legend')}
          </Typography>
        </Box>
        <Box>
          {query[legendValue] === 'false' && (
            <IconBtton onClick={() => handleDisplayLegend(true)}>
              <VisibilityIcon fontSize="small" />
            </IconBtton>
          )}
          {(!query[legendValue] || query[legendValue] === 'true') && (
            <IconBtton onClick={() => handleDisplayLegend(false)}>
              <VisibilityOffIcon fontSize="small" />
            </IconBtton>
          )}
        </Box>
      </Box>
      {(!query[legendValue] || query[legendValue] === 'true') && (
        <>
          {description && (
            <Box
              sx={{
                paddingX: '8px',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#041a25',
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: 1.66,
                }}
              >
                {description}
              </Typography>
            </Box>
          )}
          <Box
            sx={{ overflowY: 'auto', maxHeight: 500, padding: '8px' }}
            display="flex"
            flexDirection="column"
            gap="4px"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={1}
              sx={{
                flexDirection: 'column',
              }}
            >
              {legends?.map((item: any, i: number) => (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <TitleRoot color={item.color} />
                  <Tooltip title={item.value}>
                    <span>{item.value}</span>
                  </Tooltip>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
    </MapLegend>
  )
}

export default SelectorLegend
