import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import filesService from './filesService'

const initialState = {
  land: [],
  building: [],
  estimate: [],
  file: undefined,
  loading: false,
}

export const getFilesLands = createAsyncThunk(
  'files/getFilesLands',
  filesService.getFilesLands,
)

export const getFilesBuildings = createAsyncThunk(
  'files/getFilesBuildings',
  filesService.getFilesBuildings,
)

export const uploadFile = createAsyncThunk(
  'files/uploadFile',
  filesService.uploadFile,
)

export const removeFile = createAsyncThunk(
  'files/removeFile',
  filesService.removeFile,
)

export const getFilesEstimate = createAsyncThunk(
  'files/getFilesEstimate',
  filesService.getFilesEstimate,
)

export const getFile = createAsyncThunk('files/getFile', filesService.getFile)

const files = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFilesLands.fulfilled, (state, action: PayloadAction<any>) => {
        const { data = [] } = action.payload || {}

        if (data) {
          state.land = data.files
        }
      })
      .addCase(
        getFilesBuildings.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { data = [] } = action.payload || {}

          if (data) {
            state.building = data.files
          }
        },
      )
      .addCase(
        getFilesEstimate.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { data = [] } = action.payload || {}

          if (data) {
            state.estimate = data.files
          }
        },
      )
      .addCase(uploadFile.pending, (state, action) => {
        state.loading = true
        const { arg } = action.meta

        if (arg.type in state) {
          const pendingFiles = Array.from(arg.files).map((file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            isPending: true,
            id: `pending_${file.name}_${file.lastModified}`,
          }))

          ;(state as any)[arg.type] = [
            ...(state as any)[arg.type],
            ...pendingFiles,
          ]
        }
      })
      .addCase(uploadFile.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const { data = [] } = action.payload || []
        const { arg } = action.meta

        state.loading = false

        if (data && arg.type in state) {
          ;(state as any)[arg.type] = [
            ...(state as any)[arg.type].filter((file: any) => !file.isPending),
            ...data,
          ]
        }
      })
      .addCase(removeFile.fulfilled, (state, action) => {
        const { arg } = action.meta

        if (arg.type in state) {
          ;(state as any)[arg.type] = (state as any)[arg.type].filter(
            (file: any) => file.id !== arg.fileId,
          )
        }
      })
  },
})

export const filesReducer = {
  files: files.reducer,
}
