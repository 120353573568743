import { useState, ChangeEvent, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'

import { useDispatch } from 'react-redux'
import TagColorSelect from '../../../../components/Tag/TagColorSelect'
import { updateTag } from '../../redux/tags'

interface TagProps {
  value: string
  color: string
  tagId: string
}

const TagCardUpdate = ({ value, color, tagId }: TagProps) => {
  const { t } = useI18n()

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState<Record<string, any>>({
    value: '',
    color: '',
  })

  const dispatch = useDispatch<any>()

  const handleEdit = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleClick = () => {
    if (!values.value) return

    dispatch(
      updateTag({ tag_id: tagId, value: values.value, color: values.color }),
    )
    setOpen(false)
  }

  const handleColorSelect = (newVal: string) => {
    setValues({ ...values, color: newVal })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    setValues({ value, color })
  }, [tagId])

  return (
    <Box display="flex" justifyContent="center">
      <Tooltip placement="top" title={t('myTags.edit')}>
        <IconButton
          aria-label="edit"
          onClick={handleEdit}
          className="update-tag"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={onClose}
        okText={t('myTags.edit')}
        cancelText={t('common.cancel')}
        onOk={handleClick}
        onCancel={onClose}
        disableGutters
        showCloseIcon={false}
        responsive="sm"
        sx={{
          '.PreviewHeader': { borderBottom: '1px solid gainsboro' },
          '.OpenDetailsButton': { display: 'none' },
        }}
        title={t('myTags.update')}
      >
        <Box sx={{ p: 2 }}>
          {values?.value && (
            <Chip
              label={values?.value ? values.value : ''}
              sx={{
                backgroundColor: values?.color ? values.color : 'primary.main',
                color: 'white',
              }}
            />
          )}
          <TextField
            placeholder={t('common.enterTagName')}
            name="value"
            margin="normal"
            value={values?.value || ''}
            onChange={handleChange}
            size="small"
            fullWidth
            error={!values?.value}
            helperText={!values?.value ? t('common.tag_name_required') : ''}
            id="updateTag_account"
          />
          <TagColorSelect onChange={handleColorSelect} value={values.color} />
        </Box>
      </Modal>
    </Box>
  )
}

export default TagCardUpdate
