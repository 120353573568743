import Box from '@mui/material/Box'
import { Feature, format } from '@popety_io/popety-io-lib'

const FaoMapHover = ({ inquiry_start_date, work_type }: Feature) => {
  const text = [format(inquiry_start_date, 'P'), work_type]
    .filter(Boolean)
    .join(' - ')

  return (
    <Box maxWidth="300px" padding="4px" bgcolor="rgba(255, 255, 255, 0.8)">
      {text}
    </Box>
  )
}

export default FaoMapHover
