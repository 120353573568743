import { ApiClient } from '../../../../services'

const client = new ApiClient()

type GetLandBuildingsOpts = {
  landId: string | number | string[] | number[]
  buildings: Record<string, any>[]
}

const getBuildings = ({ landId, buildings }: GetLandBuildingsOpts) => {
  const buildingId = buildings?.map((building: any) => building.id)

  return client.get(`/land/${landId}/buildings/${buildingId}`)
}

type InactiveOverridedBuildingsOpts = {
  landId?: string | number | string[] | number[]
  overridedId: string
}

const inactiveOverridedBuildings = async ({
  landId,
  overridedId,
}: InactiveOverridedBuildingsOpts) => {
  return client.delete(`/land/${landId}/buildings/override/${overridedId}`)
}

type AddOrUpdateOverridedBuildingsOpts = {
  landId?: string | number | string[] | number[]
  buildingId: string
  data: Record<string, any>
  overridedId?: string
}

const addOrUpdateOverridedBuildings = async ({
  landId,
  buildingId,
  overridedId,
  data,
}: AddOrUpdateOverridedBuildingsOpts) => {
  const overridedFields = Object.keys(data).filter(
    (key) => key.endsWith('Overide') || key.endsWith('Override'),
  )

  const input: any = { overridedId }

  overridedFields.forEach((key) => {
    input[key] = data[key]
  })

  const overrided = overridedFields.some((key) => data[key])

  if (!overrided && overridedId) {
    return inactiveOverridedBuildings({ landId, overridedId })
  }

  return client.post(`/land/${landId}/buildings/${buildingId}`, input)
}

const buildingService = {
  getBuildings,
  addOrUpdateOverridedBuildings,
  inactiveOverridedBuildings,
}

export default buildingService
