import { useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LaunchIcon from '@mui/icons-material/Launch'
import { useI18n } from '@popety_io/popety-io-lib'
import { sharedSelector } from '../../../Login'

const DashboardLink = ({ tagId }: { tagId: string }) => {
  const shared = useSelector(sharedSelector)
  const { t } = useI18n()

  const handleDashboardLink = () => {
    const tab = shared?.shareTaggedLands ? 'companyTags' : 'myTags'

    window.open(
      `/dashboard?tab=${tab}&page=0&orderBy=principal_type%3Adesc&tag=${tagId}`,
      '_blank',
      'noreferrer',
    )
  }

  return (
    <Box display="flex" justifyContent="center">
      <Tooltip placement="top" title={t('myTags.dashboardLink')}>
        <IconButton
          aria-label="delete"
          onClick={handleDashboardLink}
          className="tag-dashboard-link"
        >
          <LaunchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default DashboardLink
