import { createSlice } from '@reduxjs/toolkit'
import {
  handleGetListingsAggsPurchasePerSquareMeter,
  handleGetListingAggsPurchase,
  handleGetEstimateListingBuyInsights,
  handleGetListingsAggsPriceAnnoncements,
  handleGetListingsAggsPriceAnnoncementsByPriceSquareMeter,
  handleGetAfterXYear,
  handleGetListingRoomsNb,
  handleGetRoomsByPricePerSquareMeter,
  handleGetListingDuration,
} from './estimateMarketReducers'

import { EstimateMarketState } from './types'

const initialState: EstimateMarketState = {
  loadInsights: false,
  stats: {},
  buyData: undefined,
  loadBuyData: false,
  loadAnnouncements: false,
  announcementData: undefined,
  announcementDataBySquareMeter: undefined,
  loadAnnouncementsBySquareMeter: false,
  listingDurationDistribution: undefined,
  loadingListingDuration: false,
  listingAggsPurchasePerSquareMeter: [],
  loadingListingsAggs: false,
  listingAggsPurchase: [],
  listingRoomsNb: undefined,
  listingAfterXYear: undefined,
  loadingAfterXYear: false,
  roomsByPricePerSquareMeter: undefined,
  loadByPricePerSquareMeter: false,
  loadingListingRoomsNb: false,
}

const estimateMarket = createSlice({
  name: 'estimateMarket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleGetListingsAggsPurchasePerSquareMeter(builder)
    handleGetListingAggsPurchase(builder)
    handleGetEstimateListingBuyInsights(builder)
    handleGetListingsAggsPriceAnnoncements(builder)
    handleGetListingsAggsPriceAnnoncementsByPriceSquareMeter(builder)
    handleGetAfterXYear(builder)
    handleGetListingRoomsNb(builder)
    handleGetListingDuration(builder)
    handleGetRoomsByPricePerSquareMeter(builder)
  },
})

export const estimateMarketReducer = {
  estimateMarket: estimateMarket.reducer,
}
