import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { addNote } from '../../pages/Dashboard/redux'
import { NoteRichTextInput } from '../NoteRichTextInput'
import NoteList from './NoteList'

export type LandNoteProps = {
  landId: string | string[]
}

const LandNote = ({ landId }: LandNoteProps) => {
  const dispatch = useDispatch<any>()
  const ref = useRef<HTMLDivElement>()

  const scrollToBottom = (opts?: any) => {
    const noteInput = ref.current?.querySelector<HTMLInputElement>('#noteInput')

    ref.current?.scrollTo({ top: noteInput?.offsetTop, ...opts })
  }

  const handleNoteAdd = (content: string) => {
    dispatch(addNote({ landId, content }))
    setTimeout(() => scrollToBottom({ behavior: 'smooth' }), 300)
  }

  useEffect(() => {
    scrollToBottom()
  }, [landId])

  return (
    <Box width="100%" maxHeight="500px" ref={ref}>
      <NoteList landId={landId} defaultExtend />
      <Box
        position="sticky"
        sx={{ bottom: '-17px', paddingTop: '20px', paddingBottom: '20px' }}
        bgcolor="white"
      >
        <NoteRichTextInput onConfirm={handleNoteAdd} />
      </Box>
    </Box>
  )
}

export default LandNote
