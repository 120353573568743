import FormLabel from '@mui/material/FormLabel'

import { FormRange } from '@popety_io/popety-io-lib'

type SearchFormFilterProps = {
  title: string
  name: string
  endAdornment?: React.ReactNode
}

const SearchFormFilter = ({
  title,
  name,
  endAdornment = <span />,
  ...other
}: SearchFormFilterProps) => {
  return (
    <>
      <FormLabel>{title}</FormLabel>
      <FormRange name={name} endAdornment={endAdornment} {...other} />
    </>
  )
}

export default SearchFormFilter
