import { useState } from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box } from '@mui/material'

type MouseHoverPopoverProps = {
  title?: string | React.ReactNode
  children?: React.ReactNode
  [key: string]: unknown
}

const MouseHoverPopover = ({
  title,
  children,
  ...other
}: MouseHoverPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Box {...other}>
        <Typography>{title}</Typography>
        <IconButton
          aria-label="alarm"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            color: '#969696',
            padding: '0',
          }}
        >
          <ErrorOutlineIcon fontSize="small" />
        </IconButton>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </div>
  )
}

export default MouseHoverPopover
