import { createSelector } from '@reduxjs/toolkit'

import { getPriceData } from '../../../../utils'

const buyAggsSelector = (state) => state.listingInsights.buyData

const rentAggsSelector = (state) => state.listingInsights.rentData

const buyDataSelector = createSelector(
  buyAggsSelector,
  (aggs) => aggs && getPriceData(aggs.price_over_time.price_over_time.buckets),
)

const rentDataSelector = createSelector(
  rentAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs.price_over_time.price_over_time.buckets, 12),
)

const buyMaxDateSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string,
)

const rentMaxDateSelector = createSelector(
  rentAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string,
)

const listingInsightsLoadingByIdSelector = (state) =>
  state.listingInsights.loading

export {
  buyDataSelector,
  rentDataSelector,
  buyMaxDateSelector,
  rentMaxDateSelector,
  listingInsightsLoadingByIdSelector,
}
