import { styled } from '@mui/material/styles'

type TitleProps = {
  color: string
}

export const TitleRoot = styled('div')<TitleProps>`
  background: ${({ color }) => color};
  width: 28px;
  height: 16px;
  min-width: 28px;
  margin-right: 10px;
`
