import React, { FC } from 'react'
import { ResetIcon, formatPrice } from '@popety_io/popety-io-lib'

import {
  ValueFieldBoxRoot,
  ValueFieldRoot,
  ValueRoot,
} from './ValueField.style'

interface IProps {
  value: any
  valueEdited?: number | string | undefined
  valueDefault?: number | string | undefined
  onChangeValue: (v: string | number) => void
  onReset?: () => void
  onRetrieve?: () => void
  isEdit: boolean
  startAdornment?: string
  isFormatPrice?: boolean
  sx?: any
  endAdornment?: string
}
const ValueField: FC<IProps> = ({
  value = '',
  valueEdited,
  valueDefault,
  onChangeValue,
  onReset,
  onRetrieve,
  isEdit,
  isFormatPrice,
  endAdornment,
  startAdornment,
  ...other
}) => {
  const onChange = (e: any) => {
    onChangeValue(e.target.value)
  }

  const isReset = valueDefault !== value && value === valueEdited

  if (!isEdit) {
    return (
      <ValueRoot edited={!!value && value !== valueDefault} {...other}>
        {startAdornment}
        {isFormatPrice ? formatPrice(value) : value || '-'}
        {endAdornment}
      </ValueRoot>
    )
  }

  return (
    <ValueFieldBoxRoot retrieve={!isReset}>
      <ValueFieldRoot value={value} onChange={onChange} />
      {valueEdited && (
        <ResetIcon
          sx={{ margin: 0, fontSize: 14 }}
          onClick={() => (isReset ? onReset?.() : onRetrieve?.())}
        />
      )}
    </ValueFieldBoxRoot>
  )
}

export default ValueField
