import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const getAnalyzedLandsByUser = async (input: {
  userId: string
  search?: string
}) => {
  const { userId, search = '' } = input

  return client.get(`/dashboard/${userId}/analyzed/lands${search}`)
}

const getAnalyzedLands = (search = '') => {
  return getAnalyzedLandsByUser({ userId: getUserId(), search })
}

const getAnalyzedGrouping = async (search = '') => {
  return client.get(`/dashboard/analyzed/grouping${search}`)
}

const getCompanyUsers = async () => {
  return client.get(`/dashboard/users`)
}

const analizedLandService = {
  getAnalyzedLands,
  getAnalyzedLandsByUser,
  getAnalyzedGrouping,
  getCompanyUsers,
}

export default analizedLandService
