import { useState } from 'react'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'

import { useI18n } from '@popety_io/popety-io-lib'
import BuildingTagModal from './BuildingTagModal'
import useBuildingTag from './useBuildingTag'

type useBuildingTagIconProps = {
  buildingId: string | string[]
  color?: string
  disableRequest?: boolean
}

const useBuildingTagIcon = ({
  buildingId,
  color = 'primary',
  disableRequest,
}: useBuildingTagIconProps) => {
  const { t } = useI18n()

  const tag = useBuildingTag({
    buildingIds: buildingId,
    fetch: !disableRequest,
  })
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event: any) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const Icon = tag.active ? (
    <LocalOfferIcon color={color as any} />
  ) : (
    <LocalOfferOutlinedIcon sx={{ opacity: 0.3 }} />
  )

  const Modal = (
    <BuildingTagModal
      anchorEl={anchorEl}
      onClose={handleClose}
      buildingIds={tag.sanitizedBuildingIds}
      view={1}
    />
  )

  const title = tag.active ? t('common.removeTag') : t('common.addTag')

  return { ...tag, Icon, onClick: handleOpen, title, Modal }
}

export default useBuildingTagIcon
