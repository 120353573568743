import React from 'react'

import { useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { useI18n } from '@popety_io/popety-io-lib'
import { landSelector } from '../../redux'

export type SaveOveridesProps = {
  open: boolean
  onSave: any
  onCancel: () => void
  name?: string
  planName?: string
}

const SaveOverides = ({
  open,
  onSave,
  onCancel,
  name,
  planName,
}: SaveOveridesProps) => {
  const { t } = useI18n()
  const land = useSelector(landSelector)

  const handleSave = (value: boolean | null) => {
    onSave(value)
    onCancel()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('land.underExploited.extend')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('land.underExploited.extendDesc')}{' '}
          <h3>
            "{name} {t('land.underExploited.of')} {planName}{' '}
            {t('land.underExploited.at')} {land.city_name}"
          </h3>{' '}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => handleSave(false)} color="primary">
          {t('land.underExploited.no')}
        </Button>
        <Button onClick={() => handleSave(true)} color="primary" autoFocus>
          {t('land.underExploited.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SaveOverides
