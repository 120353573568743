/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import estimateAirQualityService from './estimateAirQualityService'

const initialState = {
  loading: true,
  airQualityData: {},
}

export const getAirQualityData = createAsyncThunk(
  'estimateAirQuality/getAirQualityData',
  estimateAirQualityService.getAirQualityData,
)

const estimateAirQuality = createSlice({
  name: 'estimateAirQuality',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAirQualityData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAirQualityData.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getAirQualityData.fulfilled, (state, action) => {
      const { data = [] } = action.payload

      state.loading = false
      state.airQualityData = data || []
    })
  },
})

export const estimateAirQualityReducer = {
  estimateAirQuality: estimateAirQuality.reducer,
}
