import FormLabel from '@mui/material/FormLabel'

import { FormDateRange } from '@popety_io/popety-io-lib'

type SearchFormFilterDateProps = {
  title: string
  name: string
}

const SearchFormFilterDate = ({
  title,
  name,
  ...other
}: SearchFormFilterDateProps) => {
  return (
    <>
      <FormLabel>{title}</FormLabel>
      <FormDateRange name={name} {...other} />
    </>
  )
}

export default SearchFormFilterDate
