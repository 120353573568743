/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import estimateEnvironmentService from './estimateEnvironmentService'

const initialState = {
  loading: false,
  noise: {},
}

export const getEnvironmentData = createAsyncThunk(
  'estimateEnvironment/getEnvironmentData',
  estimateEnvironmentService.getEnvironmentData,
)

const estimateEnvironment = createSlice({
  name: 'estimateEnvironment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnvironmentData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEnvironmentData.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getEnvironmentData.fulfilled, (state, action) => {
      const { data = [] } = action.payload

      state.loading = false
      state.noise = data || []
    })
  },
})

export const estimateEnvironmentReducer = {
  estimateEnvironment: estimateEnvironment.reducer,
}
