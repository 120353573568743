import { getYearColor, Map } from '@popety_io/popety-io-lib'
import { API_BASE_URL } from '../../../../services'

const renderMethod = 'aggs'

const sourceName = 'es_mvt_transaction'
const fillStyle = 'layer_fill_transaction'

const removeTransactionTiles = ({ map }: { map: Map }) => {
  if (map.getSource(sourceName)) {
    map.removeLayer(fillStyle)
    map.removeSource(sourceName)
  }
}

const getTransactionTiles = ({ map, query }: { map: Map; query: any }) => {
  try {
    if (map.getSource(sourceName)) {
      map.removeLayer(fillStyle)
      map.removeSource(sourceName)
    }

    map.addSource(sourceName, {
      type: 'vector',
      tiles: [
        `${API_BASE_URL}/transactions/tiles?query=${query}&x={x}&y={y}&z={z}`,
      ],
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer({
      id: fillStyle,
      type: 'circle',
      source: sourceName,
      'source-layer': renderMethod,
      paint: {
        'circle-radius': 8,
        'circle-color': 'rgb(0, 173, 230)',
        'circle-opacity': 0.5,
        'circle-stroke-color': 'rgb(0, 173, 230)',
        'circle-stroke-opacity': 1,
        'circle-stroke-width': 1,
      },
    })

    map.setFilter(fillStyle, [
      'any',
      ['==', ['geometry-type'], 'Point'],
      ['==', ['geometry-type'], 'MultiPoint'],
    ])
  } catch (error) {
    console.error(error)
  }
}

const getTransactionAroudLayers = (
  transactions: any[],
  yearRange: number[],
  startDate?: any,
) => {
  const data = {
    type: 'FeatureCollection',
    features:
      transactions
        ?.map((transaction: any[]) => {
          const years = transaction?.map((t) => Number(t.date.substring(0, 4)))

          const transactionYears = years?.filter(
            (year) => year >= yearRange[0] && year <= yearRange[1],
          )

          if (transactionYears?.length <= 0) return null

          const index =
            years?.findIndex((year) => year === transactionYears[0]) || 0

          const properties = JSON.parse(
            JSON.stringify(transaction?.[index] || {}),
          )

          const geometry = properties.land_geojson
            ? JSON.parse(properties.land_geojson)
            : []

          delete properties.land_geojson

          const date = properties?.date
          const year = date ? new Date(date).getFullYear() : undefined

          properties.color = getYearColor(year, startDate)

          return { type: 'Feature', geometry, properties }
        })
        .filter(Boolean) || [],
  }

  return [
    {
      id: 'transaction',
      type: 'geojson',
      data,
      layers: [
        {
          id: 'transaction-layer',
          type: 'fill',
          paint: { 'fill-color': ['get', 'color'] },
          click: true,
          hover: true,
        },
      ],
    },
  ]
}

export {
  getTransactionAroudLayers,
  removeTransactionTiles,
  getTransactionTiles,
}
