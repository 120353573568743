import { useSelector } from 'react-redux'
import { ContentTitle, Score, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import LandScore from '../Summary/LandScore'
import { landSelector } from '../redux'

type UnderExploitedHeaderProps = {
  landUsePlansNumber: number
  specialPlansNumber: number
}

const UnderExploitedHeader = ({
  landUsePlansNumber,
  specialPlansNumber,
}: UnderExploitedHeaderProps) => {
  const { t } = useI18n()
  const land: any = useSelector(landSelector)
  const data = [
    {
      title: (
        <Score value={landUsePlansNumber || 0} color="#000" by100={false} />
      ),
      subTitle: t('common.landRadarInfo.developingArea'),
    },
    {
      title: (
        <Score value={specialPlansNumber || 0} color="#000" by100={false} />
      ),
      subTitle: t('land.underExploited.specialPlan'),
    },
    {
      title: (
        <LandScore
          score={land?.underExploitedScoreOveride || land.under_exploited_score}
          title={t('land.underExploited.underExploitedDesc')}
          showBullet
          showScoreLegend
        />
      ),
      subTitle: t('land.underExploitedScore'),
      sx: {
        '& > h6': {
          height: '100%',
          maxHeight: '100%',
        },
        maxWidth: '100%',
        paddingRight: '16px',
      },
    },
  ]

  return (
    <Box position="sticky" top={0} zIndex={10} bgcolor="background.paper">
      <ContentTitle m={1} width="100%" data={data} />
      <Divider />
    </Box>
  )
}

export default UnderExploitedHeader
