export const getStatus = (status: string, t: Translator) => {
  switch (status) {
    case 'in_force':
      return t('land.underExploited.inForce')
    case 'unknown':
      return t('land.underExploited.unknown')
    case 'in_review':
      return t('land.underExploited.inReview')
    case 'pending_approval':
      return t('land.underExploited.pendingApproval')
    default:
      return ''
  }
}
