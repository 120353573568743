const reminderLandsSelector = (state: any) => state.reminder.reminderLands

const reminderByLandIdSelector = (state: any) => state.reminder.reminderByLandId

const loadingReminderSelector = (state: any) => state.reminder.loading

export {
  reminderLandsSelector,
  reminderByLandIdSelector,
  loadingReminderSelector,
}
