import { analizedLandReducer } from './analizedLand'
import { favoriteReducer } from './favorite'
import { tagReducer } from './tag'
import { actionHistoryDashboardReducer } from './actionHistory'
import { prospectReducer } from './prospect'
import { reminderReducer } from './reminders'

export * from './favorite'
export * from './tag'
export * from './actionHistory'
export * from './analizedLand'
export * from './prospect'
export * from './reminders'

export const dashboardReducer = {
  ...favoriteReducer,
  ...tagReducer,
  ...analizedLandReducer,
  ...actionHistoryDashboardReducer,
  ...prospectReducer,
  ...reminderReducer,
}
