import { useMediaQuery } from '@popety_io/popety-io-lib'
import { useDispatch } from 'react-redux'
import { handleSideBar } from '../redux/appSlice'

const useAutoCloseSidebar = () => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const onCloseSidebar = () => {
    if (isMobile) {
      dispatch(handleSideBar(false))
    }
  }

  return { onCloseSidebar }
}

export default useAutoCloseSidebar
