import { ApiClient } from '../../../../services'

const client = new ApiClient()

type GetOwnerOpts = {
  landId?: string | number | string[] | number[]
  owner?: string
  region?: string
}

const getOwnerLands = async ({ owner, landId }: GetOwnerOpts) =>
  client.post(`land/${landId}/owners/lands`, {
    value: owner,
  })

const ownersService = {
  getOwnerLands,
}

export default ownersService
