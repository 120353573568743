import { useState, useRef } from 'react'
import { MultiOptionsButton, useI18n } from '@popety_io/popety-io-lib'

export interface rowProps {
  city_city_namecode_number: string
  city_name: string
  code_number: string
  id: string
  land_id: number
  address_full: string | undefined
}

const ActionButton = ({ land }: { land: rowProps }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const { t } = useI18n()

  const options = [
    t('common.report.Analyse détaillée'),
    t('common.externallinks.locationMap'),
  ]

  const handleAnalysisLink = () => {
    window.open(`/land-detail/plot?landId=${land.land_id}`)
  }

  const handleMapLink = () => {
    window.open(`/developer-map?landId=${land.land_id}`)
  }

  const handleItemClick = (type: string) => {
    switch (type) {
      case 'analysis':
        handleAnalysisLink()
        break
      case 'map':
        handleMapLink()
        break
      default:
    }
  }

  const handleClick = () => {
    if (selectedIndex === 0) {
      handleItemClick('analysis')
    } else {
      handleItemClick('map')
    }
  }

  const handleMenuItemClick = (event: any, index: number) => {
    setSelectedIndex(index)
    setOpen(false)
    if (index === 0) {
      handleItemClick('analysis')
    } else {
      handleItemClick('map')
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <MultiOptionsButton
      anchorRef={anchorRef}
      open={open}
      options={options}
      selectedIndex={selectedIndex}
      handleClick={handleClick}
      handleMenuItemClick={handleMenuItemClick}
      handleToggle={handleToggle}
      handleClose={handleClose}
    />
  )
}

export default ActionButton
