import { AccordionProps } from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RestrictionAccordonContainer } from './RestrictionAccordon.style'

export type RestrictionAccordonProps = AccordionProps & {
  title?: React.ReactNode
}

const RestrictionAccordon = (props: RestrictionAccordonProps) => {
  const { title, children, ...other } = props

  return (
    <RestrictionAccordonContainer {...other}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </RestrictionAccordonContainer>
  )
}

export default RestrictionAccordon
