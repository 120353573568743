import { useEffect, useState } from 'react'
import { Box, Typography, Paper, IconButton, Skeleton } from '@mui/material'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'

import {
  getEstimateSummary,
  getLandBuildings,
  getBuildingsByAddressId,
  loadBuildingsSelector,
} from '../../redux'
import {
  FloorContainer,
  EstimateSearchRoot,
  SearchForm,
} from './EstimateSearch.style'

export type SelectBuildingProps = {
  showCloseIcon?: boolean
  onClose: any
  handleChangeIndex: (newIndex: number) => void
  setValue: React.Dispatch<React.SetStateAction<any>>
}

const SelectBuilding = ({
  showCloseIcon,
  onClose,
  handleChangeIndex,
}: SelectBuildingProps) => {
  const { t } = useI18n()
  const [landBuildings, setLandBuildings] = useState<any>([])
  const dispatch = useDispatch()
  const loadBuildings = useSelector(loadBuildingsSelector)
  const { query, updateQuery } = useRouter()

  const handleChooseBuilding = (building: any) => {
    if (!building) return

    const addressId = building?.id

    try {
      dispatch(
        getEstimateSummary({
          addressId,
          geoCenter: [building?.geo_center],
        }) as any,
      )
      handleChangeIndex(1)
      updateQuery({ addressId, landId: undefined })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (query?.landId) {
      dispatch(getLandBuildings(query.landId) as any).then((buildings: any) => {
        dispatch(
          getBuildingsByAddressId(
            buildings?.payload?.data?.buildings
              ?.flatMap((building: any) =>
                building?.addresses?.flatMap((address: any) => address?.id),
              )
              .filter(Boolean),
          ) as any,
        ).then((res: any) => setLandBuildings(res?.payload?.data))
      })
    }
  }, [query?.landId])

  return (
    <EstimateSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
      <Paper className="Title">
        <Typography
          component="h2"
          fontWeight="700"
          fontSize={16}
          sx={{ textTransform: 'uppercase' }}
        >
          {t('common.Select a Building')}
        </Typography>
        {showCloseIcon && (
          <IconButton aria-label="close" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        )}
      </Paper>
      <Box
        sx={{
          padding: '30px 30px',
        }}
      >
        <SearchForm component="form">
          {loadBuildings && <Skeleton height={150} />}
          {landBuildings?.map((building: any, i: number) => (
            <div key={i}>
              <FloorContainer
                key={i}
                onClick={() => handleChooseBuilding(building)}
              >
                <h4>{building?.location_search}</h4>
              </FloorContainer>
            </div>
          ))}
        </SearchForm>
      </Box>
    </EstimateSearchRoot>
  )
}

export default SelectBuilding
