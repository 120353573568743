import { parseGeoJSON, i18n } from '@popety_io/popety-io-lib'

const getAuthorizedLimitLayers = (authorizedLimit: any) => {
  if (!authorizedLimit) return []

  const limitGeoJSON = {
    type: 'FeatureCollection',
    features: [
      {
        geometry: parseGeoJSON(authorizedLimit),
        type: 'Feature',
        properties: { name: i18n.t('land.map.buidingLimit') },
      },
    ],
  }

  return [
    {
      id: 'authorized-limit',
      type: 'geojson',
      data: limitGeoJSON,
      layers: [
        {
          id: 'authorized-limit-layer',
          type: 'fill',
          paint: { 'fill-color': 'rgba(118, 221, 255, 0.5)' },
          hover: true,
        },
        {
          id: 'authorized-limit-stroke-layer',
          type: 'line',
          layout: {},
          paint: { 'line-color': '#000', 'line-width': 1 },
          hover: true,
        },
      ],
    },
  ]
}

export { getAuthorizedLimitLayers }
