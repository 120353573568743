import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ProgressIndicator, useI18n, useRouter } from '@popety_io/popety-io-lib'
// import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import {
  dwellingsSelector,
  estimateLoadingSelector,
  estimateSelector,
  getEstimateSummary,
} from '../../redux'
import { LOCATION_TYPE } from '../../../../config'
import LocationsInput from '../../../../components/LocationsInput'
import { EstimateSearchRoot, SearchForm } from './EstimateSearch.style'

export type LocationsSearchProps = {
  showCloseIcon?: boolean
  onClose: any
  handleChangeIndex: (newIndex: number) => void
  setValue: React.Dispatch<React.SetStateAction<any>>
}

const LocationsSearch = ({
  showCloseIcon,
  onClose,
  handleChangeIndex,
  setValue,
}: LocationsSearchProps) => {
  const { t } = useI18n()
  const [valueLocation, setValueLocation] = useState<any[]>([])
  const loading = useSelector(estimateLoadingSelector)
  const dwellings = useSelector(dwellingsSelector)
  const estimate: any = useSelector(estimateSelector)
  const { query, updateQuery } = useRouter()
  // const { t } = useI18n()

  const dispatch = useDispatch()
  const regex = /Maison?/gi
  const isHouse = useMemo(() => {
    return regex.test(estimate?.category || '')
  }, [estimate])

  //   const navigate = useNavigate()

  const handleChange = (newLocations: any) => {
    setValueLocation(newLocations.filter(Boolean).slice(-1))
  }

  const handleSubmit = async () => {
    if (!valueLocation?.length) return

    const geoCenter = valueLocation
      .map((i: any) => i.geo_center)
      .filter(Boolean)

    const addressId = valueLocation?.[0]?.id

    try {
      dispatch(getEstimateSummary({ geoCenter, addressId }) as any)

      updateQuery({ addressId })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!estimate?.id || estimate.dwellingSelected) return
    if (dwellings?.length > 0 && !isHouse) {
      handleChangeIndex(1)
    } else {
      handleChangeIndex(2)
    }
  }, [dwellings, isHouse])

  useEffect(() => {
    if (valueLocation?.length && valueLocation.length > 0) {
      handleSubmit()
    }
  }, [valueLocation])

  useEffect(() => {
    const address = estimate?.buildings_administrative?.map((ba: any) =>
      ba?.addresses?.find(
        (a: any) => Number(a?.address_id || a?.id) === Number(query?.addressId),
      ),
    )?.[0]

    setValue({
      address: address
        ? `${address?.street} ${address?.street_nb}, ${address?.npa} ${address?.city}`
        : estimate?.addresses?.[0],
    })
  }, [estimate])

  useEffect(() => {
    if (query?.buildingId) {
      dispatch(
        getEstimateSummary({
          buildingId: query?.buildingId,
          geoCenter: [`POINT(${query?.lon} ${query?.lat})`],
        }) as any,
      )
    } else if (query?.addressId && query?.lat && query?.lon) {
      dispatch(
        getEstimateSummary({
          addressId: query.addressId,
          geoCenter: [`POINT(${query?.lon} ${query?.lat})`],
        }) as any,
      ).then(() => {
        updateQuery({ lat: undefined, lon: undefined })
      })
    }
  }, [query?.addressId, query?.lat, query?.lon, query?.buildingId])

  return (
    <>
      {loading && <ProgressIndicator />}

      {!loading && (
        <>
          <EstimateSearchRoot sx={{ width: { xs: '300px', sm: '500px' } }}>
            <Paper className="Title">
              <Typography
                component="h2"
                fontWeight="700"
                fontSize={16}
                sx={{ textTransform: 'uppercase' }}
              >
                {t("common.ENTREZ L'ADRESSE DU BIEN À ESTIMER")}
              </Typography>
              {showCloseIcon && (
                <IconButton aria-label="close" onClick={onClose} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Paper>
            <Box
              sx={{
                padding: '30px 30px',
              }}
            >
              <SearchForm component="form">
                <LocationsInput
                  onChange={handleChange}
                  value={valueLocation}
                  types={[LOCATION_TYPE.ADDRESS]}
                  size={10}
                  onKeyDown={handleSubmit}
                />
              </SearchForm>
            </Box>
          </EstimateSearchRoot>
          <Box />
        </>
      )}
    </>
  )
}

export default LocationsSearch
