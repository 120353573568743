import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useI18n } from '@popety_io/popety-io-lib'

/**
 * Hook to handle asynchronous thunk action lifecycle (pending, fulfilled, rejected).
 *
 * This hook is intended for use in any component that needs to know about request
 * status (loading, error, data). For components that need only the payload data,
 * you can useDispatch as usual.
 *
 * With this hook, we don't need to save request status (loading, error) in redux anymore,
 * so we gain for performance especially for reducers that have many async actions
 *
 * @param action async action to dispatch
 * @param payloadErrors indicates whether to check error fied on the payload object
 *
 * @example <caption>Initialize with async thunck action</caption>
 * const { dispatch, loading, error } = useThunk(() => login(inp))
 *
 * // and later call dispatch() when you want to run login action
 *
 * @example <caption>Dispatch with async thunck action</caption>
 * const { dispatch, loading, error } = useThunk()
 *
 * // and later call dispatch(() => login(userInput)) when you want to run login action
 *
 * @namespace Hooks
 */
const useThunk = <V = any>(
  action?: () => AsyncThunkAction<V, any, any>,
  payloadErrors = true,
) => {
  const dispatch: any = useDispatch()
  const { t } = useI18n()
  const called = useRef(false)

  const [errors, setErrors] = useState<any[] | undefined>()
  const [data, setData] = useState<V>()
  const [loading, setLoading] = useState(false)

  const getErrorsFromPayload = (payload: any) => {
    if (!payloadErrors) return

    const error = payload?.error || payload?.errors

    if (!error) return

    return Array.isArray(error) ? error : [error]
  }

  const handleDispatch = async (newAction = action) => {
    setLoading(true)
    called.current = true

    if (!newAction) return

    return dispatch(newAction())
      .then(unwrapResult)
      .then((result: V) => {
        const err = getErrorsFromPayload(result)

        if (err) {
          setErrors(err as any)
        } else {
          setErrors(undefined)
          setData(result)
        }
        setLoading(false)
      })
      .catch((err: any) => {
        // Send error to sentry
        console.error(err)

        setErrors([{ message: t('common.error500'), code: '500' }] as any)
        setLoading(false)
      })
  }

  return {
    data: data as V,
    loading,
    dispatch: handleDispatch,
    errors,
    called: called.current,
    run: dispatch,
  }
}

export default useThunk
