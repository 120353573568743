/* eslint-disable max-lines */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { getEstimatePublicLink } from '../../../../utils'
import { archiveEstimateHistory, deleteEstimateHistory } from '../../redux'
import { EstimateHistoryMenuProps, handleOpenLink } from './utils'

const EstimateHistoryMenu = ({
  dwellingId,
  id,
  geo_center,
  area,
  type,
  rooms,
  constructionYear,
  condition,
  renovationYear,
  hash,
  expirationDate,
  baths,
  archive = false,
}: EstimateHistoryMenuProps) => {
  const { t, currentLanguage } = useI18n()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget as any)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = () => {
    handleOpenLink({
      dwellingId,
      id,
      geo_center,
      area,
      type,
      rooms,
      constructionYear,
      condition,
      renovationYear,
      baths,
    })
    setAnchorEl(null)
  }

  const handleDelete = () => {
    dispatch(deleteEstimateHistory(id) as any)
    setAnchorEl(null)
  }

  const handleArchive = () => {
    dispatch(archiveEstimateHistory({ id, archive: !archive }) as any)
    setAnchorEl(null)
    setOpenModal(false)
  }

  const handlePermalink = () => {
    getEstimatePublicLink({ hash, currentLanguage })
    setAnchorEl(null)
  }

  return (
    <Box display="flex">
      <IconButton size="small" onClick={handleClick} sx={{ opacity: 1 }}>
        <MoreVertIcon color="primary" />
      </IconButton>
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {!archive ? (
            <>
              <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleOpen}>
                {t('estimate.Editer')}
              </MenuItem>
              {expirationDate && (
                <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handlePermalink}>
                  {t('estimate.Ouvrir permalink')}
                </MenuItem>
              )}
              <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleArchive}>
                {t('estimate.Archiver')}
              </MenuItem>
            </>
          ) : (
            <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleArchive}>
              {t('estimate.Désarchiver')}
            </MenuItem>
          )}
          <MenuItem
            sx={{ fontSize: '1.1rem', color: 'red' }}
            onClick={() => setOpenModal(true)}
          >
            {t('common.delete')}
          </MenuItem>
        </Menu>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        okText={t('common.delete')}
        showCloseIcon={false}
        cancelText={t('common.close')}
        onOk={handleDelete}
        onCancel={() => setOpenModal(false)}
      >
        <Typography variant="h5">
          {t('estimate.Êtes-vous sûr de vouloir supprimer cette estimation ?')}
        </Typography>
      </Modal>
    </Box>
  )
}

export default EstimateHistoryMenu
