import { combineReducers } from '@reduxjs/toolkit'
import { landDetailsReducer } from '../pages/LandDetails'
import { buildingDetailsReducer } from '../pages/BuildingDetails'
import { locationReducer } from '../components/LocationsInput'
import { authReducer } from '../pages/Login'
import { transactionReducer } from '../pages/TransactionHistory'
import { faoReducer } from '../pages/FaoHistory'
import { alertReducers } from '../pages/Alerts/redux'
import { listingReducer } from '../pages/ListingHistory'
import { companyDashboardReducer } from '../pages/CompanyDashboard/redux'
import { dashboardReducer } from '../pages/Dashboard/redux'
import { buildingDashboardReducer } from '../pages/BuildingDashboard/redux'
import { landPreviewReducer } from '../components/LandPreview'
import { developerMapReducer } from '../pages/DeveloperMap/redux'
import { accountReducer } from '../pages/Account'
import { myTagsReducer } from '../pages/Account/redux/tags/tagsSlice'
import { estimateReducer } from '../pages/Estimate'
import { appReducer } from './appSlice'

const rootReducer = combineReducers({
  ...appReducer,
  ...authReducer,
  ...locationReducer,
  ...transactionReducer,
  ...faoReducer,
  ...alertReducers,
  ...listingReducer,
  ...landDetailsReducer,
  ...buildingDetailsReducer,
  ...companyDashboardReducer,
  ...dashboardReducer,
  ...buildingDashboardReducer,
  ...landPreviewReducer,
  ...developerMapReducer,
  ...accountReducer,
  ...myTagsReducer,
  ...estimateReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  return rootReducer(state, action)
}
