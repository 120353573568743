import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/reducers'

const usersCompanyDashboardLoadingSelector = (state: RootState) =>
  state.companyDashboard.usersLoading

const usersByIdSelector = (state: RootState) => state.companyDashboard.usersById

const usersSelector = createSelector(usersByIdSelector, (users) =>
  Object.values(users),
)

const countUsersSelector = (state: RootState) =>
  state.companyDashboard.usersCount

const activeUsersSelector = createSelector(usersSelector, (users) =>
  users?.filter((user) => user.status === 'active'),
)

const inactiveUsersSelector = createSelector(usersSelector, (users) =>
  users.filter((user) => user.status === 'inactive'),
)

const officesByIdSelector = (state: RootState) =>
  state.companyDashboard.officesById

const officesSelector = createSelector(officesByIdSelector, (offices) =>
  Object.values(offices),
)

const officesLoadingSelector = (state: RootState) =>
  state.companyDashboard.officesLoading

const officesCountSelector = (state: RootState) =>
  state.companyDashboard.officesCount

export {
  usersCompanyDashboardLoadingSelector,
  usersSelector,
  activeUsersSelector,
  inactiveUsersSelector,
  officesSelector,
  countUsersSelector,
  officesLoadingSelector,
  officesCountSelector,
}
