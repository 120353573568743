import { useRouter, useI18n } from '@popety_io/popety-io-lib'

import TransportType from '../TransportType'

const LivabilityMapContent = () => {
  const { t } = useI18n()
  const { query } = useRouter()
  const isLivabilityTab =
    query.tab === 'livability' || query.subTab === 'livability'

  if (!isLivabilityTab) return <></>

  return (
    <>
      <TransportType />
      <div>{t('lang.map_content_goes_here')}</div>
    </>
  )
}

export default LivabilityMapContent
