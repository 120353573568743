/* eslint-disable max-lines */
import Grid from '@mui/material/Grid'
import { Fragment, useState } from 'react'
import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import ProtectedBuildingInformations from '../../LandDetails/Building/ProtectedBuildingInformations'
import { InfoBoxRoot } from '../../../components/InfoBox/InfoBox.style'
import { buildingSelector } from '../redux'
import { getBuildingInfosSummary } from './buildingInfo.utils'

const BuildingInfo = () => {
  const building = useSelector(buildingSelector)
  const land = building?.lands?.[0]
  const [open, setOpen] = useState(false)
  const { t } = useI18n()

  const handleCloseProtectedBuilding = () => {
    setOpen(false)
  }

  const handleClickProtectedBuilding = () => {
    setOpen(true)
  }

  const [data, data2] = getBuildingInfosSummary(
    building,
    handleClickProtectedBuilding,
  )

  return (
    <InfoBoxRoot pb={0}>
      <Typography variant="h6"> {t('building.info.information')}</Typography>

      <Box className="ContentBox">
        <Grid container spacing={{ xs: 1, sm: 3 }} alignItems="flex-start">
          <Grid item container xs={12} sm={5}>
            {data.map(
              (item: { label: string; value: string; color?: string }) => (
                <Fragment key={item.label as string}>
                  <Grid className="label" item xs={9}>
                    {item.label}
                  </Grid>
                  <Grid item xs={3} color={item.color || '#000000'}>
                    {item.value}
                  </Grid>
                </Fragment>
              ),
            )}
          </Grid>

          <Grid item container xs={12} sm={7}>
            {data2.map((item) => (
              <Fragment key={item.label as string}>
                <Grid className="label" item xs={12} sm={4}>
                  {item.label}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {item.value}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>
      <ProtectedBuildingInformations
        land={land}
        building={building}
        open={open}
        handleClose={handleCloseProtectedBuilding}
      />
    </InfoBoxRoot>
  )
}

export default BuildingInfo
