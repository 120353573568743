import { Box, Skeleton } from '@mui/material'

const CustomizedSkeleton = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ height: '250px', borderRadius: 2, width: '100%' }}
      />
    </Box>
  )
}

export default CustomizedSkeleton
