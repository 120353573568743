/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import previewService from './previewService'

const initialState = {
  preview: undefined,
  grouping: false,
  needFlyToCenter: true,
  previousPreview: {
    type: '',
    previewId: undefined,
    selectedLand: undefined,
  },
}

export const getMapTilePreview = createAsyncThunk(
  'developerMapPreview/getMapTilePreview',
  previewService.getMapTilePreview,
)

const preview = createSlice({
  name: 'developerMapPreview',
  initialState,
  reducers: {
    clearPreview: (state) => {
      state.preview = undefined
    },
    setGrouping: (state, action) => {
      state.grouping = action.payload
    },
    setNeedFlyToCenter: (state, action) => {
      state.needFlyToCenter = action.payload
    },
    setPreviousPreview: (state, action) => {
      state.previousPreview = action.payload
    },
    clearPreviousPreview: (state) => {
      state.previousPreview = {
        type: '',
        previewId: undefined,
        selectedLand: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMapTilePreview.fulfilled, (state, action) => {
      const { data } = action.payload || {}

      state.preview = data
    })
  },
})

export const previewReducer = {
  developerMapPreview: preview.reducer,
}

export const {
  clearPreview,
  setGrouping,
  setNeedFlyToCenter,
  setPreviousPreview,
  clearPreviousPreview,
} = preview.actions
