import { ID } from '@popety_io/popety-io-lib'
import { ApiClient, getCompanyId } from '../../../services'

const client = new ApiClient()

type activeOrInactivateProps = {
  id: ID
  active: boolean | string
}

type getAllByCompanyProps = {
  limit: string | number
  offset: string | number
  search: string
}

const getAllUsersByCompany = async ({
  limit,
  offset,
  search,
}: getAllByCompanyProps) => {
  const typeSearch = search || ''

  return client.post(
    `/company/${getCompanyId()}/${limit || 25}/${offset || 0}/users/analytics`,
    {
      typeSearch,
    },
  )
}

const activeOrInactivate = async ({ id, active }: activeOrInactivateProps) => {
  const response = active
    ? await client.put(`/company/users/${id}/${getCompanyId()}/inactivate`)
    : await client.put(`/company/users/${id}/${getCompanyId()}/activate`)

  if (response.data) {
    response.data = { id, status: active ? 'inactive' : 'active' }
  }

  return response
}

const getAllOfficesByCompany = async () =>
  client.get(`/company/${getCompanyId()}/offices`)

const activeOrInactivateOffice = async ({
  id,
  active,
}: activeOrInactivateProps) => {
  const response = active
    ? await client.put(`/company/offices/${id}/inactivate`)
    : await client.put(`/company/offices/${id}/activate`)

  return response
}

const addUser = async (input: any) =>
  client.post(`/company/users/${getCompanyId()}/add`, input)

const updateUser = async (input: any) =>
  client.put(`/company/users/${input.id}/${getCompanyId()}/update`, input)

type LogoOfficeProps = {
  file: File | Blob
  id: ID
}

const updateMainOffice = async ({
  id,
  companyId,
}: {
  id: number
  companyId: number
}) => {
  return client.post(`/company/offices/update/${id}/${companyId}`)
}

const updateLogoOffice = async ({ id, file }: LogoOfficeProps) => {
  return client.put(
    `/company/offices/${id}/update-logo`,
    { file },
    { formData: true },
  )
}

const addOffice = async ({ input }: any) => {
  let file: { data?: string } = {}

  const { data } = await client.post(
    `/company/${getCompanyId()}/offices/add`,
    input,
  )

  if (data?.id && input.file) {
    file = await updateLogoOffice({ id: data.id, file: input.file })
  }

  const logo = data.logo || file?.data

  return { ...data, logo }
}

const updateOffice = async (input: any) =>
  client.put(`/company/offices/update/${input.id}`, input)

const companyDashboardService = {
  getAllUsersByCompany,
  activeOrInactivate,
  getAllOfficesByCompany,
  activeOrInactivateOffice,
  addUser,
  updateUser,
  addOffice,
  updateOffice,
  updateLogoOffice,
  updateMainOffice,
}

export default companyDashboardService
