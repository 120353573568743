import { useI18n } from '@popety_io/popety-io-lib'
import {
  ListItem,
  RestrictionUnAffectedContainer,
} from './RestrictionUnAffected.style'

export type RestrictionUnAffectedProps = {
  unAffectedList?: any[]
}

const RestrictionUnAffected = ({
  unAffectedList = [],
}: RestrictionUnAffectedProps) => {
  const { t } = useI18n()

  return (
    <RestrictionUnAffectedContainer>
      {unAffectedList.map((item, i) => (
        <ListItem key={i}>{t(`land.restrictions.zone.${item.name}`)}</ListItem>
      ))}
    </RestrictionUnAffectedContainer>
  )
}

export default RestrictionUnAffected
