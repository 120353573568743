import { ApiClient } from '../../../../services'

const client = new ApiClient()

const fetchBuildingTags = async () => {
  return client.get(`/dashboard/tags/maps/buildings`)
}

const fetchByBuildingId = async (buildingId: string) => {
  return client.get(`/tags/maps/building/${buildingId}`)
}

const fetchLandsTags = async () => {
  return client.get(`/dashboard/tags/maps/lands`)
}

const fetchByLandId = async (landId: string) => {
  return client.get(`/tags/maps/land/${landId}`)
}

const tagsOnMapService = {
  fetchBuildingTags,
  fetchByBuildingId,
  fetchLandsTags,
  fetchByLandId,
}

export default tagsOnMapService
