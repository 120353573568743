import { Select, useRouter, useI18n } from '@popety_io/popety-io-lib'

const FaoFilters = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()

  const items = [{ key: 'development' }, { key: 'other' }].map((r: any) => ({
    ...r,
    value: r.key,
    label: t(`common.fao.${r.key}`),
  }))

  const handleFiltersChange = (name: string) => (newValue: any) => {
    updateQuery({ [name]: newValue?.value || newValue })
  }

  return (
    <Select
      options={items}
      placeholder={t('land.history.category')}
      value={query.classification}
      onChange={handleFiltersChange('classification')}
      sx={{ width: '100%', maxWidth: { xs: '100%', lg: '140px' } }}
      variant="outlined"
      label={t('land.history.category')}
    />
  )
}

export default FaoFilters
