import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const LandOwnersRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 12px;
`

export const LandOwnersItemRoot = styled(Box)<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  span {
    display: flex;
    white-space: pre-wrap;
    color: ${({ active }) => (active ? '#00ade6' : '#000000')};
    &:hover {
      cursor: ${({ active }) => (active ? 'pointer' : 'text')};
    }
  }
`
