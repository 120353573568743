import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const Container = styled(Box)`
  display: flex;
  justify-content: space-around;
  margin-top: 0;
  flex-wrap: nowrap;
`

export const ItemContainer = styled(Box)`
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SpanValue = styled(Box)`
  display: flex;
  text-align: center;
`

export const SpanKey = styled(Box)`
  margin-left: 5px;
  font-weight: 600;
`

export const LogoRoot = styled('div')`
  display: flex;
  border: ${({ color }) => `1px solid ${color}`};
  flex-direction: column;
  padding: 14px;
  border-radius: 12px;
  line-height: 12px;

  span {
    display: flex;
    width: 60px;
    max-width: 65px;
    justify-content: center;
    text-align: center;
  }
`
