import {
  createSlice,
  PayloadAction,
  SerializedError,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import buildingSearchHistoryService from './buildingSearchHistoryService'

interface BuildingSearchHistoryState {
  history: any
  isLoading: boolean
  error: SerializedError | null
}

const initialState: BuildingSearchHistoryState = {
  history: [],
  isLoading: false,
  error: null,
}

export const getBuildingSearchHistory = createAsyncThunk(
  'buildingSearchHistory/getBuildingSearchHistory',
  async () => {
    const response =
      await buildingSearchHistoryService.getBuildingSearchHistory()

    return response
  },
)

export const setBuildingSearchHistory = createAsyncThunk(
  'buildingSearchHistory/setBuildingSearchHistory',
  async (data: any) => {
    const response =
      await buildingSearchHistoryService.setBuildingSearchHistory(data)

    return response
  },
)

const buildingSearchHistory = createSlice({
  name: 'buildingSearchHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuildingSearchHistory.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(
        getBuildingSearchHistory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false
          state.history = action.payload?.data
        },
      )
      .addCase(setBuildingSearchHistory.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(setBuildingSearchHistory.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const buildingSearchHistoryReducer = {
  buildingSearchHistory: buildingSearchHistory.reducer,
}
