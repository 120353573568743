import React from 'react'
import Typography from '@mui/material/Typography'

import { LegendRoot } from './ScoreLegend.style'

export type ScoreLegendProps = {
  title?: string
}

const ScoreLegend = ({ title, ...other }: ScoreLegendProps) => {
  return (
    <LegendRoot variant="outlined" {...(other as any)}>
      {title && <Typography component="span">{title}</Typography>}
    </LegendRoot>
  )
}

export default ScoreLegend
