const analyzedLandsSelector = (state) => state.analizedLand.analyzedLands

const analyzedGroupingSelector = (state) => state.analizedLand.analyzedGrouping

const companyAnalyzedLandsSelector = (state) =>
  state.analizedLand.companyAnalyzedLands

const companyUsersSelector = (state) => state.analizedLand.users

const companyAnalyzedGroupingSelector = (state) =>
  state.analizedLand.companyAnalyzedGrouping

const analyzedLandsAreLoadingSelector = (state) => state.analizedLand.loading

export {
  analyzedLandsSelector,
  companyAnalyzedLandsSelector,
  analyzedGroupingSelector,
  companyUsersSelector,
  companyAnalyzedGroupingSelector,
  analyzedLandsAreLoadingSelector,
}
