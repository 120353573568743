import { ID } from '@popety_io/popety-io-lib'

import { ApiClient, getUserId } from '../../../../services'

const userId = getUserId()

const client = new ApiClient()

export type GetLandOpts = {
  geoCenter?: string | string[]
  addressId?: string | string[]
  estimateId?: string | string[]
  buildingId?: string | string[]
}

const getGeoPoint = (geoCenter: any) => {
  try {
    if (!geoCenter) return null

    const points =
      typeof geoCenter === 'string'
        ? geoCenter?.split('(')?.[1]?.replace(')', '')?.split(' ')
        : []

    const lon = geoCenter?.lon || (points?.[0] && +points[0])
    const lat = geoCenter?.lat || (points?.[1] && +points[1])

    return lon && lat ? { lat, lon } : null
  } catch (error) {
    console.error(error)

    return null
  }
}

const getEstimateSummary = ({
  geoCenter,
  addressId,
  estimateId,
  buildingId,
}: GetLandOpts) => {
  const centers = Array.isArray(geoCenter) ? geoCenter : [geoCenter]
  let query = ''
  let queryEstimateId = ''
  let queryBuildingId = ''
  let queryAddressId = ''

  const geoPoints = centers
    ? centers
        ?.map(getGeoPoint)
        ?.filter(Boolean)
        ?.map((p) => [p?.lon, p?.lat].join(','))
        ?.join('_')
    : null

  if (geoPoints || geoCenter) {
    query = `GEO_CENTER:${geoPoints || geoCenter}`
  }

  if (addressId) {
    queryAddressId = `addressId=${addressId}`
  }

  if (estimateId) {
    queryEstimateId = `&estimateId=${estimateId}`
  }

  if (buildingId) {
    queryBuildingId = `buildingId=${buildingId}`
  }

  return client.post(
    `/estimate/details/${query}?${queryAddressId}${queryEstimateId}${queryBuildingId}`,
  )
}

const getCityGeojson = ({ city }: { city: string }) => {
  return client.get(`/cities/geojson/${city}`)
}

const getLandsOwner = (body: any) => {
  return client.post(`/owner/searchLand`, { ...body })
}

const submitEstimation = ({
  id,
  body,
}: {
  id: ID
  body: Record<string, any>
}) => {
  return client.post(`/estimate/pricehubble/${id}`, { ...body })
}

const savePublicEstimateSummary = (body: any) => {
  return client.put(`/estimate/public`, { ...body })
}

const getPublicEditedEstimate = (id: any) => {
  return client.get(`/estimate/edited/public/${id}`)
}

const getPublicHashEstimate = (id: any) => {
  return client.get(`/estimate/public/hash/${id}`)
}

const saveEditedEstimate = ({ id, body }: { id: ID; body: any }) => {
  return client.put(`/estimate/edited/${id}`, { ...body })
}

const getDwellingDetails = (id: ID) => {
  return client.get(`/estimate/dwelling/${id}`)
}

const savePublicEstimateObject = (body: any) => {
  return client.put(`/estimate/object/${body.id}`, { ...body })
}
const saveEstimatePicture = ({ id, file }: { id: ID; file: File }) => {
  return client.put(`/estimate/images/${id}`, { file }, { formData: true })
}

const deleteEstimatePicture = ({
  id,
  imageUrl,
}: {
  id: ID
  imageUrl: string
}) => {
  return client.delete(`/estimate/images/${id}`, { imageUrl })
}

const getEstimateHistory = () => {
  return client.get(`/estimate/history/${userId}`)
}

const deleteEstimateHistory = (id?: ID) => {
  return client.delete(`/estimate/history/delete/${id}`)
}

const archiveEstimateHistory = ({
  id,
  archive,
}: {
  id?: ID
  archive?: boolean
}) => {
  return client.put(`/estimate/history/archive/${archive}/${id}`)
}

const getLandBuildings = (id: ID) => {
  return client.get(`/land/${id}?fields=buildings`)
}

const getBuildingsByAddressId = (addressIds: ID[]) => {
  return client.post(`/estimate/buildingsByAddressIds`, { addressIds })
}

const summaryService = {
  getEstimateSummary,
  getLandsOwner,
  getCityGeojson,
  submitEstimation,
  savePublicEstimateSummary,
  getPublicEditedEstimate,
  saveEditedEstimate,
  getDwellingDetails,
  saveEstimatePicture,
  deleteEstimatePicture,
  getPublicHashEstimate,
  savePublicEstimateObject,
  getEstimateHistory,
  deleteEstimateHistory,
  archiveEstimateHistory,
  getLandBuildings,
  getBuildingsByAddressId,
}

export default summaryService
