export const potentialSolar = (
  t: Translator,
  solar_average_exposure: number,
) => {
  if (!solar_average_exposure) {
    return null
  }

  if (solar_average_exposure <= 800) {
    return t('search.solar.Weak')
  }
  if (solar_average_exposure <= 1000) {
    return t('search.solar.Medium')
  }
  if (solar_average_exposure <= 1200) {
    return t('search.solar.Good')
  }
  if (solar_average_exposure <= 1400) {
    return t('search.solar.Very good')
  }

  return t('search.solar.Excellent')
}

export const potentialSolarColor = (solar_average_exposure: number) => {
  if (!solar_average_exposure) {
    return null
  }

  if (solar_average_exposure <= 800) {
    return '#4292c6'
  }
  if (solar_average_exposure <= 1000) {
    return '#ffffb2'
  }
  if (solar_average_exposure <= 1200) {
    return '#8ed1a5'
  }
  if (solar_average_exposure <= 1400) {
    return '#fd8d3c'
  }

  return '#e31a1c'
}
