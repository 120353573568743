/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import listingService from '../../../LandDetails/redux/listing/listingService'

const initialState = {
  loading: false,
  listings: [],
}

export const getListingsByIds = createAsyncThunk(
  'estimateListings/getListingsByIds',
  listingService.getListingsByIds,
)

const estimateListings = createSlice({
  name: 'estimateListings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListingsByIds.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getListingsByIds.fulfilled, (state, action) => {
      const { data = [] } = action.payload

      state.loading = false
      state.listings = data || []
    })
  },
})

export const estimateListingsReducer = {
  estimateListings: estimateListings.reducer,
}
