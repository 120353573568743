import React, { ReactNode, useState } from 'react'
import Box from '@mui/material/Box'
import {
  useRouter,
  fromFilter,
  toFilter,
  // Button,
} from '@popety_io/popety-io-lib'

import { MinMaxFields } from '../MinMaxFields'

export type FormRangeProps = {
  name: string
  endAdornment?: ReactNode
  id?: string
  m2?: boolean
  maxLabel?: ReactNode
  minLabel?: ReactNode
  submitText?: string
}

const FormRange = ({
  endAdornment,
  minLabel,
  maxLabel,
  m2,
  name,
  id = name,
  // submitText,
}: FormRangeProps) => {
  const { query, updateQuery } = useRouter()
  const [value, setValue]: any = useState(fromFilter(query[name]))
  // const [submited, setSubmited] = useState(true)

  const handleChange = (newValue: any[]) => {
    // setSubmited(false)
    setValue(newValue)
  }

  const handleSubmit = () => {
    // setSubmited(true)
    updateQuery({ [name]: toFilter(value?.map((i: any) => i ?? '')) })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '4px',
      }}
    >
      <MinMaxFields
        id={id}
        value={value}
        onChange={handleChange as any}
        handleSubmit={handleSubmit}
        m2={m2}
        endAdornment={endAdornment}
        marginBottom={1}
        minLabel={minLabel}
        minPlaceholder=""
        maxLabel={maxLabel}
        maxPlaceholder=""
      />
      {/* <Button
        variant="text"
        color="primary"
        onClick={handleSubmit}
        size="small"
        sx={{
          width: 'fit-content',
          alignSelf: 'flex-end',
        }}
        disabled={!value || submited}
      >
        {submitText || 'Submit'}
      </Button> */}
    </Box>
  )
}

export default FormRange
