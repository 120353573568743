import { useRouter, Feature } from '@popety_io/popety-io-lib'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import HistoryMapClickModal from '../../components/HistoryMapClickModal'
import { faoRangeSelector, faoByLandIdSelector } from '../../redux'

export type FaoClickModalProps = Feature & {
  setClickFeature?: (feature?: Feature) => void
}

const FaoClickModal = ({ properties, setClickFeature }: FaoClickModalProps) => {
  const faoByLandId = useSelector(faoByLandIdSelector)
  const { query } = useRouter()

  const faoRange = useSelector(faoRangeSelector)

  const handleModalClose = () => {
    setClickFeature?.(undefined)
  }

  useEffect(() => {
    setClickFeature?.(undefined)
  }, [query.tab])

  return (
    <HistoryMapClickModal
      open={!!properties}
      onClose={handleModalClose}
      landId={properties?.land_id}
      dataByLandId={faoByLandId}
      value={faoRange}
    />
  )
}

export default FaoClickModal
