import { styled } from '@mui/material/styles'
import ListSubheader from '@mui/material/ListSubheader'
import Autocomplete from '@mui/material/Autocomplete'

export const GroupLabel = styled(ListSubheader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  background-color: white;
  width: 100%;

  > div:last-child {
    background-color: #ebecf0;
    border-radius: 2em;
    color: #172b4d;
    display: inline-block;
    font-weight: normal;
    line-height: 8px;
    min-width: 1px;
    padding: 5px;
    text-align: center;
  }
`

export const LocationsFilterInputRoot = styled(Autocomplete)`
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .MuiChip-root {
    height: 18px;

    .MuiChip-deleteIconFilledColorDefault {
      font-size: 1rem;
    }
  }
`
