/**
 * Draw an arrow extending from the center of the screen in the direction of
 * - surise
 * - sunset
 * - the current location of the sun
 * Arrows should be drawn as the top-most layer of the map
 * Arrows should point in the correct direction even when pitch is > 0
 *
 * Implementation: GeoJSON layer whose geometry contains three lines
 * - need to calculate lat,lng for each arrow's tip as time/date changes
 */
import { Map } from '@popety_io/popety-io-lib'

const generateGeoJSON = ({
  center,
  points,
}: {
  center: number[]
  points: number[][]
}) => {
  const features = []
  const base = {
    type: 'Feature' as 'Feature',
    geometry: { type: 'LineString' as 'LineString', coordinates: [] },
    properties: null,
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < points.length; i++) {
    features.push({
      ...base,
      geometry: { ...base.geometry, coordinates: [center, points[i]] },
    })
  }

  return features
}

const LINES = [
  { id: 'sunrise-direction', color: 'orange' },
  { id: 'sunset-direction', color: 'orangered' },
  { id: 'sun-direction', color: 'gold' },
]

const drawLines = (map: Map, lng: number, lat: number, now: Date) => {
  try {
    const suncalc = (window as any).SunCalc
    const { sunrise, sunset } = suncalc.getTimes(now, lat, lng)

    const bounds = map.getBounds()
    const w = Math.abs(bounds.getWest() - lng) // +
    const e = Math.abs(lng - bounds.getEast()) // -
    const n = Math.abs(bounds.getNorth() - lat) // +
    const s = Math.abs(lat - bounds.getSouth()) // -
    const delta = Math.min(Math.min(e, w), Math.min(n, s))

    // azimuth:
    // N is -PI or PI, sin: -0, 0 cos: -1
    // E is -PI/2, sin: -1  cos: 0
    // S is 0, sin: 0, cos: 1
    // W is PI/2, sin: 1, cos: 0
    // adjust line length based on height of sun
    const pos = (position: any) => {
      const { azimuth, altitude } = position
      const ew = -Math.sin(azimuth) // E is 1, W is -1
      const ns = -Math.cos(azimuth) // N is 1, S is -1
      const alt = 1 - altitude / (Math.PI / 2)
      const padding = 0.8

      return [
        lng + padding * alt * ew * delta,
        lat + padding * alt * ns * delta,
      ]
    }

    for (const line of LINES) {
      const source = map.getSource(line.id) as any

      if (source) {
        let sunPos

        if (line.id === 'sunrise-direction') {
          sunPos = suncalc.getPosition(sunrise, lat, lng)
        } else if (line.id === 'sunset-direction') {
          sunPos = suncalc.getPosition(sunset, lat, lng)
        } else {
          sunPos = suncalc.getPosition(now, lat, lng)
        }
        const features = generateGeoJSON({
          center: [lng, lat],
          points: [pos(sunPos)],
        })

        source.setData({ type: 'FeatureCollection', features })
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export { LINES, drawLines }
