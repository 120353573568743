import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RefreshIcon from '@mui/icons-material/Refresh'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import {
  Alert,
  ProgressIndicator,
  fetchOnce,
  useI18n,
} from '@popety_io/popety-io-lib'

import Altimetry from '../components/Altimetry'
import {
  altimetryDataSelector,
  altimetrySelector,
  geoLoadingSelector,
  getAltimetry,
  landSelector,
} from '../redux'

const GeoAltimetry = () => {
  const land: any = useSelector(landSelector)
  const altimetry = useSelector(altimetrySelector)
  const altimetryData = useSelector(altimetryDataSelector)
  const loading = useSelector(geoLoadingSelector)

  const { t } = useI18n()

  const [timeoutError, setTimeoutError] = useState(false)

  const dispatch: any = useDispatch()

  const refetch = () => {
    setTimeoutError(false)
    const precision = Math.max(1, Math.round(land.area / 1000))
    const landId = land.land_id
    const input = { landId, precision, region: land.region_name }

    dispatch(getAltimetry(input))
  }

  useEffect(() => {
    const i = setTimeout(() => {
      if (loading) setTimeoutError(true)
    }, 20000)

    return () => clearTimeout(i)
  }, [loading])

  useEffect(() => {
    const precision = Math.max(1, Math.round(land.area / 1000))
    const landId = land.land_id
    const input = { landId, precision, region: land.region_name }

    if (input.landId) {
      fetchOnce('getAltimetry', input, () => {
        dispatch(getAltimetry(input))
      })
    }
  }, [land.land_id, land.area, land.region_name])

  const isValidAltimetry = !!(
    altimetryData && altimetry?.altimetry_grid?.length
  )

  return (
    <Box position="relative" flexGrow={1} minHeight="100px">
      {(loading || altimetry === undefined) && (
        <>
          {timeoutError ? (
            <IconButton onClick={refetch}>
              <RefreshIcon />
            </IconButton>
          ) : (
            <ProgressIndicator circular />
          )}
        </>
      )}
      <Alert open={altimetry === null && !loading} error>
        {t('common.notAvailable')}
      </Alert>

      {isValidAltimetry && (
        <Altimetry
          precision={altimetry.precision}
          altitudeMax={altimetry.altitude_max}
          altitudeMin={altimetry.altitude_min}
          data={altimetryData}
        />
      )}
    </Box>
  )
}

export default GeoAltimetry
