import { createSelector } from '@reduxjs/toolkit'
import * as turf from '@turf/turf'

const locationGeojsonSelector = (state) =>
  state.developerMapForm.locationGeojson

const geojsonByLocationSelector = (state) =>
  state.developerMapForm.geojsonByLocations

const isochroneSelector = (state) => state.developerMapForm.isochrone

const isochroneGeoSelector = createSelector(
  (state) => state.developerMapForm.isochrone?.features,
  (features) => (features || [])[0]?.geometry,
)

const bboxIsochroneSelector = createSelector(
  isochroneGeoSelector,
  (geojson) => {
    if (!geojson) return null

    return turf.bbox(geojson)
  },
)

const userFiltersSelector = (state) => state.developerMapForm.userFilter

const companyFiltersSelector = (state) => state.developerMapForm.companyFilter

const buildingFiltersSelector = (state) =>
  state.developerMapForm.buildingFilters

const tableDataSelector = createSelector(
  (state) => state.developerMapForm.tableData?.result,
  (result) => result || [],
)

const tableDataCountSelector = (state) =>
  state.developerMapForm.tableDataCount ||
  state.developerMapForm.tableData?.total ||
  0

const openFilterSelector = (state) => state.developerMapForm.openFilter

const userBuildingFiltersSelector = (state) =>
  state.developerMapForm.buildingUserFilter

const selectedLandsSelector = (state) => state.developerMapForm.selectedLands

const develperFormLoadingSelector = (state) => state.developerMapForm.loading

const selectedBuildingsSelector = (state) =>
  state.developerMapForm.selectedBuildings

export {
  geojsonByLocationSelector,
  locationGeojsonSelector,
  isochroneSelector,
  isochroneGeoSelector,
  userFiltersSelector,
  companyFiltersSelector,
  buildingFiltersSelector,
  tableDataSelector,
  tableDataCountSelector,
  openFilterSelector,
  userBuildingFiltersSelector,
  bboxIsochroneSelector,
  selectedLandsSelector,
  develperFormLoadingSelector,
  selectedBuildingsSelector,
}
