/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { Grid, Box, Typography, Paper } from '@mui/material'
import {
  TypeIcon,
  LivingSpaceIcon,
  PieceIcon,
  ConstructionYearIcon,
  formatPrice,
  useI18n,
  format,
  ellipsis,
} from '@popety_io/popety-io-lib'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import EstimateHistoryMenu from './EstimateHistoryMenu'
import useEstimateHistory from './useEstimateHistory'
import { handleOpenLink } from './utils'

const EstimateHistoryContainer = ({ element }: any) => {
  const { t } = useI18n()

  const {
    type,
    area,
    rooms,
    constructionYear,
    price,
    priceM2,
    dwellingId,
    condition,
    renovationYear,
    expirationDate,
    hash,
    baths,
  }: any = useEstimateHistory({ element })

  const options = [
    {
      label: t('common.type'),
      name: 'type',
      value: type ? t(`common.${type}`) : '-',
      icon: <TypeIcon />,
    },
    {
      label: t('financial.Surface habitable'),
      name: 'area',
      value: area || '-',
      endAdornment: 'm²',
      icon: <LivingSpaceIcon />,
    },
    {
      label: t('common.rooms'),
      name: 'rooms',
      value: rooms || '-',
      icon: <PieceIcon />,
    },
    {
      label: t('common.landRadarInfo.buildingYear'),
      name: 'construction_date',
      value: constructionYear || '-',
      icon: <ConstructionYearIcon />,
    },
  ]

  const handleOpen = () => {
    handleOpenLink({
      dwellingId,
      id: element?.id,
      geo_center:
        element?.value?.geoCenter || element?.default_value?.geo_center,
      area,
      type,
      rooms,
      constructionYear,
      condition,
      renovationYear,
      baths,
    })
  }

  return (
    <Grid
      container
      key={element.id}
      display="flex"
      justifyContent="space-between"
      component={Paper}
      elevation={2}
      p={1}
      sx={{ opacity: element?.archive ? 0.33 : 1, cursor: 'pointer' }}
      onClick={handleOpen}
    >
      <Grid item display="flex" gap={1} xs={12} md={6}>
        <Box
          sx={{
            width: {
              xs: '100vw',
              md: '30%',
              minWidth: '100px',
            },
          }}
        >
          <img
            alt="mapbox-land"
            src={
              element?.value_edited?.pictures?.[0] || '/assets/no-image.jpeg'
            }
            height="100%"
            width="100%"
            style={{ maxHeight: '100px' }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            component="h4"
            style={{ textTransform: 'uppercase' }}
            title={t('common.estimateion')}
          >
            {ellipsis(t('common.estimateion'), 40)}
          </Box>
          <Box lineHeight="15px">
            <Box component="h3" sx={{ color: 'primary.main' }}>
              {formatPrice(price)}
            </Box>
            <Box component="h5" sx={{ color: '#969696' }}>
              {formatPrice(priceM2)} / m2
            </Box>
          </Box>
          <Box component="div" display="flex" alignItems="center">
            <LocationOnIcon fontSize="small" />
            {element?.value?.address || element?.default_value?.address}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {options.map((option, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              marginBottom: '4px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={7}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    color: '#969696',
                    fontSize: '0.9rem',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  {option.icon}
                  <Typography
                    fontWeight="500"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {option.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sm={5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  fontWeight="500"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {option.value}
                  {option.endAdornment &&
                    option.value !== '-' &&
                    `${option.endAdornment}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={1}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexDirection="column"
      >
        <EstimateHistoryMenu
          type={type}
          id={element?.id}
          dwellingId={dwellingId}
          geo_center={
            element?.value?.geoCenter || element?.default_value?.geo_center
          }
          area={area}
          rooms={rooms}
          constructionYear={constructionYear}
          condition={condition}
          renovationYear={renovationYear}
          expirationDate={expirationDate}
          hash={hash}
          baths={baths}
          archive={element?.archive}
        />
        <Box component="h5" fontWeight={400}>
          {element?.create_timestamp && format(element?.create_timestamp, 'P')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default EstimateHistoryContainer
