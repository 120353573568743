import { ID } from '@popety_io/popety-io-lib'
import { ApiClient } from '../../../../services'

const client = new ApiClient()

type ReportTypes = {
  reportCategoryName: string
  regionId?: ID
}

const getAllReports = async ({ reportCategoryName, regionId }: ReportTypes) => {
  let query = `/report/?reportCategoryName=${reportCategoryName}`

  if (regionId) {
    query += `&regionId=${regionId}`
  }

  return client.get(query)
}

const reportsService = {
  getAllReports,
}

export default reportsService
