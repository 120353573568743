import { useState } from 'react'
import CardHeader from '@mui/material/CardHeader'

import HistoryAccordion from '../../../../LandDetails/components/HistoryAccordion/HistoryAccordion'
import LandSummaryTitle from './LandSummaryTitle'
import LandSummaryInformations from './LandSummaryInformations'
import LandSummaryAssignement from './LandSummaryAssignement'
import LandSummaryRestrictions from './LandSummaryRestrictions'
import LandSummaryOwners from './LandSummaryOwners'

export type LandProps = {
  land: {
    land_id: string | number
    geo_center: { lat: number; lon: number }
    city_name: string
    city_code_number: string | number
    area: number
    building_area: boolean
  }
  i: number
  estimate?: boolean
}

const LandSummaryContent = ({ land, i, estimate }: LandProps) => {
  const [expandedId, setExpandedId] = useState('land_accordion_0')

  const handleExpandChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedId(isExpanded ? panel : '')
    }

  if (!land) return null

  return (
    <div style={{ margin: 12 }}>
      <HistoryAccordion
        key={land.land_id}
        sx={{ width: '100%', marginBottom: 1 }}
        col1={{ title: 'title' }}
        col2={{ title: 'titeuf' }}
        expanded={expandedId === `land_accordion_${i}`}
        onChange={
          land.land_id ? handleExpandChange(`land_accordion_${i}`) : undefined
        }
        headerComponent={
          <CardHeader
            sx={{
              padding: 0,
              margin: 0,
              border: 'none',
              boxShadow: 'none',
              width: '100%',
            }}
            title={<LandSummaryTitle land={land} />}
          />
        }
      >
        <LandSummaryInformations land={land} />
        <LandSummaryAssignement land={land} />
        <LandSummaryRestrictions land={land} />
        {!estimate && <LandSummaryOwners land={land} />}
      </HistoryAccordion>
    </div>
  )
}

export default LandSummaryContent
