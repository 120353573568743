import Box from '@mui/material/Box'

import BuildingInfo from './BuildingInfo'
import Parcelle from './Parcelle'
import Energie from './Energie'
import IndiceDeLocalisation from './IndiceDeLocalisation'
import { SummaryRoot } from './Summary.style'

const Summary = () => {
  return (
    <SummaryRoot>
      {/* <BuildingNotePreview buildingId={query?.buildingId} /> */}
      <BuildingInfo />
      <Parcelle />
      <Energie />
      <IndiceDeLocalisation />
      <Box />
    </SummaryRoot>
  )
}

export default Summary
