import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AnyAction,
} from '@reduxjs/toolkit'
import { addTagToList } from '../../../Dashboard/redux'
import { addBuildingTagToList } from '../../../BuildingDashboard/redux'
import { TagsState, RejectedAction } from './tag.utils'
import tagService from './tagService'

const initialState: TagsState = {
  myTags: {
    rows: [],
    count: 25,
  },
  isLoading: false,
  error: null,
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected')
}

export const getTags = createAsyncThunk('myTags/getTags', tagService.getTags)

export const updateTag = createAsyncThunk(
  'myTags/updateTag',
  tagService.updateTag,
)

export const deleteTag = createAsyncThunk(
  'myTags/deleteTag',
  tagService.deleteTag,
)

export const addTag = createAsyncThunk(
  'myTags/addTag',
  async (payload: { value: string; color: string }, thunkAPI) => {
    const { dispatch } = thunkAPI

    const response = await tagService.addTag({
      value: payload.value,
      color: payload.color,
    })

    dispatch(addTagToList(response))
    dispatch(addBuildingTagToList(response))

    return response
  },
)

const myTags = createSlice({
  name: 'myTags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getTags.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.myTags = action.payload.data
      })
      .addCase(updateTag.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateTag.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.myTags = {
          rows: (state.myTags.rows = state.myTags.rows.map((item: any) => {
            if (item.id === action.payload.tag_id) {
              return {
                ...item,
                value: action.payload.value,
                color: action.payload.color,
              }
            }

            return item
          })),
          count: state.myTags.count + 1,
        }
      })
      .addCase(deleteTag.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(deleteTag.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.myTags = {
          rows: (state.myTags.rows = state.myTags.rows.filter(
            (item: any) => item.id !== action.payload.id,
          )),
          count: state.myTags.count - 1,
        }
      })
      .addCase(addTag.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(addTag.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.myTags.rows = [
          {
            id: action.payload.id,
            value: action.payload.value,
            color: action.payload.color,
            active: true,
            update_timestamp: new Date().toISOString(),
          },
          ...state.myTags.rows,
        ]
        state.myTags.count += 1
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.isLoading = false
        state.error = action.error
      })
  },
})

export const myTagsReducer = {
  myTags: myTags.reducer,
}
