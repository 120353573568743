import React, { FC, useState, useEffect } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { FormField, Select, useI18n } from '@popety_io/popety-io-lib'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  ButtonAddNewUnit,
  MixOfUnitsBox,
  UnitScoreSpan,
  UnitTotalPercentSpan,
  UnitTotalSpan,
  UnitTotalTitleSpan,
} from './MixOfUnits.style'

export interface UnitValue {
  id?: number | string
  type: string
  percent: number
  min_area_sqm: number
  max_area_sqm: number
  car_park_nb: number
}
export interface MixOfUnitsProps {
  values?: Array<UnitValue>
  onChange: any
}

const initialUnit: UnitValue = {
  id: Math.random(),
  car_park_nb: 0,
  max_area_sqm: 0,
  min_area_sqm: 0,
  percent: 0,
  type: '',
}

const MixOfUnits: FC<MixOfUnitsProps> = ({ onChange }) => {
  const [data, setData] = useState<Array<UnitValue>>([{ ...initialUnit }])

  const { t } = useI18n()

  const total = data.reduce(
    (pre, cur) => {
      pre.car_park_nb += cur.car_park_nb
      pre.min_area_sqm += cur.min_area_sqm
      pre.max_area_sqm += cur.max_area_sqm
      pre.percent += cur.percent

      return pre
    },
    { ...initialUnit } as UnitValue,
  )

  const onExtendData = () => {
    setData((pre) => pre.concat({ ...initialUnit, id: Math.random() }))
  }

  const onChangeData = (row: any) => {
    setData((pre) => pre.map((item) => (item.id === row.id ? row : item)))
  }

  const onDeleteData = (id?: number | string) => {
    setData((pre) => pre.filter((item) => item.id !== id))
  }

  useEffect(() => {
    onChange({
      dwelling_mix: {
        mix_nb: data.length,
        mix_perc_total: total.percent ? Number(total.percent.toFixed(2)) : 0,
        mix: data,
      },
    })
  }, [data])

  return (
    <Box>
      <MixOfUnitsBox>
        <Typography component="h3">{t('common.unit_type')}</Typography>
        <UnitScoreSpan>%</UnitScoreSpan>
        <UnitScoreSpan>{t('common.landMinArea')} (m2)</UnitScoreSpan>
        <UnitScoreSpan>{t('common.landMaxArea')} (m2)</UnitScoreSpan>
        <UnitScoreSpan sx={{ textTransform: 'capitalize' }}>
          {t('building.type.AUTOGARAGE').toLowerCase()}
        </UnitScoreSpan>
      </MixOfUnitsBox>
      {data.map((row) => (
        <MixOfUnitsBox key={row.id}>
          <Select
            options={[
              { label: 'Studio', value: 'Studio' },
              { label: '1 Bedroom', value: '1 Bedroom' },
              { label: '2 Bedroom', value: '2 Bedroom' },
              { label: '3 Bedroom', value: '3 Bedroom' },
              { label: '4 Bedroom', value: '4 Bedroom' },
              { label: '5 Bedroom', value: '5 Bedroom' },
              { label: '6 Bedroom', value: '6 Bedroom' },
            ]}
            value={row.type}
            onChange={(e: any) => {
              onChangeData({ ...row, type: e?.value })
            }}
          />
          <FormField
            value={Math.round(row.percent * 100)}
            onChange={(e: any) => {
              onChangeData({ ...row, percent: Number(e.target.value) / 100 })
            }}
          />
          <FormField
            value={row.min_area_sqm}
            onChange={(e: any) => {
              onChangeData({ ...row, min_area_sqm: Number(e.target.value) })
            }}
          />
          <FormField
            value={row.max_area_sqm}
            onChange={(e: any) => {
              onChangeData({ ...row, max_area_sqm: Number(e.target.value) })
            }}
          />
          <FormField
            value={row.car_park_nb}
            onChange={(e: any) => {
              onChangeData({ ...row, car_park_nb: Number(e.target.value) })
            }}
          />
          <IconButton onClick={() => onDeleteData(row.id)}>
            <DeleteIcon />
          </IconButton>
        </MixOfUnitsBox>
      ))}
      <ButtonAddNewUnit onClick={onExtendData}>
        + {t('common.addNewUnitType')}
      </ButtonAddNewUnit>

      <MixOfUnitsBox>
        <UnitTotalTitleSpan>{t('common.total_and_average')}</UnitTotalTitleSpan>
        <UnitTotalPercentSpan>
          {Math.round(total.percent * 100)}%
        </UnitTotalPercentSpan>
        <UnitTotalSpan>{total.min_area_sqm}m2</UnitTotalSpan>
        <UnitTotalSpan>{total.max_area_sqm}m2</UnitTotalSpan>
        <UnitTotalSpan>{total.car_park_nb}</UnitTotalSpan>
      </MixOfUnitsBox>
    </Box>
  )
}

export default MixOfUnits
