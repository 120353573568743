import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const DivContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: -5px;

  .title {
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(0, 173, 230);
  }

  .gray {
    font-size: 0.8rem;
    color: rgb(154, 174, 187);
  }

  .MuiTab-root {
    text-transform: none;
  }
`

export const StyledSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`
