import { useSelector } from 'react-redux'
import { landsSelector } from '../redux'
import LandSummaryHeader from './components/LandSummaryHeader'
import LandSummaryContent from './components/LandSummaryContent'

const LandSummary = () => {
  const lands = useSelector(landsSelector)

  return (
    <div>
      <LandSummaryHeader lands={lands} />
      {lands?.map((land: any, i: number) => (
        <LandSummaryContent key={land.land_id} land={land} i={i} />
      ))}
    </div>
  )
}

export default LandSummary
