const powerSourceHeaterLegend = (t: Translator) =>
  [
    { value: 'Mazout', color: '#000000' },
    { value: 'Gaz', color: '#1f78b4' },
    {
      value: 'Indéterminé',
      color: '#ccc',
    },
    { value: 'Bois (générique)', color: '#b15928' },
    { value: 'Aucune', color: '#b2df8a' },
    { value: 'Electricité', color: '#a6cee3' },
    { value: 'Chaleur produite à distance (générique)', color: '#e31a1c' },
    { value: 'Air', color: '#fdbf6f' },
    { value: 'Sonde géothermique', color: '#ff7f00' },
    { value: 'Soleil (thermique)', color: '#33a02c' },
  ].map((l) => ({ ...l, name: t(`search.power.${l.value}` as I18nKey) }))

export const powerSourceWaterHeater = (t: Translator) =>
  [
    { value: 'Mazout', color: '#000000' },
    { value: 'Gaz', color: '#1f78b4' },
    {
      value: 'Indéterminé',
      color: '#ccc',
    },
    { value: 'Bois (générique)', color: '#b15928' },
    { value: 'Aucune', color: '#b2df8a' },
    { value: 'Electricité', color: '#a6cee3' },
    { value: 'Chaleur produite à distance (générique)', color: '#e31a1c' },
    { value: 'Air', color: '#fdbf6f' },
    { value: 'Sonde géothermique', color: '#ff7f00' },
    { value: 'Soleil (thermique)', color: '#33a02c' },
  ].map((l) => ({ ...l, name: t(`search.power.${l.value}` as I18nKey) }))

export const solarAverageExposure = (t: Translator) =>
  [
    { value: 'Weak', color: '#4292c6' },
    { value: 'Medium', color: '#ffffb2' },
    {
      value: 'Good',
      color: '#fecc5c',
    },
    { value: 'Very good', color: '#fd8d3c' },
    { value: 'Excellent', color: '#e31a1c' },
  ].map((l) => ({ ...l, name: t(`search.solar.${l.value}` as I18nKey) }))

export const energyOptions = (t: Translator) => {
  return [
    {
      value: 'power_source_heater',
      label: t('common.power_source_heater'),
      src: '/assets/img/buildingLayers/heater.png',
      legend: powerSourceHeaterLegend(t),
      group: 'energy',
    },
    {
      value: 'power_source_water_heater',
      label: t('common.power_source_water_heater'),
      src: '/assets/img/buildingLayers/water.png',
      legend: powerSourceWaterHeater(t),
      group: 'energy',
    },
    {
      value: 'solar_average_exposure',
      label: t('common.solar_average_exposure'),
      src: '/assets/img/buildingLayers/solar.png',
      legend: solarAverageExposure(t),
      group: 'energy',
    },
  ]
}
