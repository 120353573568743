import { ProgressIndicator } from '@popety_io/popety-io-lib'
import { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { forgetAuthUser, logout } from '../pages/Login'

import RouteGuard from './RouteGuard'
import { navigations } from './navigations'

const AppRoutes = () => {
  const dispatch = useDispatch()

  const whitePageCounter = Number(localStorage.getItem('ww2') || 0)

  useEffect(() => {
    if (whitePageCounter !== 1) {
      // sessionStorage.clear()
      if ('caches' in window) {
        // Open the Cache Storage
        caches.keys().then((cacheNames) => {
          // Iterate through all cache names
          cacheNames.forEach((cacheName) => {
            // Clear each cache
            caches.delete(cacheName)
          })
        })
      }
      localStorage.setItem('ww2', String(1))
      dispatch(logout())
      dispatch(forgetAuthUser())
    }
  }, [])

  return (
    <Suspense fallback={<ProgressIndicator circular anchor="window" />}>
      <Routes>
        {navigations.map((nav) => (
          <Route
            key={nav.path}
            element={
              <RouteGuard
                auth={nav.private}
                admin={nav.admin}
                landdev={nav.landdev}
                estimate={nav.estimate}
              />
            }
          >
            <Route path={nav.path} element={nav.page} />
          </Route>
        ))}
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
