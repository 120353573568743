import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import {
  CreateNewProjectButton,
  ProjectDesignCard,
  useI18n,
} from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { projectsSelector } from '../redux/generativeDesign'
import { DesignProjectListRoot } from './Design.style'

interface IProps {
  onAddNew: () => void
}
const DesignProjectList: FC<IProps> = ({ onAddNew }) => {
  const { t } = useI18n()
  const projects = useSelector(projectsSelector)

  return (
    <DesignProjectListRoot>
      <Typography component="h3">
        {t('land.design.savedProjectForThisLand', {
          num: projects?.length || 0,
        })}
      </Typography>
      {projects.map((project: any, k: number) => (
        <ProjectDesignCard
          key={`project_design_${k}`}
          actions={project.actions}
          valuesTooltip={[]}
          img={project.img}
          projectTitle={project.projectTitle}
          description={project.description}
          createdInfo={project.createdInfo}
          modifiedInfo={project.modifiedInfo}
        />
      ))}

      <Box onClick={onAddNew}>
        <CreateNewProjectButton label="CREATE A NEW PROJET" />
      </Box>
    </DesignProjectListRoot>
  )
}

export default DesignProjectList
