import { formatPrice, formatCurrency } from '@popety_io/popety-io-lib'

// const noiseLegend = () => [
//   { value: '< 30 dB', color: '#E0F3F8' },
//   { value: '30 - 40 dB', color: '#ABD9EA' },
//   {
//     value: '40 - 50 dB',
//     color: '#C5D564',
//   },
//   { value: '50 - 60 dB', color: '#FCD923' },
//   { value: '60 - 70 dB', color: '#FCC423' },
//   { value: '70 - 80 dB', color: '#FC5E22' },
//   { value: '80 - 100 dB', color: '#F14C11' },
// ]

const min = 4000
const max = 19000
const diff = max - min

const pricingLegend = () => [
  { value: `< ${formatPrice(min)}`, color: '#5e4fa2' },
  {
    value: `${formatPrice(min)} - ${formatCurrency(min + Math.round(diff / 9) * 1, '')}`,
    color: '#3288bd',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 1)} - ${formatCurrency(min + Math.round(diff / 9) * 2, '')}`,
    color: '#66c2a5',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 2)} - ${formatCurrency(min + Math.round(diff / 9) * 3, '')}`,
    color: '#abdda4',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 3)} - ${formatCurrency(min + Math.round(diff / 9) * 4, '')}`,
    color: '#e6f598',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 4)} - ${formatCurrency(min + Math.round(diff / 9) * 5, '')}`,
    color: '#fee08b',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 5)} - ${formatCurrency(min + Math.round(diff / 9) * 6, '')}`,
    color: '#fdae61',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 6)} - ${formatCurrency(min + Math.round(diff / 9) * 7, '')}`,
    color: '#f46d43',
  },
  {
    value: `${formatPrice(min + Math.round(diff / 9) * 7)} - ${formatCurrency(min + Math.round(diff / 9) * 8, '')}`,
    color: '#d53e4f',
  },
  { value: `> ${formatPrice(max)}`, color: '#9e0142' },
]

export const pricingOptions = (t: Translator) => {
  return [
    {
      value: 'estimate-purchase-apartment-v1',
      label: t('common.estimate-purchase-apartment-v1'),
      legend: pricingLegend(),
      group: 'environment',
    },
    {
      value: 'estimate-purchase-house-v1',
      label: t('common.estimate-purchase-house-v1'),
      legend: pricingLegend(),
      group: 'environment',
    },
  ]
}
