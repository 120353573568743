import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const userId: string = getUserId()

const getEstimateActionHistory = async (estimateId: string | string[]) => {
  return client.get(`/dashboard/actionHistory/estimate/${estimateId}`)
}

const addEstimateNote = async (data: Record<string, any>) => {
  const response = await client.post(`/dashboard/estimate-notes`, data)

  const user = JSON.parse(localStorage.user || '')

  if (response.data) {
    response.data = response.data.map((note: any) => ({
      ...data,
      action: 'note',
      first_name: user.first_name,
      last_name: user.last_name,
      photo: user.photo,
      user_id: user.id,
      ...note,
      note_id: note.note_id,
    }))
  }

  return response
}

const removeEstimateNote = async (noteId: string) => {
  client.delete(`/dashboard/estimate-notes/${noteId}`)

  return { noteId }
}

const updateEstimateNote = async (input: Record<string, any>) => {
  const { note_id, content } = input

  const { data } = await client.put(
    `/dashboard/estimate-notes/${note_id}/${userId}`,
    {
      content,
    },
  )

  return { ...input, update_timestamp: data.update_timestamp || new Date() }
}

const estimateActionHistoryService = {
  addEstimateNote,
  removeEstimateNote,
  updateEstimateNote,
  getEstimateActionHistory,
}

export default estimateActionHistoryService
