import { Bullet, i18n } from '@popety_io/popety-io-lib'

const options1 = () => [
  {
    color: 'rgba(26, 150, 65, 0.7)',
    value: 5,
    text: i18n.t('common.public_transport.very well served'),
    fillCount: 5,
  },
  {
    color: 'rgba(166, 217, 106, 0.7)',
    value: 4,
    text: i18n.t('common.public_transport.well served'),
    fillCount: 4,
  },
  {
    color: 'rgb(240, 240, 72)',
    value: 3,
    text: i18n.t('common.public_transport.medium served'),
    fillCount: 3,
  },
  {
    color: 'rgb(233, 123, 14)',
    value: 2,
    text: i18n.t('common.public_transport.badly served'),
    fillCount: 2,
  },
  {
    color: 'rgba(215, 25, 28, 0.7)',
    value: 1,
    text: i18n.t('common.public_transport.very badly served'),
    fillCount: 1,
  },
]

const options2 = (isNoise?: boolean) => [
  {
    color: 'rgba(127, 188, 83, 0.7)',
    value: 3,
    text: isNoise
      ? i18n.t('common.noise_exposure.up to 45 db')
      : i18n.t('common.travel_time_to_centers.short'),
    fillCount: 3,
  },
  {
    color: 'rgb(233, 123, 14)',
    value: 2,
    text: isNoise
      ? i18n.t('common.noise_exposure.45 to 52 db')
      : i18n.t('common.travel_time_to_centers.medium'),
    fillCount: 2,
  },
  {
    color: 'rgba(177, 73, 69, 0.7)',
    value: 1,
    text: isNoise
      ? i18n.t('common.noise_exposure.more than 52 db')
      : i18n.t('common.travel_time_to_centers.long'),
    fillCount: 1,
  },
]

export type RatingProps = {
  location?: string
  isTransportQuality?: boolean
  isNoise?: boolean
  label: string
}

const Rating = ({
  isTransportQuality,
  location,
  label,
  isNoise,
}: RatingProps) => {
  let score = 1
  const data = isTransportQuality ? options1() : options2(isNoise)

  if (
    location === 'very well served' ||
    location === 'well served' ||
    location === 'up to 45 db' ||
    location === 'short' ||
    location === 'more than 100ha' ||
    location === 'more than 13 peaks'
  ) {
    score = 3
  }

  if (
    location === 'medium' ||
    location === '45 to 52 db' ||
    location === 'medium served' ||
    location === 'more than 0 up to 100ha' ||
    location === '5 up to 13 peaks'
  ) {
    score = 2
  }

  if (isTransportQuality && location === 'very well served') {
    score = 5
  }

  if (isTransportQuality && location === 'well served') {
    score = 4
  }

  if (isTransportQuality && location === 'badly served') {
    score = 2
  }

  if (isTransportQuality && location === 'very badly served') {
    score = 1
  }

  if (isTransportQuality && location === 'medium served') {
    score = 3
  }

  return <Bullet data={data} value={score} title={label} />
}

export default Rating
