import Box from '@mui/material/Box'
import { css, styled } from '@mui/material/styles'

export const LoginRoot = styled(Box)`
  margin: 0 auto;
  padding: 20px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .title-login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 48px;
    text-align: center;
    padding: 20px;
  }

  > h1,
  > p {
    text-align: center;
    margin: 0;
    margin-bottom: 16px;
    max-width: 360px;
  }

  > form {
    margin: unset;
    width: 100%;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiAlert-root {
    padding: 0 8px;
    margin-top: 16px;
    align-items: center;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      > .title-login {
        display: none;
      }
    }
  `}
`
