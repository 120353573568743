export const getPriceData = (data: any[], coef = 1) => {
  const formatNum = (price: number) => {
    if (!price) return price

    if (coef === 12) return Math.round(price * coef)

    return +parseFloat((price * coef) as any).toFixed(2)
  }

  return data.map((stats, index, array) => {
    const divider =
      1 * stats.doc_count +
      (array[index - 1] ? array[index - 1].doc_count : 0) +
      (array[index - 2] ? array[index - 2].doc_count : 0)

    const min =
      ((array[index - 1]
        ? array[index - 1].median_price.values['25.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['25.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['25.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const max =
      ((array[index - 1]
        ? array[index - 1].median_price.values['75.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['75.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['75.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const priceM2 =
      ((array[index - 1]
        ? array[index - 1].median_price.values['50.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['50.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['50.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const price_per_square_meter = divider < 5 ? null : priceM2
    const price_per_square_meter_range = divider < 5 ? null : [min, max]

    return {
      date: stats.key_as_string,
      listings: stats.doc_count,
      priceM2: formatNum(price_per_square_meter!),
      priceM2Range: price_per_square_meter_range?.map((i) => formatNum(i)),
    }
  })
}

export const getPriceHistoryMinusMonth = (month: number, data: any[]) => {
  if (!data?.length) return null

  const denum = data[data.length - 1 - month]?.priceM2

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 1]?.priceM2 / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const getPriceChange = (data: any[]) => {
  if (!data?.length) return null

  let firstPrice = null

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].priceM2) {
      firstPrice = data[i].priceM2
      break
    }
  }

  if (!firstPrice) return null

  let lastPrice = data[data.length - 1].priceM2

  for (let i = data.length - 1; i >= 0; i -= 1) {
    if (data[i].priceM2) {
      lastPrice = data[i].priceM2
      break
    }
  }

  if (!lastPrice) return null

  const score = (lastPrice / firstPrice - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const getListingHistoryMinusMonth = (month: number, data: any[]) => {
  if (!data?.length) return null

  const denum = data[data.length - 2 - month]?.listings

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 2]?.listings / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const calculatePriceM2GrowthOverLast12Months = (data: any) => {
  const currentDate = new Date()
  const lastYearDate = new Date(
    currentDate.setMonth(currentDate.getMonth() - 12),
  )

  const lastYearData = data.filter((d: any) => {
    return new Date(d.date) >= lastYearDate
  })

  const lastYearPriceM2 = lastYearData[0]?.priceM2

  const currentPriceM2 = data[data.length - 1]?.priceM2

  const growth = currentPriceM2 - lastYearPriceM2

  const percent = (growth / lastYearPriceM2) * 100

  return {
    growth: parseFloat(growth.toFixed(2)),
    percent: parseFloat(percent.toFixed(2)),
  }
}

export const getConfidenceScore = (array: any[]) => {
  const listingsNumber =
    (array[array.length - 1] ? array[array.length - 1].listings : 0) +
    (array[array.length - 2] ? array[array.length - 2].listings : 0) +
    (array[array.length - 3] ? array[array.length - 3].listings : 0)

  // eslint-disable-next-line no-nested-ternary
  return listingsNumber > 50 ? 3 : listingsNumber > 20 ? 2 : 1
}
