import { ApiClient } from '../../../../services'

const client = new ApiClient()

const fetchTags = async () => {
  return client.get(`/developerMap/land/tags`)
}

const tagsService = {
  fetchTags,
}

export default tagsService
