import Box from '@mui/material/Box'
import { Feature, format } from '@popety_io/popety-io-lib'

const TransactionMapHover = ({ date, reason }: Feature) => {
  const text = [format(date, 'P'), reason].filter(Boolean).join(' - ')

  return (
    <Box maxWidth="300px" padding="4px" bgcolor="rgba(255, 255, 255, 0.8)">
      {text}
    </Box>
  )
}

export default TransactionMapHover
