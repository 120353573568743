import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const setBuildingSearchHistory = async (data: any) => {
  return client.post(`/search-history/building-search`, data)
}

const getBuildingSearchHistory = async () => {
  return client.get(`/search-history/building-search/${getUserId()}`)
}

const buildingSearchHistoryService = {
  setBuildingSearchHistory,
  getBuildingSearchHistory,
}

export default buildingSearchHistoryService
