import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const userId: string = getUserId()

const getBuildingsActionHistory = async (buildingId: string | string[]) => {
  return client.get(`/dashboard/actionHistory/buildings/${buildingId}`)
}

export const addBuildingNote = async (data: Record<string, any>) => {
  const response = await client.post(`/dashboard/building-notes`, data)

  const user = JSON.parse(localStorage.user || '')

  if (response.data) {
    response.data = response.data.map((note: any) => ({
      ...data,
      action: 'note',
      first_name: user.first_name,
      last_name: user.last_name,
      photo: user.photo,
      user_id: user.id,
      ...note,
      note_id: note.note_id,
    }))
  }

  return response
}

export const removeBuildingNote = async (noteId: string) => {
  client.delete(`/dashboard/building-notes/${noteId}`)

  return { noteId }
}

export const updateBuildingNote = async (input: Record<string, any>) => {
  const { note_id, content } = input

  const { data } = await client.put(
    `/dashboard/building-notes/${note_id}/${userId}`,
    {
      content,
    },
  )

  return { ...input, update_timestamp: data.update_timestamp || new Date() }
}

const buildingActionHistoryService = {
  addBuildingNote,
  getBuildingsActionHistory,
  removeBuildingNote,
  updateBuildingNote,
}

export default buildingActionHistoryService
