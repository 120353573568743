type GetSunTimesOpts = {
  sunDate: any
  center: any
  month?: number
  day?: number
}

const getSunTimes = ({ sunDate, center, month, day }: GetSunTimesOpts) => {
  const SunCalc = (window as any)?.SunCalc
  const { lat, lng } = center
  const date = new Date(sunDate)

  if (month && month >= 0) date?.setMonth(month)
  if (day && day >= 0) date?.setDate(day)

  const times = SunCalc?.getTimes(new Date(date), lat, lng)

  if (times) times.date = date

  return times
}

export { getSunTimes }
