/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import SettingsIcon from '@mui/icons-material/Settings'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Tooltip from '@mui/material/Tooltip'
import { MapLegend, useI18n } from '@popety_io/popety-io-lib'

import { displayGeoBuildingsSelector, updateGeoOptions } from '../redux'

const GeoMapContent = () => {
  const displayBuildings = useSelector(displayGeoBuildingsSelector)
  const dispatch = useDispatch()

  const { t } = useI18n()

  const handleDisplayBuildings = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateGeoOptions({ displayBuildings: e.target.checked }))
  }

  return (
    <MapLegend
      title={t('common.settings')}
      id="geo-settings"
      icon={SettingsIcon}
      left="auto"
      right="16px"
      defaultOpen
    >
      <Box id="geo-settings-action">
        <Tooltip title={t('land.geoScore.withBuildings')}>
          <FormControlLabel
            value="buildings"
            control={
              <Checkbox
                color="primary"
                checked={displayBuildings}
                onChange={handleDisplayBuildings}
              />
            }
            label={t('land.geoScore.withBuildings')}
          />
        </Tooltip>
      </Box>
    </MapLegend>
  )
}

export default GeoMapContent
