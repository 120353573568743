import Grid, { GridProps } from '@mui/material/Grid'

import RestrictionItemValue from './RestrictionItemValue'
import { ListItemDescription, ListItemTitle } from './RestrictionAffected.style'

export type ListItemProps = GridProps & {
  title?: React.ReactNode
  value?: React.ReactNode
  md?: number
}

const ListItem = ({ title, value, md = 4 }: ListItemProps) => (
  <Grid container spacing={1}>
    <ListItemTitle item xs={12} md={md as any}>
      {title}
    </ListItemTitle>
    <ListItemDescription item xs={12} md={(12 - md) as any}>
      <RestrictionItemValue>{value}</RestrictionItemValue>
    </ListItemDescription>
  </Grid>
)

export default ListItem
