import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type HistorySearchOpts = Record<string, any>

const getFaos = ({ ...filter }: HistorySearchOpts) =>
  client.post('/faos/search', filter)

const countFaos = (filter: HistorySearchOpts) => {
  return client.post('/faos/count', filter)
}

const alertFaoService = {
  getFaos,
  countFaos,
}

export default alertFaoService
