import { useNavigate } from 'react-router-dom'
import { Button, Error, ErrorProps, useI18n } from '@popety_io/popety-io-lib'

import { isProd } from '../../config'

export type ErrorPageProps = Pick<ErrorProps, 'status'> & {
  error?: any
  embedded?: boolean
}

const ErrorPage = ({ status = '404', error, embedded }: ErrorPageProps) => {
  const { t } = useI18n()
  const navigate = useNavigate()
  const errorCode = error?.code || status
  const jwt = typeof localStorage !== 'undefined' && localStorage.getItem('jwt')

  let link = '/'

  if (!jwt) {
    link = '/login'
  }
  if (embedded) {
    link = window.location.pathname
  }

  const actionText = embedded ? t('common.refresh') : t('common.homePage')

  if (status !== '404' || error) {
    sessionStorage.setItem('error', '1')

    if (isProd) {
      navigate('/', { replace: true })
      window.location.reload()

      return null
    }
  }

  return (
    <Error
      status={errorCode}
      actions={
        <Button
          variant="contained"
          color="secondary"
          {...{ compponent: 'a' }}
          href={link}
        >
          {actionText}
        </Button>
      }
    />
  )
}

export default ErrorPage
