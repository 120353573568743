import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getListingInsights = (filter: Record<string, any>) => {
  return client.post(
    '/listing/insights',
    filter,
    filter?.signal && { signal: filter.signal },
  )
}

const getListingRentAndBuyInsights = (filter: Record<string, any>) => {
  return Promise.all([
    getListingInsights({ ...filter, dealType: ['rent'] }),
    getListingInsights({ ...filter, dealType: ['purchase'] }),
  ])
}

const listingInsightsService = {
  getListingInsights,
  getListingRentAndBuyInsights,
}

export default listingInsightsService
