import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const userId: string = getUserId()

const getActionHistory = async (landId: string | string[]) => {
  return client.get(`/dashboard/actionHistory/lands/${landId}`)
}

const addNote = async (data: Record<string, any>) => {
  const response = await client.post(`/dashboard/notes`, data)

  const user = JSON.parse(localStorage.user || '')

  if (response.data) {
    response.data = response.data.map((note: any) => ({
      ...data,
      action: 'note',
      first_name: user.first_name,
      last_name: user.last_name,
      photo: user.photo,
      user_id: user.id,
      ...note,
      note_id: note.note_id,
    }))
  }

  return response
}

const updateNote = async (input: Record<string, any>) => {
  const { note_id, content } = input

  const { data } = await client.put(`/dashboard/notes/${note_id}/${userId}`, {
    content,
  })

  return { ...input, update_timestamp: data.update_timestamp || new Date() }
}

const removeNote = async (noteId: string) => {
  client.delete(`/dashboard/notes/${noteId}`)

  return { noteId }
}

const addReminder = async (data: Record<string, any>) => {
  const response = await client.post(`/dashboard/reminders`, data)

  const user = JSON.parse(localStorage.getItem('user') || '')

  if (response.data) {
    const payload = {
      ...data,
      action: 'reminder',
      first_name: user.first_name,
      last_name: user.last_name,
      alarm_date: data.date,
      ...response.data,
    }

    // stringify and parse because it content date object
    response.data = JSON.parse(JSON.stringify(payload))
  }

  return response
}

const updateReminder = async ({ id, ...data }: Record<string, any>) => {
  return client.put(`/dashboard/reminders/${id}`, data)
}

const removeReminder = async (reminderId: string) => {
  return client.delete(`/dashboard/reminders/${reminderId}`)
}

const getActivityHistoryLand = async ({
  landId,
  addressIds,
  filterCategories,
  signal,
}: any) => {
  let query = ''

  if (!!addressIds || filterCategories?.length) {
    query = '?'
  }

  if (addressIds) {
    query += `addressId=${addressIds}`
  }

  if (filterCategories) {
    query += `${addressIds ? '&' : ''}filterCategories=${filterCategories}`
  }

  return client.get(`/dashboard/lands/${landId}/activities${query}`, {
    signal,
  })
}

const actionHistoryService = {
  removeNote,
  addNote,
  getActionHistory,
  updateNote,
  addReminder,
  updateReminder,
  removeReminder,
  getActivityHistoryLand,
}

export default actionHistoryService
