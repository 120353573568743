import { mapBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'

const citiesSelector = (state) => state.location.cities

const zonesSelector = (state) => state.location.zones

const regionsSelector = (state) => state.location.regions

const citiesByNameSelector = createSelector(citiesSelector, (regions) =>
  mapBy('name', regions),
)

const zonesByNameSelector = createSelector(zonesSelector, (zones) =>
  mapBy('name', zones),
)

const regionsByNameSelector = createSelector(regionsSelector, (regions) =>
  mapBy('name', regions),
)

const citiesByIdSelector = createSelector(citiesSelector, (regions) =>
  mapBy('id', regions),
)

const zonesByIdSelector = createSelector(zonesSelector, (zones) =>
  mapBy('id', zones),
)

const regionsByIdSelector = createSelector(regionsSelector, (regions) =>
  mapBy('id', regions),
)

const locationsByNameSelector = createSelector(
  regionsByNameSelector,
  zonesByNameSelector,
  citiesByNameSelector,
  (regionsByName, zonesByName, citiesByName) => ({
    regionsByName,
    zonesByName,
    citiesByName,
  }),
)

const locationsByIdSelector = createSelector(
  regionsByIdSelector,
  zonesByIdSelector,
  citiesByIdSelector,
  (regionsById, zonesById, citiesById) => ({
    regionsById,
    zonesById,
    citiesById,
  }),
)

const locationsSelector = createSelector(
  regionsSelector,
  zonesSelector,
  citiesSelector,
  (region, zone, city) => [region, zone, city].filter(Boolean).flat(),
)

const hasLocationsSelector = createSelector(
  locationsSelector,
  (regions) => !!regions?.length,
)

const addressesSelector = (state) => state.location.addresses

const allAddressesSelector = (state) => state.location.allAddresses

const allAddressesCountSelector = (state) => state.location.allAddressesCount

export {
  zonesSelector,
  regionsSelector,
  citiesSelector,
  locationsSelector,
  allAddressesSelector,
  addressesSelector,
  allAddressesCountSelector,
  citiesByNameSelector,
  regionsByNameSelector,
  zonesByNameSelector,
  hasLocationsSelector,
  locationsByNameSelector,
  citiesByIdSelector,
  zonesByIdSelector,
  regionsByIdSelector,
  locationsByIdSelector,
}
