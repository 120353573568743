/* eslint-disable max-lines */
import { formatPrice, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const MainInformation = ({ transaction }: { transaction: any }) => {
  const { t } = useI18n()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('common.type')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.3rem"
            >
              {transaction?.ownership_type ? transaction.ownership_type : '-'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                paddingBottom: '4px',
              }}
              fontSize="1.2rem"
            >
              {t('land.fao.price')}
            </Typography>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
            >
              {transaction.price ? formatPrice(transaction.price) : '-'}
            </Typography>
            {transaction?.price_per_m2 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '6px',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: '#969696',
                    paddingBottom: '10px',
                  }}
                  fontSize="1rem"
                >
                  {`${formatPrice(transaction.price_per_m2)} / m2`}
                </Typography>
                <Tooltip
                  title={t('common.transactions_hover.price_m2')}
                  placement="top"
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{
                      fontSize: '1.2rem',
                      cursor: 'pointer',
                      color: '#969696',
                    }}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '5px',
              paddingTop: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Typography variant="h5" fontSize="1.2rem">
                {t('financial.Surface habitable')}
              </Typography>
              <Tooltip
                title={t('common.transactions_hover.Surface Habitable')}
                placement="top"
              >
                <InfoOutlinedIcon
                  sx={{
                    cursor: 'pointer',
                    fontSize: '1.4rem',
                    color: '#969696',
                  }}
                />
              </Tooltip>
            </Box>
            <Typography
              color="primary"
              variant="h4"
              fontWeight="bold"
              fontSize="1.5rem"
            >
              {transaction.transaction_area > 0
                ? `${transaction.transaction_area} m2`
                : '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MainInformation
