import { createSelector } from '@reduxjs/toolkit'

import { getPriceData } from '../../../../utils/listingInsightHelper'

const estimateListingAggsSelector = (state: {
  estimateMarket: { aggregations: any }
}) => state.estimateMarket.aggregations

const listingAggsPurchasePerSquareMeterSelector = (state: {
  estimateMarket: { listingAggsPurchasePerSquareMeter: any }
}) => state.estimateMarket.listingAggsPurchasePerSquareMeter

const listingAggsPurchaseSelector = (state: {
  estimateMarket: { listingAggsPurchase: any }
}) => state.estimateMarket.listingAggsPurchase

const priceEvolutionSelector = createSelector(
  listingAggsPurchaseSelector,
  (aggs) => aggs?.price_over_time_purchase || [],
)

const priceEvolutionRegionSelector = createSelector(
  listingAggsPurchaseSelector,
  (aggs) => aggs?.region || [],
)

const priceEvolutionPerSquareMeterSelector = createSelector(
  listingAggsPurchasePerSquareMeterSelector,
  (aggs) => aggs?.price_over_time_purchase_per_square_meter || [],
)

const priceEvolutionRegionPerSquareMeterSelector = createSelector(
  listingAggsPurchasePerSquareMeterSelector,
  (aggs) => aggs?.region || [],
)

const loadByPricePerSquareMeterSelector = (state: {
  estimateMarket: { loadByPricePerSquareMeter: boolean }
}) => state.estimateMarket.loadByPricePerSquareMeter

const estimateLoadingAggsSelector = (state: {
  estimateMarket: { loadingListingsAggs: boolean }
}) => state.estimateMarket.loadingListingsAggs

const estimateLoadingInsightsSelector = (state: {
  estimateMarket: { loadInsights: boolean }
}) => state.estimateMarket.loadInsights

const buyAggsSelector = (state: any) => state.estimateMarket.buyData

const estimateBuyDataSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs?.price_over_time?.price_over_time?.buckets || []),
)

const estimateBuyMaxDateSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string,
)

const estimateBuyDataLoadingSelector = (state: any) =>
  state.estimateMarket.loadBuyData

const announcementDataSelector = (state: any) =>
  state.estimateMarket.announcementData

const announcementDataBySquareMeterSelector = (state: any) =>
  state.estimateMarket.announcementDataBySquareMeter

const loadAnnouncementsSelector = (state: any) =>
  state.estimateMarket.loadAnnouncements

const estimateAnnouncementDataSelectorSelector = createSelector(
  announcementDataSelector,
  (aggs) =>
    aggs &&
    getPriceData(aggs?.price_over_time_purchase?.price_range?.buckets || []),
)

const listingDurationDistributionSelector = (state: any) =>
  state.estimateMarket.listingDurationDistribution

const aggsListingDurationEvolutionSelector = (state: any) =>
  state.estimateMarket.listingAfterXYear

const listingDurationEvolutionSelector = createSelector(
  aggsListingDurationEvolutionSelector,
  (aggs) =>
    aggs?.after_x_year?.map((listing: any) => ({
      ...listing,
      median: listing?.median?.value,
    })) || [],
)

const regionListingDurationEvolutionSelector = createSelector(
  aggsListingDurationEvolutionSelector,
  (aggs) =>
    aggs?.region?.map((listing: any) => ({
      ...listing,
      median: listing?.median?.value,
    })) || [],
)

const roomsByPricePerSquareMeterSelector = (state: any) =>
  state.estimateMarket.roomsByPricePerSquareMeter

const loadingAfterXYearSelector = (state: any) =>
  state.estimateMarket.loadingAfterXYear

const loadAnnouncementsBySquareMeterSelector = (state: any) =>
  state.estimateMarket.loadAnnouncementsBySquareMeter

const loadListingDurationDistributionSelector = (state: any) =>
  state.estimateMarket.loadingListingDuration

const loadingListingRoomsNbSelector = (state: any) =>
  state.estimateMarket.loadingListingRoomsNb

const listingAfterXYearSelector = (state: any) =>
  state.estimateMarket.listingAfterXYear

const listingRoomsNbSelector = (state: any) =>
  state.estimateMarket.listingRoomsNb

export {
  estimateListingAggsSelector,
  estimateLoadingAggsSelector,
  listingAggsPurchasePerSquareMeterSelector,
  listingAggsPurchaseSelector,
  estimateBuyDataSelector,
  estimateLoadingInsightsSelector,
  estimateBuyMaxDateSelector,
  estimateAnnouncementDataSelectorSelector,
  announcementDataSelector,
  loadAnnouncementsSelector,
  estimateBuyDataLoadingSelector,
  buyAggsSelector,
  announcementDataBySquareMeterSelector,
  listingDurationDistributionSelector,
  listingDurationEvolutionSelector,
  listingRoomsNbSelector,
  roomsByPricePerSquareMeterSelector,
  loadingAfterXYearSelector,
  priceEvolutionSelector,
  priceEvolutionRegionSelector,
  loadByPricePerSquareMeterSelector,
  priceEvolutionPerSquareMeterSelector,
  priceEvolutionRegionPerSquareMeterSelector,
  loadAnnouncementsBySquareMeterSelector,
  regionListingDurationEvolutionSelector,
  loadListingDurationDistributionSelector,
  loadingListingRoomsNbSelector,
  listingAfterXYearSelector,
}
