import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getProspectLands = async (search = '') => {
  return client.get(`/dashboard/prospects/lands${search}`)
}

const addProspect = async (landId: string | string[]) => {
  return client.post(`/dashboard/prospects/lands/${landId}`)
}

const removeProspect = async (landId: string) => {
  return client.delete(`/dashboard/prospects/lands/${landId}`)
}

const getProspectByLandIds = (landId: string | string[]) => {
  return client.get(`/dashboard/prospects/lands/${landId}`)
}

const prospectService = {
  getProspectLands,
  getProspectByLandIds,
  removeProspect,
  addProspect,
}

export default prospectService
