import { i18n } from '@popety_io/popety-io-lib'

export const validateEstimateForm = (
  values: {
    livingArea?: string | number
    constructionYear?: string | number
    roomsNb?: string | number
    landArea?: string | number
    condition?: string
  },
  isHouse: boolean,
) => {
  const { livingArea, constructionYear, roomsNb, landArea, condition } =
    values || {}
  const errors: any = {}

  if (!livingArea) {
    errors.livingArea = i18n.t('estimate.Living area required')
  }

  if (livingArea && Number(livingArea) > 800) {
    errors.livingArea = i18n.t('estimate.Living area max 800')
  }

  if (livingArea && Number(livingArea) < 10) {
    errors.livingArea = i18n.t('estimate.Living area min 10')
  }

  if (!constructionYear) {
    errors.constructionYear = i18n.t('estimate.Construction year required')
  }

  if (constructionYear && Number(constructionYear) < 1850) {
    errors.constructionYear = i18n.t('estimate.Construction year min 1850')
  }

  if (
    constructionYear &&
    Number(constructionYear) > new Date().getFullYear() + 3
  ) {
    errors.constructionYear = i18n.t(
      'estimate.Construction year max current year',
    )
  }

  if (!roomsNb) {
    errors.roomsNb = i18n.t('estimate.Rooms required')
  }

  if (roomsNb && Number(roomsNb) > 20) {
    errors.roomsNb = i18n.t('estimate.Rooms max 20')
  }

  if (roomsNb && Number(roomsNb) < 1) {
    errors.roomsNb = i18n.t('estimate.Rooms min 1')
  }

  if (!landArea && isHouse) {
    errors.landArea = i18n.t('estimate.Land area required')
  }

  if (landArea && Number(landArea) > 5000 && isHouse) {
    errors.landArea = i18n.t('estimate.Land area max 5000')
  }

  if (landArea && Number(landArea) < 50 && isHouse) {
    errors.landArea = i18n.t('estimate.Land area min 50')
  }

  if (!condition) {
    errors.condition = i18n.t('estimate.Condition required')
  }

  return errors
}
