import { CardProps } from '@mui/material/Card'
import {
  TitleIcon,
  Title,
  CardContainer,
  CardContent,
} from './RestrictionCard.style'

export type RestrictionCardProps = CardProps & {
  id?: string
  title?: React.ReactNode
  icon?: JSX.Element
  isUnAffected?: boolean
  numberOfPlot?: string
  allNumber?: number
}

const RestrictionContentCard = (props: RestrictionCardProps) => {
  const { children, title, icon, numberOfPlot, allNumber, isUnAffected } = props

  return (
    <CardContainer>
      <TitleIcon
        bgcolor={isUnAffected ? 'rgba(127, 188, 83)' : 'rgba(177, 73, 69, 0.7)'}
      >
        {icon}
      </TitleIcon>
      <CardContent>
        <Title>
          {title} {numberOfPlot}
          {allNumber}
        </Title>
        <div>{children}</div>
      </CardContent>
    </CardContainer>
  )
}

export default RestrictionContentCard
