import { useSelector } from 'react-redux'
import { TransactionsIcon } from '@popety_io/popety-io-lib'

import { landTransactionCountSelector } from '../redux'
import CountChip from '../../../components/Tag/CountChip'

interface IProps {
  show?: boolean
}
const TransactionCountChip = ({ show }: IProps) => {
  const count = useSelector(landTransactionCountSelector)

  return <CountChip count={count} icon={<TransactionsIcon />} show={show} />
}

export default TransactionCountChip
