export type CalculateGeoScoreOpts = {
  area: number
  maxLength: number
  altitudeMin: number
  altitudeMax: number
}

const calculateGeoScore = (options: CalculateGeoScoreOpts) => {
  const { area, maxLength, altitudeMax, altitudeMin } = options

  if (area === 0) return 0

  const altitudeDiff = altitudeMax - altitudeMin

  const score =
    Math.min(1, (area / (maxLength * maxLength)) * 2) * 100 - altitudeDiff

  return Math.round(Math.max(0, score))
}

export { calculateGeoScore }
