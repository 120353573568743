import { lazy } from 'react'
import PersonIcon from '@mui/icons-material/PersonOutline'
import ApartmentIcon from '@mui/icons-material/Apartment'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import SecurityIcon from '@mui/icons-material/Security'

const AccountInfo = lazy(() => import('../../AccountInfo'))

const UserPlan = lazy(() => import('../../UserPlan'))

const CompanyInfo = lazy(() => import('../../CompanyInfo'))

const FinancialPlan = lazy(() => import('../../FinancialPlan'))

const Tags = lazy(() => import('../../Tags'))

const Security = lazy(() => import('../../Security'))

const getAccountMenuData = (t = (k: string) => k) => [
  {
    icon: <PersonIcon />,
    key: 'info',
    text: t('account.userInformation'),
    page: <AccountInfo />,
  },
  {
    icon: <ApartmentIcon />,
    key: 'company',
    text: t('account.companyInformation'),
    page: <CompanyInfo />,
  },
  {
    icon: <AccountBalanceWalletOutlinedIcon />,
    key: 'subscription',
    text: t('account.userPlan'),
    page: <UserPlan />,
  },
  {
    icon: <PaidOutlinedIcon />,
    key: 'financial-plan',
    text: t('account.financialPlan'),
    page: <FinancialPlan />,
  },
  {
    icon: <LocalOfferIcon />,
    key: 'tags',
    text: t('account.tags'),
    page: <Tags />,
  },
  {
    icon: <SecurityIcon />,
    key: 'security',
    text: 'Security',
    page: <Security />,
  },
]

export { getAccountMenuData }
