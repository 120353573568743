import { toCamelCase, toSnakeCase } from '@popety_io/popety-io-lib'

import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetLandOpts = {
  landId: string | number | string[] | number[]
  groupingId?: string
}

const getFinancialAnalysis = async ({ landId, groupingId }: GetLandOpts) =>
  client.get(
    `/land/${landId}/analysis${groupingId ? `?groupingId=${groupingId}` : ''}`,
  )

const addFinancialAnalysis = async (
  data: GetLandOpts & Record<string, any>,
) => {
  const response = await client.post(`/land/${data.landId}/analysis`, data)

  if (response.data) {
    response.data = { ...data, ...response.data }
  }

  return response
}

const updateFinancialAnalysis = async ({ id, ...data }: Record<string, any>) =>
  client.put(`/land/${data.landId}/analysis/${id}`, data)

const removeFinancialAnalysis = async ({
  landId,
  id,
}: GetLandOpts & { id: string }) =>
  client.delete(`/land/${landId}/analysis/${id}`)

const updateFinancialDefault = async (input: Record<string, any>) => {
  const data = { config: toSnakeCase(input) }

  return client.post(`/land/${input.landId}/analysis/config`, data)
}

const getFinancialDefault = async () => {
  const response = await client.get('/land/default/analysis/config')
  const payload = response.data

  if (payload) {
    response.data = toCamelCase(response.data)
  }

  return response
}

const financialAnalysisService = {
  removeFinancialAnalysis,
  addFinancialAnalysis,
  updateFinancialAnalysis,
  getFinancialAnalysis,
  updateFinancialDefault,
  getFinancialDefault,
}

export default financialAnalysisService
