import { styled } from '@mui/material/styles'
import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

export const ContainerPaper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => +theme.shape.borderRadius * 3}px;
  min-width: 275px;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;
    min-height: 100%;
    border-radius: 0px;
    z-index: 1;
    max-height: none !important;
  }
`

export const StyledDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

export const StyledFab = styled(Fab)`
  position: absolute;
  left: 0px;
  left: calc(25% - 18px);
  top: 12px;
  color: #9095a2;
  box-shadow: unset;
  background-color: #fff;
  padding: 6px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.3s, 0.3s;
  z-index: 3;
  @media (max-width: 1024px) {
    position: absolute;
    right: 0px;
    left: auto;
    top: 0px;
  }
`

export const StyledTooltip = styled(Tooltip)`
  @media (max-width: 1024px) {
    margin-right: 20px;
  }
`

export const StyledValue = styled(Box)`
  margin-left: 10px;
  color: #8d9ba3;
`
