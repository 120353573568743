export const formatSize = (sizeFile: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let size = sizeFile

  let i = 0

  while (size > 1024) {
    size /= 1024
    i += 1
  }

  if (units[i] === 'B' || units[i] === 'KB') {
    return `${Math.round(size)}${units[i]}`
  }

  return `${size.toFixed(1)}${units[i]}`
}
