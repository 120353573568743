// import { getGeoPoint } from '@popety_io/popety-io-lib'

import { ApiClient } from '../../../../services'

const client = new ApiClient()

export type GetBuildingOpts = {
  lands?: any[]
}

const getEstimateLandSummary = async ({ lands }: GetBuildingOpts) => {
  try {
    const result = lands?.map(async (land) => {
      const { data } = await client.get(
        `/land/${land?.id || land?.land_id}?building=true`,
      )

      return data
    })

    return await Promise.all(result as any)
  } catch (error) {
    console.error(error)
  }
}

const EstimateLandSummaryService = {
  getEstimateLandSummary,
}

export default EstimateLandSummaryService
