import { useState, ChangeEvent } from 'react'
import { Modal, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'

import { useDispatch } from 'react-redux'
import TagColorSelect from '../../../../components/Tag/TagColorSelect'
import { addTag } from '../../redux/tags'

interface TagProps {
  open: boolean
  handleAddTagModalState: (state: boolean) => void
  handleTagToggle?: (newVal: { tag_id: string; primary: boolean }) => () => void
}

const TagCardAdd = ({ open, handleAddTagModalState }: TagProps) => {
  const { t } = useI18n()

  const [values, setValues] = useState<Record<string, any>>({
    value: '',
    color: '#00ade6',
  })

  const dispatch = useDispatch<any>()

  const onClose = () => {
    handleAddTagModalState(false)
  }

  const handleClick = () => {
    if (!values.value) return

    dispatch(
      addTag({
        value: values.value,
        color: values.color,
      }),
    ).then(() => {
      handleAddTagModalState(false)
    })
    setValues({ value: '', color: '#00ade6' })
  }

  const handleColorSelect = (newVal: string) => {
    setValues({ ...values, color: newVal })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      okText={t('common.create')}
      cancelText={t('common.cancel')}
      onOk={handleClick}
      onCancel={onClose}
      disableGutters
      showCloseIcon={false}
      sx={{
        '.PreviewHeader': { borderBottom: '1px solid gainsboro' },
        '.OpenDetailsButton': { display: 'none' },
      }}
      title={t('myTags.add')}
    >
      <Box sx={{ p: 2 }}>
        {values?.value && (
          <Chip
            label={values?.value ? values.value : ''}
            sx={{
              backgroundColor: values?.color ? values.color : 'primary.main',
              color: 'white',
            }}
          />
        )}

        <TextField
          placeholder={t('common.enterTagName')}
          name="value"
          margin="normal"
          value={values?.value || ''}
          onChange={handleChange}
          size="small"
          fullWidth
          error={!values?.value}
          helperText={!values?.value ? t('common.tag_name_required') : ''}
          id="addTag_account"
        />
        <TagColorSelect onChange={handleColorSelect} value={values.color} />
      </Box>
    </Modal>
  )
}

export default TagCardAdd
