import { Map, useRouter } from '@popety_io/popety-io-lib'
import { useEffect } from 'react'

const EventHistoryController = ({
  map,
  loaded,
}: {
  map: Map
  loaded?: boolean
}) => {
  const { query } = useRouter()

  useEffect(() => {
    if (!map || !loaded) return

    if (map.getLayer('wms-layer')) {
      map.removeLayer('wms-layer')
    }
    if (map.getSource('wms-source')) {
      map.removeSource('wms-source')
    }

    if (query.tab !== 'history') return

    if (query?.historyLayer) {
      map.addSource('wms-source', {
        type: 'raster',
        // use the tiles option to specify a WMS tile source URL
        tiles: [
          `https://wmts100.geo.admin.ch/1.0.0/ch.swisstopo.swissimage-product/default/${query?.historyLayer}/3857/{z}/{x}/{y}.jpeg`,
        ],
        tileSize: 256,
        maxzoom: 20,
      })

      map.addLayer({
        id: 'wms-layer',
        type: 'raster',
        source: 'wms-source',
        paint: {},
      })
    }
  }, [query?.historyLayer, loaded, query.tab, query.subTab])
}

export { EventHistoryController }
