import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  AccountHeader,
  DashboardHeader,
  useI18n,
} from '@popety_io/popety-io-lib'

const TransactionsHeader = lazy(
  () => import('../../pages/TransactionHistory/components/TransactionsHeader'),
)

const FaoHeader = lazy(
  () => import('../../pages/FaoHistory/components/FaoHeader'),
)

const LandDetailsHeader = lazy(
  () => import('../../pages/LandDetails/components/LandDetailsHeader'),
)

const BuildingDetailsHeader = lazy(
  () => import('../../pages/BuildingDetails/components/BuildingDetailsHeader'),
)

const AlertsHeader = lazy(
  () => import('../../pages/Alerts/components/AlertsHeader'),
)

const ListingHeader = lazy(
  () => import('../../pages/ListingHistory/components/ListingHeader'),
)

const CompanyDashboardHeader = lazy(
  () =>
    import('../../pages/CompanyDashboard/components/CompanyDashboardHeader'),
)

const DeveloperTableHeader = lazy(
  () => import('../../pages/DeveloperMap/components/DeveloperTableHeader'),
)

const ElearningHeader = lazy(
  () => import('../../pages/Elearning/components/ElearningHeader'),
)

const EstimateHeader = lazy(
  () => import('../../pages/Estimate/components/EstimateHeader'),
)

const LayoutHeaderLeft = () => {
  const { t } = useI18n()

  return (
    <Suspense>
      <Routes>
        <Route path="/transaction-history" element={<TransactionsHeader />} />
        <Route path="/fao-history" element={<FaoHeader />} />
        <Route path="/land-detail/plot" element={<LandDetailsHeader />} />
        <Route
          path="/land-detail/building"
          element={<BuildingDetailsHeader />}
        />
        <Route path="/my-alerts" element={<AlertsHeader />} />
        <Route path="/listing-history" element={<ListingHeader />} />
        <Route
          path="/dashboard"
          element={<DashboardHeader dashboardHeader={t('common.lands')} />}
        />
        <Route
          path="/building-dashboard"
          element={<DashboardHeader dashboardHeader={t('common.buildings')} />}
        />
        <Route path="/company-dashboard" element={<CompanyDashboardHeader />} />
        <Route path="/developer-map/list" element={<DeveloperTableHeader />} />
        <Route path="/developer-map" element={<DeveloperTableHeader />} />
        <Route path="/" element={<DeveloperTableHeader />} />
        <Route
          path="/account"
          element={<AccountHeader account={t('common.Account')} />}
        />
        <Route path="/e-learning" element={<ElearningHeader />} />
        <Route path="/estimate" element={<EstimateHeader />} />
        <Route path="*" element={null} />
      </Routes>
    </Suspense>
  )
}

export default memo(LayoutHeaderLeft)
