import { centroid } from '@turf/turf'

const buildAlertSources = (data: any) => {
  return [
    {
      id: 'locations',
      type: 'geojson',
      data: data.geoPolygon,
      layers: [
        {
          id: 'locationsLayer',
          type: 'fill',
          paint: {
            'fill-opacity': 0.5,
            'fill-outline-color': 'rgb(0, 0, 0)',
            'fill-color': '#00ade6',
          },
          hover: false,
          click: false,
        },
      ],
    },
  ]
}

const getCentroid = (data: any) => {
  let center = null

  if (data?.geoPolygon?.coordinates?.length) {
    center = centroid(data?.geoPolygon)
  }

  return center?.geometry?.coordinates
}

export { buildAlertSources, getCentroid }
