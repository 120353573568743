/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tag } from '@popety_io/popety-io-lib'

import { Box } from '@mui/material'
import { isCompanyAdminSelector } from '../../pages/Login'
import { getUserId } from '../../services'
import TagModal from './TagModal'
// import useBuildingTag from './useBuildingTag'

type LandTagChipProps = {
  tags: Array<Record<string, any>>
  landIds: string | string[]
}

const userId = getUserId()

const PreviewLandTagChip = ({ tags, landIds }: LandTagChipProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)

  const isTagOwner = () => {
    return tags?.some(
      (tag) =>
        String(tag?.user_id) === userId || String(tag?.author) === userId,
    )
  }

  const handleOpen = (event: any) => {
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!tags?.length) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginTop: '1px',
        flexWrap: 'wrap',
      }}
    >
      {tags?.map((tag, index) => {
        return (
          <Tag
            key={index}
            bgColor={tag?.color || '#00ade6'}
            onClick={handleOpen}
          >
            {tag.value}
          </Tag>
        )
      })}
      <TagModal
        anchorEl={anchorEl}
        onClose={handleClose}
        landIds={landIds}
        view={1}
      />
    </Box>
  )
}

export default PreviewLandTagChip
