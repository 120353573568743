const WALL_CONSTRUCTED_BUILDING = 0.22

const getAuthorizedCos = (land_use_plans: any[], region_name: string) => {
  if (!land_use_plans) return 0

  let num

  if (region_name === 'Vaud' || region_name === 'Genève') {
    num = land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.cos || zone.cos_estimate) * zone.cover_area_perc,
      0,
    )
  } else {
    num = land_use_plans?.reduce(
      (acc, zone) =>
        acc + (zone.ios || zone.ios_estimate) * zone.cover_area_perc,
      0,
    )
  }

  const denum = land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0,
  )

  return +(num / denum).toFixed(2) || 0
}

const getAuthorizedCus = (land_use_plans: any[], region_name: string) => {
  if (!land_use_plans) return 0

  let num

  if (region_name === 'Vaud' || region_name === 'Genève') {
    num = land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.cus || zone.cus_estimate) * zone.cover_area_perc,
      0,
    )
  } else {
    num = land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.ibus || zone.ibus_estimate) * zone.cover_area_perc,
      0,
    )
  }

  const denum = land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0,
  )

  return +(num / denum).toFixed(2) || 0
}

const getAuthorizedFloorNumberMax = (land_use_plans: any[]) => {
  if (!land_use_plans) return 0

  const num = land_use_plans.reduce(
    (acc, zone) =>
      acc +
      (zone.floor_nb ? zone.floor_nb : zone.floor_nb_estimate) *
        zone.cover_area_perc,
    0,
  )

  const denum = land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0,
  )

  return +(num / denum).toFixed(2) || 0
}

const getAuthorizedUnitNumberMax = (land_use_plans: any[]) => {
  if (!land_use_plans) return 0

  const num = land_use_plans.reduce(
    (acc, zone) =>
      acc + (zone.unit_nb || zone.unit_nb_estimate) * zone.cover_area_perc,
    0,
  )

  const denum = land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0,
  )

  return +(num / denum).toFixed(2) || 0
}

const getCurrentLivingArea = (buildings: any[]) => {
  if (!buildings) return 0

  return (
    buildings.reduce((accumulator, building) => {
      if (building.floor_nb) {
        return accumulator + building.floor_nb * building.area
      }

      return accumulator + building.area
    }, 0) *
    (1 - WALL_CONSTRUCTED_BUILDING)
  )
}

export {
  getAuthorizedCos,
  getAuthorizedFloorNumberMax,
  getAuthorizedUnitNumberMax,
  getCurrentLivingArea,
  getAuthorizedCus,
}
