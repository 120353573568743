import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const RightContainer = styled(Box)`
  top: 0;
  bottom: 0;
  right: 0;
`

export const LandDetailsRoot = styled(Box)`
  height: 96%;
`
