import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

interface ColorCardProps {
  color: string
}

const ColorCard = ({ color }: ColorCardProps) => {
  return (
    <Stack direction="row" spacing={1}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className="ellipsis"
        sx={{
          background: color || '#00ade6',
          color: '#fff',
          p: '6px 8px',
          fontWeight: 400,
          lineHeight: '12px',
          width: 'fit-content',
          borderRadius: '4px',
        }}
      />
    </Stack>
  )
}

export default ColorCard
