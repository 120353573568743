import Grid from '@mui/material/Grid'
import { Fragment } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import Rating from '../../../components/BuildingPreview/LocationRating/Rating'

import { InfoBoxRoot } from '../../../components/InfoBox/InfoBox.style'
import { buildingSelector } from '../redux'

const IndiceDeLocalisation = () => {
  const { t } = useI18n()
  const building = useSelector(buildingSelector)

  const data = [
    {
      label: t('common.Travel time to city'),
      value: building?.travel_time_to_centers,
    },
    {
      label: t('common.Public transport quality'),
      value: building?.public_transport_quality,
    },
    {
      label: t('common.Noise exposure'),
      value: building?.noise_exposure,
    },
  ]

  return (
    <InfoBoxRoot pb={0}>
      <Typography variant="h6">
        {t('building.info.Location rating')?.toUpperCase()}
      </Typography>

      <Box className="ContentBox">
        <Grid container spacing={{ xs: 1, sm: 3 }} alignItems="flex-start">
          <Grid item container xs={12} sm={5}>
            {data.map((item) => (
              <Fragment key={item.label as string}>
                <Grid className="label" item xs={8}>
                  {item.label}
                </Grid>
                <Grid item xs={4}>
                  <Rating
                    isTransportQuality={
                      item.label === t('common.Public transport quality')
                    }
                    isNoise={item.label === t('common.Noise exposure')}
                    location={item.value}
                    label={item.label}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>
    </InfoBoxRoot>
  )
}

export default IndiceDeLocalisation
