import { groupBy } from '@popety_io/popety-io-lib'
import { createSelector } from '@reduxjs/toolkit'
import { getFaoAroudLayers } from './fao.layers'

const landFaosSelector = (state) => state.fao.faos?.filter(Boolean)

const landFaosLoadingSelector = (state) => state.fao.loading

const landFaoCountSelector = (state) => state.fao.faos?.length || 0

const landFaoSinceDateSelector = createSelector(
  landFaosSelector,
  (faos) =>
    faos.length > 0 &&
    Math.min.apply(
      null,
      faos?.map((fao) => new Date(fao?.inquiry_start_date)?.getFullYear()),
    ),
)

const faosAroundSelector = (state) => state.fao.faosAround

const faosAroundCountSelector = (state) => state.fao.totalAround || 0

const faoSinceSelector = createSelector(faosAroundSelector, (faos = []) =>
  faos?.length > 0
    ? Math.min(...faos.map((fao) => new Date(fao?.inquiry_start_date)))
    : undefined,
)

const faoSinceYearSelector = createSelector(faoSinceSelector, (date) =>
  date ? new Date(date).getFullYear() : undefined,
)

const faoByLandIdSelector = createSelector(faosAroundSelector, (faos = []) =>
  groupBy('land_id', faos),
)

const faoSetSelector = createSelector(faoByLandIdSelector, (faos = {}) =>
  Object.values(faos),
)

const faoRangeSelector = (state) => state.fao.faoRange

const devOnlySelector = (state) => state.fao.devOnly

const faoAroundLayersSelector = createSelector(
  faoSetSelector,
  faoRangeSelector,
  faoSinceSelector,
  devOnlySelector,
  getFaoAroudLayers,
)

const faoAggsSelector = (state) => state.fao.aggregations

const faoStatsSelector = (state) => state.fao.stats

const faosPercentSelector = (state) => state.fao.percent

export {
  landFaoCountSelector,
  landFaoSinceDateSelector,
  landFaosSelector,
  faosAroundSelector,
  faoSinceYearSelector,
  faoSinceSelector,
  faoAroundLayersSelector,
  faoRangeSelector,
  faoByLandIdSelector,
  devOnlySelector,
  faosAroundCountSelector,
  faoAggsSelector,
  faoStatsSelector,
  faosPercentSelector,
  landFaosLoadingSelector,
}
