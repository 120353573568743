import { memo } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { authRolesSelector } from '../pages/Login'

export type RouteGuardProps = {
  /**
   * Indicates whether or not this route requires admin role
   */
  admin?: boolean
  /**
   * Indicates whether or not this route requires authenticated user
   */
  auth?: boolean
  /**
   * Indicates whether or not this route requires landdev role
   */
  landdev?: boolean

  /**
   * Indicates whether or not this route requires estimate role
   */
  estimate?: boolean
}

/**
 * App route guard
 */
const RouteGuard = ({ admin, auth, landdev, estimate }: RouteGuardProps) => {
  const roles = useSelector(authRolesSelector)
  const login = !!roles.length
  const requireAdmin = admin && !roles.includes('Admin_company')
  const isBroker = landdev && !roles.includes('User_landdev')
  const isEstimate = estimate && !roles.includes('User_estimate')

  if (isEstimate) {
    return <Navigate to={{ pathname: '/' }} />
  }

  if (isBroker || (requireAdmin && login)) {
    return <Navigate to={{ pathname: '/' }} />
  }

  if (!admin && !auth) return <Outlet />

  if (!login || requireAdmin) {
    return (
      <Navigate
        to={{ pathname: '/login', search: `?from=${window.location.href}` }}
        replace
      />
    )
  }

  return <Outlet />
}

export default memo(RouteGuard)
