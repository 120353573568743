/* eslint-disable max-lines */
import TextField from '@mui/material/TextField'
import { mapBy, RegionFilterItem, useI18n } from '@popety_io/popety-io-lib'
import { memo, useMemo } from 'react'

import { groupLocations } from '../LocationsInput'
import ListboxComponent from './ListboxComponent'
import {
  GroupLabel,
  LocationsFilterInputRoot,
} from './LocationsFilterInput.style'

const toLocation = (val: any[]) =>
  val?.map((i) => ({ ...i, id: i.key || i.id }))?.filter((v) => v?.id) || []

const getLocation = (newValue: any[], group: string) => {
  return newValue
    .filter((val) => val.group === group)
    .map((i) => i.key || i.label)
}

export type LocationsFilterInputProps = {
  loading?: boolean
  country?: any[]
  region?: any[]
  zone?: any[]
  city?: any[]
  landUsePlan?: any[]
  npa?: any[]
  data?: Record<string, any>
  exclude?: Record<string, any>
  onChange: (val: any) => void
  [k: string]: any
}

const LocationsFilterInput = ({
  loading = false,
  country = [],
  region = [],
  zone = [],
  city = [],
  landUsePlan = [],
  npa = [],
  data = {},
  exclude = {},
  onChange,
  ...other
}: LocationsFilterInputProps) => {
  const { t } = useI18n()

  const countries = toLocation(data.countries)
  const regions = toLocation(data.regions)
  const zones = toLocation(data.zones)
  const cities = toLocation(data.cities)
  const landUsePlans = toLocation(data.landUsePlans)
  const npas = toLocation(data.npas)

  const groupCount = {
    countries: countries?.length,
    zones: zones?.length,
    regions: regions?.length,
    cities: cities?.length,
    landUsePlans: landUsePlans?.length,
    npas: npas?.length,
  } as any

  const excludeLocations = groupLocations({
    countries: exclude.country,
    regions: exclude.region,
    zones: exclude.zone,
    cities: exclude.city,
    landUsePlans: exclude.landUsePlan,
    npas: exclude.npa,
  })

  const excludeLocationsByUid = mapBy('uid', excludeLocations)

  const locations = groupLocations({
    countries,
    regions,
    zones,
    cities,
    landUsePlans,
    npas,
  }).filter((o) => !excludeLocationsByUid[o.uid])

  const selectedValues = {
    countries: country,
    regions: region,
    zones: zone,
    cities: city,
    landUsePlans: landUsePlan,
    npas: npa,
  }

  const selectedValueStr = JSON.stringify(selectedValues)

  const selectedLocations = useMemo(
    () => groupLocations(selectedValues),

    [
      selectedValueStr,
      country?.length,
      region?.length,
      zone?.length,
      landUsePlans?.length,
      npas?.length,
    ],
  )

  const handleChange = (e: any, newValue: any[]) => {
    const input = {
      country: getLocation(newValue, 'countries'),
      region: getLocation(newValue, 'regions'),
      zone: getLocation(newValue, 'zones'),
      city: getLocation(newValue, 'cities'),
      landUsePlan: getLocation(newValue, 'landUsePlans'),
      npa: getLocation(newValue, 'npas'),
    }

    onChange(input)
  }

  return (
    <LocationsFilterInputRoot
      size="small"
      fullWidth
      disabled={loading}
      id={`locations_${Date.now()}`}
      value={selectedLocations}
      disableListWrap
      ListboxComponent={ListboxComponent}
      onChange={handleChange as any}
      isOptionEqualToValue={(option: any, val: any) =>
        option.uid === (val.uid || val)
      }
      getOptionLabel={(option: any) => option?.key || option?.label || option}
      renderOption={(p, option: any) =>
        [
          p,
          <RegionFilterItem
            key={option.uid}
            name={option.key}
            count={option.doc_count}
          />,
        ] as any
      }
      options={locations}
      groupBy={(option: any) => option.group}
      renderGroup={(params: any) => ({
        ...params,
        group: (
          <GroupLabel
            key={params.uid}
            {...{ component: 'div' }}
            sx={{ padding: 0 }}
          >
            <span>
              {t(`common.location.${params.group}`, {
                count: groupCount[params.group],
              }).toUpperCase()}
            </span>
            <div>{groupCount[params.group]}</div>
          </GroupLabel>
        ),
      })}
      multiple
      filterSelectedOptions
      loading={loading}
      loadingText={t('common.loading')}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          {...other}
          placeholder={other?.placeholder || t('common.location')}
          inputProps={{
            ...other.inputProps,
            ...params.inputProps,
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
          data-cy="location-search-input"
        />
      )}
    />
  )
}

export default memo(LocationsFilterInput)
