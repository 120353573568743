import Box from '@mui/material/Box'
import { Slide } from '@mui/material'
import React from 'react'
import FaoDetailBody from './FaoDetailBody/FaoDetailBody'

export type ListingListProps = {
  fao: Record<string, any>
  setFao: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

const FaoDetail = ({ fao }: ListingListProps) => {
  if (Object.keys(fao)?.length === 0) return null

  return (
    <Box
      width="100%"
      sx={{
        position: 'relative',
      }}
    >
      <Slide
        direction="left"
        timeout={1000}
        in
        style={{
          width: '100%',
        }}
      >
        <Box>
          <Box
            data-cy="land-detail-accordionDetails"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <FaoDetailBody fao={fao} />
          </Box>
        </Box>
      </Slide>
    </Box>
  )
}

export default FaoDetail
