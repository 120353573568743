/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import prospectsService from './prospectsService'

const initialState = {
  prospects: [],
  buildingProspects: [],
}

export const fetchProspects = createAsyncThunk(
  'prospects/fetchProspects',
  prospectsService.fetchProspects,
)

export const fetchBuildingProspects = createAsyncThunk(
  'buildingProspect/fetchBuildingProspects',
  prospectsService.fetchBuildingProspects,
)

const prospects = createSlice({
  name: 'prospects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProspects.fulfilled, (state, action) => {
      const { data = [] } = action.payload || {}

      state.prospects = data
    })
    builder.addCase(fetchBuildingProspects.fulfilled, (state, action) => {
      const { data = [] } = action.payload || []

      state.buildingProspects = data
    })
  },
})

export const prospectsReducer = {
  prospects: prospects.reducer,
}
