import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const InfoBoxRoot = styled(Box)`
  .label {
    font-weight: 500;
    color: #969696;
    padding-right: 8px;
  }

  > h6 {
    margin-bottom: 4px;
    font-size: 1rem;
    text-transform: uppercase;
  }

  > .ContentBox {
    background: #fafafa;
    border: 1px solid #f9f9f9;
    padding: 8px 4px;
  }
`
