const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.landUnderExploited.startTitle'),
          description: t('driver.landUnderExploited.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '.current-under-exploited',
        popover: {
          title: t('driver.landUnderExploited.currentTitle'),
          description: t('driver.landUnderExploited.currentDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '.auth-under-exploited',
        popover: {
          title: t('driver.landUnderExploited.authTitle'),
          description: t('driver.landUnderExploited.authDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '.diffrence-under-exploited',
        popover: {
          title: t('driver.landUnderExploited.diffrenceTitle'),
          description: t('driver.landUnderExploited.diffrenceDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
