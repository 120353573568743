import { useLocation } from 'react-router-dom'

import SunlightExposureLegend from './SunlightExposureLegend'
import SunlightBuildingHeghit from './SunlightBuildingHeghit'

const SunlightMapContent = () => {
  const location = useLocation()

  const isBuildingDetails = location.pathname === '/land-detail/building'

  return (
    <>
      <SunlightExposureLegend />
      {isBuildingDetails && <SunlightBuildingHeghit />}
    </>
  )
}

export default SunlightMapContent
