import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const CardContainer = styled('div')`
  display: flex;
  gap: 18px;
  align-items: flex-start;
`
export const Title = styled('div')`
  font-weight: 500;
  line-height: 1.25;
  color: rgb(12, 40, 60);
`

export const TitleIcon = styled(Box)`
  color: #fff;
  padding: 0 14px;
  padding-top: 7px;
  border-radius: 5px;

  > svg {
    max-width: 18px;
    height: 18px;
  }
`

export const CardContent = styled('div')`
  flex-basis: 100%;
  color: rgb(4, 26, 37);
`
