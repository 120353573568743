/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import favoriteService from './favoriteService'

const initialState = {
  favoriteLands: undefined,
  myFavoriteLands: undefined,
  companyFavoriteLands: undefined,
  favoriteLandIds: [],
  myFavoriteLandIds: [],
  companyFavoriteLandIds: [],
  favoriteGroupingLands: undefined,
  loading: false,
}

export const addGroupingFavorite = createAsyncThunk(
  'favorite/addGrouping',
  favoriteService.addGrouping,
)

export const getMyFavoriteLands = createAsyncThunk(
  'favorite/getMyFavoriteLands',
  favoriteService.getMyFavoriteLands,
)

export const getCompanyFavoriteLands = createAsyncThunk(
  'favorite/getCompanyFavoriteLands',
  favoriteService.getCompanyFavoriteLands,
)

export const addFavorite = createAsyncThunk(
  'favorite/addFavorite',
  favoriteService.addFavorite,
)

export const removeFavorite = createAsyncThunk(
  'favorite/removeFavorite',
  favoriteService.removeFavorite,
)

export const getFavoriteByLandIds = createAsyncThunk(
  'favorite/getFavoriteByLandIds',
  favoriteService.getFavoriteByLandIds,
)

export const getFavoriteGroupingLands = createAsyncThunk(
  'favoriteGroupingLands/getFavoriteGroupingLands',
  favoriteService.getFavoriteGroupingLands,
)

const favorite = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder
      .addCase(getMyFavoriteLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getMyFavoriteLands.rejected, (state) => {
        state.loading = false
      })
      .addCase(getMyFavoriteLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.myFavoriteLands = data

        if (data) {
          state.myFavoriteLandIds.push(
            ...data.data.map((land) => parseInt(land.land_id, 10)),
          )
        }

        state.loading = false
      })
      .addCase(getCompanyFavoriteLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanyFavoriteLands.rejected, (state) => {
        state.loading = false
      })
      .addCase(getCompanyFavoriteLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.companyFavoriteLands = data

        if (data) {
          state.companyFavoriteLandIds.push(
            ...data.data.map((land) => parseInt(land.land_id, 10)),
          )
        }

        state.loading = false
      })
      .addCase(getFavoriteByLandIds.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        if (data?.length === 0) {
          state.favoriteLandIds = state.favoriteLandIds.filter(
            (id) => id !== action.meta.arg,
          )
        } else if (data?.length) {
          state.favoriteLandIds = [
            ...state.favoriteLandIds,
            ...data.map((land) => +land.land_id),
          ]
        }
      })
      .addCase(getFavoriteGroupingLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.favoriteGroupingLands = data
      })
      .addCase(addFavorite.pending, (state, action) => {
        const landId = +action.meta.arg

        state.favoriteLandIds = [...state.favoriteLandIds, landId]
      })
      .addCase(removeFavorite.pending, (state, action) => {
        const landId = +action.meta.arg

        if (state.companyFavoriteLands) {
          state.companyFavoriteLands.data =
            state.companyFavoriteLands.data.filter(
              (land) => land.land_id !== landId,
            )
          state.companyFavoriteLands.total = state.companyFavoriteLands.length
        }

        if (state.myFavoriteLands) {
          state.myFavoriteLands.data = state.myFavoriteLands.data.filter(
            (land) => land.land_id !== landId,
          )
        }
      })
  },
})

export const favoriteReducer = {
  favorite: favorite.reducer,
}
