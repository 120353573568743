/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  formatPrice,
  FilterChips,
  toReadableNumber,
  useI18n,
} from '@popety_io/popety-io-lib'

const TransactionHistoryCard = ({ transaction, onClick }: any) => {
  const { t } = useI18n()

  const chips = [
    {
      label: transaction.property_type,
      value: transaction.property_type,
      key: 'property_type',
    },
    {
      label: `${t('listing.rooms', { count: transaction.rooms_nb })}`,
      value: transaction.rooms_nb,
      key: 'rooms_nb',
    },
    {
      label: `${toReadableNumber(transaction?.area || 0)} m2`,
      value: transaction.area,
      key: 'area',
    },
    {
      label: transaction.YearOfConstruction,
      value: transaction.YearOfConstruction,
      key: 'YearOfConstruction',
    },
    { label: transaction.Floor, value: transaction.Floor, key: 'Floor' },
    {
      label: `${toReadableNumber(transaction?.UsefulArea || 0)} m2`,
      value: transaction.UsefulArea,
      key: 'UsefulArea',
    },
  ].filter(
    (i) =>
      typeof i.value === 'string' ||
      (typeof i.value === 'number' && i.value > 0),
  )

  return (
    <Box
      sx={{
        padding: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        flexWrap: 'wrap',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: { xs: '100%', lg: '20%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'space-around',
        }}
      >
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          fontWeight="500"
          fontSize="1.1rem"
        >
          {t('common.Transaction')}
        </Typography>
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          fontWeight="500"
          fontSize="1.1rem"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {formatPrice(transaction?.price)}
        </Typography>
        {transaction.price_per_m2 ? (
          <Typography fontSize="0.8rem" color="#969696">
            {transaction.price_per_m2
              ? formatPrice(transaction.price_per_m2, 0)
              : ''}
            {transaction.price_per_m2 ? ' /m2' : ''}
          </Typography>
        ) : null}
      </Box>
      <Box
        sx={{
          width: { xs: '100%', lg: '80%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'space-around',
          paddingRight: '10px',
          paddingLeft: { xs: '0', lg: '10px' },
          paddingTop: { xs: '10px', lg: '0' },
        }}
      >
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            fontWeight: 500,
            fontSize: '1rem',
            textTransform: 'capitalize',
          }}
        >
          {transaction.details}
        </Typography>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#969696',
          }}
        >
          {transaction.address}
        </Typography>
        {!!chips?.length && (
          <FilterChips
            sx={{
              '> .MuiChip-root': {
                fontSize: '0.9rem',
              },
              flexWrap: 'wrap',
            }}
            items={chips}
            size="small"
            getChipKey={(item: any) => item.key}
          />
        )}
      </Box>
    </Box>
  )
}

export default TransactionHistoryCard
