import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

export const StyledDivider = styled(Divider)`
  margin: 1rem -12px !important;
`

export const LandPreviewRoot = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;

  @media (max-width: 768px) {
    max-width: none;
  }

  .PageHeaderLeft {
    height: fit-content;
    margin-top: 16px;
    margin-bottom: -32px;

    .Radar {
      width: 350px;
      left: 0;
      top: -16px;
      margin-right: -130px;
    }
  }
`

export const Body = styled(Box)`
  padding: 12px;
`
