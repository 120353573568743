import { Suspense, useMemo } from 'react'
import Box from '@mui/material/Box'
import { ProgressIndicator, useRouter, useI18n } from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import {
  sharedSelector,
  isCompanyAdminSelector,
  landdevSelector,
} from '../Login'

import { getLandDetailsMenuData } from './components/DashboardMenu'
import { LandDetailsRoot } from './Dashboard.style'

const Dashboard = () => {
  const { t } = useI18n()
  const { query } = useRouter()
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const isLandDev = useSelector(landdevSelector)
  const shared = useSelector(sharedSelector)
  const menus = getLandDetailsMenuData(isLandDev, isCompanyAdmin, shared, t)

  const content = useMemo(() => {
    return menus.find((p, i) => (!query.tab ? i === 0 : p.key === query.tab))
      ?.page
  }, [query.tab])

  return (
    <LandDetailsRoot>
      <Suspense fallback={<ProgressIndicator circular />}>
        <Box width="100%">{content}</Box>
      </Suspense>
    </LandDetailsRoot>
  )
}

export default Dashboard
