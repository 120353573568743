import { CardProps } from '@mui/material/Card'
import RestrictionContentCard from './RestrictionContentCard'

export type RestrictionCardProps = CardProps & {
  id?: string
  title?: React.ReactNode
  icon?: JSX.Element
  isUnAffected?: boolean
  numberOfPlot?: string
  allNumber?: number
}

const RestrictionCard = (props: RestrictionCardProps) => (
  <RestrictionContentCard {...props} />
)

export default RestrictionCard
