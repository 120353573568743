import { useMemo } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import {
  ContentTitle,
  useI18n,
  Score,
  InfoTooltip,
  toReadableNumber,
} from '@popety_io/popety-io-lib'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

const LandSummaryHeader = ({ lands, type }: { lands: any; type?: string }) => {
  const { t } = useI18n()

  const area =
    lands?.reduce(
      (acc: number, land: { official_area: number }) =>
        acc + (land?.official_area || 0),
      0,
    ) || 0

  const restrictions = lands?.reduce(
    (acc: number, land: { rdppfs: any[] }) => acc + (land?.rdppfs?.length || 0),
    0,
  )

  const affectionZones = lands?.reduce(
    (acc: number, land: { land_use_plans: any[] }) =>
      acc + (land?.land_use_plans?.length || 0),
    0,
  )

  const LandSummaryHeaderData =
    type !== 'estimate'
      ? useMemo(
          () => [
            {
              title: <Score value={lands?.length} by100={false} />,
              subTitle: t('building.info.parcelle'),
              sx: {
                maxWidth: 190,
                width: 190,
              },
            },
            {
              title: (
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '2rem',
                    lineHeight: 'normal',
                  }}
                  variant="h5"
                >
                  {toReadableNumber(area)}
                </Typography>
              ),
              subTitle: (
                <Box>
                  {t('building.landSummary.area')}
                  <InfoTooltip infos={[{ text: 'Content tooltip' }]}>
                    <InfoOutlined
                      sx={{ fill: '#969696', fontSize: 12, marginLeft: 0.5 }}
                    />
                  </InfoTooltip>
                </Box>
              ),
              sx: {
                maxWidth: 190,
                width: 190,
              },
              tooltip: true,
            },
          ],

          [t, lands],
        )
      : useMemo(
          () => [
            {
              title: <Score value={area} by100={false} />,
              subTitle: t('building.landSummary.area'),
              sx: {
                maxWidth: 190,
                width: 190,
              },
            },
            {
              title: <Score value={affectionZones} by100={false} />,
              subTitle: (
                <Box>
                  {t('common.search.details.affectation')}
                  <InfoTooltip infos={[{ text: 'Content tooltip' }]}>
                    <InfoOutlined
                      sx={{ fill: '#969696', fontSize: 12, marginLeft: 0.5 }}
                    />
                  </InfoTooltip>
                </Box>
              ),

              sx: {
                maxWidth: 190,
                width: 190,
              },
              tooltip: true,
            },
            {
              title: <Score value={restrictions} by100={false} />,
              subTitle: (
                <Box>
                  {t('land.devScore.restrictions')}
                  <InfoTooltip infos={[{ text: 'Content tooltip' }]}>
                    <InfoOutlined
                      sx={{ fill: '#969696', fontSize: 12, marginLeft: 0.5 }}
                    />
                  </InfoTooltip>
                </Box>
              ),
              sx: {
                maxWidth: 190,
                width: 190,
              },
              tooltip: true,
            },
          ],

          [t, lands],
        )

  return (
    <Box
      position="sticky"
      top={0}
      pt={1}
      zIndex={10}
      bgcolor="background.paper"
    >
      <ContentTitle m={1} data={LandSummaryHeaderData} gap={3} />
      <Divider />
    </Box>
  )
}

export default LandSummaryHeader
