import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import {
  OverlayLoader,
  useRouter,
  fetchOnce,
  Driver,
  useI18n,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import {
  getTransactions,
  transactionLoadingSelector,
  countTransactions,
  getSelectedTransactions,
} from '../redux'
import { getTransactionFilters } from '../../../utils'
import { userSelector } from '../../Login'
import { saveUserLogsSearchExplore } from '../../DeveloperMap/redux'
import { updateTutorials } from '../../Account'
import getDriverInstructions from './driverInstructions'

const TransactionsTable = lazy(() => import('../TransactionTable'))
const TransactionMap = lazy(() => import('../TransactionMap'))
const TransactionAnalytics = () => null

const content = {
  map: TransactionMap,
  list: TransactionsTable,
  analytic: TransactionAnalytics,
} as Record<string, any>

const TransactionHistory = () => {
  const { query, location } = useRouter()

  const dispatch = useDispatch<any>()
  const loading = useSelector(transactionLoadingSelector)
  const user = useSelector(userSelector)

  const { t } = useI18n()

  const [displayDriver, setDisplayDriver] = useState(false)

  const filters = getTransactionFilters(query)

  const Content = useMemo(
    () => content[filters.view] || TransactionsTable,
    [filters.view],
  )

  useEffect(() => {
    const {
      page: _,
      size: __,
      view: ___,
      orderBy: ____,
      ...countFilters
    } = filters

    fetchOnce('transactionHistory', filters, () => {
      dispatch(getTransactions(filters))
    })
    fetchOnce('countTransactionHistory', countFilters, () => {
      dispatch(countTransactions(filters))
    })
  }, [location.search])

  useEffect(() => {
    if (!location?.search) return

    dispatch(
      saveUserLogsSearchExplore({
        type: 'transaction',
        query: location?.search,
      }),
    )
  }, [location?.search])

  useEffect(() => {
    if (query.selected) {
      dispatch(
        getSelectedTransactions({
          value: `ID:${query.selected.split('-').join(',')}`,
        }),
      )
    }
  }, [query?.selected])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedTransactionsHistoryDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedTransactionsHistoryDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Suspense fallback={<OverlayLoader loading />}>
      <Content />
      <OverlayLoader loading={filters.view === 'map' ? false : loading} />
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Suspense>
  )
}

export default TransactionHistory
