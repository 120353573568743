import { ApiClient } from '../../../../services'

const client = new ApiClient()

const fetchProspects = async () => {
  return client.get(`/developerMap/land/prospects`)
}

const fetchBuildingProspects = async () => {
  return client.get('/developerMap/building/prospect')
}

const prospectsService = {
  fetchProspects,
  fetchBuildingProspects,
}

export default prospectsService
