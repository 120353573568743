import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'

import EstimateTransactionDetail from '../../pages/Estimate/components/EstimateTransactions/EstimateTransactionDetail'
import FaoDetail from '../../pages/LandDetails/Fao/FaoDetail'
import ListingDetail from '../../pages/LandDetails/Listing/ListingDetail'
import { EVENT_HISTORY_CATEGORY } from './utils'

const EventHistoryDetails = ({ event, setEvent }: any) => {
  const { t } = useI18n()

  return (
    <>
      {event && event?.type === EVENT_HISTORY_CATEGORY.TRANSACTION && (
        <Box
          pt={0}
          zIndex={10}
          bgcolor="background.paper"
          sx={{
            position: {
              lg: 'sticky',
            },
            top: {
              lg: 0,
            },
          }}
        >
          <EstimateTransactionDetail
            transaction={event}
            setTransaction={setEvent}
          />
        </Box>
      )}
      {event && event?.type === EVENT_HISTORY_CATEGORY.FAO && (
        <Box
          pt={0}
          zIndex={10}
          bgcolor="background.paper"
          sx={{
            position: {
              lg: 'sticky',
            },
            top: {
              lg: 0,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              cursor: 'pointer',
              marginTop: 1,
            }}
            onClick={() => {
              setEvent({})
            }}
          >
            <ArrowBackIosIcon
              sx={{
                width: '0.8em',
                height: '0.8em',
              }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '1.1rem',
              }}
            >
              {t('estimate.comparables.retour à la liste')}
            </Typography>
          </Box>
          <FaoDetail fao={event} setFao={setEvent} />
        </Box>
      )}
      {event &&
        (event?.type === EVENT_HISTORY_CATEGORY.RENTAL ||
          event?.type === EVENT_HISTORY_CATEGORY.SALE) && (
          <Box
            pt={0}
            zIndex={10}
            bgcolor="background.paper"
            sx={{
              position: {
                lg: 'sticky',
              },
              top: {
                lg: 0,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
                marginTop: 1,
              }}
              onClick={() => {
                setEvent({})
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  width: '0.8em',
                  height: '0.8em',
                }}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.1rem',
                }}
              >
                {t('estimate.comparables.retour à la liste')}
              </Typography>
            </Box>
            <ListingDetail listing={event} setListing={setEvent} />
          </Box>
        )}
    </>
  )
}

export { EventHistoryDetails }
