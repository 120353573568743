/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import tagsOnMapService from './tagsOnMapService'

const initialState = {
  buildingsTags: [],
  buildingTag: {},
  landTags: [],
  landTag: [],
}

export const fetchBuildingTags = createAsyncThunk(
  'buildingTags/fetchBuildingTags',
  tagsOnMapService.fetchBuildingTags,
)

export const fetchByBuildingId = createAsyncThunk(
  'buildingTags/fetchByBuildingId',
  tagsOnMapService.fetchByBuildingId,
)

export const fetchLandsTags = createAsyncThunk(
  'landTags/fetchLandsTags',
  tagsOnMapService.fetchLandsTags,
)

export const fetchByLandId = createAsyncThunk(
  'landTags/fetchByLandId',
  tagsOnMapService.fetchByLandId,
)

const tagsOnMap = createSlice({
  name: 'tagsOnMap',
  initialState,
  reducers: {
    resetPreviewLandTag(state) {
      state.landTag = []
    },
    addPreviewLandTag(state, action) {
      const { payload } = action

      state.landTag = [...state.landTag, payload]?.sort((prev, next) => {
        if (
          (prev?.primary && next.primary) ||
          (!prev?.primary && !next.primary)
        ) {
          return 0
        }
        if (prev?.primary && !next.primary) {
          return -1
        }
        if (!prev?.primary && next.primary) {
          return 1
        }

        return 0
      })
    },
    updatePreviewLandTag(state, action) {
      const { payload } = action

      const isExist = state.landTag.some(
        (item) => String(item.id) === String(payload?.id),
      )

      if (isExist) {
        state.landTag = state.landTag?.map((item) => {
          if (String(item.id) === String(payload?.id)) {
            return payload
          }

          return item
        })
      } else {
        state.landTag = [...state.landTag, payload]?.sort((prev, next) => {
          if (
            (prev?.primary && next.primary) ||
            (!prev?.primary && !next.primary)
          ) {
            return 0
          }
          if (prev?.primary && !next.primary) {
            return -1
          }
          if (!prev?.primary && next.primary) {
            return 1
          }

          return 0
        })
      }
    },
    resetPreviewBuildingTag(state) {
      state.buildingsTag = {}
    },
    addPreviewBuildingTag(state, action) {
      const { payload } = action

      state.buildingsTag = payload
    },
    updatePreviewBuildingTag(state, action) {
      const { payload } = action

      state.buildingsTag = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuildingTags.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.buildingsTags = data
      })
      .addCase(fetchByBuildingId.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.buildingsTag = data?.length
          ? data?.sort((prev, next) => {
            if (
              (prev?.primary && next.primary) ||
              (!prev?.primary && !next.primary)
            ) {
              return 0
            }
            if (prev?.primary && !next.primary) {
              return -1
            }
            if (!prev?.primary && next.primary) {
              return 1
            }

            return 0
          })
          : []
      })
      .addCase(fetchLandsTags.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.landTags = data
      })
      .addCase(fetchByLandId.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        if (data?.length === 1) {
          state.landTag = data?.map((item) => {
            return {
              ...item,
              primary: true,
            }
          })
        } else {
          state.landTag = data?.length
            ? data?.sort((prev, next) => {
              if (
                (prev?.primary && next.primary) ||
                (!prev?.primary && !next.primary)
              ) {
                return 0
              }
              if (prev?.primary && !next.primary) {
                return -1
              }
              if (!prev?.primary && next.primary) {
                return 1
              }

              return 0
            })
            : []
        }
      })
  },
})

export const {
  resetPreviewBuildingTag,
  addPreviewBuildingTag,
  updatePreviewBuildingTag,
  resetPreviewLandTag,
  addPreviewLandTag,
  updatePreviewLandTag,
} = tagsOnMap.actions

export const tagsOnMapReducer = {
  tagsOnMap: tagsOnMap.reducer,
}
