import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getOverridedLandUsePlans = async () => {
  return client.get(`/developerMap/landUsePlans/override`)
}

const overridedLandUsePlanService = {
  getOverridedLandUsePlans,
}

export default overridedLandUsePlanService
