import { ApiClient } from '../../../../services'

const client = new ApiClient()

const getEnvironmentData = (filter: Record<string, any>) => {
  return client.post('/environment', filter)
}

const estimateNoiseService = { getEnvironmentData }

export default estimateNoiseService
