import Grid from '@mui/material/Grid'
import { Fragment } from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'

import { InfoBoxRoot } from '../../../components/InfoBox/InfoBox.style'
import { buildingSelector } from '../redux'
import {
  potentialSolar,
  potentialSolarColor,
} from '../../LandDetails/Building/Building.utils'

const Energie = () => {
  const { t } = useI18n()

  const building = useSelector(buildingSelector)

  const solarAverage = potentialSolar(t, building?.solar_average_exposure)
  const solarAverageColor = potentialSolarColor(
    building?.solar_average_exposure,
  )

  const data = [
    {
      label: t('building.summary.energy.Système de chauffage'),
      value: building?.heater_generator
        ? t(`common.${building?.heater_generator}`)
        : '-',
    },
    {
      label: t("building.summary.energy.Source d'énergie"),
      value: building?.power_source_heater
        ? t(`common.${building?.power_source_heater}`)
        : '-',
    },
  ]

  const data2 = [
    {
      label: t('building.energy.Solar Potential'),
      value: solarAverage,
      color: solarAverageColor,
    },
  ]

  return (
    <InfoBoxRoot pb={0}>
      <Typography variant="h6">{t('land.livability.type.energy')}</Typography>

      <Box className="ContentBox">
        <Grid container spacing={{ xs: 1, sm: 3 }} alignItems="flex-start">
          <Grid item container xs={12} sm={5}>
            {data.map((item) => (
              <Fragment key={item.label as string}>
                <Grid className="label" item xs={5}>
                  {item.label}
                </Grid>
                <Grid item xs={7}>
                  {item.value}
                </Grid>
              </Fragment>
            ))}
          </Grid>

          <Grid item container xs={12} sm={7}>
            {data2.map((item) => (
              <Fragment key={item.label as string}>
                <Grid className="label" item xs={12} sm={4}>
                  {item.label}
                </Grid>
                <Grid item xs={12} sm={8} color={item.color || '#000000'}>
                  {item.value}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Box>
    </InfoBoxRoot>
  )
}

export default Energie
