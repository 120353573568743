import { listingHistoryReducer } from './listingHistorySlice'
import { listingInsightsReducer } from './listingInsights'

export * from './listingHistorySelector'
export * from './listingHistorySlice'

export * from './listingInsights'

export const listingReducer = {
  ...listingHistoryReducer,
  ...listingInsightsReducer,
}
