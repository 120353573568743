import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const DivContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
  }

  .iconText {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .textAlign {
    text-align: center;
  }
`

export const HistoryLink = styled('a', {
  shouldForwardProp: (prop) => prop !== '$active',
})<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  cursor: ${({ $active }) => ($active ? 'pointer' : 'default')};
  color: ${({ $active }) => ($active ? '#00ade6' : 'black')};
  pointer-events: ${({ $active }) => ($active ? 'auto' : 'none')};

  svg {
    fill: ${({ $active }) => ($active ? '#00ade6' : 'black')};
  }
`

export const StyledSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`

export const DivBody = styled(Box)`
  display: flex;
  justify-content: space-around;
  background-color: white;
  gap: 12px;
  background: #ffffff2b;
`

export const SinceSpan = styled(Box)`
  font-size: 80%;
`
