import { GridProps } from '@mui/material/Grid'

import ValueGrid from './ValueGrid'

export type IndiceValueGridProps = GridProps & {
  value?: number | string
  estimate?: boolean
  unit?: string
  overide?: boolean
  isOveride?: number | undefined | boolean | null
  onChange?: (value: React.ReactNode | string) => void
  open?: boolean
  selectedType?: boolean
  keyType?: string
  currentValue?: number | string
  comment?: React.ReactNode
  isCurrentOverrided?: boolean
}

const IndiceValueGrid = (props: IndiceValueGridProps) => {
  const {
    value,
    estimate,
    isOveride,
    unit,
    onChange,
    open,
    selectedType,
    keyType,
    currentValue,
    isCurrentOverrided,
    ...other
  } = props

  return (
    <ValueGrid
      onChange={onChange}
      value={value}
      color={
        // eslint-disable-next-line no-nested-ternary
        isCurrentOverrided || isOveride
          ? '#8ed1a5'
          : estimate
            ? '#00ADE6'
            : undefined
      }
      text={`${value ?? '0'} ${(value && unit) || ''}`}
      iconSize="20px"
      open={open}
      isOveride={isOveride}
      selectedType={selectedType}
      keyType={keyType}
      currentValue={currentValue}
      {...other}
    />
  )
}

export default IndiceValueGrid
