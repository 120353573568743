const estimateEnvironmentSelector = (state: {
  estimateEnvironment: Record<string, any>
}) => state?.estimateEnvironment

const estimateNoiseSelector = (state: {
  estimateEnvironment: Record<string, any>
}) => state?.estimateEnvironment.noise

const estimateNoiseLoadingSelector = (state: {
  estimateEnvironment: Record<string, any>
}) => state?.estimateEnvironment.loading

export {
  estimateEnvironmentSelector,
  estimateNoiseSelector,
  estimateNoiseLoadingSelector,
}
