import { memo, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const TransactionActions = lazy(
  () => import('../../pages/TransactionHistory/components/TransactionActions'),
)

const FaoActions = lazy(
  () => import('../../pages/FaoHistory/components/FaoActions'),
)

const LandDetailsRightActions = lazy(
  () => import('../../pages/LandDetails/components/LandDetailsRightActions'),
)

const BuildingDetailsRightActions = lazy(
  () =>
    import(
      '../../pages/BuildingDetails/components/BuildingDetailsRightActions'
    ),
)

const AlertsActions = lazy(
  () => import('../../pages/Alerts/components/AlertsActions'),
)

const ListingActions = lazy(
  () => import('../../pages/ListingHistory/components/ListingActions'),
)

const DashboardRightActions = lazy(
  () => import('../../pages/Dashboard/components/DashboardRightActions'),
)

const BuildingDashboardRightActions = lazy(
  () =>
    import('../../pages/BuildingDashboard/components/DashboardRightActions'),
)

const CompanyDashboardRight = lazy(
  () => import('../../pages/CompanyDashboard/components/CompanyDashboardRight'),
)

const DeveloperTableRight = lazy(
  () => import('../../pages/DeveloperMap/components/DeveloperTableRight'),
)

const AccountRightActions = lazy(
  () => import('../../pages/Account/components/AccountRightActions'),
)

const EstimateRightActions = lazy(
  () => import('../../pages/Estimate/components/EstimateRightActions'),
)

const HeaderRight = () => (
  <Suspense>
    <Routes>
      <Route path="/land-detail/plot" element={<LandDetailsRightActions />} />
      <Route
        path="/land-detail/building"
        element={<BuildingDetailsRightActions />}
      />
      <Route path="/transaction-history" element={<TransactionActions />} />
      <Route path="/fao-history" element={<FaoActions />} />
      <Route path="/my-alerts" element={<AlertsActions />} />
      <Route path="/listing-history" element={<ListingActions />} />
      <Route path="/dashboard" element={<DashboardRightActions />} />
      <Route
        path="/building-dashboard"
        element={<BuildingDashboardRightActions />}
      />
      <Route path="/company-dashboard" element={<CompanyDashboardRight />} />
      <Route path="/developer-map/list" element={<DeveloperTableRight />} />
      <Route path="/developer-map" element={<DeveloperTableRight />} />
      <Route path="/" element={<DeveloperTableRight />} />
      <Route path="/account" element={<AccountRightActions />} />
      <Route path="*" element={null} />
      <Route path="/estimate" element={<EstimateRightActions />} />
    </Routes>
  </Suspense>
)

export default memo(HeaderRight)
