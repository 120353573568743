import { memo, useState, useEffect } from 'react'
import {
  ContentTitle,
  OverlayLoader,
  useI18n,
  Driver,
  useRouter,
  fetchOnce,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { landLoadingSelector, landSelector } from '../redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import LandScore from './LandScore'
import LandInfo from './LandInfo'
import ZoneInfo from './ZoneInfo'
import BuildingInfo from './BuildingInfo'
import RestrictionInfo from './RestrictionInfo'
import OwnersInfo from './OwnersInfo'
import HistoryInfo from './HistoryInfo'
import getDriverInstructions from './driverInstructions'

const Summary = () => {
  const land: any = useSelector(landSelector)
  const { t } = useI18n()
  const summaryLoading = useSelector(landLoadingSelector)
  const user = useSelector(userSelector)
  const [displayDriver, setDisplayDriver] = useState(false)

  const { query } = useRouter()
  const dispatch = useDispatch<any>()

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedSummaryDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedSummaryDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  const scores = [
    {
      title: (
        <LandScore
          score={land.developmentScoreOveride || land.development_score}
          title={t('land.devScore.developmentScoreDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.info.developmentScore'),
    },
    {
      title: (
        <LandScore
          score={land.underExploitedScoreOveride || land.under_exploited_score}
          title={t('land.underExploited.underExploitedDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.underExploitedScore'),
    },
    {
      title: (
        <LandScore
          score={land.existing_score}
          title={t('land.existingScore.legacyDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.existingScore'),
    },
    {
      title: (
        <LandScore
          score={land.livability_score}
          title={t('land.livabilityScore.livabilityScoreDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.livabilityScore'),
    },
    {
      title: (
        <LandScore
          score={land.geo_shape_score}
          title={t('land.geoScore.geoDesc')}
          showScoreLegend
        />
      ),
      subTitle: t('land.geoScore'),
    },
  ]

  return (
    <Box>
      <Box
        position="sticky"
        top={0}
        pt={1}
        zIndex={10}
        bgcolor="background.paper"
        id="land-summary-header"
      >
        <ContentTitle m={1} data={scores} />
        <Divider />
      </Box>

      {land.land_id && (
        <Box paddingBottom={{ xs: 8, lg: 4 }}>
          <LandInfo />
          <ZoneInfo />
          <BuildingInfo />
          <RestrictionInfo />
          <OwnersInfo />
          <HistoryInfo />

          {/* <LandExternalLinks
            width="fit-content"
            p={2}
            data={land}
            split={false}
          /> */}
        </Box>
      )}
      <OverlayLoader loading={summaryLoading} />
      {query.landId && (
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      )}
    </Box>
  )
}

export default memo(Summary)
