/* eslint-disable no-loop-func */
export const getAltimetryData = (altimetryGrid: any, precision: number) => {
  const altimetryGridKeys = Object.keys(altimetryGrid)
  const minX = Math.min.apply(
    null,
    altimetryGridKeys.map((key) => {
      return altimetryGrid[key].x
    }),
  )
  const minY = Math.min.apply(
    null,
    altimetryGridKeys.map((key) => {
      return altimetryGrid[key].y
    }),
  )
  const maxX = Math.max.apply(
    null,
    altimetryGridKeys.map((key) => {
      return altimetryGrid[key].x
    }),
  )
  const maxY = Math.max.apply(
    null,
    altimetryGridKeys.map((key) => {
      return altimetryGrid[key].y
    }),
  )
  const minAltitude = Math.min.apply(
    null,
    altimetryGridKeys.map((key) => {
      return altimetryGrid[key].altitude ? altimetryGrid[key].altitude : 10000
    }),
  )
  const w = Math.round((maxX - minX) / precision + 1)
  const h = Math.round((maxY - minY) / precision + 1)
  const grid = [...Array(h)].map(() => Array(w).fill(null))

  altimetryGrid.forEach((row: Record<string, any>) => {
    if (row.altitude == null) {
      // we fill the value with a -1 indicated no value for this point is known
      grid[(row.y - minY) / precision][(row.x - minX) / precision] = -1
    } else {
      grid[(row.y - minY) / precision][(row.x - minX) / precision] =
        (row.altitude - minAltitude) / precision
    }
  })
  let still = true

  while (still === true) {
    // Reset the still to false as we hope is the last loop needed :)
    still = false
    // We need to know which point have been update during this loop to not consider it
    // as "trusted point" to update another point
    const changeInLoop = {} as Record<string, any>

    // TODO: fix this issue
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    grid.forEach((y, yIdx) => {
      y.forEach((x, xIdx) => {
        // Check is this value doesn't have altitude
        if (x === -1) {
          // Try to get the previous altitude in x dimension
          // Not possible if x is the first in the dimension
          if (xIdx !== 0) {
            // we get the previous altitude only it exist and is not equal to -1
            if (
              grid[yIdx][xIdx - 1] != null &&
              grid[yIdx][xIdx - 1] !== -1 &&
              !(`${yIdx}:${xIdx - 1}` in changeInLoop)
            ) {
              grid[yIdx][xIdx] = grid[yIdx][xIdx - 1]
              changeInLoop[`${yIdx}:${xIdx}`] = 'UPDATE'

              return
            }
          }
          // Try to get the next altitude in x dimension
          // Not possible if x is the last in the dimension
          if (xIdx !== w - 1) {
            // we get the previous altitude only it exist and is not equal to -1
            if (
              grid[yIdx][xIdx + 1] != null &&
              grid[yIdx][xIdx + 1] !== -1 &&
              !(`${yIdx}:${xIdx + 1}` in changeInLoop)
            ) {
              grid[yIdx][xIdx] = grid[yIdx][xIdx + 1]
              changeInLoop[`${yIdx}:${xIdx}`] = 'UPDATE'

              return
            }
          }
          // Try to get the previous altitude in y dimension
          // Not possible if y is the first in the dimension
          if (yIdx !== 0) {
            // we get the previous altitude only it exist and is not equal to -1
            if (
              grid[yIdx - 1][xIdx] != null &&
              grid[yIdx - 1][xIdx] !== -1 &&
              !(`${yIdx - 1}:${xIdx}` in changeInLoop)
            ) {
              grid[yIdx][xIdx] = grid[yIdx - 1][xIdx]
              changeInLoop[`${yIdx}:${xIdx}`] = 'UPDATE'

              return
            }
          }
          // Try to get the next altitude in y dimension
          // Not possible if y is the last in the dimension
          if (yIdx !== h - 1) {
            // we get the previous altitude only it exist and is not equal to -1
            if (
              grid[yIdx + 1][xIdx] != null &&
              grid[yIdx + 1][xIdx] !== -1 &&
              !(`${yIdx + 1}:${xIdx}` in changeInLoop)
            ) {
              grid[yIdx][xIdx] = grid[yIdx + 1][xIdx]
              changeInLoop[`${yIdx}:${xIdx}`] = 'UPDATE'

              return
            }
          }
          still = true
        }
      })
    })
  }

  return grid
}
