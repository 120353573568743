import Typography from '@mui/material/Typography'
import Grid, { GridProps } from '@mui/material/Grid'

export type HistoryListItemProps = GridProps & {
  title: React.ReactNode
  value: string
  md: number
  isFontWeight?: boolean
}

const HistoryListItem = ({
  title,
  value,
  md = 4,
  ...other
}: HistoryListItemProps) => (
  <Grid container {...other}>
    <Grid item xs={12} md={md as any}>
      <Typography
        variant="body2"
        component="div"
        fontWeight={500}
        color="text.secondary"
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={12} md={(12 - md) as any} color="#8d9ba3">
      <Typography
        variant="body2"
        color="#041725"
        dangerouslySetInnerHTML={{ __html: value }}
        // fontWeight={500}
        sx={{ wordBreak: 'break-word' }}
      />
    </Grid>
  </Grid>
)

export default HistoryListItem
