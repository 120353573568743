// import { useEffect } from 'react'
import { useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import NightAndDay from './components/NightAndDay'
import ScaleDecibels from './components/ScaleDecibels'

const EstimateNoise = () => {
  const { t } = useI18n()
  // const { updateQuery } = useRouter()

  // useEffect(() => {
  //   updateQuery({ noiseLayer: 'noise-road-day-v1' })
  // }, [])

  return (
    <Box pt={2} display="flex" flexDirection="column" gap={4}>
      <Box>
        <Typography
          variant="h2"
          sx={{
            marginBottom: '4px',
            textTransform: 'uppercase',
          }}
          fontSize="1.7rem"
          fontWeight={500}
        >
          {t('common.noise')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#969696',
            paddingBottom: '10px',
          }}
          fontSize="1rem"
        >
          {t('estimate.Bruit et gêne estimé à cette addresse')}
        </Typography>
      </Box>
      <NightAndDay />
      <ScaleDecibels />
    </Box>
  )
}

export default EstimateNoise
