/* eslint-disable max-lines */
import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { BuildingHistoryMenuProps, handleOpenLink } from './utils'

const BuildingHistoryMenu = ({
  id,
  archive = false,
}: BuildingHistoryMenuProps) => {
  const { t } = useI18n()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget as any)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = () => {
    handleOpenLink({ id })
    setAnchorEl(null)
  }

  return (
    <Box display="flex">
      <IconButton size="small" onClick={handleClick} sx={{ opacity: 1 }}>
        <MoreVertIcon color="primary" />
      </IconButton>
      {anchorEl && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {!archive ? (
            <>
              <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleOpen}>
                {t('common.open.analysis')}
              </MenuItem>
            </>
          ) : (
            <MenuItem sx={{ fontSize: '1.1rem' }} onClick={handleOpen}>
              {t('common.open.analysis')}
            </MenuItem>
          )}
        </Menu>
      )}
    </Box>
  )
}

export default BuildingHistoryMenu
