/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import favoritesService from './favoritesService'

const initialState = {
  favorites: [],
  favoriteBuildingIds: [],
  userFavoriteLandsIds: [],
  userFavoriteBuildingsIds: [],
}

export const fetchFavorites = createAsyncThunk(
  'favorites/fetchFavorites',
  favoritesService.fetchFavorites,
)

export const getFavoriteBuildings = createAsyncThunk(
  'favorites/getfavoriteBuildings',
  favoritesService.getFavoriteBuildings,
)

export const getUserFavoriteLands = createAsyncThunk(
  'favorites/getUserFavoriteLands',
  favoritesService.getUserFavoriteLands,
)

export const getUserFavoriteBuildings = createAsyncThunk(
  'favorites/getUserFavoriteBuildings',
  favoritesService.getUserFavoriteBuildings,
)

const favorites = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addFavoriteLandId: (state, action) => {
      const { payload } = action
      const { landId } = payload

      state.favorites = [...state.favorites, landId]
      state.userFavoriteLandsIds = [...state.userFavoriteLandsIds, landId]
    },
    removeFavoriteLandId: (state, action) => {
      const { payload } = action
      const { landId } = payload

      state.favorites = state?.favorites.filter(
        (favorite) => Number(favorite) !== Number(landId),
      )
      state.userFavoriteLandsIds = state?.userFavoriteLandsIds.filter(
        (favorite) => Number(favorite) !== Number(landId),
      )
    },
    addFavoriteBuildingId: (state, action) => {
      const { payload } = action
      const { buildingId } = payload

      state.favoriteBuildingIds = [
        ...state.favoriteBuildingIds,
        Number(buildingId),
      ]
      state.userFavoriteBuildingsIds = [
        ...state.userFavoriteBuildingsIds,
        Number(buildingId),
      ]
    },
    removeFavoriteBuildingId: (state, action) => {
      const { payload } = action
      const { buildingId } = payload

      state.favoriteBuildingIds = state?.favoriteBuildingIds.filter(
        (favorite) => Number(favorite) !== Number(buildingId),
      )
      state.userFavoriteBuildingsIds = state?.userFavoriteBuildingsIds.filter(
        (favorite) => Number(favorite) !== Number(buildingId),
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.favorites = data
      })
      .addCase(getFavoriteBuildings.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.favoriteBuildingIds = data
      })
      .addCase(getUserFavoriteLands.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.userFavoriteLandsIds = data
      })
      .addCase(getUserFavoriteBuildings.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.userFavoriteBuildingsIds = data
      })
  },
})

export const {
  addFavoriteLandId,
  removeFavoriteLandId,
  addFavoriteBuildingId,
  removeFavoriteBuildingId,
} = favorites.actions

export const favoritesReducer = {
  favorites: favorites.reducer,
}
