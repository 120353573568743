import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box, { BoxProps } from '@mui/material/Box'

import { landTagSelector, fetchByLandId } from '../../pages/DeveloperMap/redux'
import PreviewLandChip from './PreviewLandChip'

type TagChipsProps = BoxProps & {
  landId: any
}

const PreviewTagChips = ({ landId }: TagChipsProps) => {
  const dispatch = useDispatch()
  const tags = useSelector(landTagSelector)

  // let newTags = []
  // const tags = landTags?.filter((item: any) => item?.value)

  useEffect(() => {
    if (landId) {
      dispatch(fetchByLandId(landId) as any)
    }
  }, [landId])

  if (!landId || !tags?.length) return null

  // if (landId?.length > 1) {
  //   newTags = tags.filter((item: any) => {
  //     return landId.every((land: any) => {
  //       return tags.some(
  //         (obj: any) =>
  //           obj.tag_id === item.tag_id && obj.land_id === Number(land),
  //       )
  //     })
  //   })
  // }

  return (
    <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
      <PreviewLandChip tags={tags} landIds={landId} />
    </Box>
  )
}

export default PreviewTagChips
