const useScaleDecibelsData = (t: any) => {
  const items = [
    {
      value: 0,
      text: t('estimate.Seuil d’audibilité'),
      exlainText: t('estimate.Seuil d’audibilité'),
      color: '#E0F3F8',
    },
    {
      value: 10,
      text: t('estimate.Respiration'),
      exlainText: t('estimate.Très calme'),
      color: '#E0F3F8',
    },
    {
      value: 20,
      text: t('estimate.Bruissement de feuilles'),
      exlainText: t('estimate.Calme'),
      color: '#E0F3F8',
    },
    {
      value: 30,
      text: t('estimate.Chuchotement'),
      color: '#ABD9EA',
    },
    {
      value: 40,
      text: t('estimate.Refrigerateur'),
      exlainText: t('estimate.Début interférence du sommeil'),
      color: '#C5D564',
    },
    {
      value: 50,
      text: t("estimate.Chant d'oiseaux"),
      color: '#FCD923',
    },
    {
      value: 60,
      text: t('estimate.Vagues'),
      exlainText: t('estimate.Conversation normale'),
      color: '#FCC423',
    },
    {
      value: 70,
      text: t('estimate.Aspirateur'),
      color: '#FC5E22',
    },
    {
      value: 80,
      text: t('estimate.Mixeur'),
      exlainText: t('estimate.Difficile pour se faire entendre'),
      color: '#F14C11',
    },
    {
      value: 90,
      text: t('estimate.Tondeuse'),
      color: '#F14C11',
    },
    {
      value: 100,
      text: t('estimate.Ambulance'),
      exlainText: t('estimate.Obligation de crier pour se faire entendre'),
      color: '#E63900',
    },
  ]

  return { items }
}

export default useScaleDecibelsData
