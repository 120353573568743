import { useRouter, Feature } from '@popety_io/popety-io-lib'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import HistoryMapClickModal from '../../components/HistoryMapClickModal'
import {
  transactionRangeSelector,
  transactionsByLandIdSelector,
} from '../../redux'

export type TransactionClickModalProps = Feature & {
  setClickFeature?: (feature?: Feature) => void
}

const TransactionClickModal = ({
  properties,
  setClickFeature,
}: TransactionClickModalProps) => {
  const transactionsByLandId = useSelector(transactionsByLandIdSelector)
  const { query } = useRouter()

  const transactionRange = useSelector(transactionRangeSelector)

  const handleModalClose = () => {
    setClickFeature?.(undefined)
  }

  useEffect(() => {
    setClickFeature?.(undefined)
  }, [query.tab])

  return (
    <HistoryMapClickModal
      open={!!properties}
      onClose={handleModalClose}
      landId={properties?.land_id}
      dataByLandId={transactionsByLandId}
      value={transactionRange}
    />
  )
}

export default TransactionClickModal
