/* eslint-disable max-lines */
import { lazy } from 'react'
import RestrictionIcon from '@mui/icons-material/NotInterested'
import GeoIcon from '@mui/icons-material/RoundedCorner'
import ArchitectureIcon from '@mui/icons-material/Architecture'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import MapIcon from '@mui/icons-material/MapsHomeWork'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  SummaryIcon,
  BuildingIcon2,
  SunlightIconV2,
  AmenitiesIcon,
  AssignementIcon,
  FinancialPlanIcon,
} from '@popety_io/popety-io-lib'
import HistoryIcon from '@mui/icons-material/History'

import FaoCountChip from '../../Fao/FaoCountChip'
import { TransactionCountChip } from '../../Transaction'
import ListingCountChip from '../../Listing/ListingCountChip'
import PlotIcon from './PlotIcon'

const Restriction = lazy(() => import('../../Restriction'))
const LandDetailsSummary = lazy(() => import('../../Summary'))
const Listing = lazy(() => import('../../Listing'))
const Transaction = lazy(() => import('../../Transaction'))
const Fao = lazy(() => import('../../Fao'))
const Geo = lazy(() => import('../../Geo'))
const FinancialAnalysis = lazy(() => import('../../FinancialAnalysis'))
const LandUsePlans = lazy(() => import('../../UnderExploited'))
const Building = lazy(() => import('../../Building'))
const Livability = lazy(() => import('../../Livability'))
const Sunlight = lazy(() => import('../../Sunlight'))
const Design = lazy(() => import('../../Design/Design'))
const LandNoise = lazy(() => import('../../LandNoise'))
const LandHistory = lazy(() => import('../LandHistory'))
const BuildingHistory = lazy(
  () => import('../../../BuildingDetails/components/BuildingHistory'),
)
const LandEventHistory = lazy(() => import('../../LandEventHistory'))

const getLandDetailsMenuData = (
  t = (k: string) => k,
  firstTransactionDate?: string,
  firstFaoDate?: string,
  isInCompany?: boolean,
  canAnalyse?: boolean,
  isHomePage?: boolean,
) => {
  const pages: any = !isHomePage
    ? [
        {
          icon: <SummaryIcon />,
          key: 'summary',
          text: t('land.summary'),
          page: <LandDetailsSummary />,
          show: true,
        },
        {
          icon: (
            <HistoryIcon
              viewBox="2 2 20 20"
              sx={{
                width: '30px',
                height: '30px',
              }}
            />
          ),
          key: 'history',
          text: t('common.historique'),
          page: <LandEventHistory />,
          show: true,
        },
        {
          icon: <BuildingIcon2 />,
          key: 'building',
          text: t('land.building'),
          page: <Building />,
          show: canAnalyse,
        },
        {
          icon: <AssignementIcon />,
          key: 'lupa',
          text: t('land.lupa'),
          page: <LandUsePlans />,
          show: canAnalyse,
        },
        {
          icon: <RestrictionIcon />,
          key: 'restriction',
          text: t('land.restrictions.title'),
          page: <Restriction />,
          show: canAnalyse,
        },
        {
          icon: <GeoIcon />,
          key: 'geo',
          text: t('land.geoScore.geoHeader'),
          page: <Geo />,
          show: canAnalyse,
        },
        {
          icon: <FaoCountChip show />,
          key: 'fao',
          text: t('land.fao'),
          page: <Fao show />,
          show: canAnalyse && !!firstFaoDate,
        },
        {
          icon: <TransactionCountChip show />,
          key: 'transaction',
          text: t('land.transaction'),
          page: <Transaction show />,
          show: canAnalyse && !!firstTransactionDate,
        },
        {
          icon: <ListingCountChip />,
          key: 'listing',
          text: t('land.listing'),
          page: <Listing />,
          show: canAnalyse,
        },
        {
          icon: <CrisisAlertIcon />,
          key: 'enviroment',
          text: t('building.environment'),
          page: <div />,
          subMenus: [
            {
              icon: <AmenitiesIcon />,
              key: 'livability',
              text: t('land.livabilityScore.livabilityHeader'),
              page: <Livability />,
              show: canAnalyse,
            },
            {
              icon: <VolumeUpIcon />,
              key: 'noise',
              text: t('common.noise'),
              page: <LandNoise />,
              show: canAnalyse,
            },
            {
              icon: <SunlightIconV2 />,
              key: 'sunlight',
              text: t('land.history.sunlight'),
              page: <Sunlight />,
              show: canAnalyse,
            },
          ],
          show: true,
        },
        {
          icon: <FinancialPlanIcon />,
          key: 'financial-plan',
          text: t('land.financialAnalysis.financialPlanHeader'),
          page: <FinancialAnalysis />,
          show: canAnalyse,
        },
      ].filter(Boolean)
    : [
        {
          icon: <PlotIcon />,
          href: '/land-detail/plot?tab=home&active=true',
          path: '/land-detail/plot',
          key: 'home',
          text: t('common.lands'),
          page: <LandHistory />,
          show: true,
        },
        {
          icon: <MapIcon />,
          href: '/land-detail/building?tab=home&active=true',
          path: '/land-detail/building',
          key: 'home',
          text: t('common.buildings'),
          page: <BuildingHistory />,
          show: true,
        },
        {
          icon: <AddCircleOutlineIcon />,
          href: '/land-detail/plot?tab=grouping&active=true',
          path: '/land-detail/plot',
          key: 'grouping',
          text: t('common.Grouping Plot'),
          page: <LandHistory />,
          show: true,
        },
      ]

  // Feature EPFL: Do not touch
  if (!isHomePage && isInCompany /* POPETY company id */) {
    pages.push({
      icon: <ArchitectureIcon />,
      key: 'design-2',
      text: 'Design',
      page: <Design />,
      show: true,
    })

    // pages.push({
    //   icon: (
    //     <img
    //       src={DesignIcon}
    //       alt="React Logo"
    //       style={{
    //         width: '100px',
    //         marginTop: '10px',
    //         height: '55px',
    //       }}
    //     />
    //   ),
    //   key: 'design',
    //   text: 'Design',
    //   page: <DesignComponent />,
    //   show: true,
    // })
  }

  return pages
}

export { getLandDetailsMenuData }
