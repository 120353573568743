// import { getGeoPoint } from '@popety_io/popety-io-lib'

import { ApiClient } from '../../../../services'
import { ICECB, IHeatingAndHotWaterValues } from './energyType'

const client = new ApiClient()

export type GetBuildingOpts = {
  lands?: any[]
}
const getRoofBuildingPotentialSolar = (buildingId: string[]) => {
  return client.get(`/building/sunlight/${buildingId}`)
}

const getRoofBuildingPolygon = (buildingId: string[]) => {
  return client.get(`/building/polygon/${buildingId}`)
}

const getUserDetailedEditing = (buildingId: string[]) => {
  return client.get(`/building/${buildingId}/detailedEditing`)
}

const updateHeatingAndHotWater = (
  buildingId: string[],
  values: IHeatingAndHotWaterValues,
) => {
  return client.put(
    `/building/${buildingId}/detailedEditing/heatingAndHotWater`,
    values,
  )
}

const updateCECB = (buildingId: string[], values: ICECB) => {
  return client.put(`/building/${buildingId}/detailedEditing/cecb`, values)
}

const getHeatConsumptionAround = (body: Record<string, any>) => {
  return client.post(`/building/building-around/idc`, body)
}

const energyService = {
  getRoofBuildingPotentialSolar,
  getRoofBuildingPolygon,
  getUserDetailedEditing,
  updateHeatingAndHotWater,
  updateCECB,
  getHeatConsumptionAround,
}

export default energyService
