import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import geoService from './geoService'

const initialState = {
  altimetryGrid: null,
  loading: false,
  displayBuildings: true,
}

export const getAltimetry = createAsyncThunk(
  'geo/getAltimetry',
  geoService.getAltimetry,
)

const geo = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    updateGeoOptions: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAltimetry.pending, (state) => {
        state.loading = true
        state.altimetryGrid = undefined
      })
      .addCase(getAltimetry.rejected, (state) => {
        state.loading = false
        state.errors = true
        state.altimetryGrid = null
      })
      .addCase(getAltimetry.fulfilled, (state, action) => {
        const { data, errors } = action.payload || {}

        state.errors = errors
        state.altimetryGrid = data
        state.loading = false

        if (data?.altitude_max == null || !data?.altitude_min == null) {
          state.altimetryGrid = null
        }
      })
  },
})

export const geoReducer = {
  geo: geo.reducer,
}

export const { updateGeoOptions } = geo.actions
