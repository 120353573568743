const noiseLegend = () => [
  { value: '< 30 dB', color: '#E0F3F8' },
  { value: '30 - 40 dB', color: '#ABD9EA' },
  {
    value: '40 - 50 dB',
    color: '#C5D564',
  },
  { value: '50 - 60 dB', color: '#FCD923' },
  { value: '60 - 70 dB', color: '#FCC423' },
  { value: '70 - 80 dB', color: '#FC5E22' },
  { value: '80 - 100 dB', color: '#F14C11' },
]

export const noiseOptions = (t: Translator) => {
  return [
    {
      value: 'noise-road-day-v1',
      label: t('common.noise-road-day-v1'),
      legend: noiseLegend(),
      group: 'environment',
    },
    {
      value: 'noise-railway-day-v1',
      label: t('common.noise-railway-day-v1'),
      legend: noiseLegend(),
      group: 'environment',
    },
    {
      value: 'noise-road-night-v1',
      label: t('common.noise-road-night-v1'),
      legend: noiseLegend(),
      group: 'environment',
    },
    {
      value: 'noise-railway-night-v1',
      label: t('common.noise-railway-night-v1'),
      legend: noiseLegend(),
      group: 'environment',
    },
  ]
}
