import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { Driver, fetchOnce, useI18n } from '@popety_io/popety-io-lib'

import { livabilityLoadingSelector } from '../redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import LivabilityHeader from './LivabilityHeader'
import LivabilityDescription from './LivabilityDescription'
import LivabilitySkeleton from './LivabilitySkeleton'
import getDriverInstructions from './driverInstructions'
import EstimateLivabilityHeader from './EstimateLivabilityHeader'

const Livability = ({ isEstimate }: { isEstimate?: boolean }) => {
  const [displayDriver, setDisplayDriver] = useState(false)
  const dispatch = useDispatch<any>()
  const { t } = useI18n()

  const livabilityLoading = useSelector(livabilityLoadingSelector)
  const user = useSelector(userSelector)

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedLivabilityDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedLivabilityDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Box>
      {!isEstimate ? <LivabilityHeader /> : <EstimateLivabilityHeader />}

      <Box
        sx={{
          width: '100%',
        }}
      >
        {livabilityLoading &&
          [1, 2, 3].map((i) => <LivabilitySkeleton key={i} />)}
      </Box>
      <Box>
        <LivabilityDescription />
      </Box>
      {!livabilityLoading && (
        <Driver
          instructions={getDriverInstructions(t)}
          display={displayDriver}
        />
      )}
    </Box>
  )
}

export default Livability
