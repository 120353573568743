import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Caption, useI18n, Driver, fetchOnce } from '@popety_io/popety-io-lib'

import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import GeoAltimetry from './GeoAltimetry'
import GeoDetails from './GeoDetails'
import GeoHeader from './GeoHeader'
import getDriverInstructions from './driverInstructions'

const Geo = () => {
  const [displayDriver, setDisplayDriver] = useState(false)
  const user = useSelector(userSelector)
  const dispatch = useDispatch<any>()

  const { t } = useI18n()

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedGeoDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedGeoDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Box>
      <GeoHeader />

      <Box p={2}>
        <Box display="flex" flexDirection="column" gap={2} pt={2}>
          <GeoDetails />
          <Box id="geo-section">
            <GeoAltimetry />
          </Box>
          <Caption>
            {t('land.source')}
            {t('land.geo.sources')}
          </Caption>
        </Box>
      </Box>
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Box>
  )
}

export default Geo
