let analytycData = {} as Record<string, any>

export const saveAnalyticData = (data: Record<string, any>, key: string) => {
  try {
    analytycData[key] = { ...analytycData[key], ...data }
  } catch (error) {
    console.error(error)
  }
}

export const getAnalyticData = () => {
  return { ...analytycData }
}

export const clearAnalyticData = () => {
  analytycData = {}
}
