import { estimateSummaryReducer } from './summary'
import { estimateLandSummaryReducer } from './landSummary'
import { estimateListingsReducer } from './estimateListings'
import { estimateActionHistoryReducer } from './estimateNotes'
import { estimateMarketReducer } from './estimateMarket'
import { estimateEnvironmentReducer } from './esimateEnvironment'
import { estimateComparablesReducer } from './estimateComparables'
import { estimateAirQualityReducer } from './estimateAirQuality'

export * from './summary'
export * from './landSummary'
export * from './estimateListings'
export * from './estimateNotes'
export * from './estimateMarket'
export * from './esimateEnvironment'
export * from './estimateComparables'
export * from './estimateAirQuality'

export const estimateReducer = {
  ...estimateSummaryReducer,
  ...estimateLandSummaryReducer,
  ...estimateListingsReducer,
  ...estimateActionHistoryReducer,
  ...estimateMarketReducer,
  ...estimateEnvironmentReducer,
  ...estimateComparablesReducer,
  ...estimateAirQualityReducer,
}
