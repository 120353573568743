import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'

export const StyledSlider = styled(Slider)`
  min-width: 230px;
  margin: 0 14px;
  margin-bottom: 14px;
  margin-right: 15px;
  width: unset;
`
