import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'

export const Title = styled(Box)`
  display: flex;
  flex-direction: column;

  .icon {
    cursor: pointer;
    margin-top: 4px;
  }
`

export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`

export const StyledBadge = styled(Badge)`
  cursor: pointer;
  vertical-align: baseline;

  .MuiBadge-badge {
    top: 50%;
    right: -3px;
    border: 2px solid #eeeeee;
  }
`
