import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/reducers'

const userDetailsSelector = createSelector(
  (state: RootState) => state.account.user,
  (user) => {
    if (!user) return null

    return { ...user, name: `${user.first_name} ${user.last_name}` }
  },
)

const userIsLoadingSelector = (state: RootState) => state.account.isLoading

const financialPlanSelector = (state: RootState) => state.account.config

const subscriptionSelector = (state: RootState) => state.account.subscription

const securityStatusLoadingSelector = (state: RootState) =>
  state.account.securityStatusLoading

const securityStatusSelector = (state: RootState) =>
  state.account.securityStatus

const userPreferencesSelector = (state: RootState) =>
  state.account.userPreferences

const loadingPreferencesSelector = (state: RootState) =>
  state.account.loadingPreferences

const errorUpdatingPreferencesSelector = (state: RootState) =>
  state.account.errorUpdatingPreferences

export {
  userDetailsSelector,
  financialPlanSelector,
  subscriptionSelector,
  userIsLoadingSelector,
  userPreferencesSelector,
  loadingPreferencesSelector,
  errorUpdatingPreferencesSelector,
  securityStatusSelector,
  securityStatusLoadingSelector,
}
