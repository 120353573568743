import { buildingSummaryReducer } from './summary'
import { buildingSearchHistoryReducer } from './buildingSearchHistory'
import { landSummaryReducer } from './landSummary'
import { energyReducer } from './energy'
import { buildingConfigurationReducer } from './configuration'

export * from './summary'
export * from './buildingSearchHistory'
export * from './landSummary'
export * from './energy'
export * from './configuration'

export const buildingDetailsReducer = {
  ...buildingSummaryReducer,
  ...buildingSearchHistoryReducer,
  ...landSummaryReducer,
  ...energyReducer,
  ...buildingConfigurationReducer,
}
