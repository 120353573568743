/* eslint-disable no-param-reassign */
// import { mapBy } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import reminderService from './reminderService'

const initialState = {
  reminderLands: [],
  reminderByLandId: {},
  loading: false,
} as any

export const getReminderLands = createAsyncThunk(
  'reminder/getReminderLands',
  reminderService.getReminderLands,
)

const reminder = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    // Handle synchronous actions here
  },
  extraReducers: (builder) => {
    // Handle async actions here, not in reducers
    builder
      .addCase(getReminderLands.fulfilled, (state, action) => {
        const { data } = action.payload || {}

        state.loading = false
        state.reminderLands = data

        //   if (data) {
        //     state.reminderByLandId.push(
        //       ...data.data.map((land: any) => parseInt(land.land_id, 10)),
        //     )
        //   }
      })
      .addCase(getReminderLands.pending, (state) => {
        state.loading = true
      })
      .addCase(getReminderLands.rejected, (state) => {
        state.loading = false
      })
  },
})

export const reminderReducer = {
  reminder: reminder.reducer,
}
