import Box from '@mui/material/Box'
import { useResize } from '@popety_io/popety-io-lib'
import TransactionsChart from './TransactionsChart'

const TransactionStats = ({ transactionsStats }: any) => {
  const { ref, width = 530 } = useResize()

  return (
    <Box ref={ref} ml="-16px" mt={3} width="100%" height="100%" minHeight={500}>
      {transactionsStats?.map((transactionStats: any) => (
        <TransactionsChart
          key={transactionStats.title}
          title={transactionStats.title}
          width={width}
          transactionStats={transactionStats}
        />
      ))}
    </Box>
  )
}

export default TransactionStats
