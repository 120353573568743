const consoleError = console.error

// TODO: we need to discuss about this
const SUPPRESSED_WARNINGS = [
  'Warning: React does not recognize the `%s` prop on a DOM element',
  'Warning: Received `%s` for a non-boolean attribute `%s`',
  'The pseudo class ":first-child" is potentially unsafe when doing server-side rendering',
  'A non-serializable value was detected in the state, in the path',
  'A non-serializable value was detected in an action, in the path:',
  'Warning: Invalid DOM property `%s`. Did you mean `%s`?%s',
]

console.error = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg?.includes?.(entry))) {
    consoleError(msg, ...args)
  }
}
