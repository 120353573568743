import { createSelector } from '@reduxjs/toolkit'

import { getPriceData } from '../../../utils'

const buyAggsSelector = (state) => state.landPreview.buyData

const rentAggsSelector = (state) => state.landPreview.rentData

const buyAppAggsSelector = (state) => state.landPreview.buyAppartmentData
const rentAppAggsSelector = (state) => state.landPreview.rentAppartmentData

const buyHouseAggsSelector = (state) => state.landPreview.buyHouseData
const rentHouseAggsSelector = (state) => state.landPreview.rentHouseData

const buyDataSelector = createSelector(
  buyAggsSelector,
  (aggs) => aggs && getPriceData(aggs.price_over_time.price_over_time.buckets),
)

const rentDataSelector = createSelector(
  rentAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs.price_over_time.price_over_time.buckets, 12),
)

const buyAppartmentDataSelector = createSelector(
  buyAppAggsSelector,
  (aggs) => aggs && getPriceData(aggs.price_over_time.price_over_time.buckets),
)

const rentAppartmentDataSelector = createSelector(
  rentAppAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs.price_over_time.price_over_time.buckets, 12),
)

const buyHouseDataSelector = createSelector(
  buyHouseAggsSelector,
  (aggs) => aggs && getPriceData(aggs.price_over_time.price_over_time.buckets),
)
const rentHouseDataSelector = createSelector(
  rentHouseAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs.price_over_time.price_over_time.buckets, 12),
)

const buyMaxDateSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string,
)

const rentMaxDateSelector = createSelector(
  rentAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string,
)

const landFaoCountSelector = (state) => state.landPreview.faos?.length

const landTransactionCountSelector = (state) =>
  state.landPreview.transactions?.length

const landPreviewByIdSelector = (state) => state.landPreview.landPreviewById

export {
  buyDataSelector,
  rentDataSelector,
  buyMaxDateSelector,
  rentMaxDateSelector,
  landFaoCountSelector,
  landTransactionCountSelector,
  landPreviewByIdSelector,
  buyAppartmentDataSelector,
  rentAppartmentDataSelector,
  buyHouseDataSelector,
  rentHouseDataSelector,
}
