/* eslint-disable import/no-cycle */
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useI18n } from '@popety_io/popety-io-lib'
import ExternalDataLinks from './ExternalDataLinks'

export type ExternalLinksProps = BoxProps & {
  data?: { lands?: any[]; buildings?: any[]; [k: string]: any }
  hideTitle?: boolean
  split?: boolean
}

export const StyledSpan = styled(Box)`
  font-size: 1.1rem;
  font-weight: 500;
`

const LandExternalLinks = ({
  data,
  hideTitle,
  ...other
}: ExternalLinksProps) => {
  const { t } = useI18n()

  if (!data) return null

  return (
    <Box {...other}>
      <Box display="flex" gap={2} justifyContent="space-between" mb="10px">
        {!hideTitle && (
          <StyledSpan>{t('common.search.details.links')}</StyledSpan>
        )}
      </Box>

      <ExternalDataLinks data={data} />
    </Box>
  )
}

export default LandExternalLinks
