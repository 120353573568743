import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import EditIcon from '@mui/icons-material/Edit'
import { useI18n } from '@popety_io/popety-io-lib'
import ModalSaveEditing from './ModalSaveEditing'
import { TableCellRoot } from './BuildingArea.style'

type BuildingAreaHeaderProps = {
  open: boolean
  handleClick: any
  handleSave: (bool: boolean) => void
  name?: string
  planName?: string
}

const BuildingAreaHeader = ({
  open,
  handleClick,
  handleSave,
  name,
  planName,
}: BuildingAreaHeaderProps) => {
  const { t } = useI18n()
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }

  return (
    <>
      <TableRow
        sx={{
          '& td, & th': { border: 0 },
        }}
      >
        {open && <TableCell width="3%" />}
        <TableCell width="35%">
          <span>{t('land.underExploited.SurfaceAndIndiceSubtitle')}</span>
        </TableCell>
        <TableCellRoot
          align="right"
          width="15%"
          className="current-under-exploited"
        >
          <span>{t('land.underExploited.currentValue')}</span>
        </TableCellRoot>
        <TableCellRoot
          align="right"
          width="15%"
          className="auth-under-exploited"
        >
          <span>{t('land.underExploited.authorizedValue')}</span>
        </TableCellRoot>
        <TableCellRoot
          align="right"
          width="15%"
          className="diffrence-under-exploited"
        >
          <span>{t('land.underExploited.differenceValue')}</span>
        </TableCellRoot>
        <TableCellRoot align="right">
          {!open ? (
            <Tooltip placement="top" title={t('land.underExploited.overide')}>
              <IconButton
                aria-label="edit"
                onClick={handleClick}
                data-cy="land-detail-edit-btn"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <IconButton size="small">
                <SaveIcon
                  onClick={handleOpenModal}
                  fontSize="small"
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
              </IconButton>
              <IconButton size="small" onClick={handleClick}>
                <CloseIcon
                  fontSize="small"
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
              </IconButton>
            </>
          )}
        </TableCellRoot>
      </TableRow>
      <ModalSaveEditing
        open={openModal}
        planName={planName}
        onCancel={handleOpenModal}
        onSave={handleSave}
        name={name}
      />
    </>
  )
}

export default BuildingAreaHeader
