import { createAsyncThunk } from '@reduxjs/toolkit'

import alertService from './alertService'

export const getAlerts = createAsyncThunk(
  'alert/getAlerts',
  alertService.getAlerts,
)

export const pauseAlert = createAsyncThunk(
  'alert/pauseAlert',
  alertService.pauseAlert,
)

export const getCityById = createAsyncThunk(
  'alert/getCityById',
  alertService.getCityById,
)

export const getZonesByCity = createAsyncThunk(
  'alert/getZonesByCity',
  alertService.getZonesByCity,
)

export const getDistrictById = createAsyncThunk(
  'alert/getDistrictById',
  alertService.getDistrictById,
)

export const getZonesByDistrict = createAsyncThunk(
  'alert/getZonesByDistrict',
  alertService.getZonesByDistrict,
)

export const getRegionById = createAsyncThunk(
  'alert/getRegionById',
  alertService.getRegionById,
)

export const getZonesByRegion = createAsyncThunk(
  'alert/getZonesByRegion',
  alertService.getZonesByRegion,
)

export const getDistrictGeojsonByZone = createAsyncThunk(
  'alert/getDistrictGeojsonByZone',
  alertService.getDistrictGeojsonByZone,
)

export const getCityGeojsonByZone = createAsyncThunk(
  'alert/getCityGeojsonByZone',
  alertService.getCityGeojsonByZone,
)

export const getRegionGeojsonByZone = createAsyncThunk(
  'alert/getRegionGeojsonByZone',
  alertService.getRegionGeojsonByZone,
)

export const createAlert = createAsyncThunk(
  'alert/createAlert',
  alertService.createAlert,
)

export const updateAlert = createAsyncThunk(
  'alert/updateAlert',
  alertService.updateAlert,
)

export const deleteAlert = createAsyncThunk(
  'alert/deleteAlert',
  alertService.deleteAlert,
)
