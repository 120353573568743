import { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Tooltip } from '@mui/material'
import { useI18n, useRouter } from '@popety_io/popety-io-lib'
import IconWIthLegends from '../../../components/IconWIthLegends'
import { TitleRoot } from '../../../components/Legend/Legend.style'

import {
  energyOptions,
  powerSourceWaterHeater,
  solarAverageExposure,
} from '../../../utils/thematic/glossary/energyOptions'

const EnergyLegend = () => {
  const { t } = useI18n()

  const { updateQuery, query } = useRouter()

  const options = energyOptions(t)
  const solarAverageLegened = solarAverageExposure(t)
  const powerSourceWaterHeaterLegened = powerSourceWaterHeater(t)

  useEffect(() => {
    return () => {
      updateQuery({ energyLayer: undefined })
      updateQuery({ displayEnergyLegend: undefined })
    }
  }, [])

  useEffect(() => {
    updateQuery({ energyLayer: 'solar_average_exposure' })
    updateQuery({ displayEnergyLegend: true })
    updateQuery({ mapLg: 'energy' })
  }, [])

  const onChange = (event: any) => {
    const { value } = event

    updateQuery({ energyLayer: value })
  }

  return (
    <IconWIthLegends
      id="energy"
      value={query.energyLayer || 'power_source_heater'}
      options={options}
      displayValue="displayEnergyLegend"
      keyValue="energyLayer"
      onChange={onChange}
    >
      <Box
        sx={{ overflowY: 'auto', maxHeight: 500, padding: '8px' }}
        display="flex"
        flexDirection="column"
        gap="4px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap={1}
          sx={{
            flexDirection: 'column',
          }}
        >
          {query.energyLayer === 'solar_average_exposure' &&
            solarAverageLegened.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <TitleRoot color={item.color} />
                <Tooltip title={item.name}>
                  <span>{item.name}</span>
                </Tooltip>
              </Box>
            ))}
          {(query.energyLayer === 'power_source_water_heater' ||
            query.energyLayer === 'power_source_heater') &&
            powerSourceWaterHeaterLegened.map((item: any, i: number) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <TitleRoot color={item.color} />
                <Tooltip title={item.name}>
                  <span>{item.name}</span>
                </Tooltip>
              </Box>
            ))}
        </Box>
      </Box>
    </IconWIthLegends>
  )
}

export default EnergyLegend
