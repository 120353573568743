/* eslint-disable no-nested-ternary */
import { useEffect } from 'react'
import {
  LayersIcon,
  MapLegend,
  useI18n,
  useRouter,
} from '@popety_io/popety-io-lib'
import { Box, Tooltip, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import IconBtton from '@mui/material/IconButton'
import { designOptions } from '../../../utils/thematic/glossary/designOptions'
import { TitleRoot } from '../../../components/Legend/Legend.style'

const DesignMapContent = () => {
  const { t } = useI18n()
  const options = designOptions(t)
  const { query, updateQuery } = useRouter()
  const displayValue = 'displayDesignLegend'

  const display = query[displayValue] === 'true'

  const handleDisplayLegend = (display: boolean) => {
    updateQuery({ [displayValue]: display })
  }

  useEffect(() => {
    updateQuery({ displayDesignLegend: false })
    updateQuery({ mapLg: 'design-legend' })

    return () => {
      updateQuery({ displayDesignLegend: undefined })
      updateQuery({ mapLg: undefined })
    }
  }, [])

  return (
    <MapLegend
      title={t('common.layers')}
      id="design-legend"
      top="16px"
      icon={LayersIcon}
      zIndex={1000}
      style={{
        width: 'auto',
        overflowX: 'hidden',
      }}
      right="16px"
      left="auto"
      padding="4px"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: '#969696',
              fontSize: '0.9rem',
              fontWeight: 500,
              lineHeight: 1.66,
              textTransform: 'uppercase',
            }}
          >
            {t('land.livabilityScore.Legend')}
          </Typography>
        </Box>
        <Box>
          <IconBtton onClick={() => handleDisplayLegend(!display)}>
            {!display ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <VisibilityOffIcon fontSize="small" />
            )}
          </IconBtton>
        </Box>
      </Box>
      {display && (
        <Box
          sx={{ overflowY: 'auto', maxHeight: 500, padding: '8px' }}
          display="flex"
          flexDirection="column"
          gap="4px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            sx={{
              flexDirection: 'column',
            }}
          >
            {options?.[0]?.legend?.map((item: any, i: number) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <TitleRoot color={item.color} />
                <Tooltip title={item.value}>
                  <span>{item.value}</span>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </MapLegend>
  )
}

export default DesignMapContent
