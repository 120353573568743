import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'

const EventHistoryHeader = ({
  countEvent,
  event,
  handleChange,
  loading,
  checked,
  regionName,
  faos,
  rentListings,
  purchaseListings,
  transactionList,
}: any) => {
  const { t } = useI18n()

  const checkMarks = [
    {
      label: t('common.Mise à l’enquête'),
      count: faos?.length ? faos?.length : 0,
      key: 'Fao',
      color: 'rgba(0, 173, 230, 1)',
    },
    regionName === 'Genève' && {
      label: t('common.Transaction'),
      count: transactionList?.length ? transactionList?.length : 0,
      key: 'Transaction',
      color: 'rgba(0, 173, 230, 0.7)',
    },
    {
      label: t('common.Annonce de location'),
      count: rentListings?.length ? rentListings?.length : 0,
      key: 'Rental',
      color: 'rgba(0, 173, 230, 0.3)',
    },
    {
      label: t('common.Annonce de vente'),
      count: purchaseListings?.length ? purchaseListings?.length : 0,
      key: 'Sale',
      color: 'rgba(0, 173, 230, 0.3)',
    },
  ].filter(Boolean)

  return (
    <Box
      position="sticky"
      top={0}
      zIndex={10}
      bgcolor="background.paper"
      sx={{
        pb: 1,
        pt: 2,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          marginBottom: '4px',
        }}
        fontSize="1.7rem"
        fontWeight={500}
      >
        {t('common.historique')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: '#969696',
          paddingBottom: '10px',
        }}
        fontSize="1rem"
      >
        {t('common.history.history_description')}
      </Typography>
      {countEvent > 0 && !event?.id ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            top: 0,
            zIndex: 1000,
            background: '#fff',
            width: '100%',
            justifyContent: 'space-between',
            marginLeft: '-9px',
          }}
        >
          {checkMarks.map((mark: any, indexMark: number) => (
            <MenuItem
              key={indexMark}
              value={mark.label}
              sx={{
                paddingX: 0,
              }}
              onClick={() => handleChange(mark.key)}
              disabled={loading}
            >
              <Checkbox
                checked={
                  checked.find((item: any) => item.key === mark.key)?.checked
                }
                name={mark.key}
                disabled={loading}
                sx={{
                  '&.Mui-checked': {
                    color: mark.color,
                  },
                }}
              />
              <ListItemText
                primary={`${mark.label} (${mark.count})`}
                sx={{
                  paddingRight: '10px',
                }}
              />
            </MenuItem>
          ))}
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export { EventHistoryHeader }
