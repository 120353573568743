const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.landRestriction.startTitle'),
          description: t('driver.landRestriction.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#restrictions',
        popover: {
          title: t('driver.landRestriction.restrictionsTitle'),
          description: t('driver.landRestriction.restrictionsDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
