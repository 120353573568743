import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const InputRoot = styled(Box)`
  position: relative;
  height: 'max-content';
  width: 100%;
  font-size: 0.77rem;
  &:focus {
    border-color: #00ade6;
  }
`

export const ButtonConfirm = styled(Button)`
  font-size: 0.77rem;
  text-transform: none;
  position: absolute;
  bottom: 6px;
  right: 2px;
`
