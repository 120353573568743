/* eslint-disable no-nested-ternary */
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import Box from '@mui/material/Box'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { styled } from '@mui/material'

const SwiperWrapper = styled(Box)`
  .swiper-button-prev {
    width: 46px;
    height: 48px;
  }
  .swiper-button-next {
    width: 46px;
    height: 48px;
  }

  .swiper-button-prev::after {
    display: none !important;
  }
  .swiper-button-next::after {
    display: none !important;
  }
`

const ListingImages = ({ listing }: { listing: any }) => {
  return (
    <SwiperWrapper>
      {!!listing?.urls_image?.filter((url: string) => url)?.length && (
        <Swiper
          spaceBetween={5}
          slidesPerView="auto"
          slidesOffsetAfter={0}
          loopAddBlankSlides={false}
          modules={[Navigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {listing.urls_image
            ?.filter((url: string) => url)
            .map((image: any, i: number) => (
              <SwiperSlide key={i}>
                <Box
                  sx={{
                    position: 'relative',
                    height: 180,
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: 2,
                  }}
                >
                  <img
                    src={image}
                    alt="Uploaded"
                    style={{
                      width: 'auto',
                      height: '100%',
                      display: 'block',
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          <SwiperSlide>
            <></>
          </SwiperSlide>
          <Box className="swiper-button-prev">
            <ArrowBackIosNewIcon />
          </Box>
          <Box className="swiper-button-next">
            <ArrowForwardIosIcon />
          </Box>
        </Swiper>
      )}
    </SwiperWrapper>
  )
}

export default ListingImages
