import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'

import { useState, useMemo } from 'react'
import { useI18n } from '@popety_io/popety-io-lib'
import { specialPlansSelector, landUsePlansSelector } from '../redux'
import HistoryAccordion from '../components/HistoryAccordion'
import { getStatus } from './UnderExploited.utils'
import UnderExploitedHeader from './UnderExploitedHeader'
import LandUsePlan, { LandUsePlansOpts } from './LandUsePlan'
import { UnderExploitedRoot } from './UnderExploited.style'
import LandUsePlanHeader from './LandUsePlan/LandUsePlanHeader'

const UnderExploitedContent = () => {
  const landUsePlans = useSelector(landUsePlansSelector)
  const specialPlans = useSelector(specialPlansSelector)
  const { t } = useI18n()

  const [expandedId, setExpandedId] = useState('history_accordion_0')

  const title = (lup: any) => (
    <Box display="flex" gap={3}>
      <Box
        sx={{
          minWidth: '150px',
          maxWidth: '205px',
        }}
      >
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >{`${t('land.underExploited.planType')} `}</Typography>
      </Box>

      <Typography className="ellipsis">
        {(lup.plan_type || '') +
          (lup.adoption_date
            ? ` ${t('land.underExploited.adopted')} ${lup.adoption_date}`
            : '') +
          (lup.status ? ` - ${getStatus(lup.status, t)}` : '')}
      </Typography>
    </Box>
  )

  const handleExpandChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedId(isExpanded ? panel : '')
    }

  const sortedLandUsePlans = useMemo(() => {
    return landUsePlans?.sort((lup) => {
      const isProjected = lup?.plan_type?.includes('projected')

      return isProjected ? -1 : 1
    })
  }, [landUsePlans])

  return (
    <>
      <UnderExploitedHeader
        landUsePlansNumber={landUsePlans?.length}
        specialPlansNumber={specialPlans?.length}
      />

      <UnderExploitedRoot container>
        {sortedLandUsePlans?.map((lup: LandUsePlansOpts, i) => {
          const boxShadow = lup?.plan_type?.includes('projected')
            ? '0px 2px 6px 0px rgb(0 173 230), 0px 2px 10px 0px rgb(0 173 230 / 90%), 0px 4px 20px -2px rgb(0 173 230 / 80%)'
            : null

          return (
            <HistoryAccordion
              key={i}
              data-cy="underExploitedContent"
              sx={{ width: '100%', marginBottom: 1, boxShadow }}
              col1={{ title: title(lup) }}
              col2={{ title: lup.id }}
              expanded={expandedId === `history_accordion_${i}`}
              onChange={
                lup.building_area
                  ? handleExpandChange(`history_accordion_${i}`)
                  : undefined
              }
              headerComponent={
                <CardHeader
                  sx={{
                    padding: 0,
                    margin: 0,
                    border: 'none',
                    boxShadow: 'none',
                    cursor: lup.building_area ? 'pointer' : 'default',
                  }}
                  title={
                    <LandUsePlanHeader
                      lup={lup}
                      isPLQ={
                        lup.plan_type === 'PLQ' ||
                        lup.plan_type === 'PQ' ||
                        lup.plan_type === 'PAD'
                      }
                    />
                  }
                />
              }
              lup={lup}
            >
              <LandUsePlan lup={lup} />
            </HistoryAccordion>
          )
        })}
      </UnderExploitedRoot>
    </>
  )
}

export default UnderExploitedContent
