/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import tagsService from './tagsService'

const initialState = {
  tags: [],
}

export const fetchTags = createAsyncThunk(
  'tags/fetchTags',
  tagsService.fetchTags,
)

const tags = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      const { data = [] } = action.payload || {}

      state.tags = data
    })
  },
})

export const tagsReducer = {
  tags: tags.reducer,
}
