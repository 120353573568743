import { matchSorter, MatchSorterOptions } from 'match-sorter'
import { useEffect, useState } from 'react'

type SearchOptions<T = unknown> = MatchSorterOptions<T> & {
  q?: string
  data: T[]
  page?: number
  pageSize?: number
  /** Sort to apply if there is no query filter */
  sort?: any
}

const search = <T = string>({ q, data = [], ...options }: SearchOptions<T>) => {
  const list = data.slice()
  const sort = options?.sort || options?.baseSort

  if (!q?.trim()) return sort ? list.sort(sort) : list

  return matchSorter(list, q, options)
}

const useSearch = <T = string>({ q, data, ...others }: SearchOptions<T>) => {
  const { page = 1, pageSize, ...options } = others
  const [result, setResult] = useState(data)

  const pagedResult = pageSize
    ? result.slice((page - 1) * pageSize, page * pageSize)
    : result

  const count = pageSize ? Math.ceil(result.length / pageSize) : result.length

  const handleSearch = () => {
    setResult(search({ q, data, ...options }))
  }

  useEffect(() => {
    const timer = setTimeout(handleSearch)

    return () => clearTimeout(timer)
  }, [q, data?.length])

  return {
    page,
    pageSize,
    result,
    count,
    pagedResult,
    search: handleSearch,
    showPagination: !!(pageSize && result.length > pageSize),
  }
}

export default useSearch
