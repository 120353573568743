/* eslint-disable max-lines */
/* eslint-disable no-lonely-if */
import { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useI18n } from '@popety_io/popety-io-lib'
import {
  landSelector,
  addOverideValuesAssignment,
  updateOverideValues,
  groupingIdSelector,
  addGroupingOverideValuesAssignment,
  updateGroupingOverideValuesAssignment,
  buildingsSelector,
} from '../../redux'
import type { LandUsePlansOpts } from '../LandUsePlan'
import BuildingAreaBody from './BuildingAreaBody'
import { GridRoot } from './BuildingArea.style'
import {
  getIndicesValues,
  getBuildingAreaInformations,
} from './buildingArea.utils'

type BuildingAreaProps = {
  lup: LandUsePlansOpts
  landUsePlanStatus: string
}

const BuildingArea = ({
  lup,
  landUsePlanStatus = 'now',
}: BuildingAreaProps) => {
  const dispatch = useDispatch()
  const land: any = useSelector(landSelector)
  const buildings: any = useSelector(buildingsSelector)
  const groupingId = useSelector(groupingIdSelector)
  const { t } = useI18n()
  const [openEdit, setOpenEdit] = useState(false)
  const [editedValue, setEditedValue]: any = useState({})
  const defaultSelectedIndice = {
    primaryIndice: lup.typeZone?.ios || land?.primaryIndice,
    secondaryIndice: lup.typeZone?.ius || land?.secondaryIndice,
  }

  const [selectedIndice, setSelectedIndice]: any = useState(
    defaultSelectedIndice,
  )

  useEffect(() => {
    setSelectedIndice(defaultSelectedIndice)
  }, [lup?.typeZone])

  const landUsePlan = useMemo(
    () => ({
      ...lup,
      ...editedValue.primaryIndice,
      ...editedValue.secondaryIndice,
      typeZone: {
        ios: selectedIndice?.primaryIndice,
        ius: selectedIndice?.secondaryIndice,
      },
    }),
    [
      editedValue.primaryIndice,
      editedValue.secondaryIndice,
      lup,
      selectedIndice?.primaryIndice,
      selectedIndice?.secondaryIndice,
    ],
  )

  const rowsArea = getIndicesValues(
    t,
    land,
    landUsePlan,
    landUsePlanStatus,
    buildings,
    openEdit,
  )

  const rowsInformations = useMemo(
    () => getBuildingAreaInformations(t, land, landUsePlan, openEdit),
    [land, landUsePlan, openEdit, t],
  )

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedIndice((select: any) => ({
      ...select,
      [e.target.name]: e.target.value,
    }))
  }

  const handleClick = () => {
    setOpenEdit(!openEdit)
    if (openEdit) {
      setSelectedIndice(defaultSelectedIndice)
      setEditedValue({})
    }
  }

  const handleSave = (isExtend = false) => {
    const occupationIndice = editedValue?.primaryIndice || {}
    const utilisationIndice = editedValue?.secondaryIndice || {}
    const typeZone = (selectedIndice.primaryIndice ||
      selectedIndice.secondaryIndice) && {
      ios: selectedIndice.primaryIndice,
      ius: selectedIndice.secondaryIndice,
    }

    const overide = {
      ...lup.overide_json,
      typeZone,
      ...editedValue,
      ...occupationIndice,
      ...utilisationIndice,
    }

    const data = {
      isExtend,
      overide,
    }

    if (lup.editId) {
      if (groupingId) {
        dispatch(
          updateGroupingOverideValuesAssignment({
            data,
            id: lup.editId,
            landUsePlanStatus,
          }) as any,
        )
      } else {
        dispatch(
          updateOverideValues({
            data,
            id: lup.editId,
            landUsePlanStatus,
          }) as any,
        )
      }
    } else {
      if (groupingId) {
        dispatch(
          addGroupingOverideValuesAssignment({
            data,
            groupingId,
            landUsePlanAssignmentId: lup.id,
            landUsePlanStatus,
          }) as any,
        )
      } else {
        dispatch(
          addOverideValuesAssignment({
            data,
            landId: land.land_id,
            landUsePlanAssignmentId: lup.id,
            landUsePlanStatus,
          }) as any,
        )
      }
    }
    setOpenEdit(false)
  }

  const handleEdit = (e: any) => {
    setEditedValue((edited: any) => ({ ...edited, ...e }))
  }

  const std = parseInt((land.area * lup.cover_area_perc).toFixed(0), 10)

  return (
    <GridRoot typeof="submit" container>
      <BuildingAreaBody
        open={openEdit}
        rowsArea={rowsArea}
        rowsInformations={rowsInformations}
        selected={selectedIndice}
        std={std}
        handleEdit={handleEdit}
        handleSave={handleSave}
        handleClick={handleClick}
        handleChangeRadio={handleChangeRadio}
        lup={lup}
      />
    </GridRoot>
  )
}

export default BuildingArea
