import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const LegendRoot = styled(Paper)`
  padding: 16px;
  width: fit-content;

  > div {
    display: flex;
    align-items: flex-start;

    > span {
      margin-left: 12px;
      padding: 3px;
    }
  }
`
