/* eslint-disable max-lines */
import { useState, useEffect, useMemo } from 'react'
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Skeleton,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import TablePagination from '@mui/material/TablePagination'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOnce, useI18n, useRouter } from '@popety_io/popety-io-lib'
import EstimateHistoryFilters from '../EstimateHistoryFilters'
import EstimateHistoryContainer from '../EstimateHistoryContainer'
import {
  estimateHistorySelector,
  getEstimateHistory,
  loadHistorySelector,
} from '../../redux'
import { filtersEstimateHistory } from './utils'

const EstimateHistory: React.FC = () => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { query, updateQuery } = useRouter()
  const [checks, setChecks] = useState({ active: true, archived: false })
  const estimateHistory = useSelector(estimateHistorySelector)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loading = useSelector(loadHistorySelector)
  const [value, setValue] = useState('')
  const { page = 0, size = 25 } = query

  useEffect(() => {
    fetchOnce('getEstimateHistory', null, () => {
      dispatch(getEstimateHistory() as any)
    })
  }, [])

  const handleChange =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecks({ ...checks, [name]: event.target.checked })
    }

  const filteredEstimateHistory = filtersEstimateHistory({
    estimateHistories: estimateHistory,
    value,
    query,
    checks,
  })

  const pagedResult = useMemo(
    () =>
      filteredEstimateHistory?.slice(+page * +size, (+page + 1) * +size) || [],
    [filteredEstimateHistory, page, size],
  )
  const hasPage = useMemo(
    () => pagedResult?.length !== filteredEstimateHistory?.length,
    [pagedResult, filteredEstimateHistory],
  )

  const handleChangePage = (e: any, newPage: number) => {
    updateQuery({ page: newPage })
  }

  const handleChangePageSize = (event: any) => {
    updateQuery({ size: +event.target.value, page: 0 })
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '4px',
            textTransform: 'uppercase',
          }}
          fontSize="1.5rem"
          fontWeight={500}
        >
          {t('estimate.Rapport estimation vente bien individuel')}
        </Typography>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => handleChange('active')(e)}
                checked={checks.active}
              />
            }
            label={t('common.Actifs')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checks.archived}
                onChange={(e) => handleChange('archived')(e)}
              />
            }
            label={t('common.Archivés')}
          />
        </FormGroup>
      </Box>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          paddingBottom: '20px',
        }}
      >
        <TextField
          variant="outlined"
          label={`${t('common.landMapSearch')}`}
          placeholder={`${t('common.landMapSearch')}...`}
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <EstimateHistoryFilters />
        {hasPage && (
          <TablePagination
            component="div"
            align="left"
            count={filteredEstimateHistory?.length}
            page={+page}
            onPageChange={handleChangePage}
            rowsPerPage={+size}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage={t('common.rowsPerPage')}
          />
        )}
        {!loading ? (
          pagedResult?.map((element: any) => (
            <EstimateHistoryContainer key={element?.id} element={element} />
          ))
        ) : (
          <div>
            {Array.from({ length: 10 }).map((i: any, index: number) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height="110px"
                sx={{
                  borderRadius: '2px',
                  marginBottom: '12px',
                }}
              />
            ))}
          </div>
        )}
        {hasPage && (
          <TablePagination
            component="div"
            align="left"
            count={filteredEstimateHistory?.length}
            page={+page}
            onPageChange={handleChangePage}
            rowsPerPage={+size}
            onRowsPerPageChange={handleChangePageSize}
            labelRowsPerPage={t('common.rowsPerPage')}
          />
        )}
      </Box>
    </Box>
  )
}

export default EstimateHistory
