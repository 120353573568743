/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Tooltip from '@mui/material/Tooltip'
import {
  MapLegend,
  useI18n,
  groupBy,
  useRouter,
} from '@popety_io/popety-io-lib'
import { rdppfsSelector } from '../../redux'

const GeoMapContent = () => {
  const rdppfs = useSelector(rdppfsSelector)
  const { updateQuery, query } = useRouter()
  const { rdppfCategories = '' } = query

  const affectedRdppfs = Object.values(groupBy('rdppf_type_id', rdppfs))

  const { t } = useI18n()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let rddpfsTypes = rdppfCategories?.split(',')

    if (!event.target.checked) {
      rddpfsTypes?.push(event.target.value)
    } else {
      rddpfsTypes = rddpfsTypes?.filter(
        (rdppf: any) => rdppf !== event.target.value,
      )
    }

    updateQuery({
      rdppfCategories: rddpfsTypes?.filter((r: string) => r !== '')?.join(','),
    })
  }

  return (
    <MapLegend
      title={t('common.settings')}
      id="geo-settings"
      icon={SettingsIcon}
      left="auto"
      right="16px"
      defaultOpen
    >
      {affectedRdppfs.map((rdppf: any, i: number) => {
        return (
          <Tooltip
            key={i}
            title={t(`land.restrictions.zone.${rdppf?.[0]?.name}`)}
          >
            <FormControlLabel
              value={rdppf?.[0]?.name}
              checked={!rdppfCategories?.split(',')?.includes(rdppf?.[0]?.name)}
              control={
                <Checkbox
                  color="primary"
                  checked={
                    !rdppfCategories?.split(',')?.includes(rdppf?.[0]?.name)
                  }
                  onChange={handleChange}
                />
              }
              label={t(`land.restrictions.zone.${rdppf?.[0]?.name}`)}
            />
          </Tooltip>
        )
      })}
    </MapLegend>
  )
}

export default GeoMapContent
