import { getYearColor, Map } from '@popety_io/popety-io-lib'
import { API_BASE_URL } from '../../../../services'

const renderMethod = 'aggs'

const sourceName = 'es_mvt_listing'
const fillStyle = 'layer_fill_listing'

const removeListingTiles = ({ map }: { map: Map }) => {
  if (map.getSource(sourceName)) {
    map.removeLayer(fillStyle)
    map.removeSource(sourceName)
  }
}

const getListingsTiles = ({ map, query }: { map: Map; query: any }) => {
  try {
    if (map.getSource(sourceName)) {
      map.removeLayer(fillStyle)
      map.removeSource(sourceName)
    }

    map.addSource(sourceName, {
      type: 'vector',
      tiles: [`${API_BASE_URL}/listing/tiles?query=${query}&x={x}&y={y}&z={z}`],
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer({
      id: fillStyle,
      type: 'circle',
      source: sourceName,
      'source-layer': renderMethod,
      paint: {
        'circle-radius': 8,
        'circle-color': 'rgb(0, 173, 230)',
        'circle-opacity': 0.5,
        'circle-stroke-color': 'rgb(0, 173, 230)',
        'circle-stroke-opacity': 1,
        'circle-stroke-width': 1,
      },
    })

    map.setFilter(fillStyle, [
      'any',
      ['==', ['geometry-type'], 'Point'],
      ['==', ['geometry-type'], 'MultiPoint'],
    ])
  } catch (error) {
    console.error(error)
  }
}

const getListingAroudLayers = (
  listings: any[],
  yearRange: number[],
  startDate: Date | string,
  rent: boolean,
  purchase: boolean,
) => {
  const data = {
    type: 'FeatureCollection',
    features:
      listings
        ?.map((listing: any[]) => {
          const years = listing?.map((t) =>
            Number(t.listing_timestamp.substring(0, 4)),
          )

          const listingYears = years?.filter(
            (year) => year >= yearRange[0] && year <= yearRange[1],
          )

          const listingType = listing?.map((l) => l.type)

          if (listingYears?.length <= 0) return null

          if (
            (!rent && listingType?.includes('rent')) ||
            (!purchase && listingType?.includes('purchase'))
          ) {
            return null
          }

          const index =
            years?.findIndex((year) => year === listingYears[0]) || 0

          const properties = JSON.parse(JSON.stringify(listing?.[index] || {}))

          const geometry = properties.land_geojson
            ? JSON.parse(properties.land_geojson)
            : []

          delete properties.land_geojson

          const date = properties?.listing_timestamp
          const year = date ? new Date(date).getFullYear() : undefined

          properties.color = getYearColor(year as number, startDate)

          return { type: 'Feature', geometry, properties }
        })
        .filter(Boolean) || [],
  }

  return [
    {
      id: 'listing',
      type: 'geojson',
      data,
      layers: [
        {
          id: 'listing-layer',
          type: 'fill',
          paint: { 'fill-color': ['get', 'color'] },
          click: true,
          hover: true,
        },
      ],
    },
  ]
}

export { getListingAroudLayers, getListingsTiles, removeListingTiles }
