import { isValidDate } from '@popety_io/popety-io-lib'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import generativeDesignService from './generativeDesignServices'

const initialState = {
  projects: [],
  generatedProjects: [],
  selectedProject: null,
}

export const getGenerativeDesign = createAsyncThunk(
  'generativeDesign/getGenerativeDesign',
  generativeDesignService.getGenerativeDesign,
)

const generativeDesign = createSlice({
  name: 'generativeDesign',
  initialState,
  reducers: {
    addProject: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key]

        if (key === 'date' && !isValidDate(value)) return

        state[key] = value
      })
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGenerativeDesign.fulfilled, (state, action) => {
      const { data } = action.payload || []

      if (data) {
        state.generatedProjects = data.projects.map((d) => ({
          ...d,
          height: d.max_height,
          groundArea: d?.ground_area,
          floorArea: d?.area,
          dwellings: d?.total_units,
          floors: d.max_floor,
        }))
      }
    })
  },
})

export const generativeDesignReducer = {
  generativeDesign: generativeDesign.reducer,
}

export const { addProject, setSelectedProject } = generativeDesign.actions
