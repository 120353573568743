import { Tag, useI18n } from '@popety_io/popety-io-lib'
import Box from '@mui/material/Box'
import useTagIcon from './useTagIcon'

type TagIconProps = {
  landIds: string | string[]
  display?: boolean
}

const TagIcon = ({ landIds, display }: TagIconProps) => {
  const { t } = useI18n()

  const tag = useTagIcon({ landId: landIds })

  return (
    <Box sx={{ mt: 0.5 }}>
      {display && (
        <Tag
          bgColor="#fff"
          onClick={tag.onClick}
          textColor="grey"
          style={{
            border: '1px solid grey',
          }}
        >
          {t('common.addLabel')}
        </Tag>
      )}

      {tag.Modal}
    </Box>
  )
}

export default TagIcon
