// import { MapColorLegend, useI18n } from '@popety_io/popety-io-lib'
// import { useSelector } from 'react-redux'

// import { transactionSinceSelector } from '../../redux'

const TransactionMapContent = () => {
  // const { t } = useI18n()
  // const transactionSince = useSelector(transactionSinceSelector)

  return (
    <>
      {/* <MapColorLegend
        startDate={transactionSince}
        land={t('land.history.currentlyAnalysed')}
        id="transaction-year"
        left="auto"
        right="56px"
        padding="3px"
      /> */}
    </>
  )
}

export default TransactionMapContent
