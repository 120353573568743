import ActionButton, { rowProps } from './ActionButton'

const buildColumns = ({ t }: Record<string, any>) => {
  try {
    return [
      {
        key: 'common.landRadarInfo.parcelle',
        render: (row: rowProps) => (
          <p>{`${row.city_name} ${row.code_number}`}</p>
        ),
        order: false,
      },
      {
        render: (row: rowProps) => <ActionButton land={row} />,
        order: false,
        width: '210px',
      },
    ]
      .filter(Boolean)
      .map((column) => ({
        ...column,
        label: t(column.key),
        style: { paddingTop: 8, paddingBottom: 8, minWidth: '220px' },
      }))
  } catch (error) {
    console.error(error)
  }
}

export { buildColumns }
