/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { iterateClassificationsOverTime } from '../stats.utils'
import faoService from './faoService'

const initialState = {
  faos: [],
  faosAround: [],
  faoRange: [2008, new Date().getFullYear()],
  devOnly: false,
  aggregations: {},
  totalAround: 0,
  loading: false,
}

export const getFaosByIds = createAsyncThunk(
  'fao/getFaosByIds',
  faoService.getFaosByIds,
)

export const getLandFaos = createAsyncThunk(
  'fao/getLandFaos',
  faoService.getLandFaos,
)

export const getFaosAround = createAsyncThunk(
  'fao/getFaosAround',
  faoService.getFaosAround,
)

export const getFaoAggs = createAsyncThunk(
  'fao/getFaoAggs',
  faoService.getFaoAggs,
)

const fao = createSlice({
  name: 'fao',
  initialState,
  reducers: {
    updateFaoOptions: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandFaos.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.faos = data?.filter(Boolean)
      })
      .addCase(getFaosByIds.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.faos = data?.filter(Boolean)
      })
      .addCase(getFaosAround.pending, (state) => {
        state.loading = true
      })
      .addCase(getFaosAround.rejected, (state) => {
        state.loading = false
      })
      .addCase(getFaosAround.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.faosAround = data.result

        state.totalAround = data?.total

        if (data?.result?.length) {
          const minFaoDate = Math.min(
            ...data.result.map((fao) => new Date(fao.inquiry_start_date)),
          )

          state.faoRange = [
            new Date(minFaoDate).getFullYear(),
            new Date().getFullYear(),
          ]
        }
        state.loading = false
      })
      .addCase(getFaoAggs.fulfilled, (state, action) => {
        const { data, filters } = action.payload || {}

        state.stats = iterateClassificationsOverTime(
          data?.aggregations,
          filters?.date?.min ? new Date(filters.date.min) : null,
        )

        state.percent =
          data?.aggregations?.classifications?.classifications?.buckets.map(
            (type) => {
              return {
                name: type?.key,
                value: Math.round(
                  (type.doc_count * 100) /
                    data.aggregations.classifications.doc_count,
                ),
              }
            },
          ) || []

        state.aggregations = data?.aggregations || {}
      })
  },
})

export const faoReducer = {
  fao: fao.reducer,
}

export const { updateFaoOptions } = fao.actions
