const roofEnergySelector = (state: any) => state.energy.roofEnergy
const userEditedDataSelector = (state: any) => state.energy.userEdited
const userEditedLoadingSelector = (state: any) =>
  state.energy.userEdited.isLoading

const roofPolygonSelector = (state: any) => state.energy.roofPolygon

const heatConsumptionAroundLoadingSelector = (state: any) =>
  state.energy.loadHeatConsumptionAround

const heatConsumptionAroundSelector = (state: any) =>
  state.energy.heatConsumptionAround

export {
  roofEnergySelector,
  userEditedDataSelector,
  userEditedLoadingSelector,
  roofPolygonSelector,
  heatConsumptionAroundSelector,
  heatConsumptionAroundLoadingSelector,
}
