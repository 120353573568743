export const designLegend = () => [
  { value: '1 Room', color: '#FFFF04' },
  { value: '2 Rooms', color: '#FF8002' },
  { value: '3 Rooms', color: '#FF0201' },
  { value: '4 Rooms', color: '#8000FF' },
]

export const designOptions = (t: Translator) => {
  return [
    {
      value: 'noise-road-day-v1',
      label: t('common.noise-road-day-v1'),
      legend: designLegend(),
      group: 'environment',
    },
  ]
}
