import { useState } from 'react'
import { useSelector } from 'react-redux'
import ArticleIcon from '@mui/icons-material/Article'
import OpenButton from '@mui/icons-material/OpenWith'
import IconButton from '@mui/material/IconButton'

import { useI18n } from '@popety_io/popety-io-lib'
import { importantDocumentsSelector } from '../../redux'
import { StyledGrid, StyledSpan } from './LandUsePlan.style'
import LandUsePlanDocumentsDialog from './LandUsePlanDocumentsDialog'
import type { LandUsePlansOpts } from './LandUsePlan'

type LandUsePlanDocumentsProps = {
  lup: LandUsePlansOpts
}

const LandUsePlanDocuments = ({ lup }: LandUsePlanDocumentsProps) => {
  const { t } = useI18n()
  const [openDocuments, setOpenDocument] = useState(false)
  const importantDocuments = useSelector(importantDocumentsSelector)

  const handleOpen = () => {
    setOpenDocument(!openDocuments)
  }

  return (
    <>
      <StyledGrid item xs={12}>
        <StyledSpan>{t('land.underExploited.documents')}</StyledSpan>

        {importantDocuments?.length > 0 && (
          <>
            {importantDocuments.map(
              (
                legalProvisionDocument: {
                  id: number
                  url: string
                  name: string
                },
                index: number,
              ) => {
                return (
                  Number(lup.id) === Number(legalProvisionDocument.id) && (
                    <div
                      key={legalProvisionDocument.id + index}
                      className="documents"
                    >
                      <ArticleIcon className="articleIcon" />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="pgaLink"
                        href={legalProvisionDocument?.url}
                      >
                        {legalProvisionDocument.name}
                      </a>
                    </div>
                  )
                )
              },
            )}
          </>
        )}
        <div
          className="planDetail"
          style={{ marginLeft: 4 }}
          onClick={handleOpen}
        >
          <IconButton size="small" className="button" aria-label="Description">
            <OpenButton className="icon" />
          </IconButton>
          {t('land.underExploited.displayDocuments')}
        </div>
      </StyledGrid>

      <LandUsePlanDocumentsDialog
        open={openDocuments}
        handleOpen={handleOpen}
        lup={lup}
      />
    </>
  )
}

export default LandUsePlanDocuments
