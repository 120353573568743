import { FC } from 'react'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { Typography } from '@mui/material'
import HouseIcon from '@mui/icons-material/House'
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage'
import { useI18n } from '@popety_io/popety-io-lib'

import { ButtonDesignStepOne, DesignStepOneRoot } from './DesignStepOne.style'

interface IProps {
  onChose: (type: string) => void // 'single' | 'multi' | 'apartment'
}
const DesignStepOne: FC<IProps> = ({ onChose }) => {
  const { t } = useI18n()

  const handleClick = (type: string) => {
    onChose(type)
  }

  return (
    <DesignStepOneRoot>
      <ButtonDesignStepOne onClick={() => handleClick('singleHouse')}>
        <HouseIcon sx={{ fontSize: 28 }} />
        <Typography component="span">
          {t('land.design.singleHourse')}
        </Typography>
      </ButtonDesignStepOne>
      <ButtonDesignStepOne onClick={() => handleClick('multiFamilyHouse')}>
        <HolidayVillageIcon sx={{ fontSize: 28 }} />
        <Typography component="span">
          {t('land.design.multiFamilyHourse')}
        </Typography>
      </ButtonDesignStepOne>
      <ButtonDesignStepOne onClick={() => handleClick('apartments')}>
        <ApartmentIcon sx={{ fontSize: 28 }} />
        <Typography component="span">{t('land.design.apartments')}</Typography>
      </ButtonDesignStepOne>
    </DesignStepOneRoot>
  )
}

export default DesignStepOne
