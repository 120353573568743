interface DateCardProps {
  date: string
}

const DateCard = ({ date }: DateCardProps) => {
  const localDate = new Date(date)

  return <p>{localDate.toLocaleDateString('en-US')}</p>
}

export default DateCard
