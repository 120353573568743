import {
  NoteRichTextInput as NoteRichTextField,
  useI18n,
} from '@popety_io/popety-io-lib'
import { NoteRichTextInputRoot } from './NoteRichTextInput.style'

interface IProps {
  onConfirm: (v: string) => void
}
const NoteRichTextInput = ({ onConfirm }: IProps) => {
  const { t } = useI18n()

  return (
    <NoteRichTextInputRoot>
      <NoteRichTextField
        placeholder={t('common.notes.enterNoteHere')}
        onConfirm={onConfirm}
        id="noteInput"
        textBtn={t('common.confirm')}
      />
    </NoteRichTextInputRoot>
  )
}

export default NoteRichTextInput
