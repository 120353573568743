const getDataList = (
  data: any,
  sliderValue?: any,
  isRent?: boolean,
  isPurchase?: boolean,
  devOnly?: boolean,
) =>
  data?.filter((d: any) => {
    if (
      d?.listing_timestamp?.substring(0, 4) < sliderValue[0] ||
      d?.listing_timestamp?.substring(0, 4) > sliderValue[1]
    ) {
      return false
    }

    if (
      d?.inquiry_start_date?.substring(0, 4) < sliderValue[0] ||
      d?.inquiry_start_date?.substring(0, 4) > sliderValue[1] ||
      d?.date?.substring(0, 4) < sliderValue[0] ||
      d?.date?.substring(0, 4) > sliderValue[1]
    ) {
      return false
    }

    if (!devOnly && isRent === undefined && isPurchase === undefined) {
      return true
    }

    if (
      devOnly &&
      !(
        d.classification?.includes('development') ||
        d.classification?.includes('development_beta')
      )
    ) {
      return false
    }

    if (
      (!isRent && d?.type === 'rent') ||
      (!isPurchase && d.type === 'purchase')
    ) {
      return false
    }

    return true
  })

export { getDataList }
