import { createSelector } from '@reduxjs/toolkit'

import { landAuthorizedInfoSelector } from '../summary'
import {
  getCalculatedValues,
  computeLandCost,
  computePreliminaryCost,
  computeConstructionCost,
  computeAdditionalCost,
} from './cfcHelper'
import { computeSellingCost } from './sellingHelper'

const analysisByIdSelector = (state) => state.financialAnalysis.analysisById

const selectedAnalysisSelector = (state) =>
  state.financialAnalysis.selectedAnalysis

const financialPlanSelector = (state) => state.financialAnalysis.config

const financialAnalysisListSelector = createSelector(
  analysisByIdSelector,
  (analysisById) => Object.values(analysisById),
)

const hasAnalysisSelector = createSelector(
  financialAnalysisListSelector,
  (list) => list.length > 1,
)

const selectedFinancialAnalysisSelector = createSelector(
  selectedAnalysisSelector,
  landAuthorizedInfoSelector,
  financialPlanSelector,
  (selectedAnalysis, landInfo, config) => {
    const {
      authorizedCos,
      authorizedFloorNumberMax,
      authorizedUnitNumberMax,
      landArea,
      area,
      std,
      currentLivingArea,
    } = landInfo

    const data = selectedAnalysis.analysis_data

    const editableData = {
      landArea: data.landArea ?? landArea,
      std: data.std ?? std,
      buildingGroundArea:
        data.buildingGroundArea ?? Math.round(area * authorizedCos),
      floors: data.floors ?? Math.round(authorizedFloorNumberMax) ?? 2,
      unitNb: data.unitNb ?? Math.round(authorizedUnitNumberMax) ?? 1,
      walls: data.walls ? data.walls : config.wall,
    }

    const configNotaryFee = (config.notaryFee * 100).toFixed(2)
    const configTransfertRight = (config.transfertRight * 100).toFixed(2)

    return {
      ...selectedAnalysis,
      analysis_data: {
        ...data,
        ...editableData,

        // Computed values
        ...getCalculatedValues(editableData, editableData.std || std, landInfo),
        currentLivingArea,
        landscapingCost: data.landscapingCost ?? config.landscapingPrice,
        ownFundsPercentage: data.ownFundsPercentage ?? config.ownFunds,
        interestRate: data.interestRate ?? config.interestRate,
        projectSellingPrice: data.projectSellingPrice ?? config.sellingPrice,

        // constants for financial calculation
        preparationCost: data.preparationCost ?? config.preparationPrice,
        demolitonCost: data.demolitonCost ?? config.demolitionPrice,
        constructionCost: data.constructionCost ?? config.buildingPrice,
        correlativeHeight: data.correlativeHeight ?? config.correlativeHeight,
        constructionTime: data.constructionTime ?? config.constructionTime,
        salesComission: data.salesComission ?? config.salesCommissions,
        notaryFee: data.notaryFee ?? configNotaryFee,
        transfertRight: data.transfertRight ?? configTransfertRight,
        authorisationAndTaxe: config.authorisationAndTaxe,
        managementFee: config.managementFee,
        othersAdditional: config.othersAdditional,

        // Additional code
        cfcZeroAdditionalCodes: data.cfcZeroAdditionalCodes.filter(Boolean),
        cfcOneAdditionalCodes: data.cfcOneAdditionalCodes.filter(Boolean),
        cfcTwoAdditionalCodes: data.cfcTwoAdditionalCodes.filter(Boolean),
        cfcFiveAdditionalCodes: data.cfcFiveAdditionalCodes.filter(Boolean),
      },
    }
  },
)

const reportAnalysisSelector = createSelector(
  financialAnalysisListSelector,
  selectedFinancialAnalysisSelector,
  (financialAnalysis, analysis) =>
    !analysis || +analysis.id === 0
      ? financialAnalysis.filter((f) => +f.id !== 0)
      : [analysis],
)

const landCostSelector = createSelector(
  selectedFinancialAnalysisSelector,
  computeLandCost,
)

const preliminaryCostSelector = createSelector(
  selectedFinancialAnalysisSelector,
  computePreliminaryCost,
)

const constructionCostSelector = createSelector(
  selectedFinancialAnalysisSelector,
  computeConstructionCost,
)

const totalCostNoAdditionalSelector = createSelector(
  landCostSelector,
  preliminaryCostSelector,
  constructionCostSelector,
  ({ landCostTotal }, { preliminaryCostTotal }, { constructionCostTotal }) =>
    Math.round(landCostTotal + preliminaryCostTotal + constructionCostTotal),
)

const additionalCostSelector = createSelector(
  selectedFinancialAnalysisSelector,
  totalCostNoAdditionalSelector,
  computeAdditionalCost,
)

const projectCostTotalSelector = createSelector(
  additionalCostSelector,
  totalCostNoAdditionalSelector,
  constructionCostSelector,
  (additionalCost, totalCostNoAdditional) =>
    Math.round(additionalCost.additionalCostTotal + totalCostNoAdditional),
)

const sellingCostSelector = createSelector(
  selectedFinancialAnalysisSelector,
  projectCostTotalSelector,
  computeSellingCost,
)

const salesComissionFeesSelector = createSelector(
  sellingCostSelector,
  selectedFinancialAnalysisSelector,
  ({ grossSellingPrice }, { analysis_data: data }) =>
    Math.round(grossSellingPrice * (data.salesComission / 100)),
)

export {
  financialAnalysisListSelector,
  selectedFinancialAnalysisSelector,
  landCostSelector,
  preliminaryCostSelector,
  constructionCostSelector,
  totalCostNoAdditionalSelector,
  additionalCostSelector,
  projectCostTotalSelector,
  sellingCostSelector,
  salesComissionFeesSelector,
  hasAnalysisSelector,
  reportAnalysisSelector,
  financialPlanSelector,
  selectedAnalysisSelector,
}
