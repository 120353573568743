import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'

export const TagFormRoot = styled(Box)`
  padding-top: 8px;
  width: 300px;
`

export const TagChooserRoot = styled(Box)`
  width: 300px;

  .checkIcon {
    font-size: 18px;
  }

  > button {
    justify-content: flex-start;
    box-shadow:
      0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`

export const TagPopoverRoot = styled(Popover)`
  .MuiPopover-paper {
    padding: 0;
    width: 250px;
    box-shadow:
      rgb(0 0 0 / 20%) 10px 11px 15px 7px,
      rgb(0 0 0 / 14%) 10px 24px 38px 30px,
      rgb(0 0 0 / 12%) 10px 9px 46px 8px;
  }
`
