/* eslint-disable max-lines */
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { EstimateMarketState } from './types'
import {
  getListingsAggsPurchasePerSquareMeter,
  getListingAggsPurchase,
  getEstimateListingBuyInsights,
  getListingsAggsPriceAnnoncementsByPrice,
  getListingsAggsPriceAnnoncementsByPriceSquareMeter,
  getAfterXYear,
  getListingRoomsNb,
  getRoomsByPricePerSquareMeter,
  getListingDuration,
} from './estimateMarketThunk'

export const handleGetListingsAggsPurchasePerSquareMeter = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getListingsAggsPurchasePerSquareMeter.pending, (state) => {
      state.loadByPricePerSquareMeter = true
    })
    .addCase(getListingsAggsPurchasePerSquareMeter.rejected, (state) => {
      state.loadByPricePerSquareMeter = false
    })
    .addCase(
      getListingsAggsPurchasePerSquareMeter.fulfilled,
      (state, action: any) => {
        const { data } = action.payload || {}

        state.loadByPricePerSquareMeter = false
        if (data?.aggregations) {
          state.listingAggsPurchasePerSquareMeter = data?.aggregations || {}
        }
      },
    )
}

export const handleGetListingAggsPurchase = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getListingAggsPurchase.pending, (state) => {
      state.loadingListingsAggs = true
    })
    .addCase(getListingAggsPurchase.rejected, (state) => {
      state.loadingListingsAggs = false
    })
    .addCase(getListingAggsPurchase.fulfilled, (state, action: any) => {
      const { data } = action.payload || {}

      state.loadingListingsAggs = false
      if (data?.aggregations) {
        state.listingAggsPurchase = data?.aggregations || {}
      }
    })
}

export const handleGetEstimateListingBuyInsights = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getEstimateListingBuyInsights.rejected, (state) => {
      state.loadInsights = false
      state.loadBuyData = false
    })
    .addCase(getEstimateListingBuyInsights.pending, (state) => {
      state.loadBuyData = true
      state.loadInsights = true
    })
    .addCase(
      getEstimateListingBuyInsights.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loadInsights = false
        const { data = {} } = action.payload

        state.loadBuyData = false
        state.buyData = data
      },
    )
}

export const handleGetListingsAggsPriceAnnoncements = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getListingsAggsPriceAnnoncementsByPrice.rejected, (state) => {
      state.loadAnnouncements = false
    })
    .addCase(getListingsAggsPriceAnnoncementsByPrice.pending, (state) => {
      state.loadAnnouncements = true
    })
    .addCase(
      getListingsAggsPriceAnnoncementsByPrice.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loadAnnouncements = false
        const { data = {} } = action.payload

        state.announcementData =
          data?.aggregations?.price_over_time_purchase?.price_range.buckets
      },
    )
}

export const handleGetListingsAggsPriceAnnoncementsByPriceSquareMeter = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(
      getListingsAggsPriceAnnoncementsByPriceSquareMeter.rejected,
      (state) => {
        state.loadAnnouncementsBySquareMeter = false
      },
    )
    .addCase(
      getListingsAggsPriceAnnoncementsByPriceSquareMeter.pending,
      (state) => {
        state.loadAnnouncementsBySquareMeter = true
      },
    )
    .addCase(
      getListingsAggsPriceAnnoncementsByPriceSquareMeter.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { data = {} } = action.payload

        state.loadAnnouncementsBySquareMeter = false
        state.announcementDataBySquareMeter =
          data?.aggregations?.price_over_time_purchase?.price_range.buckets
      },
    )
}

export const handleGetAfterXYear = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getAfterXYear.pending, (state) => {
      state.loadingAfterXYear = true
    })
    .addCase(getAfterXYear.rejected, (state) => {
      state.loadingAfterXYear = false
    })
    .addCase(getAfterXYear.fulfilled, (state, action: any) => {
      const { data } = action.payload || {}

      state.loadingAfterXYear = false
      state.listingAfterXYear = data?.aggregations || {}
    })
}

export const handleGetListingRoomsNb = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getListingRoomsNb.pending, (state) => {
      state.loadingListingRoomsNb = true
    })
    .addCase(getListingRoomsNb.rejected, (state) => {
      state.loadingListingRoomsNb = false
    })
    .addCase(getListingRoomsNb.fulfilled, (state, action: any) => {
      const { data } = action.payload || {}

      state.loadingListingRoomsNb = false
      if (data?.aggregations) {
        state.listingRoomsNb =
          data?.aggregations?.price_over_time_purchase?.price_range.buckets ||
          {}
      }
    })
}

export const handleGetListingDuration = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder
    .addCase(getListingDuration.pending, (state) => {
      state.loadingListingDuration = true
    })
    .addCase(getListingDuration.rejected, (state) => {
      state.loadingListingDuration = false
    })
    .addCase(getListingDuration.fulfilled, (state, action: any) => {
      const { data } = action.payload || {}

      state.loadingListingDuration = false
      if (data?.aggregations) {
        state.listingDurationDistribution =
          data?.aggregations?.price_over_time_purchase?.price_range.buckets ||
          {}
      }
    })
}

export const handleGetRoomsByPricePerSquareMeter = (
  builder: ActionReducerMapBuilder<EstimateMarketState>,
) => {
  builder.addCase(
    getRoomsByPricePerSquareMeter.fulfilled,
    (state, action: any) => {
      const { data } = action.payload || {}
      const { aggs } = action.meta.arg || ''

      if (data?.aggregations) {
        state.roomsByPricePerSquareMeter = data?.aggregations?.[aggs] || {}
      }
    },
  )
}
