import { Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from 'react-redux'

import { useI18n } from '@popety_io/popety-io-lib'
import {
  futureLandUsePlansDocumentsSelector,
  sortByOfficialIdDocumentsSelector,
} from '../../redux'
import {
  DocumentsBody,
  DocumentsContainer,
  StyledDivider,
  StyledA,
  DialogSpan,
  LabelContainer,
} from './LandUsePlan.style'
import type { LandUsePlansOpts } from './LandUsePlan'

type LandUsePlanDocumentsDialog = {
  lup: LandUsePlansOpts
  open: boolean
  handleOpen: () => void
}

const PgaDocumentsDialog = ({
  lup,
  open,
  handleOpen,
}: LandUsePlanDocumentsDialog) => {
  const { t } = useI18n()
  const futureLandUsePlansDocuments = useSelector(
    futureLandUsePlansDocumentsSelector,
  )
  const sortByOfficialIdDocuments = useSelector(
    sortByOfficialIdDocumentsSelector,
  )

  const noDocs =
    !sortByOfficialIdDocuments?.length && !futureLandUsePlansDocuments?.length

  const getType = (document: { label: string }) => {
    const type = parseInt(document.label, 10)

    if (type === 1) return t('land.underExploited.legalBasis')

    if (type === 2) return t('land.underExploited.legalProvisions')

    return t('land.underExploited.other')
  }

  return (
    <Dialog open={open} onClose={handleOpen}>
      <DialogContent>
        <DialogTitle sx={{ p: 0, pb: 2 }}>
          {t('land.underExploited.documents')}
        </DialogTitle>

        {sortByOfficialIdDocuments?.map((documents) => (
          <Fragment key={documents?.label}>
            <DocumentsContainer>
              <DialogSpan>
                {t(`land.underExploited.${documents?.label}`)}
              </DialogSpan>
              <LabelContainer>
                {documents?.options.map((document) => (
                  <DocumentsBody key={document.label}>
                    <span className="spanDocument">{getType(document)}</span>
                    {document?.options.map(
                      (
                        doc: {
                          id: number
                          url: string
                          name: string
                        },
                        i: number,
                      ) =>
                        +lup.id === +doc.id && (
                          <StyledA
                            key={i}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={doc.url}
                          >
                            {doc.name}
                          </StyledA>
                        ),
                    )}
                  </DocumentsBody>
                ))}
              </LabelContainer>
            </DocumentsContainer>
            <StyledDivider />
          </Fragment>
        ))}

        {futureLandUsePlansDocuments?.map(
          (
            document: {
              id: number
              url: string
              name: string
              label: string
            },
            i: number,
          ) =>
            +lup.id === +document.id && (
              <DialogContent key={i}>
                <span style={{ marginRight: 5 }}>{getType(document)}</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={document.url}
                >
                  {document.name}
                </a>
              </DialogContent>
            ),
        )}

        {noDocs && (
          <DialogContent sx={{ pl: 0 }}>
            {t('land.underExploited.noDocuments')}
          </DialogContent>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PgaDocumentsDialog
