import { ApiClient } from '../../../../services'

const client = new ApiClient()

const fetchPrincipalType = async (cityNames: string[]) => {
  return client.get(`/zones/primary/${cityNames.map(encodeURIComponent)}`)
}

const fetchLandUsePlan = async (cityNames: string[]) => {
  return client.get(`/zones/landuseplan/${cityNames.map(encodeURIComponent)}`)
}

const fetchLandUsePlanByNpa = async (npaIds: string[]) => {
  return client.get(`/zones/npa/landuseplan/${npaIds.map(encodeURIComponent)}`)
}

const fetchLandUsePlanByMunicipalities = async (municipalities: string[]) => {
  return client.get(
    `/zones/municipalities/landuseplan/${municipalities.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanByRegions = async (regions: string[]) => {
  return client.get(
    `/zones/regions/landuseplan/${regions.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanByDistricts = async (districts: string[]) => {
  return client.get(
    `/zones/districts/landuseplan/${districts.map(encodeURIComponent)}`,
  )
}

const fetchFutureLandUsePlan = async (cityNames: string[]) => {
  return client.get(
    `/zones/futurelanduseplan/${cityNames.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanAssignement = async (cityNames: string[]) => {
  return client.get(
    `/zones/landuseassignement/${cityNames.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanAssignementByMunicipalities = async (
  municipalitiesIds: string[],
) => {
  return client.get(
    `/zones/municipalities/landuseassignement/${municipalitiesIds.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanAssignementByDistricts = async (
  districtsIds: string[],
) => {
  return client.get(
    `/zones/districts/landuseassignement/${districtsIds.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanAssignementByRegions = async (regionsIds: string[]) => {
  return client.get(
    `/zones/regions/landuseassignement/${regionsIds.map(encodeURIComponent)}`,
  )
}

const fetchLandUsePlanAssignementByNpa = async (npaIds: string[]) => {
  return client.get(
    `/zones/npa/landuseassignement/${npaIds.map(encodeURIComponent)}`,
  )
}

const fetchFutureLandUsePlanAssignement = async (cityNames: string[]) => {
  return client.get(
    `/zones/futurelanduseassignement/${cityNames.map(encodeURIComponent)}`,
  )
}

const fetchPrincipalTypeByDistrict = async (districtNames: string[]) => {
  return client.get(
    `/zones/primary/district/${districtNames.map(encodeURIComponent)}`,
  )
}

const fetchPrincipalTypeByRegion = async (regionNames: string[]) => {
  return client.get(
    `/zones/primary/region/${regionNames.map(encodeURIComponent)}`,
  )
}

const fetchPrincipalTypeByNpa = async (npaIds: string[]) => {
  return client.get(`/zones/primary/npa/${npaIds.map(encodeURIComponent)}`)
}

const fetchPrincipalTypeByMunicipality = async (municipalityIds: string[]) => {
  return client.get(
    `/zones/primary/municipality/${municipalityIds.map(encodeURIComponent)}`,
  )
}

const landuseplanService = {
  fetchPrincipalType,
  fetchLandUsePlan,
  fetchFutureLandUsePlan,
  fetchLandUsePlanAssignement,
  fetchFutureLandUsePlanAssignement,
  fetchPrincipalTypeByDistrict,
  fetchPrincipalTypeByRegion,
  fetchPrincipalTypeByNpa,
  fetchLandUsePlanByNpa,
  fetchLandUsePlanAssignementByNpa,
  fetchLandUsePlanByMunicipalities,
  fetchLandUsePlanByRegions,
  fetchLandUsePlanByDistricts,
  fetchLandUsePlanAssignementByMunicipalities,
  fetchLandUsePlanAssignementByDistricts,
  fetchLandUsePlanAssignementByRegions,
  fetchPrincipalTypeByMunicipality,
}

export default landuseplanService
