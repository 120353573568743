import { Map, useRouter } from '@popety_io/popety-io-lib'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  sunDateSelector,
  showSunExposureSelector,
  updateSunlightOptions,
} from '../../redux'
import SunExposureTooltip from './SunExposureTooltip'
import { getSunTimes } from './SunTime.util'

type SunExposureProps = {
  map: Map
  shadeMap: any
}

const SunExposure = ({ shadeMap, map }: SunExposureProps) => {
  const { query } = useRouter()
  const sunDate = useSelector(sunDateSelector)
  const showSunExposure = useSelector(showSunExposureSelector)

  const dispatch = useDispatch()

  const isSunlightTab = query.tab === 'sunlight' || query.subTab === 'sunlight'

  useEffect(() => {
    if (!isSunlightTab) return

    const center = map.getCenter()

    const sunlightTimes = getSunTimes({ sunDate, center })

    const sunlightSummerTimes = getSunTimes({
      sunDate,
      center,
      month: 5,
      day: 21,
    })

    const sunlightWinterTimes = getSunTimes({
      sunDate,
      center,
      month: 11,
      day: 21,
    })

    dispatch(
      updateSunlightOptions({
        sunlightTimes,
        sunlightWinterTimes,
        sunlightSummerTimes,
      }),
    )

    shadeMap?.setSunExposure(showSunExposure, {
      startDate: sunlightTimes?.sunrise,
      endDate: sunlightTimes?.sunset,
    })
  }, [sunDate, showSunExposure])

  return <SunExposureTooltip map={map} shadeMap={shadeMap} />
}

export default SunExposure
