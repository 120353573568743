/* eslint-disable max-lines */
const isValid = (value: string) =>
  value !== null && value !== undefined && value !== ''

const getCalculatedValues = (
  editableData: Record<string, any>,
  std: number,
  landInfo: Record<string, any>,
) => {
  const floors = isValid(editableData.floors)
    ? editableData.floors
    : Math.round(landInfo.authorizedFloorNumberMax)
  const buildingGroundArea = isValid(editableData.buildingGroundArea)
    ? editableData.buildingGroundArea
    : Math.round(landInfo.area * landInfo.authorizedCos)
  const unitNb = isValid(editableData.unitNb)
    ? editableData.unitNb
    : Math.round(landInfo.authorizedUnitNumberMax)

  const cus = (buildingGroundArea * floors) / std

  const floorsArea = buildingGroundArea * floors

  const livingAreaPerUnit =
    (floorsArea * (1 - editableData.walls / 100)) / unitNb

  return {
    cos: parseFloat((buildingGroundArea / std).toFixed(2)),
    cus: parseFloat(cus.toFixed(2)),
    livingWithWallsArea: buildingGroundArea * floors,
    livingArea: Math.round(floorsArea * (1 - editableData.walls / 100)),
    livingAreaPerUnit: Math.round(livingAreaPerUnit),
  }
}

const computeLandCost = (analysis: Record<string, any>) => {
  const data = analysis.analysis_data
  const bareLandPrice = Math.round(data.landArea * data.currentLandPrice)

  const constructedBuildingPrice = Math.round(
    data.currentLivingArea * data.currentBuildingPrice,
  )

  const landPrice = bareLandPrice + constructedBuildingPrice

  const notaryFee =
    data?.notaryFee <= 0.09 ? data.notaryFee : (data.notaryFee / 100).toFixed(3)

  const transfertRight =
    data?.transfertRight <= 0.09
      ? data.transfertRight
      : (data.transfertRight / 100).toFixed(3)

  const notaryFees = Math.round(landPrice * notaryFee)
  const transfertRights = Math.round(landPrice * transfertRight)

  const otherLandCost = Math.round(
    notaryFees +
      transfertRights +
      data.cfcZeroAdditionalCodes.reduce(
        (acc: any, code: any) => acc + code.value,
        0,
      ),
  )

  const landCostTotal = Math.round(landPrice + otherLandCost)

  return {
    landCostTotal,
    bareLandPrice,
    constructedBuildingPrice,
    landPrice,
    transfertRights,
    otherLandCost,
    notaryFees,
  }
}

const computePreliminaryCost = (analysis: Record<string, any>) => {
  const data = analysis.analysis_data
  const preparationCost = Math.round(data.preparationCost * data.landArea)
  const demolitonCost = Math.round(data.demolitonCost * data.currentLivingArea)

  const otherPreliminaryCost = Math.round(
    Math.round(
      data.cfcOneAdditionalCodes.reduce(
        (acc: any, code: any) => acc + code.value,
        0,
      ),
    ),
  )
  const preliminaryCostTotal = Math.round(
    preparationCost +
      demolitonCost +
      data.cfcOneAdditionalCodes.reduce(
        (acc: number, code: any) => acc + code.value,
        0,
      ),
  )

  return {
    preparationCost,
    demolitonCost,
    otherPreliminaryCost,
    preliminaryCostTotal,
  }
}

const computeConstructionCost = (analysis: Record<string, any>) => {
  const data = analysis.analysis_data

  const buildingVolume = Math.round(
    data.livingWithWallsArea * data.correlativeHeight,
  )

  const constructionCost = Math.round(data.constructionCost * buildingVolume)
  const landscapingCost = Math.round(data.landscapingCost * data.landArea)

  const constructionMiscellaneousCost = Math.round(
    (constructionCost +
      landscapingCost +
      data.cfcTwoAdditionalCodes.reduce(
        (acc: number, code: any) => acc + code.value,
        0,
      )) *
      data.othersAdditional,
  )

  const otherConstructionCost = Math.round(
    Math.round(
      data.cfcTwoAdditionalCodes.reduce(
        (acc: number, code: any) => acc + code.value,
        0,
      ),
    ),
  )

  const constructionCostTotal = Math.round(
    constructionCost +
      landscapingCost +
      constructionMiscellaneousCost +
      data.cfcTwoAdditionalCodes.reduce(
        (acc: any, code: any) => acc + code.value,
        0,
      ),
  )

  return {
    constructionCost,
    buildingVolume,
    landscapingCost,
    otherConstructionCost,
    constructionCostTotal,
    constructionMiscellaneousCost,
  }
}

const computeAdditionalCost = (
  analysis: Record<string, any>,
  totalCostNoAdditional: any,
) => {
  const data = analysis.analysis_data

  const authorisationAndTaxes = Math.round(
    totalCostNoAdditional * data.authorisationAndTaxe,
  )

  const interimInterest = Math.round(
    totalCostNoAdditional *
      (1 - data.ownFundsPercentage / 100) *
      (data.interestRate / 100 / 12) *
      data.constructionTime,
  )

  const managementFees = Math.round(totalCostNoAdditional * data.managementFee)

  const othersMiscellaneousCost = Math.round(
    (totalCostNoAdditional +
      data.cfcFiveAdditionalCodes.reduce(
        (acc: number, code: any) => acc + code.value,
        0,
      )) *
      data.othersAdditional,
  )

  const othersAdditionalCostTotal = Math.round(
    othersMiscellaneousCost +
      data.cfcFiveAdditionalCodes.reduce(
        (acc: number, code: any) => acc + code.value,
        0,
      ),
  )

  const additionalCostTotal = Math.round(
    authorisationAndTaxes +
      interimInterest +
      managementFees +
      othersAdditionalCostTotal,
  )

  const ownCapital = totalCostNoAdditional * (1 - data.ownFundsPercentage / 100)

  return {
    additionalCostTotal,
    authorisationAndTaxes,
    othersAdditionalCostTotal,
    othersMiscellaneousCost,
    managementFees,
    interimInterest,
    ownCapital,
  }
}

export {
  getCalculatedValues,
  computeLandCost,
  computePreliminaryCost,
  computeConstructionCost,
  computeAdditionalCost,
}
