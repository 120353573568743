/* eslint-disable max-lines */
import { parseGeoJSON } from '@popety_io/popety-io-lib'
import * as turf from '@turf/turf'

const getGeoLayers = (
  land: Record<string, any>,
  buildings: Record<string, any>[],
  displayBuildings: boolean,
) => {
  if (!land?.geo_polygon) return []

  const plot = {
    type: 'FeatureCollection',
    features: [
      {
        geometry: land.geo_polygon?.geometry || land.geo_polygon,
        type: 'Feature',
      },
    ],
  } as any

  const options = { tolerance: 0.00001, highQuality: false }
  const simplifiedLand = turf.simplify(plot, options)

  const simplifiedBuildings =
    buildings
      ?.map(
        (b) =>
          b.geo_polygon && turf.simplify(parseGeoJSON(b.geo_polygon), options),
      )
      .filter(Boolean) || []

  const polygonLand: any[] = Array.isArray(
    simplifiedLand?.features[0]?.geometry?.coordinates[0][0]?.[0],
  )
    ? simplifiedLand?.features[0]?.geometry?.coordinates?.[0]?.[0]
    : simplifiedLand?.features[0]?.geometry?.coordinates?.[0]

  const pointsLength = polygonLand
    .map((point, index, array) => {
      if (index !== 0) {
        const distance = Math.round(
          // eslint-disable-next-line no-unsafe-optional-chaining
          turf.distance(turf.point(array?.[index - 1]), turf.point(point)) *
            1000,
        )

        const center = turf.center(turf.points([array?.[index - 1], point]))
          ?.geometry?.coordinates

        return {
          type: 'Feature',
          geometry: { type: 'Point', coordinates: [center[0], center[1]] },
          properties: { title: `${distance}m` },
        }
      }

      return null
    })
    .filter(Boolean)

  const pointsBuildings = simplifiedBuildings
    ?.map((building) =>
      building?.coordinates?.[0]?.[0]
        .map((point: any, index: number, array: any[]) => {
          if (index !== 0) {
            const distance = Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              turf.distance(turf.point(array?.[index - 1]), turf.point(point)) *
                1000,
            )

            const center = turf.center(turf.points([array?.[index - 1], point]))
              ?.geometry?.coordinates

            return {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [center[0], center[1]],
              },
              properties: { title: `${distance}m` },
            }
          }

          return null
        })
        .filter(Boolean),
    )
    .flat()

  const pointsGeo = {
    type: 'FeatureCollection',
    features: pointsLength,
  }

  const buildingPointGeo = {
    type: 'FeatureCollection',
    features: displayBuildings ? pointsBuildings : [],
  }

  const buildingGeo = {
    type: 'FeatureCollection',
    features: displayBuildings
      ? simplifiedBuildings?.map((building) => ({
          geometry: building,
          properties: {
            stroke: 'rgba(0, 0, 0, 0.1)',
            'stroke-width': 2,
            'stroke-opacity': 1,
            fill: 'rgba(0, 0, 0, 0.1)',
          },
          type: 'Feature',
        }))
      : [],
  }

  return [
    {
      id: 'geo-land',
      type: 'geojson',
      data: simplifiedLand,
      layers: [
        {
          id: 'geoLayer',
          type: 'fill',
          paint: {
            'fill-color': 'rgba(0, 173, 230, 0.3)',
            'fill-outline-color': 'rgba(0, 0, 0, 0.3)',
          },
        },
      ],
    },
    {
      id: 'geo-buildings',
      type: 'geojson',
      data: buildingGeo,
      layers: [
        {
          id: 'geoBuildingLayer',
          type: 'fill',
          paint: { 'fill-color': ['get', 'fill'] },
        },
      ],
    },
    {
      id: 'geoPointsBuildings',
      type: 'geojson',
      data: buildingPointGeo,
      layers: [
        {
          id: 'geoPointsBuildingsLayer',
          type: 'symbol',
          layout: {
            // get the title name from the source's "title" property
            'text-field': ['get', 'title'],
            'text-size': 11,
            'text-font': ['Roboto Regular'],
            'text-anchor': 'center',
          },
        },
      ],
    },
    {
      id: 'geoPoints',
      type: 'geojson',
      data: pointsGeo,
      layers: [
        {
          id: 'geoPointsLayer',
          type: 'symbol',
          layout: {
            // get the title name from the source's "title" property
            'text-field': ['get', 'title'],
            'text-size': 11,
            'text-font': ['Roboto Regular'],
            'text-anchor': 'center',
          },
        },
      ],
    },
  ]
}

export { getGeoLayers }
