import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import EstimateLandSummaryService from './landSummaryService'

export type LandSummaryState = {
  loading: boolean
  lands: any
}

const initialState: LandSummaryState = {
  loading: false,
  lands: [],
}

export const getEstimateLandSummary = createAsyncThunk(
  'estimateLandSummary/getEstimateLandSummary',
  EstimateLandSummaryService.getEstimateLandSummary,
)

const estimateLandSummary = createSlice({
  name: 'estimateLandSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEstimateLandSummary.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEstimateLandSummary.fulfilled, (state, action) => {
      const data = action.payload || {}

      state.lands = data
      state.loading = false
    })
    builder.addCase(getEstimateLandSummary.rejected, (state) => {
      state.loading = false
    })
  },
})

export const estimateLandSummaryReducer = {
  estimateLandSummary: estimateLandSummary.reducer,
}
