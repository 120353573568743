/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { Grid, Box, Typography, Paper } from '@mui/material'
import { useI18n, format, ellipsis } from '@popety_io/popety-io-lib'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ApartmentIcon from '@mui/icons-material/Apartment'
import OkButton from '@mui/icons-material/CheckCircle'
import NotOkButton from '@mui/icons-material/RemoveCircle'
import LandRadar from '../../../../components/LandRadar'
import { TagChips } from '../../../../components/Tag'
import LandHistoryMenu from './LandHistoryMenu'
import { handleOpenLink } from './utils'

const LandHistoryContainer = ({ element }: any) => {
  const { t } = useI18n()

  const handleOpen = () => {
    handleOpenLink({
      id: element?.land_id || element?.id,
    })
  }

  const options = [
    {
      label: t('common.search.details.area'),
      name: 'area',
      value: element.official_area || element?.area,
      endAdornment: 'm²',
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('land.devScore.buildingNb'),
      name: 'building_nb',
      value: element.building_nb || '-',
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
    {
      label: t('land.underExploited.buildingArea'),
      name: 'buildingArea',
      value: element?.building_area ? (
        <OkButton
          style={{ color: 'rgb(142, 209, 165)' }}
          fontSize="small"
          className="iconOk"
          sx={{
            maxHeight: '20px',
          }}
        />
      ) : (
        <NotOkButton color="error" fontSize="small" className="iconNotOk" />
      ),
      icon: (
        <ApartmentIcon
          sx={{
            fontSize: '1.4rem',
          }}
        />
      ),
    },
  ]

  return (
    <Grid
      container
      key={element.id}
      display="flex"
      justifyContent="space-between"
      component={Paper}
      elevation={2}
      sx={{
        opacity: element?.archive ? 0.33 : 1,
        paddingTop: 1.8,
        paddingBottom: 1.8,
        paddingLeft: 1.8,
        paddingRight: 1,
        cursor: 'pointer',
      }}
      onClick={handleOpen}
    >
      <Grid item display="flex" alignItems="center" gap={1} xs={12} md={6}>
        <Box
          sx={{
            minWidth: 100,
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <LandRadar land={element} archive={element?.archived} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box
            style={{ textTransform: 'uppercase' }}
            sx={{
              fontWeight: 500,
              fontSize: '1.1rem',
            }}
            title={t('common.estimateion')}
            color={element?.archived ? 'text.disabled' : 'text.primary'}
          >
            {ellipsis(
              `${element.municipality_name} ${element.code_number}`,
              40,
            )}
          </Box>
          <Box
            sx={{
              minHeight: '15px',
            }}
          >
            <TagChips landIds={element.land_id || element.id} />
          </Box>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            sx={{
              marginLeft: '-4px',
              marginTop: '4px',
            }}
            color={element?.archived ? 'text.disabled' : 'text.primary'}
          >
            <LocationOnIcon fontSize="small" />
            {element?.address_full || element.address_postal_code_name}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {options.map((option, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
            color={element?.archived ? 'text.disabled' : 'text.primary'}
          >
            <Grid container spacing={0}>
              <Grid item sm={7} spacing={0}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    color: '#969696',
                    fontSize: '0.9rem',
                    justifyContent: 'start',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {option.icon}
                  <Typography
                    fontWeight="500"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {option.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                spacing={0}
                sm={5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  fontWeight="500"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    maxHeight: '20px',
                  }}
                >
                  {option.value}
                  {option.endAdornment &&
                    option.value !== '-' &&
                    `${option.endAdornment}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={1}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexDirection="column"
      >
        <LandHistoryMenu
          id={element?.land_id || element?.id}
          archive={element?.archived}
        />
        <Box component="h5" fontWeight={400}>
          {(element?.date || element?.timestamp) &&
            format(element?.date || element?.timestamp, 'P')}
        </Box>
      </Grid>
    </Grid>
  )
}

export default LandHistoryContainer
