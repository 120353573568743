/* eslint-disable max-lines */
import Box from '@mui/material/Box'
import {
  useResize,
  useRouter,
  useI18n,
  formatPrice,
} from '@popety_io/popety-io-lib'
import { useSelector } from 'react-redux'
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts'
import {
  listingAggsPurchaseSelector,
  listingAggsRentSelector,
} from '../../redux'
import CustomizedXQuartertoYearLabel from './CustomizedXQuartertoYearLabel'
import { mutateRentData, mutatePurchaseData } from './utils'

const ListingStats = () => {
  const { t } = useI18n()
  const { ref, width = 530 } = useResize()
  const listingAggsPurchase = useSelector(listingAggsPurchaseSelector)
  const listingAggsRent = useSelector(listingAggsRentSelector)
  const pricePerSquareMeter = t('common.pricem2')
  const { query } = useRouter()

  const {
    dataRentAllYearlyHistory,
    dataRentAllPrice,
    dataRentAllPriceEvolution,
  } = mutateRentData(listingAggsRent, pricePerSquareMeter)

  const { dataBuyAllYearlyHistory, dataBuyAllPrice, dataBuyAllPriceEvolution } =
    mutatePurchaseData(listingAggsPurchase, pricePerSquareMeter)

  return (
    <Box ref={ref} ml="-16px" mt={3} width="100%" height="100%" minHeight={500}>
      {(query.dealType === 'purchase' || !query.dealType) && (
        <Box
          sx={{
            backgroundColor: '#fff',
            width,
            padding: '0px 0px 0px 0px',
            fontWeight: 400,
            fontFamily: 'Roboto',
            color: 'rgb(4, 26, 37)',
            marginRight: 12,
            marginLeft: 5,
          }}
        >
          <span style={{ fontSize: '1.1rem' }}>
            {t('common.price')} m<sup>2</sup>
          </span>
          <div style={{ fontSize: '1.2rem', marginTop: 10, marginBottom: 15 }}>
            <span style={{ fontSize: '1.5rem', color: '#00ade6' }}>
              {formatPrice(dataBuyAllPrice)}
            </span>
            <span
              style={{
                fontSize: '0.9rem',
                paddingLeft: 10,
                color: 'rgba(127, 188, 83, 0.7)',
              }}
            >
              {' '}
              +{dataBuyAllPriceEvolution.toFixed(2)}%
            </span>
            <span
              style={{
                fontSize: '0.7rem',
                paddingLeft: 5,
                color: 'rgb(207, 207, 207)',
              }}
            >
              / {t('common.12 dernier mois')}
            </span>
          </div>
          <AreaChart
            width={width}
            height={140}
            data={dataBuyAllYearlyHistory}
            margin={{
              top: 5,
              right: 40,
              left: 0,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00ade6" stopOpacity={0.15} />
                <stop offset="55%" stopColor="#00ade6" stopOpacity={0.08} />
                <stop offset="90%" stopColor="#00ade6" stopOpacity={0.03} />
                <stop offset="100%" stopColor="#00ade6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey={pricePerSquareMeter}
              stroke="#00ade6"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <XAxis
              style={{ fontSize: '0.7rem' }}
              dataKey="date"
              axisLine={false}
              interval="preserveEnd"
              tickLine={false}
              type="category"
              domain={['dataMin', 'dataMax']}
              tick={<CustomizedXQuartertoYearLabel />}
              tickFormatter={(value) => {
                return value.replace(/\s/g, '')
              }}
            />
            <YAxis
              domain={['dataMin', 'dataMax']}
              hide
              axisLine={false}
              tickLine={false}
            />
            <Tooltip />
          </AreaChart>
        </Box>
      )}
      {(query.dealType === 'rent' || !query.dealType) && (
        <Box
          sx={{
            backgroundColor: '#fff',
            width,
            padding: '0px 0px 0px 0px',
            fontWeight: 400,
            fontFamily: 'Roboto',
            color: 'rgb(4, 26, 37)',
            marginRight: 12,
            marginLeft: 5,
          }}
        >
          <span style={{ fontSize: '1.1rem' }}>
            {t('land.listing.Loyer')} m<sup>2</sup>/{t('land.listing.an')}
          </span>
          <div style={{ fontSize: '1.2rem', marginTop: 10, marginBottom: 15 }}>
            <span style={{ fontSize: '1.5rem', color: '#00ade6' }}>
              {formatPrice(dataRentAllPrice)}
            </span>
            <span
              style={{
                fontSize: '0.9rem',
                paddingLeft: 10,
                color: 'rgba(127, 188, 83, 0.7)',
              }}
            >
              {' '}
              +{dataRentAllPriceEvolution.toFixed(2)}%
            </span>
            <span
              style={{
                fontSize: '0.7rem',
                paddingLeft: 5,
                color: 'rgb(207, 207, 207)',
              }}
            >
              / {t('common.12 dernier mois')}
            </span>
          </div>
          <AreaChart
            width={width}
            height={140}
            data={dataRentAllYearlyHistory}
            margin={{
              top: 5,
              right: 40,
              left: 0,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00ade6" stopOpacity={0.15} />
                <stop offset="55%" stopColor="#00ade6" stopOpacity={0.08} />
                <stop offset="90%" stopColor="#00ade6" stopOpacity={0.03} />
                <stop offset="100%" stopColor="#00ade6" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey={pricePerSquareMeter}
              stroke="#00ade6"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
            <XAxis
              style={{ fontSize: '0.7rem' }}
              dataKey="date"
              axisLine={false}
              interval="preserveEnd"
              tickLine={false}
              type="category"
              domain={['dataMin', 'dataMax']}
              tick={<CustomizedXQuartertoYearLabel />}
            />
            <YAxis
              domain={['dataMin', 'dataMax']}
              hide
              axisLine={false}
              tickLine={false}
            />
            <Tooltip />
          </AreaChart>
        </Box>
      )}
    </Box>
  )
}

export default ListingStats
