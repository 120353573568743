// import { popetyIoClassLegend } from '../../../../utils/thematic/glossary/classificationOptions'

export type EstimateHistoryMenuProps = {
  dwellingId?: string
  id?: string
  geo_center?: Record<string, any>
  area?: number
  type?: string
  rooms?: number
  constructionYear?: number
  condition?: string
  renovationYear?: number
  hash?: string
  expirationDate?: string
  archive?: boolean
  baths?: number
}

const buildingColor = (classification: string) => {
  switch (classification) {
    case 'Retail':
      return '#D3B39C'
    case 'Residential':
      return '#F9D518'
    case 'Public':
      return '#8C8C8C'
    case 'Other':
      return '#CCCCCC'
    case 'Offices':
      return '#9ecae1'
    case 'Not classified':
      return '#CCCCCC'
    case 'Mixed_2':
      return '#F3733C'
    case 'Mixed_1':
      return '#F3A83C'
    case 'Industrial':
      return '#C39EF9'
    case 'Garage':
      return '#bbbbbb'
    default:
      return '#F3733C'
  }
}

export const getMultipleLayers = (element: Record<string, any>) => {
  // const lands = element?.value?.lands || element?.default_value?.lands
  const building =
    element?.value?.geo_polygon || element?.default_value?.estimate?.geo_polygon
  const estimate = element?.value?.estimate || element?.default_value?.estimate

  if (!building) return []

  // const landPolygon = lands.map((land: any) => ({
  //   geometry: land?.geo_polygon,
  //   type: 'Feature',
  //   properties: {
  //     stroke: '#555555',
  //     'stroke-width': 2,
  //     'stroke-opacity': 1,
  //     fill: '#00ADE6',
  //     'fill-opacity': 0.5,
  //   },
  // }))

  const buildingPolygon = building && {
    geometry: building,
    type: 'Feature',
    properties: {
      stroke: '#000',
      'stroke-width': 2,
      'stroke-opacity': 1,
      fill: buildingColor(estimate.popety_classification),
      'fill-opacity': 0.4,
      height: estimate?.height || 3,
    },
  }

  const GeoJSON = {
    type: 'FeatureCollection',
    features: [buildingPolygon].filter(Boolean),
  }

  return GeoJSON
}

const handleOpenLink = ({
  dwellingId,
  id,
  geo_center,
  area,
  type,
  rooms,
  constructionYear,
  condition,
  renovationYear,
  baths,
}: EstimateHistoryMenuProps) => {
  let url = '?tab=summary'

  if (dwellingId) {
    url += `&dwellingId=${dwellingId}`
  }

  if (id) {
    url += `&estimateId=${id}`
  }

  if (geo_center) {
    url += `&geoCenter=${geo_center?.lon},${geo_center?.lat}`
  }

  if (area) {
    url += `&livingArea=${area}`
  }

  if (type) {
    const propertyType = type.charAt(0).toUpperCase() + type.slice(1)

    url += `&propertyCategory=${propertyType}`
    url += `&propertyType=${propertyType}`
  }

  if (rooms) {
    url += `&roomsNb=${rooms}`
  }

  if (constructionYear) {
    url += `&constructionYear=${constructionYear}`
  }

  if (condition) {
    url += `&condition=${condition}`
  }

  if (renovationYear) {
    url += `&renovationYear=${renovationYear}`
  }

  if (baths) {
    url += `&numberOfBathrooms=${baths}`
  }

  window.open(url, '_blank')
}

export { handleOpenLink }
