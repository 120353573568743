/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
const getIndexData = (t: any) => {
  return {
    items: [
      {
        value: 'A',
        text: t('common.air_quality_good'),
        color: '#93d1ca',
        max: 20,
      },
      {
        value: 'B',
        text: t('common.air_quality_normal'),
        explainText: t('common.air_quality_normal_description'),
        color: '#86b78a',
        max: 40,
      },
      {
        value: 'C',
        text: t('common.air_quality_moderate'),
        color: '#eaca6f',
        max: 60,
        description: t('common.air_quality_high_description'),
      },
      {
        value: 'D',
        text: t('common.air_quality_high'),
        explainText: t('common.air_quality_high_description'),
        color: '#cd6b5a',
        max: 80,
      },
      {
        value: 'E',
        text: t('common.air_quality_very_high'),
        color: '#9e3e40',
        max: 100,
        description: t('common.air_quality_very_high_description'),
      },
      {
        value: 'F',
        text: t('common.air_quality_extreme'),
        explainText: t('common.air_quality_extreme_description'),
        color: '#5b3671',
        max: Infinity,
      },
    ],
  }
}

export { getIndexData }
