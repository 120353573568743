import { IconButton, Popover } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import { BulletProps, Legend } from '@popety_io/popety-io-lib'
import { memo, useState } from 'react'

export type ScoreInfoLegendProps = {
  data: BulletProps['data']
  title?: BulletProps['title']
}

const ScoreInfoLegend = ({ data, title }: ScoreInfoLegendProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'legend-popper' : undefined

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <InfoIcon sx={{ color: '#969696', fontSize: '0.969rem' }} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Legend
          title={title}
          data={data}
          sx={{ padding: 1, h6: { fontSize: '0.95rem' } }}
        />
      </Popover>
    </>
  )
}

export default memo(ScoreInfoLegend)
