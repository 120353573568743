import { Box, Typography, Switch } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  estimateEditedSelector,
  estimateIdSelector,
  saveEditedEstimate,
} from '../../../redux'

type EstimateFilesHeaderProps = {
  active: boolean
  handleIsActive: (checked: boolean) => void
}

const EstimateFilesHeader = ({
  active,
  handleIsActive,
}: EstimateFilesHeaderProps) => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const estimateId = useSelector(estimateIdSelector)
  const estimateEdited: any = useSelector(estimateEditedSelector)

  const handleActive = (checked: boolean) => (name: string) => {
    handleIsActive(checked)

    dispatch(
      saveEditedEstimate({
        id: estimateId,
        body: { ...estimateEdited, [name]: checked },
      }) as any,
    )
  }

  return (
    <Box
      id="estimate-files-header"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: '4px',
          fontSize: '1.1rem',
          textTransform: 'uppercase',
        }}
      >
        {t('common.files')}
      </Typography>
      <Switch
        checked={active}
        onChange={(e, checked) => handleActive(checked)('isFiles')}
        size="small"
        //   disabled={isLoading}
      />
    </Box>
  )
}

export default EstimateFilesHeader
