import { Suspense, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import {
  fetchOnce,
  ProgressIndicator,
  useRouter,
  useMediaQuery,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import { getLandDetailsMenuData } from './components/AccountMenu'
import { LandDetailsRoot } from './Account.style'
import {
  getCurrentUserDetails,
  getFinancialDefault,
  userDetailsSelector,
} from './redux'

const pages = getLandDetailsMenuData()

const Account = () => {
  const { query } = useRouter()
  const user: any = useSelector(userDetailsSelector)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const dispatch = useDispatch<any>()

  const content = useMemo(() => {
    return pages.find((p, i) => (!query.tab ? i === 0 : p.key === query.tab))
      ?.page
  }, [query.tab])

  useEffect(() => {
    fetchOnce('getCurrentUserDetails', user?.id, () => {
      dispatch(getCurrentUserDetails())
      dispatch(getFinancialDefault())
    })
  }, [user?.id])

  return (
    <LandDetailsRoot
      bgcolor={`${query.tab === 'tags' ? '' : 'background.paper'}`}
      sx={{
        mx: query.tab === 'tags' || !isDesktop ? 0 : 3,
        border: query.tab === 'tags' ? '' : '1px solid #ebebeb',
      }}
    >
      <Suspense fallback={<ProgressIndicator circular />}>
        <Box width="100%">{content}</Box>
      </Suspense>
    </LandDetailsRoot>
  )
}

export default Account
