import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import {
  OverlayLoader,
  useRouter,
  fetchOnce,
  Driver,
  useI18n,
} from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import {
  getFaos,
  faoLoadingSelector,
  countFaos,
  getSelectedFaos,
} from '../redux'
import { getFaoFilters } from '../../../utils'
import { userSelector } from '../../Login'
import { updateTutorials } from '../../Account'
import getDriverInstructions from './driverInstructions'

const FaoTable = lazy(() => import('../FaoTable'))
const FaoMap = lazy(() => import('../FaoMap'))
const FaoAnalytics = () => null

const content = {
  map: FaoMap,
  list: FaoTable,
  analytic: FaoAnalytics,
} as Record<string, any>

const FaoHistory = () => {
  const { query, location } = useRouter()

  const { t } = useI18n()

  const [displayDriver, setDisplayDriver] = useState(false)
  const user = useSelector(userSelector)

  const dispatch = useDispatch<any>()
  const loading = useSelector(faoLoadingSelector)

  const filters = getFaoFilters(query)

  const Content = useMemo(
    () => content[filters.view] || FaoTable,
    [filters.view],
  )

  useEffect(() => {
    const {
      page: _,
      size: __,
      view: ___,
      orderBy: ____,
      ...countFilters
    } = filters

    fetchOnce('fao', filters, () => {
      dispatch(getFaos(filters))
    })
    fetchOnce('countFao', countFilters, () => {
      dispatch(countFaos(filters))
    })
  }, [location.search])

  useEffect(() => {
    if (query.selected) {
      dispatch(
        getSelectedFaos({
          value: `ID:${query.selected.split('-').join(',')}`,
        }),
      )
    }
  }, [query?.selected])

  useEffect(() => {
    const hasDisplayedInstructions = localStorage.getItem(
      'hasDisplayedFaoHistoryDriver',
    )

    if (!hasDisplayedInstructions && !user?.isCreateTimeThanThreeMonthAgo) {
      setDisplayDriver(true)
      const payload = { hasDisplayedFaoHistoryDriver: true }

      fetchOnce('updateTutorials', JSON.stringify(payload), () => {
        dispatch(updateTutorials(payload))
      })
    }
  }, [])

  return (
    <Suspense fallback={<OverlayLoader loading />}>
      <Content />
      <OverlayLoader loading={filters.view === 'map' ? false : loading} />
      <Driver instructions={getDriverInstructions(t)} display={displayDriver} />
    </Suspense>
  )
}

export default FaoHistory
