import { useState } from 'react'
import { Button, useI18n } from '@popety_io/popety-io-lib'

import { MixOfUnits } from '../components/MixOfUnits'
import { DesignActionButton, DesignStepThreeRoot } from './Design.style'

interface IProps {
  handleContinue: (e: any) => void
  handleBack: () => void
  loading: boolean
}
const DesignStepThree = ({ handleBack, handleContinue, loading }: IProps) => {
  const { t } = useI18n()

  const [dwellingsMix, setDwellingsMix] = useState({
    dwellings_mix: { mix_nb: 0, mix_perc_total: 0, mix: [] },
  })

  const handleContinueClick = () => {
    handleContinue({
      ...dwellingsMix,
    })
  }

  return (
    <DesignStepThreeRoot>
      <MixOfUnits onChange={(e: any) => setDwellingsMix(e)} />
      <DesignActionButton>
        <Button
          onClick={handleContinueClick}
          variant="contained"
          disabled={loading}
        >
          GENERATE
        </Button>
        <Button onClick={handleBack}>{t('land.design.back')}</Button>
      </DesignActionButton>
    </DesignStepThreeRoot>
  )
}

export default DesignStepThree
