import { Map } from '@popety_io/popety-io-lib'
import { API_BASE_URL } from '../../../../../../services'

const renderMethod = 'aggs'

const sourceName = 'es_mvt_noise'
const fillStyle = 'layer_fill_noise'

const removeEnvironmentTiles = ({ map }: { map: Map }) => {
  if (map.getSource(sourceName)) {
    map.removeLayer(fillStyle)
    map.removeSource(sourceName)
  }
}

const getEnvironmentTiles = ({
  map,
  indexName = 'noise-road-day-v1',
  geometryFieldName = 'geo_center',
}: {
  map: Map
  indexName?: string
  geometryFieldName?: string
}) => {
  try {
    if (map.getSource(sourceName)) {
      map.removeLayer(fillStyle)
      map.removeSource(sourceName)
    }

    map.addSource(sourceName, {
      type: 'vector',
      tiles: [
        `${API_BASE_URL}/environment/noise/tiles?index=${indexName}&geometry=${geometryFieldName}&renderMethod=${renderMethod}&x={x}&y={y}&z={z}`,
      ],
      minzoom: 0,
      maxzoom: 24,
    })

    map.addLayer({
      id: fillStyle,
      type: 'fill',
      source: sourceName,
      'source-layer': renderMethod,
      paint: {
        'fill-outline-color': 'transparent',
        'fill-opacity': 0.5,
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'avg_db.value'],
          0,
          '#E0F3F8',
          10,
          '#E0F3F8',
          20,
          '#E0F3F8',
          30,
          '#ABD9EA',
          40,
          '#C5D564',
          50,
          '#FCD923',
          60,
          '#FCC423',
          70,
          '#FC5E22',
          80,
          '#F14C11',
        ],
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export { removeEnvironmentTiles, getEnvironmentTiles }
