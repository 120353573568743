import { ApiClient, getUserId } from '../../../../services'

const client = new ApiClient()

const getAnalyzedBuildingsByUser = async (input: {
  userId: string
  search?: string
}) => {
  const { userId, search = '' } = input

  return client.get(`/dashboard/${userId}/analyzed/buildings${search}`)
}

const getAnalyzedBuildings = (search = '') => {
  return getAnalyzedBuildingsByUser({ userId: getUserId(), search })
}

const getCompanyUsers = async () => {
  return client.get(`/dashboard/users`)
}

const addAnalyzedBuilding = async ({
  buildingId,
}: {
  buildingId: string | number
}) => {
  return client.post(
    `/dashboard/analyzed/${getUserId()}/buildings/${buildingId}`,
  )
}

const analizedBuildingService = {
  getAnalyzedBuildings,
  getAnalyzedBuildingsByUser,
  getCompanyUsers,
  addAnalyzedBuilding,
}

export default analizedBuildingService
