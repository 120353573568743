import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

export const DesignStepOneRoot = styled(Box)`
  display: flex;
  gap: 24px;
  padding: 24px;
`

export const ButtonDesignStepOne = styled(Button)`
  padding: 12px 0 5px;
  border: 1px solid rgba(150, 150, 150, 1);
  border-radius: 8px;
  width: 55px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #969696;
  background: transparent;
  justify-content: start;
  &:hover {
    background: transparent;
    border-color: #00ade6;
    color: #00ade6;
  }
  span {
    padding: 0;
    flex: 1;
    text-align: center;
    font-size: 0.692rem;
    text-transform: none;
  }
`
