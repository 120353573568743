import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FavoriteIcon from '@mui/icons-material/Star'
import UnfavoriteIcon from '@mui/icons-material/StarBorder'

import { fetchOnce, useI18n } from '@popety_io/popety-io-lib'
import {
  addFavoriteBuilding,
  removeFavoriteBuilding,
} from '../../pages/BuildingDashboard/redux'
import {
  favoriteBuildingSelector,
  userFavoriteBuildingsSelector,
  addFavoriteBuildingId,
  removeFavoriteBuildingId,
  getFavoriteBuildings,
} from '../../pages/DeveloperMap/redux'
import { isCompanyAdminSelector, sharedSelector } from '../../pages/Login'
import useClearUpEffect from '../../hooks/useClearUpEffect'

export type FavoriteBuildingProps = {
  buildingId: string | string[]
  opacity?: number
  disable?: boolean
}

const useFavoriteBuilding = ({
  buildingId,
  opacity = 0.3,
  disable,
}: FavoriteBuildingProps) => {
  const myFavorites = useSelector(favoriteBuildingSelector)
  const userFavoriteBuildings = useSelector(userFavoriteBuildingsSelector)
  const isCompanyAdmin = useSelector(isCompanyAdminSelector)
  const dispatch = useDispatch<any>()
  const shared = useSelector(sharedSelector)

  let buildingIds = Array.isArray(buildingId) ? buildingId : [buildingId]

  buildingIds = buildingIds.filter(
    (value, index, array) => array.indexOf(value) === index,
  )

  const isFavorite = buildingIds?.every((id) => myFavorites.includes(+id))

  const isFavoriteOwner = buildingIds?.every((id) =>
    userFavoriteBuildings.includes(+id),
  )

  const canDelete = isFavoriteOwner || isCompanyAdmin

  const { t } = useI18n()

  const handleFavoriteToggle = (e: MouseEvent<any>) => {
    e.stopPropagation()

    if (!buildingId) return

    buildingIds.forEach((id) => {
      if (myFavorites.includes(+id)) {
        if (shared?.sharedFavorite && canDelete) {
          dispatch(removeFavoriteBuilding(id))
          dispatch(removeFavoriteBuildingId({ buildingId: id }))
        } else if (!shared?.sharedFavorite) {
          dispatch(removeFavoriteBuilding(id))
          dispatch(removeFavoriteBuildingId({ buildingId: id }))
        }
      } else {
        dispatch(addFavoriteBuilding(id))
        dispatch(addFavoriteBuildingId({ buildingId: id }))
      }
    })
  }

  useClearUpEffect(() => {
    if (!disable) {
      fetchOnce('favoriteBuildings', null, () =>
        dispatch(getFavoriteBuildings() as any),
      )
    }
  }, [disable])

  const title = isFavorite
    ? t('common.favoriteIcon')
    : t('common.unfavoriteIcon')

  const Icon = isFavorite ? (
    <FavoriteIcon
      color={shared?.sharedFavorite && !canDelete ? 'disabled' : 'primary'}
    />
  ) : (
    <UnfavoriteIcon
      sx={{
        opacity,
      }}
    />
  )

  return { title, onClick: handleFavoriteToggle, Icon }
}

export default useFavoriteBuilding
