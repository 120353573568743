import { createSelector } from '@reduxjs/toolkit'

const buildingActionHistorySelector = (state: any) =>
  state.buildingActionHistory.buildingsActionHistory

const buildingActionHistoriesSelector = createSelector(
  buildingActionHistorySelector,
  (h) =>
    Object.values(h).sort(
      (a: any, b: any) =>
        new Date(b.create_timestamp).getTime() -
        new Date(a.create_timestamp).getTime(),
    ),
)

const buildingNotesSelector = createSelector(
  buildingActionHistoriesSelector,
  (h) =>
    h.filter(
      (buildingActionHistory: any) => buildingActionHistory.action === 'note',
    ),
)

export { buildingNotesSelector, buildingActionHistoriesSelector }
