const getDriverInstructions = (t: any) => {
  return {
    animate: true,
    showProgress: true,
    showButtons: ['next', 'previous', 'close'],
    nextBtnText: t('driver.next'),
    prevBtnText: t('driver.previous'),
    steps: [
      {
        popover: {
          title: t('driver.landFao.startTitle'),
          description: t('driver.landFao.startDescription'),
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#tab-tab-0',
        popover: {
          title: t('driver.landFao.firstTabTitle'),
          description: t('driver.landFao.firstTabDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#tab-tab-1',
        popover: {
          title: t('driver.landFao.secondTabTitle'),
          description: t('driver.landFao.secondTabDescription'),
          side: 'bottom',
          align: 'start',
        },
      },
    ],
  }
}

export default getDriverInstructions
