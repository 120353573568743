const previewSelector = (state) => state.developerMapPreview.preview

const previewGeoPolygonSelector = (state) =>
  state.developerMapPreview.preview?.geo_polygon

const previewGeoCenterSelector = (state) =>
  state.developerMapPreview.preview?.geo_center

const groupingSelector = (state) => state.developerMapPreview.grouping

const needFlyToCenterSelector = (state) =>
  state.developerMapPreview.needFlyToCenter

const previousPreviewSelector = (state) =>
  state.developerMapPreview.previousPreview

export {
  previewSelector,
  previewGeoPolygonSelector,
  groupingSelector,
  previewGeoCenterSelector,
  needFlyToCenterSelector,
  previousPreviewSelector,
}
