import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import landSummaryService from './landSummaryService'

export type LandSummaryState = {
  loading: boolean
  lands: any
}

const initialState: LandSummaryState = {
  loading: false,
  lands: [],
}

export const getLandSummary = createAsyncThunk(
  'landSummary/getLandSummary',
  landSummaryService.getLandSummary,
)

const landSummary = createSlice({
  name: 'landSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLandSummary.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLandSummary.fulfilled, (state, action) => {
      const data = action.payload || {}

      state.lands = data
      state.loading = false
    })
    builder.addCase(getLandSummary.rejected, (state) => {
      state.loading = false
    })
  },
})

export const landSummaryReducer = {
  landSummary: landSummary.reducer,
}
