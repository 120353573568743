import { Box, Typography } from '@mui/material'
import { useI18n } from '@popety_io/popety-io-lib'
import { ScaleDecibelsRoot } from './ScaleDecibles.style'
import useScaleDecibelsData from './useScaleDecibelsData'

const ScaleDecibels = () => {
  const { t } = useI18n()

  const data = useScaleDecibelsData(t)

  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          fontSize: '1.1rem',
          textTransform: 'uppercase',
        }}
      >
        {t('estimate.echelle des décibels')}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        {data?.items?.map((item, key) => {
          return (
            <ScaleDecibelsRoot key={`scale_${key}`}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 4,
                  marginBottom: '6px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 4,
                  }}
                >
                  {item.exlainText ? (
                    <Box
                      display="flex"
                      gap={2}
                      alignItems="center"
                      sx={{
                        width: '140px',
                      }}
                    >
                      <span>{item.exlainText}</span>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: '140px',
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 500,
                        backgroundColor: item.color,
                        padding: '8px',
                        width: '50px',
                        textAlign: 'center',
                      }}
                      className={item.exlainText ? 'arrow-container' : ''}
                    >
                      {item.value}
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{ color: '#969696', flex: '1' }}
                  sx={{
                    width: '140px',
                  }}
                >
                  {item.text}
                </Box>
              </Box>
            </ScaleDecibelsRoot>
          )
        })}
      </Box>
    </div>
  )
}

export default ScaleDecibels
