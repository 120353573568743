import { Box, styled } from '@mui/material'

export const ScaleDecibelsRoot = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'bgColor' && prop !== 'pRight' && prop !== 'bgHover',
})<{ bgColor?: string; pRight?: boolean; bgHover?: string }>`
  display: flex;
  flex-direction: ${({ pRight }) => (pRight ? 'row-reverse' : 'row')};

  .tagBody {
    background-color: ${({ bgColor }) => bgColor};
    height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: ${({ pRight }) => (pRight ? 'row-reverse' : 'row')};
    position: relative;
    h1 {
      margin: 0;
      font-size: 1.384rem;
      font-weight: 500;
    }

    .value {
      font-size: 0.769rem;
      display: flex;
      gap: 2px;
    }
    .icon-close {
      position: absolute;
      right: -25px;
    }
  }

  :hover {
    .tagBody {
      background-color: ${({ bgHover }) => bgHover};
    }
    .tagArrow {
      border-left-color: ${({ bgHover }) => bgHover};
      border-right-color: ${({ bgHover }) => bgHover};
    }
  }
`
